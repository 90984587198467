import { Component, Input, OnInit } from '@angular/core';
import * as _ from "lodash";
import { Subject } from "rxjs";
import * as FileSaver from "file-saver";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { BOOKING_API_ENPOINTS, SERIES_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

    @Input() isSubRequest;
    @Input() requestDetails;
    @Input() isSeriesComments;

    public userDetails;
    public comment = '';
    public audlogList = [];
    public commentFile = '';
    public commentList = [];
    public commentCategory = '';
    public commentFileData: File;
    public actionPendingList = [];
    public commentsAndLogs: any[];
    public selecedRequestDetails = {};
    public isSubSeriesFlag = false;
    public isSeriesCommentsFlag = false;
    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    public commentscategory: string[] = ["Others", "Pax Count Increase", "Pax Count Decrease", "Change Itinerary", "Cancel Entire Group", "Passenger Name Change"];
    public logMapping: any = {
        "CREATE_REQUEST": "has created the request",
        "UPDATE_PAX_DETAILS": "has updated pax details for request",
        "CONFIRM_PAX_DETAILS": "has confirmed pax details for request",
        "PAYMENT": "has made a payment for request",
        "ONLINE PAYMENT": "has made a payment for request",
        "QUOTE_EXPIRY": "Quote has been expired for request",
        "SYSTEM_CHANGE": "has been changed for the request",
        "ACCEPT": "has accepted the request",
        "NEGOTIATION": "has negotiated the request",
        "QUOTE": "has quoted the request",
        "UPDATE_QUOTE_VALID_DATE": " has updated quote valid time for the request",
        "FARE_ACCEPT": "has accepted fare for the request",
        "PENDING_PAX_DETAILS": " is yet to confirm pax details for request",
        "UPDATE_TICKET_DETAILS": "has updated ticket details for request",
        "UPDATE_PNR_NO": "has updated the pax details for request",
        "REQUEST_CANCEL": "has cancelled the request",
        "ANOTHER_QUOTE_NEED": "has requested another quote for request",
        "UPDATE_PAYMENT_STATUS": "has updated the payment status for request",
        "PAX_SIZE_CHANGE": "has changed the pax size for request",
        "FLIGHT_DETAILS_UPDATE": "has updated the flight details for request",
        "CLAIM": "has claimed the request",
        "UNCLAIM": "has unclaimed the request",
        "QUOTED_FARE": "quoted fare per pax ",
        "QUOTED_VALID_TILL": "quote valid till",
        "TOTAL_FARE": "quoted total fare",
        "ASSIGNEE_CHANGE": "Assignee has been changed ",
        "STATUS_CHANGE": "has changed the status",
        "EXPECTED_FARE": "expected fare ",
        "SIMILAR REQUEST AVAILABILITY CHECK": "Similar Request Availability status - ",
        "BASE FARE AND TAX FARE SPLIT": "Fare Split for request",
        "SEAT AVAILABILITY CHECK": "Seat Availability status - ",
        "CREATE_PNR": "has created PNR for request",
        "ISSUE_TICKET": "has issued ticket for request",
        "FINAL_QUOTE": " final quote",
        "CANCEL_REASON": " has cancelled the request due to",
        "DOWNSIZE": "has reduce the pax for request",
        "CUSTOMER CONFIRMATION": "has confirmed the quote for request",
        "UPDATE_COMMENT_ACTION_FLAG": "has updated the comment action"
    };

    constructor(
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private loaderService: LoaderService

    ) { }

    ngOnInit(): void {
        this.isSubSeriesFlag = this.isSubRequest;
        this.isSeriesCommentsFlag = this.isSeriesComments;
        this.userDetails = this.grmsUtils.getUserDetails();
        this.selecedRequestDetails = _.cloneDeep(this.requestDetails);
        this.selecedRequestDetails['loginUserID'] = this.userDetails.loginUserId;
    }

    loadCommentFile(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            this.commentFileData = file;
        } else {
            this.commentFileData = undefined;
        }
    }

    addComment() {
        if (this.grmsUtils.isNotNull(this.comment.trim())) {
            let requestId = (this.isSeriesCommentsFlag && !this.isSubSeriesFlag) ? this.requestDetails.seriesRequestId : this.requestDetails.requestId;
            let formData: FormData = new FormData();
            let userDetails = this.grmsUtils.getUserDetails();
            if (this.commentFileData !== undefined) {
                if (!this.isValidFile(this.commentFileData)) {
                    return;
                }
                formData.append("commentDocument", this.commentFileData, this.commentFileData.name);
            }
            formData.append("comments", this.comment);
            formData.append("user", userDetails.loginUserId);
            formData.append("commentCategory", this.commentCategory);
            formData.append("requestID", requestId);
            let apiURL = (this.isSeriesCommentsFlag) ? SERIES_API_ENDPOINTS.SERIES_REQUEST_COMMENTS : BOOKING_API_ENPOINTS.addComments;
            this.loaderService.show();
            this.httpService.postCall(apiURL, formData, this.grmsUtils.getFileAuthHeader())
                .pipe(takeUntil(this.unSubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        this.commentCategory = '';
                        this.commentFile = '';
                        this.comment = '';
                        this.snackBar.open("Your Comment has been Submitted successfully", 'X', { duration: 3000 });
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        } else {
            this.snackBar.open("Please provide your comments", 'X', { duration: 3000 });
        }

    }

    isValidFile(file: File) {
        if (file.size / (1024 * 1024) > 16) {
            this.snackBar.open("Kindly ulpoad file less than 16MB size", 'X', { duration: 3000 });
            return false;
        }
        let supportedFileList = [".pdf", ".jpg", ".png", ".zip", ".doc", ".docx", ".JPG"];
        let isValidFile: boolean = false;
        for (let fileType of supportedFileList) {
            if (file.name.endsWith(fileType)) {
                isValidFile = true;
            }
        }
        if (!isValidFile) {
            this.snackBar.open("File type should be .pdf, .jpg, .JPG, .png, .zip, .doc or .docx", 'X', { duration: 3000 });
        }
        return isValidFile;
    }

    fetchComments(event) {
        if (event.tab.textLabel !== 'Add Comments') {
            if (event.tab.textLabel === 'COMMENT_ACTION_NOTTAKEN') {
                this.actionPendingList = [];
            }
            this.loaderService.show();
            let payload = {};
            let queryParam = (this.isSeriesCommentsFlag) ? "Series Request" : "";
            if (this.isSubSeriesFlag) {
                payload = {
                    isSubRequest: (this.isSubSeriesFlag) ? true : false,
                    actionType: (event.tab.textLabel !== 'Work Logs') ? event.tab.textLabel : 'All',
                    seriesSubRequests: [
                        this.selecedRequestDetails
                    ],
                    loginUserID: this.userDetails.loginUserId
                }
            } else {
                this.selecedRequestDetails['isSubRequest'] = (this.isSubSeriesFlag) ? true : false;
                this.selecedRequestDetails['actionType'] = (event.tab.textLabel !== 'Work Logs') ? event.tab.textLabel : 'All';
                payload = this.selecedRequestDetails;
            }

            let inputBody = this.isSeriesCommentsFlag ? payload : this.selecedRequestDetails;

            let apiURL = this.isSeriesCommentsFlag ? SERIES_API_ENDPOINTS.SERIES_REQUEST_WORKLOG + queryParam : BOOKING_API_ENPOINTS.fetchWorklog;
            this.httpService.postCall(apiURL, inputBody, this.grmsUtils.getAuthHeader())
                .pipe(takeUntil(this.unSubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        if (this.isSubRequest) {
                            this.constructCommentsandLogs(response.requestDetailsList[0].seriesSubRequests[0], event.tab.textLabel);
                        } else {
                            this.constructCommentsandLogs(response.requestDetailsList[0], event.tab.textLabel);
                        }

                    },
                    error: (errorDetails) => {
                        if (event.tab.textLabel === 'COMMENT_ACTION_NOTTAKEN') {
                            this.actionPendingList = [];
                        }
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }
    }

    constructCommentsandLogs(commentsResponse, textLabel) {
        if (textLabel === "All") {
            this.audlogList = commentsResponse.auditLogDetails ? commentsResponse.auditLogDetails : [];
            this.commentList = commentsResponse.commentDetails ? commentsResponse.commentDetails : [];
            this.clubCommentsAndLogs();
        } else if (textLabel === "Comments") {
            this.commentList = commentsResponse.commentDetails ? commentsResponse.commentDetails : [];
        } else if (textLabel === "Work Logs") {
            this.audlogList = commentsResponse.auditLogDetails ? commentsResponse.auditLogDetails : [];
        } else {
            this.actionPendingList = commentsResponse.commentDetails ? commentsResponse.commentDetails : [];
        }
    }

    clubCommentsAndLogs() {
        this.commentsAndLogs = new Array();
        if (this.commentList) {
            for (let comment of this.commentList) {
                let commentsAndLog: any = {
                    "time": (new Date(comment.createdDate)),
                    "type": "C",
                    "data": comment
                };
                this.commentsAndLogs.push(commentsAndLog);
            }
        }
        if (this.audlogList) {
            for (let audit of this.audlogList) {
                let commentsAndLog: any = {
                    "time": (new Date(audit.createdDate)),
                    "type": "A",
                    "data": audit
                };
                this.commentsAndLogs.push(commentsAndLog);
            }
        }

    }

    getLogPhrase(audlog: any) {
        if (audlog.userId == '' && audlog.action != "QUOTE_EXPIRY") {
            return 'System ' + this.logMapping[audlog.action];
        }
        return this.logMapping[audlog.action];
    }

    getReadableTime(createdDate: string) {
        let gDate: Date = new Date(createdDate);
        let cDate: Date = new Date();
        let timeDiff: number = cDate.getTime() - gDate.getTime();
        let MS: number = 1000;
        let SS: number = 60 * MS;
        let mm: number = 60 * SS;
        let HH: number = 24 * mm;
        let dd: number = 30 * HH;
        let MM: number = 12 * dd;
        let result: any = "";
        if (timeDiff > MM) {
            result = Math.round(timeDiff / MM) + " years";
        } else if (timeDiff > dd) {
            result += "" + Math.round(timeDiff / dd) + " months";
        } else if (timeDiff > HH) {
            result += "" + Math.round(timeDiff / HH) + " days";
        } else if (timeDiff > mm) {
            result += "" + Math.round(timeDiff / mm) + " hours";
        } else if (timeDiff > SS) {
            result += "" + Math.round(timeDiff / SS) + " minutes";
        } else if (timeDiff > MS) {
            result += "" + Math.round(timeDiff / MS) + " seconds";
        }
        result += " ago";
        return result;
    }

    getChildLogPhrase(audlog: any) {
        if (audlog.action === "QUOTE" || audlog.action === "FARE_ACCEPT") {
            return " has changed " + this.logMapping[audlog.fieldName];
        } else if (audlog.action === "ACCEPT") {
            return " has accepted " + this.logMapping[audlog.fieldName];
        } else if (audlog.action === "BASE FARE AND TAX FARE SPLIT") {
            if (audlog.fieldName === 'BASE_FARE') {
                return "Adult Base Fare ";
            }
            if (audlog.fieldName === 'TAX_FARE') {
                return "Adult Tax Fare ";
            }
            if (audlog.fieldName === 'INFANT_BASE_FARE') {
                return "Infant Base Fare ";
            }
            if (audlog.fieldName === 'INFANT_TAX_FARE') {
                return "Infant Tax Fare ";
            }
        } else if (audlog.action === "PAYMENT" && audlog.fieldName == 'PAYMENT_STATUS') {
            return "has made a payment, Payment status changed";
        } else if (audlog.action === "SYSTEM_CHANGE" && audlog.fieldName == 'PAYMENT_STATUS') {
            return "Payment  Status has been changed to ";
        } else if (audlog.action == 'UPDATE_PAYMENT_STATUS' && audlog.fieldName == 'AMOUND_PAID') {
            return " has updated the paid amount ";
        } else if (audlog.action == 'CREATE_PNR') {
            return " has created PNR - ";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'REFUND_FARE') {
            return " has set the refund fare";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'EXPECTED_FARE') {
            return "Expected fare has been revised";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'QUOTED_FARE') {
            return "Quoted fare has been revised";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'TOTAL_FARE') {
            return "Total fare has been revised";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'PAYMENT_STATUS') {
            return " has updated the payment status for request";
        } else if (audlog.action == 'ISSUE_TICKET' && (audlog.fieldName == 'REVISED_TAX_FARE' || audlog.fieldName == 'TAX_FARE')) {
            return "Adult tax fare has been revised";
        } else if (audlog.action == 'ISSUE_TICKET' && audlog.fieldName == 'INFANT_TAX_FARE') {
            return "Infant tax fare has been revised";
        }
        else {
            if (audlog.userId == '' && ["ASSIGNEE_CHANGE", "SIMILAR REQUEST AVAILABILITY CHECK", "SEAT AVAILABILITY CHECK "].indexOf(audlog.action) == -1) {
                return 'System ' + this.logMapping[audlog.action];
            }
            return this.logMapping[audlog.action];
        }

    }

    loadSubLogs(i: number, auditId: number, loadTo: string) {
        let said: string = "" + auditId;
        let requestId = (this.isSeriesCommentsFlag && !this.isSubSeriesFlag) ? this.requestDetails.seriesRequestId : this.requestDetails.requestId;
        let payload = {
            "requestId": requestId,
            "actionType": "AUDIT_CHILD",
            "auditLogDetails": [
                { "parentAuditId": said }
            ],
            "loginUserID": this.selecedRequestDetails['loginUserID']
        };
        this.loaderService.show();
        this.httpService.postCall(BOOKING_API_ENPOINTS.fetchWorklog, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    if (loadTo === "LOGS") {
                        this.audlogList[i].audlog = response.requestDetailsList[0].auditLogDetails;
                    } else if (loadTo === "ALL") {
                        this.commentsAndLogs[i].data.audlog = response.requestDetailsList[0].auditLogDetails;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    actionTaken(commentId) {
        let requestId = (this.isSeriesCommentsFlag && !this.isSubSeriesFlag) ? this.requestDetails.seriesRequestId : this.requestDetails.requestId;
        let payload = {
            "loginUserID": this.userDetails?.loginUserId,
            "requestId": requestId,
            "actionType": "UPDATE_COMMENT_ACTION_FLAG",
            "commentDetails": [
                {
                    "commentActionFlag": true,
                    "commentId": commentId
                }
            ]
        }
        if (this.isSeriesCommentsFlag) {
            delete payload['requestId'];
            payload['isSubRequest'] = this.isSubSeriesFlag;
            payload['seriesRequestId'] = requestId;
            if (this.isSubRequest) {
                payload['seriesSubRequests'] = [
                    this.selecedRequestDetails
                ];
                payload['loginUserID'] = this.userDetails.loginUserId;
            }
        }
        let apiURL = (this.isSeriesComments) ? SERIES_API_ENDPOINTS.SERIES_UPDATE_COMMENTS_ACTION : BOOKING_API_ENPOINTS.updateMarkAsDone;
        this.loaderService.show();
        this.httpService.postCall(apiURL, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    setTimeout(() => {
                        let tempObj = { tab: { textLabel: "COMMENT_ACTION_NOTTAKEN" } };
                        this.fetchComments(tempObj);
                    }, 1000);
                    this.snackBar.open("Comments marked as done successfully", 'X', { duration: 3000 });
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    downloadAttachment(fileId: any, filename: string, fileType: string) {
        this.loaderService.show();
        this.httpService.getCall(BOOKING_API_ENPOINTS.downloadComments + fileId, this.grmsUtils.getFileJSONHeader(fileType))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    var newBlob = new Blob([response], { type: fileType });
                    const data = window.URL.createObjectURL(newBlob);
                    let downloadLink = document.createElement('a');
                    downloadLink.href = data;
                    downloadLink.setAttribute('download', filename);
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                },
                error: (errorDetails) => {
                    // this.grmsUtils.errorHandling(errorDetails);
                    this.loaderService.hide();
                    var data = new Blob([errorDetails.error.Blob], { type: fileType });
                    FileSaver.saveAs(data, filename);
                }
            });
    }

}
