import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { REQUEST_LIST } from 'src/app/shared/static/constants';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { REPORT_API_ENDPOINTS, SERIES_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { DownloadUtil } from 'src/app/shared/static/downloadUtils';
import { MenuAccessService } from 'src/app/shared/services/menu-access.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-dashboard-request',
    templateUrl: './dashboard-request.component.html',
    styleUrls: ['./dashboard-request.component.scss']
})
export class DashboardRequestComponent implements OnInit {

    public requestStatus = [
        "OPEN", "QUOTED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED",
        "PAX CONFIRMED", "PAX INFO PENDING", "REQUEST CANCELED", "QUOTE EXPIRED",
        "TICKETS ISSUED", "CUSTOMER CONFIRMED", "TICKET PENDING", "MODIFIED REQUESTS",
        "SCHEDULE CHANGES", "PAYMENT PENDING", "SERVICE REQUEST"];
    public toBePaidEnableOption = ["QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED"];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public tripTypeList = [
        { code: 'One Way', desc: 'ONE WAY' },
        { code: 'Multiple', desc: 'MULTIPLE' },
        { code: 'Round Trip', desc: 'ROUND TRIP' }
    ];
    public userDetails;
    public requestList = REQUEST_LIST;
    public isValidDateFilter = false;
    public isFilterApplied = false;
    public searchForm!: FormGroup;
    public selectedRequest = '';
    public maxDate = new Date();
    public requestIdData = [];
    public showFiter = false;
    public noRecords = false;
    public dateValidErr = '';
    public requestDesc = '';
    public showGrid = false;
    public currentCode = '';
    public currentPage = 0;
    public requestId = '';
    public reqStatus = '';
    public startDate = '';
    public userRole = '';
    public fileName = '';
    public reqType = '';
    public endDate = '';

    public columnDefs = [
        {
            'requestId': 'Request Id',
            'createdDate': 'Raised Date',
            'tripType': 'Trip Type',
            'from': 'Departure',
            'to': 'Arrival',
            'flightNumber': 'Flight Number',
            'firstTravelDate': 'Travel Date',
            'classOfTravel': 'Class Type',
            'paxCount': 'Pax Count',
            'requestStatus': 'Request Status',
            'paymentStatus': 'Payment Status',
            'totalFare': 'Total Amount',
            'toBePaid': 'To Be Paid',
            'lastModifiedDate': 'Last Update Date'
        }
    ];

    constructor(
        private fb: FormBuilder,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private downloadUtils: DownloadUtil,
        private routeParams: ActivatedRoute,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private menuService: MenuAccessService,
        private userProfileService: UserProfileService,
    ) { }

    ngOnInit(): void {
        this.userDetails = this.grmsUtils.getUserDetails();
        this.createForm();
        this.menuService.hideAndShowMenu();
        this.selectedRequest = this.cookieService.get('currentMenu');
        this.userProfileService.userRole
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.userRole = action;
            });
        this.routeParams.paramMap.subscribe((params: ParamMap) => {
            let filteredRequest = this.requestList.filter((obj) => {
                if (obj.code === params.get('id')) {
                    return obj;
                }
            });

            this.requestDesc = filteredRequest[0].key;
            this.currentCode = filteredRequest[0].code;
            this.fileName = filteredRequest[0].key.replace(' ', '_') + 'Details';
            this.loadGridData();
        });

    }

    createForm() {
        this.searchForm = this.fb.group({
            requestId: [""],
            tripType: [""],
            requestStatus: [""],
            createdBy: [""],
            startDate: [],
            endDate: [],
        });
    }

    loadGridData() {
        let payload = this.getRequestJSON('LAST_UPDATED_DATE');
        this.requestServiceCall(payload);
    }

    openFilters() {
        this.searchForm.reset();
        this.showFiter = true;
    }

    closeDialog() {
        this.showFiter = false;
    }

    searchFilters() {
        let payload = this.getRequestJSON('lastUpdatedDate');
        let tempObj2 = JSON.parse(JSON.stringify(this.searchForm.value));
        let requestDto = this.constructPayloadDetails(tempObj2);
        payload['requestDto'] = requestDto;
        Object.keys(payload).forEach(k => {
            if (!payload[k] && payload[k] === null) { delete payload[k] }
        });
        if (payload['requestDto'].length > 0) {
            this.isFilterApplied = true;
            let endPoint = (this.userDetails.gsaUser !== 'Y' && this.selectedRequest !== 'gsa') ? REPORT_API_ENDPOINTS.filterReports : REPORT_API_ENDPOINTS.gsaFilterReports;
            this.requestServiceCall(payload, null, true, endPoint);
        } else {
            this.isFilterApplied = false;
            if (this.isValidDateFilter) {
                this.snackBar.open("Atleast one filed required to filter data", 'X', { duration: 3000 });
            }
            return;
        }
    }

    constructPayloadDetails(tempObj2) {
        let tempArr = [];
        if (tempObj2.startDate && tempObj2.endDate) {
            tempObj2.startDate = moment(tempObj2.startDate).format('DD-MMM-YYYY');
            tempObj2.endDate = moment(tempObj2.endDate).format('DD-MMM-YYYY');
            if (this.dateValidation(tempObj2)) {
                this.isFilterApplied = true;
                this.isValidDateFilter = true;
            }
        } else {
            this.isFilterApplied = true;
            this.isValidDateFilter = true;
        }
        if (this.isFilterApplied) {
            for (const key in tempObj2) {
                let tempObj = {};
                if (this.grmsUtils.isNotNull(tempObj2[key])) {
                    tempObj['column'] = (key === 'startDate') ? 'departureDate' : (key === 'endDate') ? 'departureDate1' : key;
                    tempObj['value'] = tempObj2[key];
                    tempArr.push(tempObj);
                }
            }
        }
        return tempArr;
    }

    removeFilters() {
        this.isFilterApplied = false;
        this.loadGridData();
    }

    loadMoreRequestDeatils() {
        this.currentPage = this.currentPage + 1;
        let payload = this.getRequestJSON('LAST_UPDATED_DATE', true);
        this.requestServiceCall(payload, true);
    }

    dateValidation(payload) {
        this.dateValidErr = '';
        let date1 = new Date(payload.startDate);
        let date2 = new Date(payload.endDate);
        let diff = Math.abs(date1.getTime() - date2.getTime());
        let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffDays < 90) {
            return true;
        } else {
            this.dateValidErr = "Travel Date filter should be less than 90 days";
            this.isValidDateFilter = false;
            return false;
        }
    }

    getRequestJSON(sortColumn, isLoadMore?) {

        return {
            "downloadOption": false,
            "pageNumber": (isLoadMore) ? this.currentPage : 0,
            "recPerPage": 100,
            "sortField": sortColumn,
            "sortOrder": "desc",
            "loginUserID": this.userDetails.loginUserId,
        }
    }

    requestServiceCall(payload, isLoadMoreOptn?: Boolean, isFilterOptn?: Boolean, APIURL?) {
        this.loaderService.show();
        this.showGrid = false;
        let apiEndPoint;
        if (!this.grmsUtils.isNotNull(isLoadMoreOptn)) {
            this.requestIdData = [];
        }
        if (this.currentCode !== 'pp') {
            payload["requestStatus"] = this.requestDesc.toUpperCase()
        }

        if (APIURL) {
            apiEndPoint = APIURL;
        } else {
            apiEndPoint = (this.currentCode === 'pp' && !isFilterOptn) ? REPORT_API_ENDPOINTS.searchPaymentPending : REPORT_API_ENDPOINTS.searchReports;
            apiEndPoint = (this.userDetails.gsaUser !== 'Y' && (this.selectedRequest !== 'gsa' && this.selectedRequest !== 'series')) ? apiEndPoint :
                (this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_REQ_SEARCH : REPORT_API_ENDPOINTS.gsaSearchReports;
        }
        apiEndPoint = (this.selectedRequest === 'series' && this.currentCode === 'pp') ? SERIES_API_ENDPOINTS.SERIES_PAYMENT_PENDING : apiEndPoint;

        if (this.userDetails.gsaUser === 'Y' || this.selectedRequest === 'gsa') {
            delete this.columnDefs[0]['paymentStatus'];
            delete this.columnDefs[0]['toBePaid'];
        }

        this.httpService.postCall(apiEndPoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        response.requestDetailsList.forEach((obj) => {
                            let tempObj = {};
                            let toBePaid = this.getToBePaid(obj);
                            if (this.selectedRequest === 'series') {
                                delete this.columnDefs[0].flightNumber;
                                tempObj = this.getSeriesRequestDetails(obj);
                            } else {
                                tempObj['requestId'] = obj.requestId;
                                tempObj['createdDate'] = (this.grmsUtils.isNotNull(obj.requestgeneralData.createdDate)) ? moment(obj.requestgeneralData.createdDate).format('DD-MMM-YYYY') : '';
                                tempObj['tripType'] = obj.requestgeneralData.tripType;
                                tempObj['from'] = (this.grmsUtils.isNotNull(obj.requestgeneralData.from)) ? obj.requestgeneralData.from : obj.origin;
                                tempObj['to'] = (this.grmsUtils.isNotNull(obj.requestgeneralData.to)) ? obj.requestgeneralData.to : obj.destination;
                                tempObj['flightNumber'] = obj.flightNumber;
                                tempObj['firstTravelDate'] = (this.grmsUtils.isNotNull(obj.firstTravelDate)) ? obj.firstTravelDate : moment(obj.departureDate).format('DD-MMM-YYYY');
                                tempObj['classOfTravel'] = obj.classOfTravel;
                                tempObj['paxCount'] = obj.requestgeneralData.noOfAdult + obj.requestgeneralData.noOfChild;
                                tempObj['requestStatus'] = obj.requestStatus;
                                tempObj['totalFare'] = obj.requestgeneralData.totalFare;
                                tempObj['lastModifiedDate'] = (this.grmsUtils.isNotNull(obj.requestgeneralData.lastModifiedDate)) ? moment(obj.requestgeneralData.lastModifiedDate).format('DD-MMM-YYYY') : '';
                            }
                            if (this.userDetails.gsaUser !== 'Y' && this.selectedRequest !== 'gsa') {
                                tempObj['paymentStatus'] = obj.paymentStatus ? obj.paymentStatus : '-';
                                tempObj['toBePaid'] = (this.toBePaidEnableOption.indexOf(obj.requestStatus) > -1) ? toBePaid : '-';
                            }
                            this.requestIdData.push(tempObj);
                        });
                        this.showGrid = true;
                        this.showFiter = false;
                        this.noRecords = this.requestIdData.length === 0 ? true : false;
                        this.loaderService.hide();
                    }
                },
                error: (errorDetails) => {
                    this.isFilterApplied = false;
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getSeriesRequestDetails(requestObj) {
        let tempObj = {};
        tempObj['from'] = requestObj.origin;
        tempObj['to'] = requestObj.destination;
        tempObj['tripType'] = requestObj.tripType;
        tempObj['totalFare'] = requestObj.totalFare;
        tempObj['requestId'] = requestObj.seriesRequestId;
        tempObj['classOfTravel'] = requestObj.classOfTravel;
        tempObj['requestStatus'] = requestObj.requestStatus;
        tempObj['paxCount'] = requestObj.noOfAdult + requestObj.noOfChild;
        tempObj['createdDate'] = requestObj.createdDate ? moment(requestObj.createdDate).format('DD-MMM-YYYY') : "";
        tempObj['lastModifiedDate'] = requestObj.lastUpdatedDate ? moment(requestObj.lastUpdatedDate).format('DD-MMM-YYYY') : "";
        tempObj['firstTravelDate'] = requestObj.departureStartDate ? moment(requestObj.departureStartDate).format('DD-MMM-YYYY') : "";
        return tempObj;
    }

    getToBePaid(requestDetails) {
        let getPenalty1 = 0;
        let getPenalty2 = 0;
        let getPenalty3 = 0;
        let getMiscelle1 = 0;
        let getMiscelle2 = 0;
        let getMiscelle3 = 0;
        let toBePaidShow = 0;

        if (!!requestDetails) {
            if (requestDetails.hasOwnProperty('updatePaymentdescription')) {
                getPenalty1 = (requestDetails.updatePaymentdescription.hasOwnProperty('penaltyAmount')) ? Number(requestDetails.updatePaymentdescription.penaltyAmount) : 0;
                getMiscelle1 = (requestDetails.updatePaymentdescription.hasOwnProperty('miscellaneousCharges')) ? Number(requestDetails.updatePaymentdescription.miscellaneousCharges) : 0;
            }
            if (requestDetails.hasOwnProperty('updatePaymentdescriptiondtw')) {
                getPenalty2 = (requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyAmount')) ? Number(requestDetails.updatePaymentdescriptiondeposite2.penaltyAmount) : 0;
                getMiscelle2 = (requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousCharges')) ? Number(requestDetails.updatePaymentdescriptiondeposite2.miscellaneousCharges) : 0;
            }
            if (requestDetails.hasOwnProperty('updatePaymentdescriptiondeposite3')) {
                getPenalty3 = (requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyAmount')) ? Number(requestDetails.updatePaymentdescriptiondeposite3.penaltyAmount) : 0;
                getMiscelle3 = (requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousCharges')) ? Number(requestDetails.updatePaymentdescriptiondeposite3.miscellaneousCharges) : 0;
            }
        }

        if (requestDetails.hasOwnProperty('updatePaymentdescriptiondeposite3')) {
            // zero deposit toBePaid amount
            if (!requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount3') && !requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('ticketingAmount2') && !requestDetails.updatePaymentdescription.hasOwnProperty('ticketingAmount1')) {
                toBePaidShow = Number(requestDetails.requestgeneralData.toBePaid || '0');
            }
            // After 3rd deposit toBePaid amount
            else if (requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount3') && requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('ticketingAmount2') && requestDetails.updatePaymentdescription.hasOwnProperty('ticketingAmount1')) {
                toBePaidShow = (requestDetails.updatePaymentdescriptiondeposite3.ticketingAmount3 + getPenalty1 + getMiscelle1 + getPenalty2 + getMiscelle2 + getPenalty3 + getMiscelle3) - requestDetails.requestgeneralData.amountPaid;
            }
            // After 2nd deposit toBePaid amount
            else if (!requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount3') && requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('ticketingAmount2') && requestDetails.updatePaymentdescription.hasOwnProperty('ticketingAmount1')) {
                toBePaidShow = (requestDetails.updatePaymentdescriptiondeposite2.ticketingAmount2 + getPenalty1 + getMiscelle1 + getPenalty2 + getMiscelle2) - requestDetails.requestgeneralData.amountPaid;
            }
            // After 1st deposit toBePaid amount
            else if (!requestDetails.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount3') && !requestDetails.updatePaymentdescriptiondeposite2.hasOwnProperty('ticketingAmount2') && requestDetails.updatePaymentdescription.hasOwnProperty('ticketingAmount1')) {
                toBePaidShow = (requestDetails.updatePaymentdescription.ticketingAmount1 + getPenalty1 + getMiscelle1) - requestDetails.requestgeneralData.amountPaid;
            }
        }

        return toBePaidShow;
    }

    downloadFile() {
        if (this.requestIdData.length > 0) {
            this.loaderService.show();
            let downloadColumnDefs = Object.keys(this.requestIdData[0]);
            downloadColumnDefs.forEach((word, index) => {
                const firstLetter = word.charAt(0).toUpperCase();
                const rest = word.slice(1).toLowerCase();

                downloadColumnDefs[index] = firstLetter + rest;
            });
            let finalColumnArr = [];
            finalColumnArr.push(downloadColumnDefs);
            this.downloadUtils.exportAsExcelFile(this.requestIdData, finalColumnArr, this.fileName);
        } else {
            this.snackBar.open("No Records to Download", 'X', { duration: 3000 });
        }

    }

}
