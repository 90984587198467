import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { SideNavBarService } from "../../shared/services/side-nav-bar.service";
import { GrmsUtils } from "src/app/shared/static/GRMSUtils";
import { GB_CONSTANTS, GRMS_CONSTANT } from '../../shared/static/constants';
import { LOGIN_API_ENDPOINTS } from "src/app/shared/httpService/apiURLConstant";
import { LoaderService } from "src/app/shared/services/gb-loader.services";
import { UserProfileService } from "src/app/shared/services/user-profile.service";
import { SessionService } from "src/app/shared/services/session.service";
import { PasswordPolicyComponent } from "./password-policy/password-policy.component";
import { MatDialog } from "@angular/material/dialog";
import { MenuAccessService } from "src/app/shared/services/menu-access.service";

@Component({
    selector: 'gb-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

    public errorMessage;
    public captchaResponse: any;
    public hidePassword = false;
    public loginForm!: FormGroup;
    public ACTION_TYPE = 'login';
    public isExistingUser = false;
    public showPasswordField = true;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public SITE_KEY = "6LekfGEeAAAAAG8bFBHpbg01ZtyJpOYIMcGthLwI";
    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS.LOGIN_FORM;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private sideNavBar: SideNavBarService,
        private sessionService: SessionService,
        private menuAccessService: MenuAccessService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private userProfileService: UserProfileService,
    ) { }

    ngOnInit(): void {
        this.userProfileService.existingUserFlag
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isExistingUser = action;
            });

        if (!this.isExistingUser && this.grmsUtils.isNotNull(this.cookieService.get('auth_token')) && this.grmsUtils.isNotNull(this.cookieService.get('userDetails'))) {
            this.menuAccessService.calculateSessionTime();
            let userDetails = this.grmsUtils.getUserDetails();
            if (userDetails.user_role === "ROLE_AGENT" || userDetails.user_role === "ROLE_USER" || userDetails.user_role === "ROLE_SUB_AGENT") {
                this.router.navigate(['/request/create']);
            } else {
                this.router.navigate(['/request']);
            }
        } else {
            this.createForm();
            this.sideNavBar.showRegisterIcon();
            this.sideNavBar.hideMenu();
            this.sideNavBar.hideSideNavBar();
            this.userProfileService.removeUserProfile();
            if (this.isExistingUser && this.grmsUtils.isNotNull(this.cookieService.get('auth_token')) && this.grmsUtils.isNotNull(this.cookieService.get('userDetails'))) {
                const dialogRef = this.dialog.open(PasswordPolicyComponent, {
                    width: '425px',
                    height: '130px',
                    disableClose: true,
                })

                dialogRef.afterClosed().subscribe(result => {
                });
            } else {
                this.userProfileService.setExistingUserFlag();
            }
        }
    }

    createForm() {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            password: [null, [Validators.required]]
        });
    }

    validateForm(fieldname: any): any {
        if (fieldname === 'email') {
            return this.loginForm.controls[fieldname].errors?.required ? this.validationMessage.EMAIL_MANDATORY :
                this.loginForm.controls[fieldname].errors?.pattern ? this.validationMessage.EMAIL_INVALID : '';
        } else if (fieldname === 'password') {
            return this.loginForm.controls[fieldname].errors?.required ? this.validationMessage.PASSWORD_MANDATORY :
                this.loginForm.controls[fieldname].errors?.pattern ? this.validationMessage.PASSWORD_INVALID : '';
        }
    }

    recaptchaExecute() {
        if (this.showPasswordField) {
            this.loaderService.show();
            this.reCaptchaV3Service.execute(this.SITE_KEY,
                this.ACTION_TYPE,
                (token) => {
                    this.captchaResponse = token;
                    this.login();
                });
        } else {
            this.login();
        }

    }

    login() {
        this.loaderService.show();
        if (this.showPasswordField) {
            if (this.captchaResponse) {
                let payload = {
                    login: {
                        "channel": "GRMS_APP",
                        "captchaValue": this.captchaResponse,
                        "userId": this.loginForm.controls['email'].value,
                        "password": this.grmsUtils.encryptedData(this.loginForm.controls['password'].value)
                    }
                }
                this.httpService.postCall(LOGIN_API_ENDPOINTS.checkLogin, payload, { responseType: 'text' })
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: (response: any) => {
                            this.loaderService.hide();
                            let responseData = this.grmsUtils.decryptedData(response);
                            response = JSON.parse(responseData);
                            if (response.statusCode === "200") {
                                this.setCookie(response);
                                this.sessionService.setSessionTimeout();
                                if (response.errMsgs?.errorCode === "211") {
                                    this.snackBar.open("Invalid Username or Password", "x", { duration: 3000 });
                                    return false;
                                }
                                if (response.resetPassword) {
                                    this.router.navigate(['/change-password']);
                                } else if (response.userType === "ROLE_AGENT" || response.userType === "ROLE_USER" ||
                                    response.userType === "ROLE_SUB_AGENT") {
                                    this.router.navigate(['/request/create']);
                                } else {
                                    this.router.navigate(['/request']);
                                }
                            } else if (response.statusCode === '703') {
                                this.setCookie(response);
                                const dialogRef = this.dialog.open(PasswordPolicyComponent, {
                                    width: '425px',
                                    height: '130px',
                                    disableClose: true,
                                })
                                dialogRef.afterClosed().subscribe(result => {
                                });
                            } else if (!!response?.errMsgs?.errorMessage) {
                                this.snackBar.open(response.errMsgs.errorMessage, "X", { duration: 3000 })
                            }
                        },
                        error: (errorDetails) => {
                            this.loginForm.controls['password'].setValue('');
                            this.loginForm.controls['password'].setValidators(null);
                            this.loginForm.controls['password'].updateValueAndValidity();
                            setTimeout(() => {
                                this.loginForm.controls['password'].setValidators([Validators.required]);
                            }, 1000)
                            this.grmsUtils.errorHandling(errorDetails, 'loginPage');
                        }

                    });
            } else {
                this.snackBar.open("Google Recaptcha Value is Required. Kindly Retry Login", 'x', { duration: 3000 });
                this.loaderService.hide();
                return;
            }
        } else {
            let payload = {
                "emailId": this.loginForm.controls['email'].value,
            }
            this.httpService.postCall(LOGIN_API_ENDPOINTS.forgetPassword, payload, this.grmsUtils.getJsonHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        if (response.statusCode === 200) {
                            this.loginForm.reset();
                            this.showPasswordField = true;
                            this.snackBar.open("Temporary password has been mailed to the registered mail id.", 'X', { duration: 3000 });
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }

                });
        }
    }

    forgotPassword() {
        this.loginForm.controls['password'].setValidators(null);
        this.loginForm.controls['password'].updateValueAndValidity();
        this.showPasswordField = false;
    }

    setCookie(userDetails) {
        let loginTime = moment(new Date(), 'YYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A');
        let userData = this.getUserdetails(userDetails);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
        this.cookieService.set("login_time", loginTime, expiryDate, '/', '', true, "Lax");
        this.cookieService.set("currentMenu", '', expiryDate, '/', '', true, "Lax");
        this.cookieService.set("auth_token", userDetails.accessToken, expiryDate, '/', '', true, "Lax");
        this.cookieService.set("userDetails", JSON.stringify(userData), expiryDate, '/', '', true, "Lax");
        this.cookieService.set("refresh_token", userDetails.refreshToken, expiryDate, '/', '', true, "Lax");
        sessionStorage.setItem("auth_token", userDetails.accessToken);
        sessionStorage.setItem("login_type", userDetails.login_type);
        this.userProfileService.setUsername();
        this.userProfileService.setUserRole();
        this.userProfileService.setVistaraUserFlag();
        this.userProfileService.setExistingUserFlag();
        return true;

    }

    getUserdetails(userDetails) {
        let userData = {};
        userData['userId'] = userDetails.userId;
        userData['gsaUser'] = (userDetails?.gsaUser === 'Y') ? userDetails.gsaUser : 'N';
        userData['currency'] = (userDetails?.gsaUser === 'Y') ? userDetails.currency : '';
        userData['username'] = userDetails.emailId;
        userData['user_role'] = userDetails.userType;
        userData['firstname'] = userDetails.firstName;
        userData['travelAgent'] = userDetails.travelAgent;
        userData['login_type'] = userDetails.userLoginType;
        userData['existingUser'] = userDetails.existingUser;
        return userData;
    }

    backToLogin() {
        this.loginForm.reset();
        this.showPasswordField = true;
    }

    ngOnDestroy() {

    }
}
