import { trigger, transition, query, style, animate, group } from '@angular/animations';

export const fadeAnimation =
    trigger('fadeAnimation', [
        transition('* <=> *', slideTo('right')),
    ]);

function slideTo(direction) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'fixed',
                height: '100%',
                width: '100%'
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-100%' })
        ], optional),
        group([
            query(':leave', [
                animate('600ms ease', style({ [direction]: '100%' }))
            ], optional),
            query(':enter', [
                animate('600ms ease', style({ [direction]: '0%' }))
            ], optional)
        ])
    ];
}
