export class ProfileDetails {
    firstName?: string;
    lastName?: string;
    emailId?: string;
    phoneNumber?: Number;
    password?: string;
    oldPassword?: string;
    address1?: string;
    address2?: string;
    currencyCode?: string;
    country?: string;
    state?: string;
    city?: string;
    pincode?: string;
    travelAgent?: boolean;
    iataCode?: Number;
    agencyName?: string;
    cvNumber?: string;
    spei?: string;
    createdBy?: string;
    status?: string;
    statusCode?: string;
    userType?: string;
    lusr?: string;
    opr?: string;
    operation?: string;
    paymentExemption?: string;
    acnty?: string;
    registrationFee?: number;
    paymentExempted?: boolean;
    userId?: number;
    isAdmin?: boolean;
    captchavalue?: string;
}
