import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-password-policy',
    templateUrl: './password-policy.component.html',
    styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<PasswordPolicyComponent>,
    ) { }

    ngOnInit(): void {
    }

    updatePassword() {
        this.dialogRef.close();
        this.router.navigate(['/change-password']);
    }

}
