import { Injectable } from '@angular/core';
import { SideNavBarService } from './side-nav-bar.service';
import { UserProfileService } from './user-profile.service';
import { SessionService } from './session.service';
import { GRMS_CONSTANT } from '../static/constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class MenuAccessService {

    public loggedInTime;

    constructor(
        private userProfileService: UserProfileService,
        private sessionService: SessionService,
        private cookieService: CookieService,
        private sideNavBar: SideNavBarService
    ) { }

    hideAndShowMenu() {
        this.sideNavBar.hideRegisterIcon();
        this.sideNavBar.showSideNavBar();
        this.sideNavBar.showMenu();
        this.userProfileService.setUsername();
        this.userProfileService.setUserRole();
        this.userProfileService.setVistaraUserFlag();
        this.userProfileService.setExistingUserFlag();
        this.calculateSessionTime();
    }

    calculateSessionTime() {
        this.loggedInTime = this.cookieService.get('login_time');
        let formattedLoginTime = this.loggedInTime;
        formattedLoginTime = new Date(formattedLoginTime).getTime();
        if (this.loggedInTime) {
            let timeDifference = (new Date().getTime() - formattedLoginTime);
            let minDiff = timeDifference / 60 / 1000;
            let finalTimeLeft = minDiff * 60;
            finalTimeLeft = GRMS_CONSTANT.GRMS_SESSION_WARN_TIME - finalTimeLeft;
            this.sessionService.setSessionTimeout(finalTimeLeft);
        }

    }
}
