import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap, take, catchError } from 'rxjs/operators';
import { PaxRulesModel } from '../../shared/interface/create-request/pax-rules.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
@Component({
    selector: 'gb-paxpopup',
    templateUrl: './pax-popup-form.component.html',
    styleUrls: ['./pax-popup-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaxPopupFormComponent implements OnInit, OnDestroy {
    public paxForm: FormGroup;
    public paxRules: PaxRulesModel = {
        adult: {
            add: false,
            sub: true
        },
        child: {
            add: false,
            sub: true
        },
        infant: {
            add: false,
            sub: true
        }
    };

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private classOfTravel: any;
    public paxLimit: any;
    public infantMaxPaxLimit = 4;
    private totalPaxLimit: any;
    public action: any;



    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<PaxPopupFormComponent>,
        private snackBar: MatSnackBar,
        public router: Router,
        private formBuilder: FormBuilder) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
    }

    ngOnInit(): void {
        this.classOfTravel = this.data?.classOfTravel;
        this.paxLimit = this.data?.paxLimit;
        this.action = !!this.data?.action ? this.data?.action : '';
        this.createForm();
    }

    createForm() {
        this.paxForm = this.formBuilder.group({
            adult: [this.paxLimit.min],
            child: [0],
            infant: [0]
        });
        this.listenFormChanges();
    }

    validateForm(fieldname) {
        let errorControls = this.paxForm.controls[fieldname];
        switch (fieldname) {
            case 'adult':
                return errorControls.errors?.required ? "This field requied" : errorControls.errors?.max ? `This field max count ${this.paxLimit.max}` : '';
            case 'child':
                return errorControls.errors?.required ? "This field requied" : errorControls.errors?.max ? `This field max count ${this.paxLimit.max}` : '';
            case 'infant':
                return errorControls.errors?.required ? "This field requied" : errorControls.errors?.max ? `This field max count ${this.infantMaxPaxLimit} and  less then or equal to adult` : '';
            default:
                return null;
        }
    }
    modifyPax(paxType: string, countType: string) {
        (countType === 'add') ?
            this.paxForm.controls[paxType].setValue(this.paxForm.value[paxType] = parseInt(!!this.paxForm.value[paxType]?this.paxForm.value[paxType] : 0 ) + 1) :
            this.paxForm.controls[paxType].setValue(this.paxForm.value[paxType] = parseInt(this.paxForm.value[paxType]) - 1);
    }

    listenFormChanges() {
        this.paxForm.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(count => {
                const totalPax = parseInt(this.paxForm.value.adult) + parseInt(this.paxForm.value.child);
                this.totalPaxLimit = totalPax
                // if (this.classOfTravel == 'Business') {
                //     this.changePaxCount(count, totalPax, this.paxLimit.max);
                // } else if (this.classOfTravel == 'Premium Economy') {
                //     this.changePaxCount(count, totalPax, this.paxLimit.max);
                // }
                // else {
                //     this.changePaxCount(count, totalPax, this.paxLimit.max);
                // }
                this.changePaxCount(count, totalPax, this.paxLimit.max);
            });
        if (!!this.data.paxInfo) {
            this.paxForm.setValue(this.data.paxInfo);
        }
    }



    changePaxCount(count:any, totalPax:any, limit:any):void {
        for (const key in count) {
            if (key === 'adult') {
                if (count.infant > parseInt(count.adult)) {
                    this.paxForm.controls['infant'].setErrors({max:true})
                    this.paxForm.controls['infant'].markAsTouched()
                    this.paxRules['infant'].add = false;
                    this.paxForm.controls['infant'].setValue(count.adult);
                }
                (count[key] === '' || parseInt(count[key]) === 0 || parseInt(count[key]) === 1 ) ? this.paxRules[key].sub = true : this.paxRules[key].sub = false;
            }
            else {
                (count[key] === ''  || parseInt(count[key]) === 0) ? this.paxRules[key].sub = true : this.paxRules[key].sub = false;

            }

            if (key === 'infant' && count['infant'] > this.infantMaxPaxLimit) {
                this.paxForm.controls['infant'].setErrors({ max: true })
                this.paxForm.controls['infant'].markAsTouched()
            }
            else if (key === 'adult' && count['adult'] > this.paxLimit.max) {
                this.paxForm.controls['adult'].setErrors({ max: true })
                this.paxForm.controls['adult'].markAsTouched()
            }
            else if (key === 'child' && count['child'] > this.paxLimit.max) {
                this.paxForm.controls['child'].setErrors({ max: true })
                this.paxForm.controls['child'].markAsTouched()
            }

            if (totalPax === limit) {
                this.paxRules['adult'].add = true;
                this.paxRules['child'].add = true;
                if (count.infant < 4 || count['adult'] != '' ) {
                    this.paxRules['infant'].add = false;
                } else {
                    this.paxRules['infant'].add = true;
                }
            } else if (key === 'infant') {
                // || count.infant > parseInt(count['adult'])
                if (count.infant < 4 ) {
                    this.paxRules[key].add = false;
                } else {
                    this.paxRules[key].add = true;
                }
            }
            else {
                this.paxRules[key].add = false;
            }
            if(count['adult'] === ''){
                this.paxRules['infant'].add = true;
            }
        }
    }

    closeDialog(btnText: string) {
        if (btnText == 'OK') {
            if (!!this.paxForm.valid) {
                if (this.totalPaxLimit >= this.paxLimit.min && this.totalPaxLimit <= this.paxLimit.max && this.paxForm.value.infant <= this.infantMaxPaxLimit) {
                    this.dialogRef.close({ event: 'close', data: (btnText === 'OK') ? this.paxForm?.value : this.data.paxInfo, action:'ok' });
                } else {
                    if(this.paxForm.value['adult'] == '' && this.paxForm.value['infant'] > 0){
                        this.snackBar.open(`Infant can't  travel without adult  `, "X", { duration: 3000 });
                    }else{
                        if(this.paxLimit.min == 1){
                            this.snackBar.open(`Request minimum 10  Passengers and maximum ${this.paxLimit.max} and Infants maximum limit ${this.infantMaxPaxLimit}`, 'X', { duration: 3000 });
                        }
                        else{
                            this.snackBar.open(`Request minimum ${this.paxLimit.min} Passengers and maximum ${this.paxLimit.max} and Infants maximum limit ${this.infantMaxPaxLimit}`, 'X', { duration: 3000 });
                        }

                    }
                }
            } else {
                if(this.paxForm.value['adult'] == '' && this.paxForm.value['infant'] > 0){
                    this.snackBar.open(`Infant can't  travel without adult  `, "X", { duration: 3000 });
                }else{
                     this.snackBar.open(`Kindly select minimum ${this.paxLimit.min} pax  and maximum ${this.paxLimit.max} pax and Infants maximum limit ${this.infantMaxPaxLimit} `, "X", { duration: 3000 });
                }

            }
        } else if (btnText == 'cancel') {
            this.dialogRef.close({ event: 'close', data: (btnText === 'cancel') ? this.data.paxInfo : this.paxForm?.value, action:'cancel' });
        }
        else if (btnText == 'close') {
            this.dialogRef.close({ event: 'close', data: '' })
        }

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
