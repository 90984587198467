import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(
        private cookieService: CookieService,
    ) { }

    public idleTimeWarning: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);
    public userName: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public userRole: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public existingUserFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public vistaraUserFlag: BehaviorSubject<string> = new BehaviorSubject<string>('Non-Vistara');
    public selectedMenu: BehaviorSubject<string> = new BehaviorSubject<string>('adhoc');

    setUsername() {
        let userProfile = this.getUserDetails();
        let userName = (userProfile.firstname) ? userProfile.firstname : "Guest User";
        this.userName.next(userName);
    }

    setVistaraUserFlag() {
        let userProfile = this.getUserDetails();
        let vistaraUserStatus = userProfile.login_type ? userProfile.login_type : 'Non-Vistara';
        this.vistaraUserFlag.next(vistaraUserStatus);
    }

    setUserRole() {
        let userProfile = this.getUserDetails();
        let userRole = (userProfile.user_role) ? userProfile.user_role : "";
        userRole = (userRole === "ROLE_AGENT") ? 'Agent' : (userRole === "ROLE_USER") ? 'User' :
            (userRole === "ROLE_SUB_AGENT") ? 'SA' : (userRole === "ROLE_ADMIN") ? 'Admin' : (userRole === "ROLE_GS") ? 'GS' : (userRole === "ROLE_SU") ? 'SU' :
                (userRole === "ROLE_DESK_USER") ? 'DU' : "";
        this.userRole.next(userRole);
    }

    setExistingUserFlag() {
        let userProfile = this.getUserDetails();
        let existingUserState = (userProfile !== "" && userProfile?.existingUser === 'Y') ? true : false;
        this.existingUserFlag.next(existingUserState);
    }

    removeUserProfile() {
        this.userName.next('');
        this.userRole.next('');
        this.vistaraUserFlag.next('Non-Vistara');
    }

    getUserDetails() {
        let userProfile = (this.cookieService.get('userDetails')) ? JSON.parse(this.cookieService.get('userDetails')) : "";
        return userProfile;
    }

    setSelectedMenu(inputVal) {
        this.selectedMenu.next(inputVal);
    }

    setIdleCount(inputVal) {
        this.idleTimeWarning.next(inputVal);
    }

}
