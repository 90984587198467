import { Component, Inject, NgZone, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap, take } from "rxjs/operators";
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '../../services/gb-loader.services';
import { GrmsUtils } from '../../static/GRMSUtils';
import { HttpService } from '../../httpService/http.service';
import { GRMS_CONSTANT } from '../../static/constants';
import { LOGIN_API_ENDPOINTS, REGISTER_API_POINTS } from '../../httpService/apiURLConstant';
import { SessionService } from '../../services/session.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-session-model',
    templateUrl: './session-model.component.html',
    styleUrls: ['./session-model.component.scss']
})
export class SessionModelComponent implements OnInit {

    constructor(
        private router: Router,
        private ngZone: NgZone,
        private location: Location,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private sessionService: SessionService,
        private dialogRef: MatDialogRef<SessionModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
    }

    public isSessionExpire = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnInit() {
        this.loaderService.hide();
        this.isSessionExpire = this.data.isSessionExpire;
    }

    deleteCookie() {
        this.dialogRef.close();
        sessionStorage.removeItem('auth_token');
        this.cookieService.delete('auth_token', '/');
        this.cookieService.delete('login_time', '/');
        this.cookieService.delete('userDetails', '/');
        this.cookieService.delete('refresh_token', '/');
        this.cookieService.delete('currentMenu', '/');
        if (sessionStorage.login_type != 'Vistara') {
            sessionStorage.removeItem('login_type');
            this.router.navigate(['/']);
        } else {
            sessionStorage.removeItem('login_type');
            window.location.href = 'https://federation-uat.airvistara.com/adfs/ls/?wa=wsignout1.0';
        }
    }

    extendSession() {
        let userDetails = this.cookieService.get('userDetails');
        let payload = {
            "accessToken": this.cookieService.get('auth_token'),
            "refreshToken": this.cookieService.get('refresh_token')
        }
        this.loaderService.show();
        this.httpService.postCall(REGISTER_API_POINTS.sessionExtend, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    this.ngZone.run(() => {
                        this.dialogRef.close();
                    });
                    let expiryDate = new Date();
                    let loginTime = moment(new Date(), 'YYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A');
                    expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
                    sessionStorage.removeItem('auth_token');
                    sessionStorage.removeItem('login_type');
                    this.cookieService.delete('auth_token', '/');
                    this.cookieService.delete('login_time', '/');
                    this.cookieService.delete('userDetails', '/');
                    this.cookieService.delete('refresh_token', '/');
                    sessionStorage.setItem('auth_token', response.accessToken);
                    sessionStorage.setItem('login_type', userDetails['login_type']);
                    this.cookieService.set("login_time", loginTime, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("userDetails", userDetails, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("auth_token", response.accessToken, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("refresh_token", response.refreshToken, expiryDate, '/', '', false, "Lax");
                    // this.sessionService.setSessionTimeout();
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });

    }

    logoutApp() {
        this.dialogRef.close();
        this.deleteCookie();
    }

}
