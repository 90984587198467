import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as FileSaver from "file-saver";
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe, Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { BOOKING_API_ENPOINTS, BOOKINGVIEW_API_ENPOINTS, ONLINE_API_ENPOINTS, REPORT_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import * as _ from "lodash";
import { HttpService } from 'src/app/shared/httpService/http.service';
import { LoaderService } from '../../shared/services/gb-loader.services';
import { GrmsDiffCals } from '../../shared/static/grms-cals';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { GBRequestGeneralData, AttachmentDetails, AuditDetails } from '../../shared/interface/create-request/gbresponse'
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MenuAccessService } from 'src/app/shared/services/menu-access.service';
import { PopOverContentComponent } from '../../shared/component/pop-over-content/pop-over-content.component';
import { SearchFlightListComponent } from '../search-flight-list/search-flight-list.component';
import { PaxPopupFormComponent } from '../pax-popup-form/pax-popup-form.component';
import { CommanModalComponent } from 'src/app/shared/model/comman-modal/comman-modal.component';
import { GRMS_CONSTANT } from "../../shared/static/constants";

@Component({
    selector: 'gb-request-view',
    templateUrl: './request-view.component.html',
    styleUrls: ['./request-view.component.scss']
})
export class RequestViewComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public readrules: any = {
        "expectedfare": ["QUOTED", "UNDER NEGOTIATION"],
        "confirmquoteaction": ["QUOTED", "UNDER NEGOTIATION"],
        "quotedfare": ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED"],
        "infantquotedfare": ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING"],
        "pnrno": ["QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED"],
        "payment": ["QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        "acceptaction": ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED"],
        "quoteactionvalid": ["QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED", "QUOTE ACCEPTED"],
        "quoteaction": ["OPEN", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED"],
        "negotiateaction": ["QUOTED", "UNDER NEGOTIATION"],
        "requestcancelaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "requestorrequestcancelaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "QUOTE EXPIRED"],
        "quoteexpiry": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "quotevalidtill": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "multiplequoteeaction": ["OPEN"],
        "flightupdateaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "updatepaxaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING"],
        "updateticketaction": ["PAX CONFIRMED"],
        "paxinfopendingaction": ["PNR CREATED"],
        "confirmpaxinfoaction": ["PNR CREATED", "PAX INFO PENDING"],
        "paymentstatusupdateaction": ["QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        "commentaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED", "QUOTE EXPIRED"],
        "anotherquoteeaction": ["QUOTE EXPIRED"],
        "paxsizechangeaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED"],
        "paxchangeaction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED",],
        "similarRequestAction": ["OPEN", "QUOTED", "CUSTOMER CONFIRMED", "UNDER NEGOTIATION", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED", "QUOTE EXPIRED"],
        "enableNegotiationAction": ["QUOTED"],
        "paymentDescriptionAction": ["QUOTE ACCEPTED", "PNR CREATED", "PAX CONFIRMED", "TICKETS ISSUED"],
        'downSizePax': ["PNR CREATED", "PAX INFO PENDING"],
        'downUpPax': ["PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        'materializationRate': ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED",]
    };
    public progressBarList: string[] = ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED", "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED"];
    public dateTimePickerConfig = {
        showSpinners: true,
        showSeconds: false,
        enableMeridian: false,
        stepHour: 1,
        stepMinute: 1,
        stepSecond: 1,
        color: 'primary',
    };
    public tableDatas: any = [];
    public columnHeader: any;
    public similarReqColumnHeader: any;
    public similarReqTableDatas: any = [];
    public childReqColumnHeader: any;
    public childReqTableDatas: any = [];

    public requestId: any;
    public activeIndex: any = [];
    public isClaim: boolean = true;
    private currentStatus: string;
    public quoteValueMinDate: Date = new Date();
    public quoteValueMaxDate: Date = new Date();
    public paymentValueMinDate: Date = new Date();
    public paymentValueMaxDate: Date = new Date();
    private newPaymentDetails: GBRequestGeneralData['newPaymentDetails'] = {}
    private newPaxDetails: GBRequestGeneralData['paxDetails'] = [];
    public paymentDescriptionData1: GBRequestGeneralData['updatePaymentdescription'] = {}
    public paymentDescriptionData2: GBRequestGeneralData['updatePaymentdescriptiondeposite2'] = {}
    public paymentDescriptionData3: GBRequestGeneralData['updatePaymentdescriptiondeposite3'] = {}
    private newAttachmentDetails: AttachmentDetails[] = []
    public isNotPastTravel: boolean = true;
    public gbRequest: GBRequestGeneralData = {
        requestId: '',
        requestStatus: '',
        userTourCode: false,
        isMultipleQuote: false,
        currAssinee: '',
        currAssineeName: '',
        createdBy: '',
        newPaymentDetails: this.newPaymentDetails,
        requesterDetails: {
            userName: '',
            telephoneNumber: '',
            emailID: '',
            iataCode: 0,
            salesPersonEmailId: '',
            state: '',
            city: '',
            pinCode: 0
        },
        requestgeneralData: {
            groupName: '',
            groupType: '',
            companyName: '',
            from: '',
            to: '',
            noOfAdult: 0,
            noOfChild: 0,
            noOfInfant: 0,
            groupSize: 0,
            expectedFarePerPax: 0,
            totalFare: '',
            amountPaid: 0,
            flexibleTravelDates: '',
            tripType: '',
            quoteValidTill: '',
            quotedFarePerPax: 0,
            pnrNo: '',
            infantFarePerPax: 0,
            refundFare: 0,
            revisedTaxFare: 0,
            seatAvailability: '',
            baseFare: 0,
            taxFare: 0,
            infantBaseFare: 0,
            infantTaxFare: 0,
            seatAvailabilityLastUpdate: '',
            finalQuote: false,
            corporateCode: '',
            toBePaid: 0,
            ticketingAmount: 0,
            groupsizelimit: 0,
            createdDate: '',
            lastModifiedDate: '',
            materializationRate: 0,
            paymentStatus: '',
            notifyOthers: ''
        },
        itinerayDetails: [{
            itineraryno: '',
            from: '',
            to: '',
            classOfTravel: '',
            departureDate: '',
            arrivalDate: '',
            flightNumber: '',
            flightTime: '',
            availableSeats: '',
            netAvailableSeats: 0,
            totalseatcapacity: 0,
            status: '',
            travelDuration: '',
            stops: '',
        }
        ],
        updatePaymentdescription: {
            atch: this.newAttachmentDetails,
            penaltyattachment: '',
            refundattachment: '',
            miscellaneousattachment: '',
            penaltyAmount: '',
            penaltyDueDate: '',
            penaltyPaymentRemarks: '',
            penaltyPaymentStatus: '',
            refundAmount: '',
            refundDueDate: '',
            refundPaymentRemarks: '',
            refundPaymentStatus: '',
            miscellaneousCharges: '',
            miscellaneousChargesDueDate: '',
            miscellaneousPaymentRemarks: '',
            miscellaneousPaymentStatus: '',
            createdBy: '',
            lastUpdatedBy: '',
            requestId: '',
            paymentDescriptionId: '',
            ticketingAmount1: '',
        },
        updatePaymentdescriptiondeposite2: {
            atch: this.newAttachmentDetails,
            penaltyattachment: '',
            refundattachment: '',
            miscellaneousattachment: '',
            penaltyAmount: '',
            penaltyDueDate: '',
            penaltyPaymentRemarks: '',
            penaltyPaymentStatus: '',
            refundAmount: '',
            refundDueDate: '',
            refundPaymentRemarks: '',
            refundPaymentStatus: '',
            miscellaneousCharges: '',
            miscellaneousChargesDueDate: '',
            miscellaneousPaymentRemarks: '',
            miscellaneousPaymentStatus: '',
            createdBy: '',
            lastUpdatedBy: '',
            requestId: '',
            paymentDescriptionId: '',
            ticketingAmount2: '',
        },
        updatePaymentdescriptiondeposite3: {
            atch: this.newAttachmentDetails,
            penaltyattachment: '',
            refundattachment: '',
            miscellaneousattachment: '',
            penaltyAmount: '',
            penaltyDueDate: '',
            penaltyPaymentRemarks: '',
            penaltyPaymentStatus: '',
            refundAmount: '',
            refundDueDate: '',
            refundPaymentRemarks: '',
            refundPaymentStatus: '',
            miscellaneousCharges: '',
            miscellaneousChargesDueDate: '',
            miscellaneousPaymentRemarks: '',
            miscellaneousPaymentStatus: '',
            createdBy: '',
            lastUpdatedBy: '',
            requestId: '',
            paymentDescriptionId: '',
            ticketingAmount3: '',
        },
        paymentDetails: [
            {
                paymentMode: '',
                paymentStatus: '',
                totalAmount: 0,
                amountPaid: 0,
                recCreatedId: 0,
                utrNo: ''
            }
        ],
        paymentStatus: '',
        paxDetails: this.newPaxDetails,
    }
    private loginUserID: any;
    public currentrole: string;
    public currentassignee: boolean;
    @ViewChild('quotevalidtill') quotevalidtill: any;
    @ViewChild('paymentDeadline') paymentDeadline: any;
    public quotevalidtillMax: Date;
    public acceptValidTill: Date;
    public acceptValidTillMax: Date;
    public isFlightDetailsUpdated: boolean = false;
    private userDetails;
    public quotevalidtillControl = new FormControl();
    public paymentvalidtillControl = new FormControl();
    public paymentDeadlineValid: any;
    public loginUserRole;
    public showSimilarRequestDetails: boolean = false;
    public showChildRequestDetails: boolean = false;
    private seRequest = { "loginUserID": '', "requestId": '' }
    public paymentDetailsToShow: any = [];
    public isQuoteExpiryEnabled: boolean = true;
    public offlineAmountPaid: number = 0;
    public totalAmount: number = 0;
    public totalAmountPaid: number = 0;
    public somePaymentDoneFlag: boolean = false;
    private totalAmountReceived: number = 0;
    private totalAmountNotReceived: number = 0;
    public totalAmountToBePaid: number = 0;
    public ticketingAmount: any;
    public paymentFileData: File;
    public utrNo: string = "";
    public fdDate: any;
    public finalDepositDate: any;
    public finalDepositFlag: boolean;
    public idDate: any;
    public initialDepositDate: any;
    public secondDepositDate: any;
    public secondDepositFlag: boolean;
    public onlineAmountPaid: number = 0;
    public editPaymentDescFlagDeposit1 = false;
    public editPaymentDescFlagDeposit2 = false;
    public editPaymentDescFlagDeposit3 = false;
    isNaN: Function = Number.isNaN;
    public paymentstatuslist: string[] = ["INVALID PAYMENT", "PARTIAL PAYMENT DONE", "PAYMENT DONE"];
    public getPenaltyDeposit1: any;
    public getMiscellDeposit1: any;
    public getPenaltyDeposit2: any;
    public getMiscellDeposit2: any;
    public getPenaltyDeposit3: any;
    public getMiscellDeposit3: any;
    public getRefundDeposit1: any;
    public getRefundDeposit2: any;
    public getRefundDeposit3: any;
    public diffInTime: any;
    public diffeInDays: any;
    public isEligibleForPayementStatusChange: boolean = false;
    public paxLimitDetail: any;
    public setPaxLimit: any;
    public showPaxDetails: boolean = false;
    public enableFlightDetailsToUpdate: boolean;
    public isPaxUpdate: boolean = false;
    public isCancelRequestEnabled: boolean = false;
    public ticketingAmt1: any;
    public ticketingAmt2: any;
    public ticketingAmt3: any;
    public enablePaymentDeadline: boolean;
    public adultCount: any;
    public childCount: any;
    public infantCount: any;
    public oldAdultValue: any;
    public newAdultValue: any;
    public oldChildValue: any;
    public newChildValue: any;
    public oldInfantValue: any;
    public newInfantValue: any;
    public audlogList: AuditDetails[];
    public paymentMode: any = 'OFFLINE';
    public enableIssueTicketBtn: boolean;
    public destinationCodeVal = '';
    public tripItineraryInfo = [];
    public finalItineraryArr = [];
    public itineraryDetails = [];
    public showComments = false;
    public originCodeVal = '';
    public tripType;
    public isGSAUser = 'N';
    public showRequest = true;
    public childRequestAvailable: any;
    public isRefresh = false;
    public hideAllButton = false;
    public iataCodeVal: any;
    public infantMaxPaxLimit = 4;
    public PaxExpanseList = ["PAYMENT DONE", 'PNR CREATED', 'PAX CONFIRMED', 'TICKET ISSUED'];
    public generalExpanseList = ['OPEN', 'QUOTED', 'UNDER NEGOTIATION', 'CUSTOMER CONFIRMED'];
    public PaymentExpanseList = ["PARTIAL PAYMENT DONE", "PAYMENT OVERDUE", "ADVANCE OVERDUE", "INVALID PAYMENT", "AWAITING CONFIRMATION"];
    public isPaymentExpanse = false;
    public isGenrealExpanse = true;
    public isPaxExpanse = false;
    private getAmountPercentage: any;
    public preMaxLimit: number;
    public busMaxLimit: number;
    private currentPaxCount: number;
    public isTicketPopupOpened = false;
    private balancePercentage: number;
    public validScheduleDate = true;
    public previousItineraryDetails: any;
    public isGSARequest = false;
    public showPaxTable = false;

    constructor(private router: ActivatedRoute,
        private routerNav: Router,
        private loaderService: LoaderService,
        private snackBar: MatSnackBar,
        private datePipe: DatePipe,
        private httpService: HttpService,
        private grmsUtils: GrmsUtils,
        private cookieService: CookieService,
        private menuService: MenuAccessService,
        private matDialog: MatDialog,
        private location: Location,
        private changeDetector: ChangeDetectorRef
    ) { }
    ngOnInit(): void {
        this.menuService.hideAndShowMenu();
        this.isTicketPopupOpened = false;
        this.userDetails = this.grmsUtils.getUserDetails();
        this.loginUserID = this.userDetails.loginUserId;
        this.loginUserRole = this.userDetails.user_role;
        this.currentrole = (this.userDetails.user_role === "ROLE_ADMIN" || this.userDetails.user_role === "ROLE_DESK_USER"
            || this.userDetails.user_role === "ROLE_GS" || this.userDetails.user_role === "ROLE_SU") ? "user" : "requester";
        this.currentassignee = true;
        this.router.params.forEach((params: Params) => {
            this.requestId = params["id"];
            if (this.requestId) {
                this.seRequest.requestId = this.requestId;
                this.seRequest.loginUserID = this.loginUserID;
                this.setPaxLimit = 0;
                this.tableDatas = []
                this.getRequestDetails()
            }
        });
    }

    reloadCommentComp() {
        this.showComments = false;
        this.changeDetector.detectChanges();
        this.showComments = true;
    }

    getRequestDetails() {
        this.loaderService.show();
        this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.searchById, this.seRequest, this.grmsUtils.getAuthWithTextHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    response = JSON.parse(this.grmsUtils.decryptedData(response));
                    if (response["requestDetailsList"].length === 0) {
                        this.showRequest = false;
                        return false;
                    }
                    this.showRequest = true;
                    let getResponse: GBRequestGeneralData = response["requestDetailsList"][0];
                    this.childRequestAvailable = response["requestDetails"]?.childRequestAvailable ? response["requestDetails"]?.childRequestAvailable : '';
                    this.gbRequest = getResponse;
                    this.isRefresh = true;
                    this.reloadCommentComp();
                    this.showComments = true;
                    this.seRequest.requestId = response['requestDetails'].requestId;
                    this.showPaxTable = true;
                    this.iataCodeVal = (this.gbRequest.requesterDetails?.iataCode !== 0) ? this.gbRequest.requesterDetails?.iataCode : '';
                    if (this.isGSAUser == 'Y' && response['requestDetails'].requestStatus === 'QUOTE ACCEPTED') {
                        this.hideAllButton = true;
                    } else {
                        this.hideAllButton = false;
                    }

                    if (this.PaxExpanseList.indexOf(response['requestDetails'].requestStatus) > -1 &&
                        this.PaymentExpanseList.indexOf(response['requestDetails'].paymentStatus) < 0) {
                        this.isPaxExpanse = true;
                        this.isPaymentExpanse = false;
                        this.isGenrealExpanse = false;
                    } else if (this.PaymentExpanseList.indexOf(response['requestDetails'].paymentStatus) > -1) {
                        this.isPaxExpanse = false;
                        this.isPaymentExpanse = true;
                        this.isGenrealExpanse = false;
                    } else if (this.generalExpanseList.indexOf(response['requestDetails'].requestStatus) > -1) {
                        this.isPaxExpanse = false;
                        this.isPaymentExpanse = false;
                        this.isGenrealExpanse = true;
                    }

                    this.itineraryDetails = this.gbRequest.itinerayDetails;
                    this.tripType = this.gbRequest.requestgeneralData.tripType;
                    this.isGSAUser = this.gbRequest.requesterDetails['gsaUser'];
                    this.isGSARequest = this.gbRequest.requestId.includes('GSA');
                    let expiryDate = new Date();
                    expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);

                    this.cookieService.set('currentMenu', (this.isGSARequest) ? 'gsa' : 'adhoc', expiryDate, '/', '', false, "Lax");
                    this.constructItineraryDetails(this.gbRequest.requestgeneralData);

                    this.currentStatus = response['requestDetailsList'][0].requestStatus;
                    this.quotevalidtill = !!this.gbRequest.requestgeneralData.quoteValidTill ? this.gbRequest.requestgeneralData.quoteValidTill : '';
                    let userPaxUpdate = true;


                    this.enableIssueTicketBtn = true;
                    if ((!!this.gbRequest.currAssinee.includes('DESK_USER') || this.gbRequest.currAssinee.indexOf("DESK_USER") > -1) && (this.loginUserRole === "ROLE_ADMIN" || this.loginUserRole === "ROLE_DESK_USER"
                        || this.loginUserRole === 'ROLE_GS' || this.loginUserRole === 'ROLE_SU')) {
                        this.isClaim = false;
                        userPaxUpdate = false;
                        this.enableIssueTicketBtn = false;
                    } else if ((this.gbRequest.currAssinee.indexOf(this.loginUserID) > -1) && (this.loginUserRole === "ROLE_ADMIN" || this.loginUserRole === "ROLE_DESK_USER"
                        || this.loginUserRole === 'ROLE_GS' || this.loginUserRole === 'ROLE_SU')) {
                        this.isClaim = true;
                    }
                    this.quoteValueMaxDate = new Date(this.gbRequest.itinerayDetails[0].departureDate)
                    if (this.quoteValueMaxDate.getTime() < new Date().getTime()) {
                        this.isNotPastTravel = false;
                    }
                    if (!!this.quotevalidtill) {
                        let getQuoteVal = moment(this.gbRequest.requestgeneralData.quoteValidTill)
                        getQuoteVal['_i'];
                        this.quotevalidtillControl.setValidators(null);
                        this.quotevalidtillControl.setErrors(null);
                        this.quotevalidtillControl.setValue(new Date(getQuoteVal['_i']))
                        this.quotevalidtill = this.quotevalidtillControl.value;
                        this.currentassignee = true;
                    } else {
                        this.quotevalidtillControl = new FormControl();
                    }

                    if (!!this.gbRequest.requestgeneralData.acceptValidTill) {
                        let getPaymentDeadline = moment(this.gbRequest.requestgeneralData.acceptValidTill)
                        getPaymentDeadline['_i'];

                        this.paymentvalidtillControl.setValidators(null);
                        this.paymentvalidtillControl.setErrors(null);
                        this.paymentvalidtillControl.setValue(new Date(getPaymentDeadline['_i']))
                        this.paymentDeadlineValid = this.paymentvalidtillControl.value;
                        this.paymentValueMaxDate = new Date(this.gbRequest.itinerayDetails[0].departureDate)

                    }
                    else {
                        this.paymentvalidtillControl = new FormControl();
                        this.paymentValueMaxDate = new Date(this.gbRequest.itinerayDetails[0].departureDate)
                    }


                    /**Setting Up current Assignee**/
                    this.paxLimitDetail = this.gbRequest;
                    this.adultCount = this.gbRequest.requestgeneralData.noOfAdult;
                    this.childCount = this.gbRequest.requestgeneralData.noOfChild;
                    this.infantCount = this.gbRequest.requestgeneralData.noOfInfant;

                    if (this.gbRequest.paymentStatus === "AWAITING CONFIRMATION") {
                        this.isEligibleForPayementStatusChange = true;
                    } else { this.isEligibleForPayementStatusChange = false; }

                    if (!this.currentassignee) {
                        this.isQuoteExpiryEnabled = false;
                    }

                    if (this.loginUserRole === "ROLE_ADMIN" || this.loginUserRole === "ROLE_DESK_USER"
                        || this.loginUserRole === 'ROLE_GS' || this.loginUserRole === 'ROLE_SU') {
                        if (this.gbRequest.requestStatus === "PNR CREATED" && this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE") {
                            this.enableFlightDetailsToUpdate = true;
                        } else {
                            this.enableFlightDetailsToUpdate = false;
                        }
                    } else {
                        this.enableFlightDetailsToUpdate = false;
                    }

                    if (this.loginUserRole === "ROLE_ADMIN" || this.loginUserRole === "ROLE_DESK_USER"
                        || this.loginUserRole === 'ROLE_GS' || this.loginUserRole === 'ROLE_SU') {
                        if ((this.gbRequest.requestStatus === "QUOTE ACCEPTED" && this.gbRequest.paymentStatus !== "PARTIAL PAYMENT DONE") &&
                            (this.gbRequest.requestStatus === "QUOTE ACCEPTED" && this.gbRequest.paymentStatus !== "AWAITING CONFIRMATION") &&
                            (this.gbRequest.requestStatus === "QUOTE ACCEPTED" && this.gbRequest.paymentStatus !== "PAYMENT DONE")) {
                            this.enablePaymentDeadline = true;
                        } else {
                            this.enablePaymentDeadline = false;
                        }
                    } else {
                        this.enablePaymentDeadline = false;
                    }

                    this.paymentDetailsToShow = this.gbRequest.paymentDetails.filter(p => p.paymentStatus !== "INVALID PAYMENT" && p.paymentStatus !== "INITIATED" && p.paymentStatus !== 'FAILED' && p.paymentStatus !== 'CANCELED');
                    this.paymentMode = !!this.paymentDetailsToShow[0]?.paymentMode ? this.paymentDetailsToShow[0]?.paymentMode : this.paymentMode;
                    this.loadAuditLogs()
                    this.progressChange();
                    this.paxUpdate();
                    this.loaderService.hide();
                    // this.loadFactor('val')
                    this.similarReqTableDatas = [];
                    if (!this.isTicketPopupOpened && this.gbRequest['ticketStatus'] === 'TICKETS INPROGRESS' || this.gbRequest['ticketStatus'] === 'TICKETING ERROR') {
                        let isTicketError = (this.gbRequest['ticketStatus'] === 'TICKETING ERROR') ? true : false;
                        this.isTicketPopupOpened = true;
                        const dialogRef = this.matDialog.open(CommanModalComponent, {
                            disableClose: true,
                            width: '42%',
                            height: '22%',
                            data: { "isTicketError": isTicketError, 'showTicketPopup': true, 'showSeriesRequest': false, 'isDeletePopup': false }
                        })

                        dialogRef.afterClosed().subscribe(result => {
                        });
                    }

                },

                error: (errorDetails) => {
                    this.loaderService.hide();
                    if (errorDetails.error?.requestDetailsList?.length === 0) {
                        this.showRequest = false;
                        return false;
                    }
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })
    }

    reFreshDetails() {
        this.isRefresh = false;
        this.routerNav.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.routerNav.navigate([decodeURI(this.location.path())])
        });
        // this.getRequestDetails()
    }

    progressChange() {
        this.activeIndex = [];
        for (let i = 0; i < this.progressBarList.length; i++) {
            if (this.currentStatus === 'REQUEST CANCELED') {
                this.activeIndex.push(0);
                return;
            } else {
                this.activeIndex.push(i);
                if (this.progressBarList[i] == this.currentStatus) {
                    return;
                }
                if (this.currentStatus == 'QUOTE EXPIRED') {
                    this.activeIndex.push(2);
                    return;
                }
            }
        }
    }

    loadAuditLogs() {
        let workLogInfo = {
            "requestId": this.gbRequest.requestId,
            "loginUserID": this.userDetails.loginUserId,
            "loginUserType": this.userDetails.user_role,
            "actionType": "AUDIT_PARENT"
        }

        this.httpService.postCall(REPORT_API_ENDPOINTS.worklogs, workLogInfo, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.audlogList = response.requestDetailsList[0].auditLogDetails;
                    for (let aud of this.audlogList) {
                        if (aud.action === "ACCEPT") {
                            let initialDate = aud.createdDate;
                            initialDate = initialDate.split(' ')[0];
                            this.idDate = new Date(initialDate);
                            this.initialDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                            break;
                        }
                    }

                    this.paymentCalculating()
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    assineeDetils() {
        this.gbRequest['isInternational'] = !!this.gbRequest['isInternational'] ? true : false;
        this.gbRequest.currAssinee = this.loginUserID;
        this.gbRequest.currAssineeName = this.userDetails.firstname;
        this.gbRequest['loginUserID'] = this.loginUserID;
        this.gbRequest['loginUserType'] = this.userDetails.user_role;
    }

    updateRequest(eventProp?: any, actionType?: any) {
        this.loaderService.show();
        this.assineeDetils();
        this.gbRequest['loginUserID'] = this.loginUserID;
        this.gbRequest = this.getPaymentTableName(this.gbRequest, eventProp);
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest));
        this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.updateRequest, inputData, this.grmsUtils.getAuthWithTextHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    this.loaderService.hide();
                    response = JSON.parse(this.grmsUtils.decryptedData(response));
                    if (response['statusCode'] !== 200) {
                        this.snackBar.open(response['statusMsg'], 'X', { duration: 3000 });
                    } else {
                        this.gbRequest = response["requestDetails"];
                        this.currentStatus = response['requestDetails'].requestStatus;
                        if (actionType === 'downSize' && this.gbRequest.paymentStatus === 'PAYMENT DONE' && response['refundEligible']) {
                            this.updateRefundToReport(eventProp);
                        }
                        if (eventProp >= 0 && actionType === 'downSize' && this.gbRequest.paymentStatus === 'PARTIAL PAYMENT DONE') {
                            this.loaderService.show();
                            this.getDepositWiseTa(eventProp);
                            return;
                        } else if (actionType !== 'downSize') {
                            if (this.isGSAUser == 'Y' && eventProp === 'Quote accepted') {
                                this.hideAllButton = true;
                            }
                            if (eventProp != 'Ticketed') {
                                this.snackBar.open(`${eventProp} successfully`, 'X', { duration: 3000 })
                            }
                            this.reFreshDetails();
                        }
                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    if (eventProp === 'Flight details updated') {
                        this.gbRequest.itinerayDetails = this.previousItineraryDetails;
                    }
                    if (errorDetails.error['statusCode'] === 789) {
                        this.snackBar.open(errorDetails.error['errMsgs'][0], 'X', { duration: 3000 });
                        return false;
                    }
                    if (eventProp === 'Payment status updated') {
                        this.getRequestDetails();
                    }
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })

    }

    claimRequest($event) {
        this.loaderService.show();
        let status;
        if ($event == 'claim') {
            this.isClaim = false;
            this.gbRequest['actionType'] = 'CLAIM';
            this.gbRequest.currAssinee = this.loginUserID;
            status = 'Claimed'
        } else {
            this.isClaim = true;
            this.gbRequest['actionType'] = 'UNCLAIM';
            status = 'Unclaimed'
        }
        this.updateRequest(status)
    }

    isEligibleFor(roles: any, modulename: any): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (roles[i] === this.currentrole) {
                if (this.currentassignee) {
                    if (this.readrules[modulename].indexOf(this.gbRequest.requestStatus) > -1) {
                        return false;
                    }
                }
                if (modulename === "paxsizechangeaction" || modulename === "paxchangeaction" || modulename === "enableNegotiationAction" ||
                    modulename === "paymentDescriptionAction") {
                    if (this.readrules[modulename].indexOf(this.gbRequest.requestStatus) > -1) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    dateChange(el, event: MatDatepickerInputEvent<Date>) {
        let firstItrTimes = this.gbRequest.itinerayDetails[0].flightTime.split('-');
        let getFirstItrDateTime = this.gbRequest.itinerayDetails[0].departureDate + ' ' + firstItrTimes[0];
        let firstItrDateTime = moment(getFirstItrDateTime);
        if (new Date().getTime() < new Date(firstItrDateTime['_i']).getTime() && new Date(firstItrDateTime['_i']).getTime() > new Date(event.value).getTime()) {
        } else {
            this.snackBar.open("Kindly select quote valid date between these date " + new Date() + " to " + this.gbRequest.itinerayDetails[0].departureDate, 'X', { duration: 3000 });
        }
    }

    searchSimilarRequests(event?) {
        this.loaderService.show();
        this.seRequest.requestId = this.gbRequest.requestId;
        this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.SimilarRequest, this.seRequest, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                next: (responseDetails: any) => {
                    this.loaderService.hide();
                    if (responseDetails.statusCode === 202) {
                        this.snackBar.open('No Records Found for Similar Requests', 'X', { duration: 3000 })
                        if (event != 'similarTabSec') {
                            this.updateRequest(event)
                        }

                    } else {
                        this.similarReqTableDatas = this.mapRequestDetailsIntoList(responseDetails['requestDetailsList']);
                        if (event != 'similarTabSec') {
                            //   this.updateRequest(event)
                            if (this.similarReqTableDatas.length > 0) {
                                let dialogRef = this.matDialog.open(PopOverContentComponent, {
                                    disableClose: true,
                                    width: '31%',
                                    data: { data: 'Similar Requests available for this request', action: 'similar' }
                                })
                                dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                                    if (result?.data == 'isSimilar' && event != 'similarTabSec') {
                                        this.updateRequest(event)
                                    }
                                })
                                //this.snackBar.open('There are some Similar Requests also available ', 'X', { duration: 3000 })
                            } else {
                                this.snackBar.open('No Records Found for Similar Requests', 'X', { duration: 3000 })
                                if (event != 'similarTabSec') {
                                    this.updateRequest(event)
                                }
                            }
                        }
                    }

                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })
    }

    /**Child Request Start **/
    childDetailsInfoList(requestDetailsList: any) {
        this.childReqColumnHeader = {
            requestId: "Request ID",
            requestStatus: 'Request Status',
            noOfAdult: 'Adult',
            noOfChild: 'Child',
            noOfInfant: 'Infant',
            expectedFarePerPax: 'Expected Fare Per Pax',
            quotedFarePerPax: 'Quoted Fare Per Pax'
        }
        let requestDataList = [];
        for (let requestDetails of requestDetailsList) {
            let request = {
                'requestId': '', 'requestStatus': '', 'noOfAdult': '', 'noOfChild': '', 'noOfInfant': '', 'expectedFarePerPax': '',
                'quotedFarePerPax': ''
            }
            request.requestId = requestDetails.requestId;
            request.requestStatus = requestDetails.requestStatus;
            request.noOfAdult = requestDetails.noOfAdult;
            request.noOfChild = requestDetails.noOfChild;
            request.noOfInfant = requestDetails.noOfInfant;
            request.expectedFarePerPax = requestDetails.expectedFarePerPax;
            request.quotedFarePerPax = requestDetails.quotedFarePerPax;
            requestDataList.push(request);
        }
        return requestDataList;
    }

    searchChildRequests(isFrom?) {
        let previousPaxCount: number = 0;
        let previousInfantPaxCount: number = 0;
        this.loaderService.show();
        this.httpService.postCall(REPORT_API_ENDPOINTS.getChildRequest, this.seRequest, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                next: (responseDetails: any) => {
                    this.loaderService.hide()
                    this.childReqTableDatas = this.childDetailsInfoList(responseDetails.requestDetails.childRequestDetails);
                    if (this.childReqTableDatas.length > 0 && isFrom === 'isPaxUpSize') {
                        this.childReqTableDatas.forEach(childEle => {
                            previousPaxCount += childEle.noOfAdult + childEle.noOfChild;
                            previousInfantPaxCount += childEle.noOfInfant;
                        });
                        if (previousPaxCount >= 9 && previousInfantPaxCount >= 4) {
                            this.snackBar.open(`Could not able to upsize already limit exists`, 'X', { duration: 3000 });
                        } else {
                            this.paxUpSizeCountIncrease(previousPaxCount, previousInfantPaxCount)
                        }
                    } else if (isFrom === 'isPaxUpSize') {
                        this.paxUpSizeCountIncrease(previousPaxCount, previousInfantPaxCount)
                    }
                    return;

                },
                error: () => {

                }
            });
    }

    loadFactor(val?: string) {
        // this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.loadFactor, { requestId: this.gbRequest.requestId }, this.grmsUtils.getAuthHeader())
        //     .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        //         next: (res) => {
        //         },
        //         error: (errorDetails) => {
        //             this.grmsUtils.errorHandling(errorDetails);
        //         }
        //     })
        this.submitQuote(val)
    }

    submitQuote(val: string) {
        let isValidAcceptRequest = this.checkValidAcceptOption(this.gbRequest.itinerayDetails);
        this.gbRequest.requestStatus = this.gbRequest.requestStatus;
        this.quotevalidtill = this.datePipe.transform(this.quotevalidtillControl.value, 'dd-MMM-yyyy H:mm:ss')
        if (this.gbRequest['materializationRate'] > 0) {
            this.gbRequest['materializationRate'] = 0;
            this.snackBar.open("Current status materialization rate not rquired so we are reset into 0 ", 'X', { duration: 3000 })
        }
        if (!!this.quotevalidtill && Number(this.gbRequest.requestgeneralData['quotedFarePerPax']) > 0) {
            if (!this.gbRequest.isInternational && this.gbRequest.requestgeneralData.noOfInfant > 0 && this.gbRequest.requestgeneralData.infantFarePerPax <= 0) {
                this.snackBar.open("Quoted Fare Per Infant must be greater than 0", 'X', { duration: 3000 });
                return;
            }
            if (new Date(this.quotevalidtill).getTime() < new Date(this.gbRequest.itinerayDetails[0].departureDate).getTime()) {
                if (isValidAcceptRequest) {
                    this.searchSimilarRequests('Quoted');
                    this.gbRequest.requestgeneralData['quoteValidTill'] = this.datePipe.transform(this.quotevalidtill, 'dd-MMM-yyyy H:mm:ss')
                    this.gbRequest['actionType'] = 'QUOTE';
                } else {
                    this.snackBar.open("Kindly update the flight number and time in 'Itinerary Information' section", 'X', { duration: 3000 });
                }
            } else {
                this.snackBar.open("Quote valid date should be before the first Itinerary departure date  ", 'X', { duration: 3000 });
            }
        } else {
            this.snackBar.open('Kindly Update Quoted Fare Per Pax should be greater than 0 and Quote Valid Till field ', 'X', { duration: 3000 })
        }

    }

    negotiateRequest() {
        this.gbRequest['actionType'] = 'NEGOTIATION';
        this.updateRequest('Negotiation updated')
    }

    paymentValidity() {
        let diffDaysFromNow = 0;
        let diffDays = 0;
        if (this.currentrole === "user") {
            this.paymentDeadline = this.datePipe.transform(this.paymentvalidtillControl.value, 'dd-MMM-yyyy H:mm:ss ');
            diffDaysFromNow = GrmsDiffCals.findDayDifference(new Date(), this.paymentDeadline);
            diffDays = GrmsDiffCals.findDayDifference(this.paymentDeadline, this.gbRequest.itinerayDetails[0].departureDate);

            if (diffDays >= -0.99 && diffDaysFromNow >= -0.99) {
                if (!GrmsDiffCals.findTimeDifferenceStatus(new Date(this.paymentDeadline))) {
                    this.snackBar.open("Kindly select paymnet validity date between these date " + new Date() + " to " + this.gbRequest.itinerayDetails[0].departureDate, "X", { duration: 3000 });
                    return;
                }
                this.gbRequest.requestgeneralData.acceptValidTill = this.datePipe.transform(this.paymentDeadline, 'dd-MMM-yyyy H:mm:ss ');
                this.gbRequest['actionType'] = 'UPDATE_AUTO_EXPIRY_TIMELINE';
                this.updateRequest('Payment  validity')

            } else {
                this.snackBar.open("Kindly select paymnet validity date between these date " + new Date() + " to " + this.gbRequest.itinerayDetails[0].departureDate, "X", { duration: 3000 });
            }
        } else {
            this.snackBar.open("You cannot perform this action", "X", { duration: 3000 });
        }

    }

    acceptQuote() {
        this.quotevalidtill = this.datePipe.transform(this.quotevalidtillControl.value, 'dd-MMM-yyyy H:mm:ss')
        if (!!this.quotevalidtill && Number(this.gbRequest.requestgeneralData['quotedFarePerPax']) > 0) {
            if (!this.gbRequest.isInternational && this.gbRequest.requestgeneralData.noOfInfant > 0 && this.gbRequest.requestgeneralData.infantFarePerPax <= 0) {
                this.snackBar.open("Quoted Fare Per Infant must be greater than 0", 'X', { duration: 3000 });
                return;
            }
            if (new Date(this.quotevalidtill).getTime() < new Date(this.gbRequest.itinerayDetails[0].departureDate).getTime()) {
                let isValidAcceptRequest = this.checkValidAcceptOption(this.gbRequest.itinerayDetails);
                if (isValidAcceptRequest) {
                    this.searchSimilarRequests('Quote accepted');
                    this.gbRequest.requestgeneralData['quoteValidTill'] = this.datePipe.transform(this.quotevalidtillControl.value, 'dd-MMM-yyyy H:mm:ss')
                    this.gbRequest['actionType'] = "ACCEPT";
                } else {
                    this.snackBar.open("Kindly update the flight number and time in 'Itinerary Information' section", 'X', { duration: 3000 });
                }
            } else {
                this.snackBar.open("Quote valid date should be before the first Itinerary departure date  ", 'X', { duration: 3000 });
            }
        } else {
            this.snackBar.open('Kindly Update Quoted Fare Per Pax and Quote Valid Till field ', 'X', { duration: 3000 })
        }

    }

    confirmQuote() {
        this.gbRequest.requestgeneralData['quoteValidTill'] = this.datePipe.transform(this.quotevalidtill, 'dd-MMM-yyyy H:mm:ss')
        this.gbRequest['actionType'] = "FARE_ACCEPT";
        this.updateRequest('Quote confirmed ')
    }

    quoteValidity() {
        let diffDaysFromNow = 0;
        let diffDays = 0;
        if (this.currentrole === "user") {
            this.quotevalidtill = this.quotevalidtillControl.value
            diffDaysFromNow = GrmsDiffCals.findDayDifference(new Date(), this.quotevalidtill);
            diffDays = GrmsDiffCals.findDayDifference(this.quotevalidtill, this.gbRequest.itinerayDetails[0].departureDate);

            if (diffDays >= -0.99 && diffDaysFromNow >= -0.99) {
                if (!GrmsDiffCals.findTimeDifferenceStatus(new Date(this.quotevalidtill))) {
                    this.snackBar.open("Kindly select quote valid date between these date " + new Date() + " to " + this.gbRequest.itinerayDetails[0].departureDate, "X", { duration: 3000 });
                    return;
                }
                this.gbRequest.requestgeneralData.quoteValidTill = this.datePipe.transform(this.quotevalidtill, 'dd-MMM-yyyy H:mm:ss')
                this.gbRequest['actionType'] = "UPDATE_QUOTE_VALID_DATE";
                this.loaderService.show();
                this.updateRequest('Quote validity date updated ')

            } else {
                this.snackBar.open("Kindly select quote valid date between these date " + new Date() + " to " + this.gbRequest.itinerayDetails[0].departureDate, "X", { duration: 3000 });
            }
        } else {
            this.snackBar.open("You cannot perform this action", "X", { duration: 3000 });
        }
    }

    checkValidAcceptOption(requestFormDetails: any) {
        for (let i = 0; i < requestFormDetails.length; i++) {
            if ((requestFormDetails[i].flightNumber !== null && requestFormDetails[i].flightNumber !== "undefined" && requestFormDetails[i].flightNumber !== "") &&
                (requestFormDetails[i].flightTime !== null && requestFormDetails[i].flightTime !== "undefined" && requestFormDetails[i].flightTime !== "")) {
            } else
                return false;
        }
        return true;
    }

    isValidFile(file: File): boolean {
        if (file.size / (1024 * 1024) > 16) {
            this.snackBar.open("Kindly enter file less than 16MB", 'X', { duration: 3000 });
            return false;
        }
        let supportedFileList = [".pdf", ".jpg", ".png", ".zip", ".doc", ".docx", ".JPG"];
        let isValidFile: boolean = false;
        for (let fileType of supportedFileList) {
            if (file.name.endsWith(fileType)) {
                isValidFile = true;
            }
        }
        if (!isValidFile) {
            this.snackBar.open("File type should be .pdf, .jpg, .JPG, .png, .zip, .doc or .docx", 'X', { duration: 3000 });

        }
        return isValidFile;
    }

    loadPaymentFile(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            if (this.isValidFile(file)) {
                this.paymentFileData = file;
            } else {
                this.paymentFileData = undefined;
                return false;
            }
        } else {
            this.paymentFileData = undefined;
        }
    }

    paymentDataMapDeposit1() {
        this.paymentDescriptionData1['penaltyAmount'] = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyAmount') ? this.gbRequest.updatePaymentdescription.penaltyAmount : '';
        this.paymentDescriptionData1.penaltyDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescription.penaltyDueDate : '';
        this.paymentDescriptionData1.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescription.penaltyPaymentRemarks : '';
        this.paymentDescriptionData1.penaltyPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescription.penaltyPaymentStatus : '';
        this.paymentDescriptionData1.refundAmount = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundAmount') ? this.gbRequest.updatePaymentdescription.refundAmount : '';
        this.paymentDescriptionData1.refundDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescription.refundDueDate : '';
        this.paymentDescriptionData1.refundPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('rprefundPaymentRemarksr') ? this.gbRequest.updatePaymentdescription.refundPaymentRemarks : '';
        this.paymentDescriptionData1.refundPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescription.refundPaymentStatus : '';
        this.paymentDescriptionData1.miscellaneousCharges = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousCharges') ? this.gbRequest.updatePaymentdescription.miscellaneousCharges : '';
        this.paymentDescriptionData1.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescription.miscellaneousChargesDueDate : '';
        this.paymentDescriptionData1.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescription.miscellaneousPaymentRemarks : '';
        this.paymentDescriptionData1.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescription.miscellaneousPaymentStatus : '';
        this.editPaymentDescFlagDeposit1 = true;
    }

    paymentDataMapDeposit2() {
        this.paymentDescriptionData2['penaltyAmount'] = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyAmount') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyAmount : '';
        this.paymentDescriptionData2.penaltyDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyDueDate : '';
        this.paymentDescriptionData2.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyPaymentRemarks : '';
        this.paymentDescriptionData2.penaltyPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyPaymentStatus : '';
        this.paymentDescriptionData2.refundAmount = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundAmount') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundAmount : '';
        this.paymentDescriptionData2.refundDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundDueDate : '';
        this.paymentDescriptionData2.refundPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundPaymentRemarks : '';
        this.paymentDescriptionData2.refundPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundPaymentStatus : '';
        this.paymentDescriptionData2.miscellaneousCharges = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('mc') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousCharges : '';
        this.paymentDescriptionData2.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousChargesDueDate : '';
        this.paymentDescriptionData2.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousPaymentRemarks : '';
        this.paymentDescriptionData2.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousPaymentStatus : '';
        this.editPaymentDescFlagDeposit2 = true;
    }

    paymentDataMapDeposit3() {
        this.paymentDescriptionData3['penaltyAmount'] = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyAmount') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyAmount : '';
        this.paymentDescriptionData3.penaltyDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyDueDate : '';
        this.paymentDescriptionData3.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyPaymentRemarks : '';
        this.paymentDescriptionData3.penaltyPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyPaymentStatus : '';
        this.paymentDescriptionData3.refundAmount = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundAmount') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundAmount : '';
        this.paymentDescriptionData3.refundDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundDueDate : '';
        this.paymentDescriptionData3.refundPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundPaymentRemarks : '';
        this.paymentDescriptionData3.refundPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundPaymentStatus : '';
        this.paymentDescriptionData3.miscellaneousCharges = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousCharges') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousCharges : '';
        this.paymentDescriptionData3.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousChargesDueDate : '';
        this.paymentDescriptionData3.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousPaymentRemarks : '';
        this.paymentDescriptionData3.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousPaymentStatus : '';
        this.editPaymentDescFlagDeposit3 = true;
    }

    onradioButtonChange($event) {
        this.paymentMode = $event.value;
    }

    paymentCalculating(): void {
        let upgradeLimitOriginBase = false;
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        this.preMaxLimit = GRMS_CONSTANT.PREMIUM_DEFAULT_MAXLIMIT;
        this.busMaxLimit = GRMS_CONSTANT.BUSINESS_DEFAULT_MAXLIMIT;
        if (this.gbRequest.requestgeneralData.tripType == 'One Way' && !!this.gbRequest.isInternational) {
            for (let i = 0; i < this.gbRequest.itinerayDetails.length; i++) {
                if (this.gbRequest.itinerayDetails[i].classOfTravel == 'Premium Economy') {
                    if (this.gbRequest.itinerayDetails[i].from == 'CDG' || this.gbRequest.itinerayDetails[i].from == 'LHR' || this.gbRequest.itinerayDetails[i].from == 'FRA' ||
                        this.gbRequest.itinerayDetails[i].to == 'CDG' || this.gbRequest.itinerayDetails[i].to == 'LHR' || this.gbRequest.itinerayDetails[i].to == 'FRA') {
                        this.preMaxLimit = GRMS_CONSTANT.PREMIUM_ORIGIN_MAXLIMIT;
                        break;
                    }
                } else if (this.gbRequest.itinerayDetails[i].classOfTravel == 'Business') {
                    if (this.gbRequest.itinerayDetails[i].from == 'CDG' || this.gbRequest.itinerayDetails[i].from == 'LHR' || this.gbRequest.itinerayDetails[i].from == 'FRA' ||
                        this.gbRequest.itinerayDetails[i].to == 'CDG' || this.gbRequest.itinerayDetails[i].to == 'LHR' || this.gbRequest.itinerayDetails[i].to == 'FRA') {
                        this.busMaxLimit = GRMS_CONSTANT.BUSINESS_ORIGIN_MAXLIMIT;
                        break;
                    }
                }
            }
        }

        this.totalAmountPaid = parseFloat(this.gbRequest.requestgeneralData.totalFare);
        if (this.gbRequest) {
            if (this.gbRequest.requestgeneralData) {
                if (this.gbRequest.requestgeneralData.quoteValidTill) {
                    this.quotevalidtill = this.gbRequest.requestgeneralData.quoteValidTill
                }
                if (this.gbRequest.requestgeneralData?.acceptValidTill) {
                    this.acceptValidTill = new Date(this.gbRequest.requestgeneralData.acceptValidTill)
                }
            }
        }

        this.totalAmount = parseInt(this.gbRequest.requestgeneralData.totalFare);
        let amountNotReceived: number = 0;
        let amountReviced: number = 0;
        let invalidAmount: number = 0;
        let invalidPayments: string[] = ["FAILED", "INITIATED", "CANCELED", "INVALID PAYMENT", "AWAITING CONFIRMATION"];
        this.totalAmountPaid = 0;
        if (this.gbRequest.paymentDetails !== undefined) {
            for (let paymentDetails of this.gbRequest.paymentDetails) {
                if (invalidPayments.indexOf(paymentDetails.paymentStatus) === -1) {
                    if (paymentDetails.paymentStatus === "PAYMENT DONE" || paymentDetails.paymentStatus === "PARTIAL PAYMENT DONE") {
                        amountReviced += paymentDetails.amountPaid;
                        this.isQuoteExpiryEnabled = false;
                        this.somePaymentDoneFlag = true;
                    } else {
                        amountNotReceived += paymentDetails.amountPaid;
                    }
                    this.totalAmountPaid += paymentDetails.amountPaid;
                } else {
                    if (paymentDetails.paymentStatus === "AWAITING CONFIRMATION") {
                        amountNotReceived += paymentDetails.amountPaid;
                    } else {
                        invalidAmount += paymentDetails.amountPaid;
                    }
                }
            }
            this.totalAmountNotReceived = amountNotReceived;
            this.totalAmountReceived = amountReviced;
            if (this.gbRequest.paymentStatus !== "PAYMENT DONE") {
                this.totalAmountToBePaid = this.totalAmount - this.totalAmountReceived;
            }
            this.ticketingAmount = this.gbRequest.requestgeneralData.ticketingAmount;
            this.paymentDetailsToShow = this.gbRequest.paymentDetails.filter(p => p.paymentStatus !== "INVALID PAYMENT" && p.paymentStatus !== "INITIATED" && p.paymentStatus !== 'FAILED' && p.paymentStatus !== 'CANCELED');
        }

        let dateOfJourney = new Date(this.gbRequest.itinerayDetails[0]?.departureDate);
        let requestCreaDate = new Date(this.gbRequest.requestgeneralData.createdDate);
        this.diffeInDays = GrmsDiffCals.findDayDifference(requestCreaDate, dateOfJourney);
        this.getPenaltyDeposit1 = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescription.penaltyAmount) : 0;
        this.getMiscellDeposit1 = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousCharges') ? Number(this.gbRequest.updatePaymentdescription.miscellaneousCharges) : 0;
        this.getPenaltyDeposit2 = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite2.penaltyAmount) : 0;
        this.getMiscellDeposit2 = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousCharges') ? Number(this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousCharges) : 0;
        this.getPenaltyDeposit3 = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite3.penaltyAmount) : 0;
        this.getMiscellDeposit3 = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousCharges') ? Number(this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousCharges) : 0;
        this.getRefundDeposit1 = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundAmount') ? Number(this.gbRequest.updatePaymentdescription.refundAmount) : 0;
        this.getRefundDeposit2 = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite2.refundAmount) : 0;
        this.getRefundDeposit3 = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite3.refundAmount) : 0;

        let totalPaxCount = this.gbRequest.requestgeneralData.noOfChild + this.gbRequest.requestgeneralData.noOfAdult;
        // let totatFareAmount = this.gbRequest.requestgeneralData.ticketingAmount + this.getPenaltyDeposit1 + this.getPenaltyDeposit2 + this.getPenaltyDeposit3 + this.getMiscellDeposit1 + this.getMiscellDeposit2 + this.getMiscellDeposit3;
        // this.totalAmountToBePaid = totatFareAmount - this.totalAmountReceived;

        if (this.gbRequest.paymentStatus !== "PAYMENT DONE" && (this.gbRequest.paymentStatus === "PAYMENT OVERDUE" || this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE" || this.somePaymentDoneFlag)) {
            this.totalAmountToBePaid = this.totalAmount;
            this.offlineAmountPaid = this.totalAmountToBePaid;
            this.ticketingAmt1 = this.gbRequest.updatePaymentdescription.hasOwnProperty('ticketingAmount1') ? Number(this.gbRequest.updatePaymentdescription.ticketingAmount1) : this.gbRequest.requestgeneralData.ticketingAmount;
            this.ticketingAmt2 = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('ticketingAmount2') ? Number(this.gbRequest.updatePaymentdescriptiondeposite2.ticketingAmount2) : this.gbRequest.requestgeneralData.ticketingAmount;
            this.ticketingAmt3 = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount3') ? Number(this.gbRequest.updatePaymentdescriptiondeposite3.ticketingAmount3) : this.gbRequest.requestgeneralData.ticketingAmount;

            //  ToBePaid After first deposit
            if (this.paymentDetailsToShow.length == 1) {
                this.utrNo = '';
                this.totalAmountToBePaid = (this.ticketingAmt1 + this.getPenaltyDeposit1 + this.getMiscellDeposit1) - this.totalAmountReceived;
                this.offlineAmountPaid = this.totalAmountToBePaid;
            }
            //  ToBePaid After second deposit
            else if (this.paymentDetailsToShow.length == 2) {
                this.utrNo = '';
                this.totalAmountToBePaid = (this.ticketingAmt2 + this.getPenaltyDeposit1 + this.getMiscellDeposit1 + this.getPenaltyDeposit2 + this.getMiscellDeposit2) - this.totalAmountReceived;
                this.offlineAmountPaid = this.totalAmountToBePaid;
                this.paymentstatuslist = [" ", "INVALID PAYMENT", "PAYMENT DONE"];
            }
            //  ToBePaid After third deposit
            else if (this.paymentDetailsToShow.length == 3) {
                this.totalAmountToBePaid = (this.ticketingAmt3 + this.getPenaltyDeposit1 + this.getMiscellDeposit1 + this.getPenaltyDeposit2 + this.getMiscellDeposit2 + this.getPenaltyDeposit3 + this.getMiscellDeposit3) - this.totalAmountReceived;
                this.offlineAmountPaid = this.totalAmountToBePaid;
                this.paymentstatuslist = [" ", "INVALID PAYMENT", "PAYMENT DONE"];
            }

            if (!!this.gbRequest.requestgeneralData.amountPaid) {
                this.getAmountPercentage = ((this.gbRequest.requestgeneralData.amountPaid / this.totalAmount) * 100);
                if (parseFloat(this.getAmountPercentage) > 50) {
                    this.finalDepositFlag = true;
                } else {
                    this.balancePercentage = (50 - this.getAmountPercentage) / 100;
                }
            }

            if (this.gbRequest.itinerayDetails[0].classOfTravel == "Economy") {
                this.finalDepositFlag = true;
                if (this.gbRequest.isInternational && totalPaxCount >= 31 && totalPaxCount <= 99 && this.paymentDetailsToShow.length == 2 && this.gbRequest.paymentStatus !== "PAYMENT OVERDUE") {
                    this.paymentstatuslist = ["INVALID PAYMENT", "PARTIAL PAYMENT DONE", "PAYMENT DONE"];
                }

            } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Premium Economy") {
                this.finalDepositFlag = true;
                if (this.gbRequest.isInternational && totalPaxCount >= 16 && totalPaxCount <= this.preMaxLimit && this.paymentDetailsToShow.length == 2 && this.gbRequest.paymentStatus !== "PAYMENT OVERDUE") {
                    this.paymentstatuslist = ["INVALID PAYMENT", "PARTIAL PAYMENT DONE", "PAYMENT DONE"];
                }
            } else {
                this.finalDepositFlag = true;
                if (this.gbRequest.isInternational && totalPaxCount >= 5 && totalPaxCount <= this.busMaxLimit && this.paymentDetailsToShow.length == 2 && this.gbRequest.paymentStatus !== "PAYMENT OVERDUE") {
                    this.paymentstatuslist = ["INVALID PAYMENT", "PARTIAL PAYMENT DONE", "PAYMENT DONE"];
                }
            }


            if (this.gbRequest.itinerayDetails[0].classOfTravel == "Economy") {
                if (this.gbRequest.isInternational && totalPaxCount >= 31
                    && totalPaxCount <= 60
                    && this.paymentDetailsToShow.length == 1
                    && this.diffeInDays >= 45 && this.getAmountPercentage < 50) {
                    this.offlineAmountPaid = this.totalAmount * this.balancePercentage;
                    this.finalDepositFlag = false;
                }
                if (this.gbRequest.isInternational && totalPaxCount >= 61 && totalPaxCount <= 99
                    && this.paymentDetailsToShow.length == 1 && this.diffeInDays >= 60
                    && this.getAmountPercentage < 50) {
                    this.offlineAmountPaid = this.totalAmount * this.balancePercentage;
                    this.finalDepositFlag = false;
                }

            } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Premium Economy") {
                if (this.gbRequest.isInternational && totalPaxCount >= 16
                    && totalPaxCount <= this.preMaxLimit
                    && this.paymentDetailsToShow.length == 1 && this.diffeInDays >= 45
                    && upgradeLimitOriginBase == false && this.getAmountPercentage < 50) {
                    this.offlineAmountPaid = this.totalAmount * this.balancePercentage;
                    this.finalDepositFlag = false;
                }
            } else {
                if (this.gbRequest.isInternational && totalPaxCount > 4
                    && totalPaxCount <= this.busMaxLimit
                    && this.paymentDetailsToShow.length == 1
                    && this.diffeInDays >= 45
                    && upgradeLimitOriginBase == false
                    && this.getAmountPercentage < 50) {
                    //this.diffeInDays >= 31 && this.diffeInDays <= 45
                    this.offlineAmountPaid = this.totalAmount * this.balancePercentage;
                    this.finalDepositFlag = false;
                }
            }

        }
        else {
            this.offlineAmountPaid = this.totalAmount * .25;
            this.finalDepositFlag = false;
            this.utrNo = '';
            // if (this.offlineAmountPaid < 1500) {
            //     let minimumPercentage = ((1500 / this.totalAmount) * 100)
            //     let finalMinimumAmount = this.totalAmount * (minimumPercentage / 100);
            //     this.offlineAmountPaid = finalMinimumAmount;
            // }
            let paymentPaxCount = this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild + this.gbRequest.requestgeneralData.noOfInfant;
            let minimumAmountCheck = 1500 * paymentPaxCount * this.finalItineraryArr.length;
            if (minimumAmountCheck >= this.offlineAmountPaid) {
                this.offlineAmountPaid = minimumAmountCheck;
            }


            if (!!this.gbRequest.isInternational) {
                if (this.gbRequest.itinerayDetails[0].classOfTravel == "Economy") {
                    if ((this.diffeInDays >= 30 && this.diffeInDays < 45 && totalPaxCount > 30 && totalPaxCount <= 60)
                        || (this.diffeInDays >= 30 && this.diffeInDays < 60 && totalPaxCount > 60 && totalPaxCount <= 99)) {
                        this.offlineAmountPaid = this.totalAmount * .5;
                    }
                } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Premium Economy") {
                    if (this.diffeInDays >= 30 && this.diffeInDays < 45 && totalPaxCount > 15 && totalPaxCount <= this.preMaxLimit) {
                        this.offlineAmountPaid = this.totalAmount * .5;
                    }
                } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Business") {
                    if (this.diffeInDays >= 30 && this.diffeInDays < 45 && totalPaxCount > 4 && totalPaxCount <= this.busMaxLimit) {
                        this.offlineAmountPaid = this.totalAmount * .5;
                    }
                }
            }

            if (this.diffeInDays <= 10) {
                this.offlineAmountPaid = this.totalAmount;
            }


            if (this.gbRequest?.paymentDetails[this.gbRequest.paymentDetails.length - 1]?.paymentStatus == 'AWAITING CONFIRMATION') {
                this.totalAmountToBePaid = this.totalAmount - this.totalAmountReceived;
                this.offlineAmountPaid = this.totalAmountToBePaid;
            }
        }

        try {
            if (this.totalAmountNotReceived + this.totalAmountReceived === this.totalAmount) {
                this.paymentstatuslist = [" ", "INVALID PAYMENT", "PAYMENT DONE"];
            }
        } catch (e) { }
        this.isFlightDetailsUpdated = this.checkValidAcceptOption(this.gbRequest.itinerayDetails);
        if (this.currentrole === "requester") {
            this.isQuoteExpiryEnabled = false;
        }

        let depDate = new Date(this.gbRequest.itinerayDetails[0].departureDate);
        this.fdDate = depDate;
        if (this.gbRequest.isInternational) {
            if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays < 30 && this.diffeInDays > 10) {
                this.idDate.setDate(this.idDate.getDate() + 2);
                this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                this.finalDepositFlag = true;
            }
            else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Economy") {
                if (totalPaxCount <= 30) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 31 && totalPaxCount <= 60) {
                    if (this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE" && this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 45) {
                        this.secondDepositFlag = true;
                    }
                    if (this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 30 && this.diffeInDays < 45) {
                        this.finalDepositFlag = true;
                    }
                    let departureDate = new Date(this.gbRequest.itinerayDetails[0].departureDate);
                    this.fdDate = departureDate;
                    this.fdDate.setDate(departureDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                    this.secondDepositDate = depDate.setDate(depDate.getDate() - 45);
                    this.secondDepositDate = GrmsDiffCals.getFormatedStringDate(this.secondDepositDate);
                } else if (totalPaxCount >= 61 && totalPaxCount <= 99) {
                    if (this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE" && this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 60) {
                        this.secondDepositFlag = true;
                    }
                    if (this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 30 && this.diffeInDays < 60) {
                        this.finalDepositFlag = true;
                    }
                    let departureDate = new Date(this.gbRequest.itinerayDetails[0].departureDate);
                    this.fdDate = departureDate;
                    this.fdDate.setDate(departureDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                    this.secondDepositDate = depDate.setDate(depDate.getDate() - 60);
                    this.secondDepositDate = GrmsDiffCals.getFormatedStringDate(this.secondDepositDate);
                }
                else {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                }

            } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Premium Economy") {
                if (totalPaxCount <= 15) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 16 && totalPaxCount <= this.preMaxLimit && upgradeLimitOriginBase == false) {
                    if (this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE" && this.paymentDetailsToShow.length == 1 && this.diffeInDays >= 45) {
                        this.secondDepositFlag = true;
                    }
                    if (this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 30 && this.diffeInDays < 45) {
                        this.finalDepositFlag = true;
                    }
                    let departureDate = new Date(this.gbRequest.itinerayDetails[0].departureDate);
                    this.fdDate = departureDate;
                    this.fdDate.setDate(departureDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                    this.secondDepositDate = depDate.setDate(depDate.getDate() - 45);
                    this.secondDepositDate = GrmsDiffCals.getFormatedStringDate(this.secondDepositDate);
                } else {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                }

            } else {
                if (totalPaxCount <= 4) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 5 && totalPaxCount <= this.busMaxLimit && upgradeLimitOriginBase == false) {
                    if (this.gbRequest.paymentStatus === "PARTIAL PAYMENT DONE" && this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 45) {
                        this.secondDepositFlag = true;
                    }
                    if (this.paymentDetailsToShow.length == 1 && this.getAmountPercentage < 50 && this.diffeInDays >= 30 && this.diffeInDays < 45) {
                        this.finalDepositFlag = true;
                    }
                    let departureDate = new Date(this.gbRequest.itinerayDetails[0].departureDate);
                    this.fdDate = departureDate;
                    this.fdDate.setDate(departureDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                    this.secondDepositDate = depDate.setDate(depDate.getDate() - 45);
                    this.secondDepositDate = GrmsDiffCals.getFormatedStringDate(this.secondDepositDate);
                } else {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);

                }
            }
        } else {
            if (this.gbRequest.itinerayDetails[0].classOfTravel == "Economy") {
                if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays < 21 && this.diffeInDays > 10 && totalPaxCount <= 30) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                }
                else if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays < 30 && this.diffeInDays > 10 && totalPaxCount > 30 && totalPaxCount <= 99) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                }
                else if (totalPaxCount <= 30 && this.diffeInDays >= 21) {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 31 && totalPaxCount <= 99 && this.diffeInDays >= 30) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                }

            } else if (this.gbRequest.itinerayDetails[0].classOfTravel == "Premium Economy") {
                if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays > 10 && this.diffeInDays < 21 && totalPaxCount <= 15) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                } else if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays > 10 && this.diffeInDays < 30 && totalPaxCount > 15 && totalPaxCount <= this.preMaxLimit) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                } else if (totalPaxCount <= 15 && this.diffeInDays >= 21) {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 16 && totalPaxCount <= this.preMaxLimit && this.diffeInDays >= 30) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                }

            } else {
                if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays < 21 && this.diffeInDays > 10 && totalPaxCount <= 4) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                } else if (this.gbRequest.paymentDetails.length >= 1 && this.diffeInDays < 30 && this.diffeInDays > 10 && totalPaxCount > 4 && totalPaxCount <= this.busMaxLimit) {
                    this.idDate.setDate(this.idDate.getDate() + 2);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.idDate);
                } else if (totalPaxCount <= 4 && this.diffeInDays >= 21) {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else if (totalPaxCount >= 5 && totalPaxCount <= this.busMaxLimit && this.diffeInDays >= 30) {
                    this.fdDate.setDate(depDate.getDate() - 30);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                } else {
                    this.fdDate.setDate(depDate.getDate() - 21);
                    this.finalDepositDate = GrmsDiffCals.getFormatedStringDate(this.fdDate);
                }
            }
        }
        try {
            this.loaderService.hide()
        } catch (e) { }

        this.editPaymentDescFlagDeposit1 = false;
        this.editPaymentDescFlagDeposit2 = false;
        this.editPaymentDescFlagDeposit3 = false;

    }

    addPayment() {
        this.loaderService.show();
        let amountPaid: number = 0;
        if (this.gbRequest.paymentDetails !== undefined) {
            for (let paymentDetails of this.gbRequest.paymentDetails) {
                amountPaid += paymentDetails.amountPaid;
            }
            if (this.offlineAmountPaid > this.totalAmountToBePaid) {
                this.snackBar.open("Kindly enter the amount lesser than or equal to the Amount To be Paid", "X", { duration: 3000 });
                return;
            }

        }

        try {
            this.loaderService.show();
        } catch (e) { }
        let formData: FormData = new FormData();
        // this.loadFactor()
        if (this.paymentFileData !== undefined || this.utrNo != "") {
            if (this.paymentFileData !== undefined && !this.isValidFile(this.paymentFileData)) {
                return;
            }
            if (this.paymentFileData !== undefined) {
                formData.append("paymenDocument", this.paymentFileData, this.paymentFileData.name);
            }
            if (this.utrNo != "") {
                formData.append("utrNo", this.grmsUtils.encryptedData(this.utrNo.toString()));
            }
            formData.append("paymentMode", this.grmsUtils.encryptedData("OFFLINE"));
            formData.append("amountPaid", this.grmsUtils.encryptedData(this.offlineAmountPaid.toString()) + "");
            formData.append("user", this.grmsUtils.encryptedData(this.loginUserID));
            formData.append("requestID", this.grmsUtils.encryptedData(this.gbRequest.requestId));

            this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.offlinePayment, formData, this.grmsUtils.getAuthMultiHeader('text'))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response) => {
                        response = JSON.parse(this.grmsUtils.decryptedData(response));
                        this.getRequestDetails()
                    },
                    error: (errorDetails) => {
                        this.loaderService.hide();
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                })



        } else {
            this.loaderService.hide();
            this.snackBar.open("kindly update payment documents or Remarks", 'X', { duration: 3000 });
        }
    }

    makeOnlinePayment() {

        this.assineeDetils()
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        let amountPaid: number = 0;
        if (this.gbRequest.paymentDetails !== undefined) {
            for (let paymentDetails of this.gbRequest.paymentDetails) {
                amountPaid += paymentDetails.amountPaid;
            }
            if (this.onlineAmountPaid > this.totalAmountToBePaid) {
                this.snackBar.open("Kindly enter the amount lesser than or equal to the Amount To be Paid", 'X', { duration: 3000 });
                return;
            }
        }

        this.newPaymentDetails['paymentMode'] = 'ONLINE';
        this.newPaymentDetails['amountPaid'] = this.offlineAmountPaid;
        this.newPaymentDetails['loginUserID'] = this.loginUserID;
        this.newPaymentDetails['loginUserType'] = this.loginUserRole;
        this.newPaymentDetails['totalAmount'] = parseFloat(this.gbRequest.requestgeneralData.totalFare);
        //  this.gbRequest.newPaymentDetails['amountPaid'] = this.offlineAmountPaid;
        this.gbRequest['actionType'] = "PAYMENT";

        let totalPax = this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild;
        let conveniencefeeTotal;
        if (!!this.gbRequest.isInternational) {
            conveniencefeeTotal = totalPax * 700;

        } else {
            conveniencefeeTotal = totalPax * 300;
        }

        let toBePayFees = conveniencefeeTotal - this.gbRequest.requestgeneralData['paidConvenienceFee'];

        this.newPaymentDetails['paidConvenienceFee'] = toBePayFees > 0 ? conveniencefeeTotal : this.gbRequest.requestgeneralData['paidConvenienceFee'];
        this.newPaymentDetails['toBePaidConvenienceFee'] = toBePayFees;
        this.gbRequest.newPaymentDetails = this.newPaymentDetails;
        let paymentEncrpt;
        if (toBePayFees > 0) {
            let dialogRef = this.matDialog.open(PopOverContentComponent, {
                disableClose: true,
                width: '31%',
                data: { data: [{ 'tickectToBepay': this.offlineAmountPaid, 'convenience': toBePayFees }], action: 'conveniencefee' }
            })
            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                if (result?.data == 'conveniencefee') {
                    this.gbRequest.newPaymentDetails['amountPaid'] = this.offlineAmountPaid + toBePayFees;
                    paymentEncrpt = { paymentData: this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest)) }
                    this.onlinePayRedirect(paymentEncrpt)
                }
            })
        } else if (toBePayFees < 0) {
            let dialogRef = this.matDialog.open(PopOverContentComponent, {
                disableClose: true,
                width: '31%',
                data: { data: [{ 'tickectToBepay': this.offlineAmountPaid, 'convenience': toBePayFees }], action: 'conveniencefee' }
            })
            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                if (result?.data == 'conveniencefee') {
                    this.gbRequest.newPaymentDetails['amountPaid'] = this.offlineAmountPaid + toBePayFees;
                    paymentEncrpt = { paymentData: this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest)) }
                    this.onlinePayRedirect(paymentEncrpt)
                }
            })
        } else {
            this.gbRequest.newPaymentDetails['amountPaid'] = this.offlineAmountPaid;
            paymentEncrpt = { paymentData: this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest)) }
            this.onlinePayRedirect(paymentEncrpt)
        }
    }

    onlinePayRedirect(paymentEncrpt) {
        this.loaderService.show();
        this.httpService.postCall(ONLINE_API_ENPOINTS.onlinePayment, paymentEncrpt, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (!!response) {
                        let submitMe = document.createElement("form");
                        submitMe.action = response['paymentURL'];
                        submitMe.method = "post";
                        submitMe.enctype = "application/x-www-form-urlencoded";
                        let inputelement3 = document.createElement("input");
                        inputelement3.setAttribute("type", "hidden");
                        inputelement3.setAttribute("name", "access_code");
                        inputelement3.setAttribute("value", response['accessCode']);
                        submitMe.appendChild(inputelement3)

                        let inputelement2 = document.createElement("input");
                        inputelement2.setAttribute("type", "hidden");
                        inputelement2.setAttribute("name", "encRequest");

                        inputelement2.value = response['paymentData'];

                        submitMe.appendChild(inputelement2);

                        let inputelement4 = document.createElement("input");
                        inputelement4.setAttribute("type", "submit");
                        inputelement4.setAttribute("name", "submit_payment");
                        inputelement4.setAttribute("value", 'PARTIAL PAYMENT DONE');
                        submitMe.appendChild(inputelement4);
                        document.body.appendChild(submitMe);

                        inputelement4.click();

                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });


    }


    updatePaymentStatus() {
        if (this.gbRequest.paymentStatus == " " || this.gbRequest.paymentStatus == 'AWAITING CONFIRMATION') {
            this.snackBar.open("Payment Status cannot be updated as Empty", 'X', { duration: 3000 });
            return;
        }
        this.gbRequest['actionType'] = "UPDATE_PAYMENT_STATUS";
        if (this.gbRequest.paymentStatus !== "" && this.gbRequest.paymentStatus !== "undefined") {
            this.updateRequest('Payment status updated');

        } else {
            this.snackBar.open("Please select payment status to update", 'X', { duration: 3000 });
        }
    }

    createPnr(event?) {
        this.loaderService.show();
        let requestDetailsForPnr = {};
        requestDetailsForPnr['loginUserID'] = this.loginUserID;
        requestDetailsForPnr['requestId'] = this.gbRequest.requestId
        requestDetailsForPnr['requestStatus'] = this.gbRequest.requestStatus;
        requestDetailsForPnr['paymentStatus'] = this.gbRequest.paymentStatus;
        requestDetailsForPnr['actionType'] = "CREATE_PNR";
        requestDetailsForPnr['itinerayDetails'] = this.gbRequest.itinerayDetails;
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(requestDetailsForPnr));
        this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.updateRequest, inputData, this.grmsUtils.getAuthWithTextHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    response = JSON.parse(this.grmsUtils.decryptedData(response));
                    if (response?.statusCode !== 200) {
                        this.snackBar.open(response['statusMsg'], 'X', { duration: 3000 });
                    } else {
                        this.snackBar.open('PNR Created Successfully', 'X', { duration: 3000 })
                        this.getRequestDetails()
                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    paxDownSizeChangeAfterConfirm(event) {
        this.getRequestDetails();
    }

    mapRequestDetailsIntoList(requestDetailsList: any) {
        this.similarReqColumnHeader = {
            requestId: "Request ID",
            createdDate: 'Raised Date',
            tripType: 'Request Type',
            from: 'Departure',
            to: 'Arrival',
            paxCount: 'Pax Count',
            requestStatus: 'Request Status',
            paymentStatus: 'Payment Status',
            totalFare: 'Total Amount',
            lastModifiedDate: 'Last Modified Date'
        }
        let requestDataList = [];
        for (let requestDetails of requestDetailsList) {
            let request = {
                'requestId': '', 'createdDate': ',', 'from': '', 'to': '', 'tripType': '', 'paxCount': '',
                'requestStatus': '', 'paymentStatus': ',', 'totalFare': '', 'lastModifiedDate': '',
                'createdBy': '', 'currAssinee': ''
            }

            request.requestId = requestDetails.requestId;
            request.createdDate = requestDetails.requestgeneralData.createdDate;
            request.from = requestDetails.requestgeneralData.from;
            request.to = requestDetails.requestgeneralData.to;
            request.tripType = requestDetails.requestgeneralData.tripType;
            request.paxCount = requestDetails.requestgeneralData.noOfAdult + requestDetails.requestgeneralData.noOfChild;
            request.requestStatus = requestDetails.requestStatus;
            request.paymentStatus = !!requestDetails.requestgeneralData?.paymentStatus ? requestDetails.requestgeneralData?.paymentStatus : '-';
            request.totalFare = requestDetails.requestgeneralData.totalFare;
            request.lastModifiedDate = requestDetails.requestgeneralData.lastModifiedDate;
            request.createdBy = requestDetails.createdBy;
            request.currAssinee = requestDetails.currAssinee;
            requestDataList.push(request);
        }
        return requestDataList;
    }

    paxUpdate() {
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        if (!!this.gbRequest.isInternational) {
            this.columnHeader = {
                select: "", paxRowNo: "Sno", title: "Title*", firstName: "First Name*", lastName: "Last Name",
                type: "Type*", dob: "DOB", email: "Email",
                contactNo: "Contact Number", mealPreference: "Meal",
                associatedAdult: "Associated Adult", ticketNumber: 'Ticket No', deleted: 'User Status',
                docdetails: 'API Info',
            }
        } else {
            this.columnHeader = {
                select: "", paxRowNo: "Sno", title: "Title*", firstName: "First Name*", lastName: "Last Name",
                type: "Type*", dob: "DOB", email: "Email",
                contactNo: "Contact Number", mealPreference: "Meal",
                associatedAdult: "Associated Adult", ticketNumber: 'Ticket No', deleted: 'User Status'

            }
        }
        if (this.gbRequest.requestStatus === 'TICKETS ISSUED') {
            delete this.columnHeader['action'];
            delete this.columnHeader['deleted'];
        }
        this.setPaxLimit = this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild + this.gbRequest.requestgeneralData.noOfInfant;
        if (!!this.gbRequest.paxDetails && this.gbRequest.paxDetails.length > 0) {
            this.tableDatas = this.gbRequest.paxDetails;
        }
    }
    /** Pax Size Change Action Start **/
    paxSizeChange() {
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        if (this.gbRequest.requestgeneralData.paymentStatus == 'PAYMENT DONE'
            && ((this.gbRequest.requestgeneralData.noOfAdult > this.adultCount) || (this.gbRequest.requestgeneralData.noOfChild > this.childCount) || (this.gbRequest.requestgeneralData.noOfInfant > this.infantCount))) {
            this.snackBar.open("Please generate PNR for further process", 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.paymentDetails.length != 0) {
            for (let payDet of this.gbRequest.paymentDetails) {
                if (payDet.paymentStatus == "AWAITING CONFIRMATION" && this.gbRequest.requestgeneralData.paymentStatus == "AWAITING CONFIRMATION") {
                    this.snackBar.open("Kindly update the payment status before updating the pax size", 'X', { duration: 3000 });
                    return;
                }
            }
        }
        if (this.gbRequest.requestgeneralData.noOfInfant > 4) {
            this.snackBar.open("Infant should be less than or equal 4", 'X', { duration: 3000 });
            return
        }
        this.gbRequest.requestgeneralData.groupSize = this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild;
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Premium Economy' && this.gbRequest.requestgeneralData.groupSize < this.gbRequest.requestgeneralData.groupsizelimit) {
            this.snackBar.open(`Group size (Adult+Child) should be less than ${this.preMaxLimit} and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Premium Economy' && this.gbRequest.requestgeneralData.groupSize > this.preMaxLimit) {
            this.snackBar.open(`Group size (Adult+Child) should be less than ${this.preMaxLimit} and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Business' && (this.gbRequest.requestgeneralData.groupSize < this.gbRequest.requestgeneralData.groupsizelimit)) {
            this.snackBar.open(`Group size (Adult+Child) should be less than ${this.busMaxLimit} and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Business' && this.gbRequest.requestgeneralData.groupSize > this.busMaxLimit) {
            this.snackBar.open(`Group size (Adult+Child) should be less than ${this.busMaxLimit} and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Economy' && this.gbRequest.requestgeneralData.groupSize < this.gbRequest.requestgeneralData.groupsizelimit) {
            this.snackBar.open(`Group size (Adult+Child) should be less than 99 and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Economy' && this.gbRequest.requestgeneralData.groupSize >= 99) {
            this.snackBar.open(`Group size (Adult+Child) should be less than 99 and greater than or equal ${this.gbRequest.requestgeneralData.groupsizelimit}`, 'X', { duration: 3000 });
            return;
        }
        try {
            this.loaderService.show();
        } catch (e) { }

        let groupSizeLimit = 0;

        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Business') {
            groupSizeLimit = 4;
        } else {
            groupSizeLimit = 10;
        }

        // if (this.gbRequest.requestgeneralData.groupSize >= this.gbRequest.requestgeneralData.groupsizelimit && this.gbRequest.requestgeneralData.noOfInfant <= 4 && this.gbRequest.requestgeneralData.noOfInfant <= this.gbRequest.requestgeneralData.noOfAdult) {
        if (this.gbRequest.requestgeneralData.groupSize >= groupSizeLimit && this.gbRequest.requestgeneralData.noOfInfant <= 4 && this.gbRequest.requestgeneralData.noOfInfant <= this.gbRequest.requestgeneralData.noOfAdult) {
            this.gbRequest['actionType'] = "PAX_SIZE_CHANGE";
            this.gbRequest['loginUserID'] = this.loginUserID;

            this.gbRequest['requestgeneralData']['infantFarePerPax'] = (this.grmsUtils.isNull(this.gbRequest['requestgeneralData']['infantFarePerPax'])) ? 0 : this.gbRequest['requestgeneralData']['infantFarePerPax'];
            let inputData = this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest));
            this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.updateRequest, inputData, this.grmsUtils.getAuthWithTextHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response) => {
                        this.loaderService.hide();
                        response = JSON.parse(this.grmsUtils.decryptedData(response));
                        this.snackBar.open("Pax size has been updated successfully", 'X', { duration: 3000 });
                        this.getRequestDetails();
                        setTimeout(() => {
                            this.getDepositWiseTa();
                        }, 500);
                        setTimeout(() => {
                            this.validationCheckOnReducePax();
                            this.newAdultValue = 0;
                            this.oldAdultValue = 0;
                            this.oldChildValue = 0;
                            this.newChildValue = 0;
                            if (typeof this.oldInfantValue !== "undefined" && typeof this.newInfantValue !== "undefined") {
                                if (this.oldInfantValue == 0 && this.newInfantValue > 0 && this.gbRequest.isInternational) {
                                    this.newInfantValue = 0;
                                    this.oldInfantValue = 0;
                                    this.paxSizeChange();
                                }
                            }
                            this.newInfantValue = 0;
                            this.oldInfantValue = 0;
                        }, 4000);

                    },
                    error: (errorDetails) => {
                        this.loaderService.hide();
                        if (errorDetails.error['statusCode'] === 422) {
                            this.snackBar.open('Infant Fare should be greater than 0', 'X', { duration: 3000 });
                            return false;
                        }
                        this.grmsUtils.errorHandling(errorDetails);
                    }

                });
        } else {
            this.loaderService.hide();
            if (this.gbRequest.requestgeneralData.groupSize < groupSizeLimit)
                // this.snackBar.open("Minimum Group Size is : " + (this.gbRequest.requestgeneralData.groupsizelimit), "X", { duration: 3000 });
                this.snackBar.open("Minimum Group Size is :" + groupSizeLimit, "X", { duration: 3000 });
            else if (this.gbRequest.requestgeneralData.noOfInfant > 4)
                this.snackBar.open("Maximum 4 Infants are allowed for the request", "X", { duration: 3000 });
            else if (this.gbRequest.requestgeneralData.noOfInfant > this.gbRequest.requestgeneralData.noOfAdult)
                this.snackBar.open("Infant Value can't be greater than the Adult Value", "X", { duration: 3000 });
        }
    }

    paxDownSizeChange(event?) {
        let eventName;
        this.gbRequest["downsizeEligible"] = false;
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        if (!!event) {
            eventName = event.eventName;
        } else {
            eventName = "downSizePenalty";
            if (this.gbRequest.paymentStatus === "PAYMENT DONE") {
                this.gbRequest["downsizeEligible"] = true;
            }
        }

        if (this.adultCount < this.gbRequest.requestgeneralData.noOfAdult) {
            this.snackBar.open("Can't increase Adult Count", 'X', { duration: 3000 })
            this.gbRequest.requestgeneralData.noOfAdult = this.adultCount;
            return;
        }
        if (this.childCount < this.gbRequest.requestgeneralData.noOfChild) {
            this.snackBar.open("Can't increase child Count", 'X', { duration: 3000 })
            this.gbRequest.requestgeneralData.noOfChild = this.childCount;
            return;
        }

        if (this.infantCount < this.gbRequest.requestgeneralData.noOfInfant) {
            this.snackBar.open("Can't increase Infant Count", 'X', { duration: 3000 })
            this.gbRequest.requestgeneralData.noOfInfant = this.infantCount;
            return;
        }



        this.gbRequest.requestgeneralData.groupSize = this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild;
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Premium Economy' && this.gbRequest.requestgeneralData.groupSize < 10) {
            this.snackBar.open("Downsize limit exceeded", 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Business' && this.gbRequest.requestgeneralData.groupSize < 4) {
            this.snackBar.open("Downsize limit exceeded", 'X', { duration: 3000 });
            return;
        }
        if (this.gbRequest.itinerayDetails[0].classOfTravel == 'Economy' && this.gbRequest.requestgeneralData.groupSize < 10) {
            this.snackBar.open("Downsize limit exceeded", 'X', { duration: 3000 });
            return;
        }

        if (this.gbRequest.requestgeneralData.noOfInfant <= 4 && this.gbRequest.requestgeneralData.noOfInfant <= this.gbRequest.requestgeneralData.noOfAdult) {
            this.gbRequest['actionType'] = "PAX_SIZE_CHANGE";

            this.loaderService.show();
            let downSizeInfo = {
                "requestId": this.gbRequest.requestId,
                "isInternational": this.gbRequest.isInternational,
                "classOfTravel": this.gbRequest.itinerayDetails[0].classOfTravel,
                "requestgeneralData": {
                    "noOfAdult": this.gbRequest.requestgeneralData.noOfAdult,
                    "noOfChild": this.gbRequest.requestgeneralData.noOfChild,
                    "noOfInfant": this.gbRequest.requestgeneralData.noOfInfant
                },

            }
            let inputData = this.grmsUtils.encryptedData(JSON.stringify(downSizeInfo));
            this.httpService.postCall(BOOKING_API_ENPOINTS.penaltyCal, inputData, this.grmsUtils.getAuthWithTextHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response) => {
                        this.loaderService.hide()
                        let penaltyAmount: any;
                        response = JSON.parse(this.grmsUtils.decryptedData(response));
                        if (response['statusMsg'].toLowerCase() === 'ok') {
                            if (response['downSizePenalty'] >= 0) {
                                penaltyAmount = response['downSizePenalty'];
                                let dialogRef = this.matDialog.open(PopOverContentComponent, {
                                    disableClose: true,
                                    width: '31%',
                                    data: { data: penaltyAmount, action: eventName }
                                })
                                dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                                    if (result.data == 'continue') {
                                        this.updateRequest(penaltyAmount, 'downSize')
                                    } else if (result.data == "afterPaxConfirm") {
                                        this.downSizeAfterPaxConfirmChange(event, penaltyAmount)
                                    } else {
                                        this.reFreshDetails()
                                        //this.getRequestDetails()
                                    }
                                })

                            }
                        } else {
                            this.snackBar.open(`${response['statusMsg']}`, "X", { duration: 3000 });
                        }

                    },
                    error: (errorDetails) => {
                        this.loaderService.hide();
                        this.grmsUtils.errorHandling(errorDetails);
                    }

                });

        } else {
            this.loaderService.hide();
            if (this.gbRequest.requestgeneralData.noOfInfant > 8)
                this.snackBar.open("Maximum 8 Infants are allowed for the request", "X", { duration: 3000 });
            else if (this.gbRequest.requestgeneralData.noOfInfant > this.gbRequest.requestgeneralData.noOfAdult)
                this.snackBar.open("Infant Value can't be greater than the Adult Value", "X", { duration: 3000 });
        }

    }

    downSizeAfterPaxConfirmChange(event: any, penaltyAmount) {
        this.loaderService.show();
        this.gbRequest.paxDetails = event?.paxDetail;
        this.gbRequest["actionType"] = "DOWNSIZE";
        this.gbRequest['loginUserID'] = this.loginUserID;
        this.gbRequest = this.getPaymentTableName(this.gbRequest, penaltyAmount);
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(this.gbRequest));
        this.httpService.postCall(BOOKINGVIEW_API_ENPOINTS.PaxDownSize, inputData, this.grmsUtils.getAuthWithTextHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    response = JSON.parse(this.grmsUtils.decryptedData(response));
                    this.gbRequest = response["requestDetails"];
                    if (response['statusMsg'].toLowerCase() === 'ok' && this.gbRequest.paymentStatus === 'PAYMENT DONE' && response['refundEligible']) {
                        this.updateRefundToReport(penaltyAmount);
                    } else {
                        this.loaderService.hide()
                        this.snackBar.open(response['statusMsg'], "X", { duration: 3000 })
                        this.reFreshDetails()
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });


    }

    paxUpSizeChange() {
        this.searchChildRequests('isPaxUpSize');
    }

    paxUpSizeCountIncrease(previousPaxCount, previousInfantPaxCount) {
        let paxLimit = { min: 1, max: 9 }
        let paxInfo = { adult: paxLimit.min, child: 0, infant: 0 };
        let upsizeGroupSize = previousPaxCount;
        let upSizeInfantLimit = previousInfantPaxCount;
        this.loaderService.hide()
        let dialogRef = this.matDialog.open(PaxPopupFormComponent, {
            disableClose: true,
            width: '31%',
            data: { paxInfo: paxInfo, classOfTravel: this.gbRequest.itinerayDetails[0].classOfTravel, paxLimit: paxLimit, action: 'paxUpsize' }
        })
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (!!result?.data) {
                this.loaderService.show();
                upsizeGroupSize += parseInt(result?.data?.adult) + parseInt(result?.data?.child)
                upSizeInfantLimit += parseInt(result?.data?.infant);
                if (this.gbRequest.requestgeneralData.noOfInfant + parseInt(result?.data?.infant) > 4) {
                    this.loaderService.hide();
                    this.snackBar.open(`total Infant Pax size less than or equal ${this.infantMaxPaxLimit}`, 'X', { duration: 3000 });
                    return;
                }
                if (upSizeInfantLimit > 4) {
                    this.loaderService.hide();
                    this.snackBar.open(`Infant Pax size less than or equal ${this.infantMaxPaxLimit}`, 'X', { duration: 3000 });
                } else if (upsizeGroupSize > 9) {
                    this.loaderService.hide();
                    this.snackBar.open(`Could not able to upsize already limit exists 9 (Adult + Child)`, 'X', { duration: 3000 });
                } else {
                    let createRequest: any = {
                        "requestgeneralData": {}
                    };
                    let generalData = createRequest.requestgeneralData;
                    const noOfPassengers = result?.data?.adult + result?.data?.child;
                    generalData['noOfAdult'] = result?.data?.adult;
                    generalData['noOfChild'] = result?.data?.child;
                    generalData['noOfInfant'] = result?.data?.infant;
                    generalData['groupSize'] = upsizeGroupSize;
                    createRequest.loginUserID = this.gbRequest.createdBy;
                    createRequest.isInternational = this.gbRequest.isInternational;
                    createRequest.parentId = this.gbRequest.requestId;
                    createRequest.modifyRequest = "Y";
                    createRequest.modifyAction = "UPSIZE_ACTION";
                    generalData['flexibleTravelDates'] = !!this.gbRequest.requestgeneralData?.flexibleTravelDates ? 'Y' : 'N';
                    generalData['groupType'] = this.gbRequest.requestgeneralData.groupType;
                    generalData['fdb'] = false;
                    generalData['tripType'] = this.gbRequest.requestgeneralData.tripType;
                    generalData['finalQuote'] = false;
                    generalData['groupsizelimit'] = 6;
                    generalData['groupName'] = this.gbRequest.requestgeneralData.groupName;
                    generalData['companyName'] = !!this.gbRequest.requestgeneralData.companyName ? this.gbRequest.requestgeneralData.companyName : '';
                    generalData['corporateCode'] = !!this.gbRequest.requestgeneralData.corporateCode ? this.gbRequest.requestgeneralData.corporateCode : '';
                    generalData['expectedFarePerPax'] = this.gbRequest.requestgeneralData.expectedFarePerPax;
                    createRequest.itinerayDetails = []
                    this.gbRequest.itinerayDetails.forEach((el, i) => {
                        let newitinerayDetails = {}
                        newitinerayDetails['showCalendar'] = false;
                        newitinerayDetails['from'] = el.from;
                        newitinerayDetails["flightNumber"] = el.flightNumber;
                        newitinerayDetails['to'] = el.to;
                        newitinerayDetails["departureDate"] = this.datePipe.transform(el.departureDate, "dd-MMM-yyyy");
                        newitinerayDetails["travelDuration"] = el.travelDuration;
                        newitinerayDetails["arrivalDate"] = this.datePipe.transform(el.arrivalDate, "dd-MMM-yyyy");
                        newitinerayDetails["sdt"] = new Date();
                        newitinerayDetails["flightTime"] = el.flightTime;
                        newitinerayDetails["NoOfStops"] = el.stops;
                        newitinerayDetails["stops"] = el.stops;
                        newitinerayDetails["itineraryno"] = el.itineraryno;
                        newitinerayDetails["classOfTravel"] = el.classOfTravel;
                        newitinerayDetails['PopOverDetails'] = []
                        createRequest.itinerayDetails.push(newitinerayDetails)
                    })
                    this.httpService.postCall(BOOKING_API_ENPOINTS.createRequest, createRequest, this.grmsUtils.getAuthHeader())
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe({
                            next: (response) => {
                                this.loaderService.hide();
                                this.childRequestAvailable = 'YES';
                                let dialogRef = this.matDialog.open(PopOverContentComponent, {
                                    disableClose: true,
                                    width: '80%',
                                    height: 'auto',
                                    data: { data: response['requestDetails'].requestId, action: 'createdUpSize' }
                                });
                                dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                                    this.reFreshDetails()
                                })

                            },
                            error: (errorDetails) => {
                                this.loaderService.hide();
                                this.grmsUtils.errorHandling(errorDetails);
                            }
                        })
                }
            } else {
                this.reFreshDetails()
            }
        })
    }

    updateAdult(oldPax: any, newPax: any) {
        this.oldAdultValue = oldPax;
        this.newAdultValue = newPax;
    }

    updateChild(oldPax: any, newPax: any) {
        this.oldChildValue = oldPax;
        this.newChildValue = newPax;
    }

    updateInfant(oldPax: any, newPax: any) {
        this.oldInfantValue = oldPax;
        this.newInfantValue = newPax;
    }

    /** Pax Size Change Action End **/
    getDepositWiseTa(penaltyAddAmount?) {
        let refundAmount;
        let totalPenaltyAmount = 0;
        let paymentDescList = ['updatePaymentdescription', 'updatePaymentdescriptiondeposite2', 'updatePaymentdescriptiondeposite3'];
        if (this.gbRequest.paymentStatus === 'PAYMENT DONE') {
            for (let i = 0; i < paymentDescList.length; i++) {
                if (this.gbRequest[paymentDescList[i]].hasOwnProperty('penaltyAmount')) {
                    totalPenaltyAmount += Number(this.gbRequest[paymentDescList[i]].penaltyAmount);
                }
            }
            let totatlFareAmount = this.gbRequest.requestgeneralData.ticketingAmount + totalPenaltyAmount;
            refundAmount = this.totalAmountPaid - totatlFareAmount;
        }
        if (this.paymentDetailsToShow.length == 1) {
            let getpenaltyAmount = penaltyAddAmount > 0 ? penaltyAddAmount : '0';
            // this.paymentDescriptionData1['penaltyAmount'] = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescription.penaltyAmount) + getpenaltyAmount : getpenaltyAmount;
            this.paymentDescriptionData1.penaltyDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescription.penaltyDueDate : '';
            this.paymentDescriptionData1.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescription.penaltyPaymentRemarks : '';
            this.paymentDescriptionData1.penaltyPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescription.penaltyPaymentStatus : '';
            this.paymentDescriptionData1.refundAmount = refundAmount ? refundAmount : 0;
            this.paymentDescriptionData1.refundDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescription.refundDueDate : '';
            this.paymentDescriptionData1.refundPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('rprefundPaymentRemarksr') ? this.gbRequest.updatePaymentdescription.refundPaymentRemarks : '';
            this.paymentDescriptionData1.refundPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescription.refundPaymentStatus : '';
            this.paymentDescriptionData1.miscellaneousCharges = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousCharges') ? this.gbRequest.updatePaymentdescription.miscellaneousCharges : '';
            this.paymentDescriptionData1.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescription.miscellaneousChargesDueDate : '';
            this.paymentDescriptionData1.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescription.miscellaneousPaymentRemarks : '';
            this.paymentDescriptionData1.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescription.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescription.miscellaneousPaymentStatus : '';
            this.updatePaymentDescriptionDeposit1();
            return;
        }
        if (this.paymentDetailsToShow.length == 2) {
            let getpenaltyAmount = penaltyAddAmount > 0 ? penaltyAddAmount : '0';
            // this.paymentDescriptionData2['penaltyAmount'] = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite2.penaltyAmount) + getpenaltyAmount : getpenaltyAmount;
            this.paymentDescriptionData2.penaltyDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyDueDate : '';
            this.paymentDescriptionData2.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyPaymentRemarks : '';
            this.paymentDescriptionData2.penaltyPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.penaltyPaymentStatus : '';
            this.paymentDescriptionData2.refundAmount = refundAmount ? refundAmount : 0;
            this.paymentDescriptionData2.refundDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundDueDate : '';
            this.paymentDescriptionData2.refundPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundPaymentRemarks : '';
            this.paymentDescriptionData2.refundPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.refundPaymentStatus : '';
            this.paymentDescriptionData2.miscellaneousCharges = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('mc') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousCharges : '';
            this.paymentDescriptionData2.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousChargesDueDate : '';
            this.paymentDescriptionData2.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousPaymentRemarks : '';
            this.paymentDescriptionData2.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite2.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite2.miscellaneousPaymentStatus : '';
            this.updatePaymentDescriptionDeposit2();
            return;
        }
        if (this.paymentDetailsToShow.length == 3) {
            let getpenaltyAmount = penaltyAddAmount > 0 ? penaltyAddAmount : '0';
            // this.paymentDescriptionData3['penaltyAmount'] = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyAmount') ? Number(this.gbRequest.updatePaymentdescriptiondeposite3.penaltyAmount) + getpenaltyAmount : getpenaltyAmount;
            this.paymentDescriptionData3.penaltyDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyDueDate : '';
            this.paymentDescriptionData3.penaltyPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyPaymentRemarks : '';
            this.paymentDescriptionData3.penaltyPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('penaltyPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.penaltyPaymentStatus : '';
            this.paymentDescriptionData3.refundAmount = refundAmount ? refundAmount : 0;
            this.paymentDescriptionData3.refundDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundDueDate : '';
            this.paymentDescriptionData3.refundPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundPaymentRemarks : '';
            this.paymentDescriptionData3.refundPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('refundPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.refundPaymentStatus : '';
            this.paymentDescriptionData3.miscellaneousCharges = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousCharges') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousCharges : '';
            this.paymentDescriptionData3.miscellaneousChargesDueDate = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousChargesDueDate') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousChargesDueDate : '';
            this.paymentDescriptionData3.miscellaneousPaymentRemarks = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousPaymentRemarks') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousPaymentRemarks : '';
            this.paymentDescriptionData3.miscellaneousPaymentStatus = this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('miscellaneousPaymentStatus') ? this.gbRequest.updatePaymentdescriptiondeposite3.miscellaneousPaymentStatus : '';
            this.updatePaymentDescriptionDeposit3();
            return;
        }
    }

    updatePaymentDescriptionDeposit1(updateTypeCall?: any) {
        this.editPaymentDescFlagDeposit1 = false;
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        let userDetails = this.grmsUtils.getUserDetails();
        let formData: FormData = new FormData();
        // if (this.paymentDescriptionData1['penaltyAmount'] && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData1.penaltyAmount)) {
        //     this.snackBar.open("Kindly Enter Valid Numeric Value for Penalty Amount", "X", { duration: 3000 });
        //     return false;
        // }
        if (this.paymentDescriptionData1.refundAmount && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData1.refundAmount)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Refund Amount", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData1.miscellaneousCharges && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData1.miscellaneousCharges)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Miscellaneous Charges", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData1.penaltyattachment !== undefined) {
            formData.append("penaltyattachment", this.paymentDescriptionData1.penaltyattachment, this.paymentDescriptionData1.penaltyattachment.name);
        }
        if (this.paymentDescriptionData1.refundattachment !== undefined) {
            formData.append("refundattachment", this.paymentDescriptionData1.refundattachment, this.paymentDescriptionData1.refundattachment.name);
        }
        if (this.paymentDescriptionData1.miscellaneousattachment !== undefined) {
            formData.append("miscellaneousattachment", this.paymentDescriptionData1.miscellaneousattachment, this.paymentDescriptionData1.miscellaneousattachment.name);
        }

        if (this.paymentDescriptionData1.penaltyAmount) {
            formData.append("penaltyAmount", this.paymentDescriptionData1.penaltyAmount);
        }
        if (this.paymentDescriptionData1.penaltyDueDate) {
            formData.append("penaltyDueDate", this.paymentDescriptionData1.penaltyDueDate);
        }
        if (this.paymentDescriptionData1.penaltyPaymentRemarks) {
            formData.append("penaltyPaymentRemarks", this.paymentDescriptionData1.penaltyPaymentRemarks);
        }
        if (this.paymentDescriptionData1.penaltyPaymentStatus) {
            formData.append("penaltyPaymentStatus", this.paymentDescriptionData1.penaltyPaymentStatus);
        }

        if (this.paymentDescriptionData1.refundAmount) {
            formData.append("refundAmount", this.paymentDescriptionData1.refundAmount);
        }
        if (this.paymentDescriptionData1.refundDueDate) {
            formData.append("refundDueDate", this.paymentDescriptionData1.refundDueDate);
        }
        if (this.paymentDescriptionData1.refundPaymentRemarks) {
            formData.append("refundPaymentRemarks", this.paymentDescriptionData1.refundPaymentRemarks);
        }
        if (this.paymentDescriptionData1.refundPaymentStatus) {
            formData.append("refundPaymentStatus", this.paymentDescriptionData1.refundPaymentStatus);
        }

        if (this.paymentDescriptionData1.miscellaneousCharges) {
            formData.append("miscellaneousCharges", this.paymentDescriptionData1.miscellaneousCharges);
        }
        if (this.paymentDescriptionData1.miscellaneousChargesDueDate) {
            formData.append("miscellaneousChargesDueDate", this.paymentDescriptionData1.miscellaneousChargesDueDate);
        }
        if (this.paymentDescriptionData1.miscellaneousPaymentRemarks) {
            formData.append("miscellaneousPaymentRemarks", this.paymentDescriptionData1.miscellaneousPaymentRemarks);
        }
        if (this.paymentDescriptionData1.miscellaneousPaymentStatus) {
            formData.append("miscellaneousPaymentStatus", this.paymentDescriptionData1.miscellaneousPaymentStatus);
        }

        formData.append("createdBy", userDetails?.loginUserId);
        formData.append("lastUpdatedBy", userDetails?.loginUserId);
        formData.append("requestId", this.gbRequest.requestId);
        if (!!this.gbRequest.updatePaymentdescription.id) {
            formData.append("paymentDescriptionId", this.gbRequest.updatePaymentdescription.id)
        }
        if (this.paymentDetailsToShow.length == 2) {
            formData.append("ticketingAmount1", (this.gbRequest.requestgeneralData.ticketingAmount).toString());
        }
        if (this.paymentDetailsToShow.length == 3) {

            formData.append("ticketingAmount1", (this.gbRequest.requestgeneralData.ticketingAmount).toString());
        }
        if (this.paymentDetailsToShow.length == 1) {
            this.paymentDescriptionData1.ticketingAmount1 = (this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild) * Number(this.gbRequest.requestgeneralData.quotedFarePerPax) + (this.gbRequest.requestgeneralData.noOfInfant * this.gbRequest.requestgeneralData.infantFarePerPax);
            formData.append("ticketingAmount1", this.paymentDescriptionData1.ticketingAmount1);
        }

        this.httpService.postCall(BOOKING_API_ENPOINTS.updatePaymentDescriptiondeposite1, formData, this.grmsUtils.getAuthMultiHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (updateTypeCall !== "previousUpdate") {
                        this.reFreshDetails()
                    } else {
                        setTimeout(() => {
                            this.reFreshDetails();
                        }, 1000)
                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }

            });


    }

    updatePaymentDescriptionDeposit2(updateTypeCall?: any) {
        this.editPaymentDescFlagDeposit2 = false;
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        let formData: FormData = new FormData();
        // if (this.paymentDescriptionData2.penaltyAmount && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData2.penaltyAmount)) {
        //     this.snackBar.open("Kindly Enter Valid Numeric Value for Penalty Amount", "X", { duration: 3000 });
        //     return false;
        // }
        if (this.paymentDescriptionData2.refundAmount && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData2.refundAmount)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Refund Amount", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData2.miscellaneousCharges && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData2.miscellaneousCharges)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Miscellaneous Charges", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData2.penaltyattachment !== undefined) {
            formData.append("penaltyattachment", this.paymentDescriptionData2.penaltyattachment, this.paymentDescriptionData2.penaltyattachment.name);
        }
        if (this.paymentDescriptionData2.refundattachment !== undefined) {
            formData.append("refundattachment", this.paymentDescriptionData2.refundattachment, this.paymentDescriptionData2.refundattachment.name);
        }
        if (this.paymentDescriptionData2.miscellaneousattachment !== undefined) {
            formData.append("miscellaneousattachment", this.paymentDescriptionData2.miscellaneousattachment, this.paymentDescriptionData2.miscellaneousattachment.name);
        }

        if (this.paymentDescriptionData2.penaltyAmount) {
            formData.append("penaltyAmount", this.paymentDescriptionData2.penaltyAmount);
        }
        if (this.paymentDescriptionData2.penaltyDueDate) {
            formData.append("penaltyDueDate", this.paymentDescriptionData2.penaltyDueDate);
        }
        if (this.paymentDescriptionData2.penaltyPaymentRemarks) {
            formData.append("penaltyPaymentRemarks", this.paymentDescriptionData2.penaltyPaymentRemarks);
        }
        if (this.paymentDescriptionData2.penaltyPaymentStatus) {
            formData.append("penaltyPaymentStatus", this.paymentDescriptionData2.penaltyPaymentStatus);
        }

        if (this.paymentDescriptionData2.refundAmount) {
            formData.append("refundAmount", this.paymentDescriptionData2.refundAmount);
        }
        if (this.paymentDescriptionData2.refundDueDate) {
            formData.append("refundDueDate", this.paymentDescriptionData2.refundDueDate);
        }
        if (this.paymentDescriptionData2.refundPaymentRemarks) {
            formData.append("refundPaymentRemarks", this.paymentDescriptionData2.refundPaymentRemarks);
        }
        if (this.paymentDescriptionData2.refundPaymentStatus) {
            formData.append("refundPaymentStatus", this.paymentDescriptionData2.refundPaymentStatus);
        }

        if (this.paymentDescriptionData2.miscellaneousCharges) {
            formData.append("miscellaneousCharges", this.paymentDescriptionData2.miscellaneousCharges);
        }
        if (this.paymentDescriptionData2.miscellaneousChargesDueDate) {
            formData.append("miscellaneousChargesDueDate", this.paymentDescriptionData2.miscellaneousChargesDueDate);
        }
        if (this.paymentDescriptionData2.miscellaneousPaymentRemarks) {
            formData.append("miscellaneousPaymentRemarks", this.paymentDescriptionData2.miscellaneousPaymentRemarks);
        }
        if (this.paymentDescriptionData2.miscellaneousPaymentStatus) {
            formData.append("miscellaneousPaymentStatus", this.paymentDescriptionData2.miscellaneousPaymentStatus);
        }

        formData.append("createdBy", this.userDetails?.loginUserId);
        formData.append("lastUpdatedBy", this.userDetails?.loginUserId);
        formData.append("requestId", this.gbRequest.requestId);

        if (!!this.gbRequest.updatePaymentdescriptiondeposite2.id) {
            formData.append("paymentDescriptionId", this.gbRequest.updatePaymentdescriptiondeposite2.id)
        }
        if (!this.gbRequest.updatePaymentdescription.hasOwnProperty('ticketingAmount1')) {
            this.updatePaymentDescriptionDeposit1('previousUpdate');
        }
        if (this.paymentDetailsToShow.length == 2) {
            this.paymentDescriptionData2.ticketingAmount2 = (this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild) * Number(this.gbRequest.requestgeneralData.quotedFarePerPax) + (this.gbRequest.requestgeneralData.noOfInfant * this.gbRequest.requestgeneralData.infantFarePerPax);
            formData.append("ticketingAmount2", this.paymentDescriptionData2.ticketingAmount2);
        }
        if (this.paymentDetailsToShow.length == 3) {

            formData.append("ticketingAmount2", (this.gbRequest.requestgeneralData.ticketingAmount).toString());
        }

        // need to enable
        this.httpService.postCall(BOOKING_API_ENPOINTS.updatePaymentDescriptiondeposite2, formData, this.grmsUtils.getAuthMultiHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (updateTypeCall !== 'previousUpdate') {
                        this.reFreshDetails()
                    } else {
                        setTimeout(() => {
                            this.reFreshDetails();
                        }, 1000)
                    }

                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }

            });

    }

    updatePaymentDescriptionDeposit3() {
        this.editPaymentDescFlagDeposit3 = false;
        this.gbRequest.requestgeneralData.noOfAdult = Number(this.gbRequest.requestgeneralData.noOfAdult);
        this.gbRequest.requestgeneralData.noOfChild = Number(this.gbRequest.requestgeneralData.noOfChild);
        this.gbRequest.requestgeneralData.noOfInfant = Number(this.gbRequest.requestgeneralData.noOfInfant);
        let formData: FormData = new FormData();
        // if (this.paymentDescriptionData3.penaltyAmount && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData3.penaltyAmount)) {
        //     this.snackBar.open("Kindly Enter Valid Numeric Value for Penalty Amount", "X", { duration: 3000 });
        //     return false;
        // }
        if (this.paymentDescriptionData3.refundAmount && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData3.refundAmount)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Refund Amount", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData3.miscellaneousCharges && !/^[0-9]*\.?[0-9]*$/.test(this.paymentDescriptionData3.miscellaneousCharges)) {
            this.snackBar.open("Kindly Enter Valid Numeric Value for Miscellaneous Charges", "X", { duration: 3000 });
            return false;
        }
        if (this.paymentDescriptionData3.penaltyattachment !== undefined) {
            formData.append("penaltyattachment", this.paymentDescriptionData3.penaltyattachment, this.paymentDescriptionData3.penaltyattachment.name);
        }
        if (this.paymentDescriptionData3.refundattachment !== undefined) {
            formData.append("refundattachment", this.paymentDescriptionData3.refundattachment, this.paymentDescriptionData3.refundattachment.name);
        }
        if (this.paymentDescriptionData3.miscellaneousattachment !== undefined) {
            formData.append("miscellaneousattachment", this.paymentDescriptionData3.miscellaneousattachment, this.paymentDescriptionData3.miscellaneousattachment.name);
        }

        if (this.paymentDescriptionData3.penaltyAmount) {
            formData.append("penaltyAmount", this.paymentDescriptionData3.penaltyAmount);
        }
        if (this.paymentDescriptionData3.penaltyDueDate) {
            formData.append("penaltyDueDate", this.paymentDescriptionData3.penaltyDueDate);
        }
        if (this.paymentDescriptionData3.penaltyPaymentRemarks) {
            formData.append("penaltyPaymentRemarks", this.paymentDescriptionData3.penaltyPaymentRemarks);
        }
        if (this.paymentDescriptionData3.penaltyPaymentStatus) {
            formData.append("penaltyPaymentStatus", this.paymentDescriptionData3.penaltyPaymentStatus);
        }

        if (this.paymentDescriptionData3.refundAmount) {
            formData.append("refundAmount", this.paymentDescriptionData3.refundAmount);
        }
        if (this.paymentDescriptionData3.refundDueDate) {
            formData.append("refundDueDate", this.paymentDescriptionData3.refundDueDate);
        }
        if (this.paymentDescriptionData3.refundPaymentRemarks) {
            formData.append("refundPaymentRemarks", this.paymentDescriptionData3.refundPaymentRemarks);
        }
        if (this.paymentDescriptionData3.refundPaymentStatus) {
            formData.append("refundPaymentStatus", this.paymentDescriptionData3.refundPaymentStatus);
        }

        if (this.paymentDescriptionData3.miscellaneousCharges) {
            formData.append("miscellaneousCharges", this.paymentDescriptionData3.miscellaneousCharges);
        }
        if (this.paymentDescriptionData3.miscellaneousChargesDueDate) {
            formData.append("miscellaneousChargesDueDate", this.paymentDescriptionData3.miscellaneousChargesDueDate);
        }
        if (this.paymentDescriptionData3.miscellaneousPaymentRemarks) {
            formData.append("miscellaneousPaymentRemarks", this.paymentDescriptionData3.miscellaneousPaymentRemarks);
        }
        if (this.paymentDescriptionData3.miscellaneousPaymentStatus) {
            formData.append("miscellaneousPaymentStatus", this.paymentDescriptionData3.miscellaneousPaymentStatus);
        }

        formData.append("createdBy", this.userDetails?.loginUserId);
        formData.append("lastUpdatedBy", this.userDetails?.loginUserId);
        formData.append("requestId", this.gbRequest.requestId);
        if (!!this.gbRequest.updatePaymentdescriptiondeposite3.id) {
            formData.append("paymentDescriptionId", this.gbRequest.updatePaymentdescriptiondeposite3.id)
        }

        if (!this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount1')) {
            this.updatePaymentDescriptionDeposit1("previousUpdate");
        }
        if (!this.gbRequest.updatePaymentdescriptiondeposite3.hasOwnProperty('ticketingAmount2')) {
            this.updatePaymentDescriptionDeposit2("previousUpdate");
        }
        if (this.paymentDetailsToShow.length == 3) {
            this.paymentDescriptionData3.ticketingAmount3 = (this.gbRequest.requestgeneralData.noOfAdult + this.gbRequest.requestgeneralData.noOfChild) * Number(this.gbRequest.requestgeneralData.quotedFarePerPax) + (this.gbRequest.requestgeneralData.noOfInfant * this.gbRequest.requestgeneralData.infantFarePerPax);
            formData.append("ticketingAmount3", this.paymentDescriptionData3.ticketingAmount3);
        }

        //need to enable
        this.httpService.postCall(BOOKING_API_ENPOINTS.updatePaymentDescriptiondeposite3, formData, this.grmsUtils.getAuthMultiHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    this.reFreshDetails()
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }

            });


    }

    validationCheckOnReducePax() {
        if (this.paymentDetailsToShow.length == 1) {
            if (this.newAdultValue < this.oldAdultValue || this.newChildValue < this.oldChildValue || this.newInfantValue < this.oldInfantValue) {
                this.snackBar.open("Kindly fill Penalty and Miscelleneous amounts of Deposit-1 payment description table. Note: Kindly update as 0 if there is no Penalty and Miscelleneous charges.", "X", { duration: 1000 });
                return;
            }
        } else if (this.paymentDetailsToShow.length == 2) {
            if (this.newAdultValue < this.oldAdultValue || this.newChildValue < this.oldChildValue || this.newInfantValue < this.oldInfantValue) {

                this.snackBar.open("Kindly fill Penalty and Miscelleneous amounts of Deposit-2 payment description table. Note: Kindly update as 0 if there is no Penalty and Miscelleneous charges.", "X", { duration: 1000 });
                return;
            }
        } else if (this.paymentDetailsToShow.length == 3) {
            if (this.newAdultValue < this.oldAdultValue || this.newChildValue < this.oldChildValue || this.newInfantValue < this.oldInfantValue) {
                this.snackBar.open("Kindly fill Penalty and Miscelleneous amounts of Deposit-3 payment description table. Note: Kindly update as 0 if there is no Penalty and Miscelleneous charges.", "x", { duration: 1000 });
                return;
            }
        }
    }

    getUpdatePaxInfo(event) {
        this.gbRequest['actionType'] = 'UPDATE_PAX_DETAILS';
        this.gbRequest['paxDetails'] = event;
        this.gbRequest.requestgeneralData['gst'] = 'N';
        this.updateRequest('Pax Details updated');
    }

    confirmPaxInfo(event) {
        this.gbRequest['actionType'] = 'CONFIRM_PAX_DETAILS';
        this.gbRequest['paxDetails'] = event;

        let dialogRef = this.matDialog.open(PopOverContentComponent, {
            disableClose: true,
            width: '45%',
            data: { data: '', action: 'gstform' }
        })
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (!!result.data) {
                this.gbRequest.requestgeneralData['gst'] = 'Y';
                this.gbRequest.requestgeneralData['gstnumber'] = result.data.gstnumber;
                this.gbRequest.requestgeneralData['gstcompanyname'] = result.data.gstcompanyname;
                this.gbRequest.requestgeneralData['gstphonenumber'] = result.data.gstphonenumber;
                this.gbRequest.requestgeneralData['gstemail'] = result.data.gstemail;
                this.gbRequest.requestgeneralData['gstcompanyaddress'] = result.data.gstcompanyaddress + ',' + result.data.gstcompanyaddress2 + ',' + result.data.country + ',' + result.data.state + ',' + result.data.city + ',' + result.data.pincode;
            }
            else {
                this.gbRequest.requestgeneralData['gst'] = 'N';
            }
            this.updateRequest('PAX details confirmed');
        })

    }

    tickSubmit() {
        this.gbRequest['actionType'] = 'ISSUE_TICKET';
        this.updateRequest('Ticketed');
    }

    /**Request Cancel Action Start **/
    cancelRequest() {

        let dialogRef = this.matDialog.open(PopOverContentComponent, {
            disableClose: true,
            width: '31%',
            data: { data: 'Similar Requests available for this request', action: 'cancelRequest' }
        })
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            this.loaderService.hide();
            if (!!result.data) {
                this.gbRequest['actionType'] = "REQUEST_CANCEL";
                this.gbRequest['reasonForCancellation'] = result.data;
                this.updateRequest('Request cancelled ');

            }
        })

    }



    constructItineraryDetails(requestGeneralData) {
        this.finalItineraryArr = [];
        let tripType = requestGeneralData.tripType.replace(" ", "_");
        tripType = (tripType !== 'Multiple') ? tripType.replaceAll(' ', '_') : tripType;
        let originCode = requestGeneralData.from;
        if (tripType === "One_Way") {
            this.finalItineraryArr.push(this.constructOneWayTrip(originCode));
        } else {
            this.finalItineraryArr = [...this.constructRoundTrip()];
        }
    }

    constructOneWayTrip(originCode) {
        let tempObj = {};
        if (this.itineraryDetails.length > 0) {
            for (let i = 0; i < this.itineraryDetails.length; i++) {
                let index = i;
                let itineraryObj = _.cloneDeep(this.itineraryDetails[i]);
                itineraryObj['connectingFlight'] = [];
                itineraryObj['minDate'] = new Date();
                let preDate = moment(itineraryObj.departureDate);
                itineraryObj['travelDate'] = preDate ? preDate['_d'] : new Date();
                let previousCompareObj = {};
                for (let j = 0; j < this.itineraryDetails.length; j++) {
                    let compareItineraryObj = this.itineraryDetails[j];
                    if (index !== j) {
                        if (itineraryObj.to === compareItineraryObj.from) {
                            itineraryObj['connectingFlight'].push(compareItineraryObj);
                        } else if (Object.keys(previousCompareObj).length > 0 && previousCompareObj['to'] == compareItineraryObj.from) {
                            itineraryObj['connectingFlight'].push(compareItineraryObj);
                        }
                        previousCompareObj = compareItineraryObj;
                    }
                }
                if (originCode === itineraryObj.from) {
                    tempObj = itineraryObj;
                }
            }
        } else {
            return this.itineraryDetails;
        }
        return tempObj;
    }

    constructRoundTrip() {
        let tempArr = [];
        if (this.itineraryDetails.length > 0) {
            let groupByTrip = _.groupBy(this.itineraryDetails, obj => obj.stops);
            Object.keys(groupByTrip).forEach(([key], index) => {
                let itineraryDetails = groupByTrip[key];
                let originCode = itineraryDetails[0].from;
                let minDate;
                if (index === 0) {
                    minDate = new Date();
                } else {
                    let itineraryLength = groupByTrip[index - 1].length - 1;
                    let preDate = moment(groupByTrip[index - 1][itineraryLength].arrivalDate);
                    minDate = preDate ? preDate['_d'] : new Date();
                }
                groupByTrip[index][0]['minDate'] = minDate;
                let preDepartureDate = moment(groupByTrip[index][0].departureDate);
                groupByTrip[index][0]['travelDate'] = preDepartureDate ? preDepartureDate['_d'] : new Date();
                if (this.itineraryDetails.length > 0) {
                    for (let i = 0; i < itineraryDetails.length; i++) {
                        let index = i;
                        let itineraryObj = _.cloneDeep(itineraryDetails[i]);
                        itineraryObj['connectingFlight'] = [];
                        let previousCompareObj = {};
                        for (let j = 0; j < itineraryDetails.length; j++) {
                            let compareItineraryObj = itineraryDetails[j];
                            if (index !== j) {
                                if (itineraryObj.to === compareItineraryObj.from) {
                                    itineraryObj['connectingFlight'].push(compareItineraryObj);
                                } else if (Object.keys(previousCompareObj).length > 0 && previousCompareObj['to'] == compareItineraryObj.from) {
                                    itineraryObj['connectingFlight'].push(compareItineraryObj);
                                }
                                previousCompareObj = compareItineraryObj;
                            }
                        }
                        if (originCode === itineraryObj.from) {
                            tempArr.push(itineraryObj);
                        }
                    }
                } else {
                    return this.itineraryDetails;
                }
            });

        }
        return tempArr;
    }

    dateValueChange(e, index, trip) {
        this.validScheduleDate = true;
        let selectedDate = moment(e.value._d).format('DD-MMM-YYYY');
        if (this.finalItineraryArr.length > 1) {
            for (let i = 0; i < index; i++) {
                let nextItineraryDate = this.finalItineraryArr[i].departureDate;
                if (new Date(selectedDate) < new Date(nextItineraryDate)) {
                    this.validScheduleDate = false;
                    let preDate = moment(trip.departureDate);
                    trip.travelDate = preDate ? preDate['_d'] : new Date();
                    this.snackBar.open('Please change Itineraries Depature Date for your plan', 'x', { duration: 3000 });
                } else {
                    this.validScheduleDate = true;
                }
            }

            if (this.validScheduleDate) {
                for (let i = index + 1; i < this.finalItineraryArr.length; i++) {
                    let nextItineraryDate = this.finalItineraryArr[i].departureDate;
                    if (this.grmsUtils.isNotNull(nextItineraryDate) && new Date(selectedDate) > new Date(nextItineraryDate)) {
                        this.validScheduleDate = false;
                        let preDate = moment(trip.departureDate);
                        trip.travelDate = preDate ? preDate['_d'] : new Date();
                        this.snackBar.open('Please change Itineraries Depature Date for your plan', 'x', { duration: 3000 });
                    } else {
                        this.validScheduleDate = true;
                    }
                }
            }

        } else {
            this.validScheduleDate = true;
        }

        if (this.validScheduleDate) {
            trip.departureDate = selectedDate;
        }
    }

    searchFlightDetails(trip, flightDate, cabinclass, index) {
        if (this.validScheduleDate) {
            this.getOriginDestination(trip);
            let payload = {
                "endDate": flightDate,
                "flightDate": flightDate,
                "departureStation": this.originCodeVal,
                "arrivalStation": this.destinationCodeVal,
                "cabinclass": cabinclass,
                "session": null,
                "searchReq": null
            }
            this.loaderService.show();
            this.httpService.postCall(BOOKING_API_ENPOINTS.searchFlights, payload, this.grmsUtils.getAuthHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        this.openFlightListDialog(payload, response, cabinclass, index, trip)
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                })
        } else {
            this.snackBar.open('Kindly change other Itineraries Depature Date to schedule current Flight', 'X', { duration: 3000 });
        }

    }

    getOriginDestination(trip) {
        this.originCodeVal = trip.from;
        this.destinationCodeVal = trip.to;
        if (trip.connectingFlight.length > 0) {
            let index = trip.connectingFlight.length - 1;
            this.destinationCodeVal = trip.connectingFlight[index].to;
        }
    }

    openFlightListDialog(requestData, responseData, cabinclass, i, trip) {
        let Index = i
        let dialogRef = this.matDialog.open(SearchFlightListComponent, {
            disableClose: true,
            width: '80%',
            height: '50%',
            data: { flightResponse: responseData, flightRequest: requestData, tripData: trip }
        });
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (!!result.data.details) {
                result.data.details.map((conData) => {
                    conData['startIndex'] = Index;
                })
            } else {
                result.data['startIndex'] = Index;
            }
            this.tripItineraryInfo.splice(Index, 0, result.data);
            this.updaetFlightDetails(cabinclass, Index, trip);
        });

    }

    updaetFlightDetails(cabinclass, Index, trip) {
        this.previousItineraryDetails = [...this.gbRequest.itinerayDetails];
        let currentItineraryDetails = [...this.gbRequest.itinerayDetails];
        let itineraryData: any = [];
        let currentItineraries = _.groupBy(currentItineraryDetails, obj => obj.stops);
        this.tripItineraryInfo.forEach((el) => {
            if (!!el.details) {
                el.details.map((inEl) => {
                    itineraryData.push({
                        'showCalendar': false,
                        'from': inEl.originCode,
                        "flightNumber": inEl.flightNo,
                        'to': inEl.destinationCode,
                        "departureDate": this.datePipe.transform(inEl.departureDate, "dd-MMM-yyyy"),
                        "travelDuration": el.travelDuration,
                        "arrivalDate": this.datePipe.transform(inEl.arrivalDate, "dd-MMM-yyyy"),
                        "sdt": new Date(),
                        "flightTime": `${inEl.departureTime}-${inEl.arrivalTime}`,
                        "NoOfStops": el.stops,
                        "stops": inEl.startIndex,
                        "classOfTravel": cabinclass,
                        'PopOverDetails': []

                    })
                })
            } else {
                itineraryData.push({
                    'showCalendar': false,
                    'from': el.originCode,
                    "flightNumber": el.flightNo,
                    'to': el.destinationCode,
                    "departureDate": this.datePipe.transform(el.departureDate, "dd-MMM-yyyy"),
                    "travelDuration": el.travelDuration,
                    "arrivalDate": this.datePipe.transform(el.arrivalDate, "dd-MMM-yyyy"),
                    "sdt": new Date(),
                    "flightTime": `${el.departureTime}-${el.arrivalTime}`,
                    "NoOfStops": el.stops,
                    "stops": el.startIndex,
                    "classOfTravel": cabinclass,
                    'PopOverDetails': []
                })
            }
        });
        currentItineraryDetails = [];
        Object.keys(currentItineraries).forEach(([key], index) => {
            if (trip.stops === key) {
                for (let i = 0; i < itineraryData.length; i++) {
                    currentItineraryDetails.push(itineraryData[i]);
                }
            } else {
                for (let i = 0; i < currentItineraries[key].length; i++) {
                    currentItineraryDetails.push(currentItineraries[key][i]);
                }
            }
        });

        for (let i = 0; i < currentItineraryDetails.length; i++) {
            currentItineraryDetails[i].itineraryno = i + '1';
        }

        this.gbRequest.itinerayDetails = [...currentItineraryDetails]
        this.gbRequest['actionType'] = 'FLIGHT_DETAILS_UPDATE';
        this.updateRequest('Flight details updated');
    }

    getConvertedCurrency() {
        if (this.isGSAUser === 'Y') {
            let payload = {
                "convertFrom": this.gbRequest.requesterDetails['currency'],
                "baseAmount": Number(this.gbRequest.requestgeneralData['quotedFarePerPax'])
            };
            this.loaderService.show();
            this.httpService.postCall(BOOKING_API_ENPOINTS.currencyConvert, payload, this.grmsUtils.getAuthHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.gbRequest.requestgeneralData['convertedQuotedFarePerPax'] = response.convertCurrency.convertedAmount;
                            this.loaderService.hide();
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }

    }

    downloadAttachment(fileId: any, attchName: string, acceptFileType: string) {
        if (fileId) {
            this.loaderService.show();
            this.httpService.getCall(REPORT_API_ENDPOINTS.downloadFile + fileId, this.grmsUtils.getFileJSONHeader(acceptFileType))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        let blob: any = new Blob([response]);
                        FileSaver.saveAs(blob, attchName);
                    },
                    error: (errorDetails: any) => {
                        this.loaderService.hide();
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }
    }

    updateRefundToReport(penaltyAmount) {
        let refundAmount: any;
        let totalPenaltyAmount = 0;
        let amountReviced: number = 0;
        let paymentDescList = ['updatePaymentdescription', 'updatePaymentdescriptiondeposite2', 'updatePaymentdescriptiondeposite3'];
        for (let i = 0; i < paymentDescList.length; i++) {
            if (this.gbRequest[paymentDescList[i]].hasOwnProperty('penaltyAmount')) {
                let penaltyAmount = Number(this.gbRequest[paymentDescList[i]].penaltyAmount);
                totalPenaltyAmount = totalPenaltyAmount + penaltyAmount;
            }
        }
        let ticketingAmount = this.gbRequest.requestgeneralData.ticketingAmount + totalPenaltyAmount;
        if (this.gbRequest.paymentDetails !== undefined) {
            for (let paymentDetails of this.gbRequest.paymentDetails) {
                if (paymentDetails.paymentStatus === "PAYMENT DONE" || paymentDetails.paymentStatus === "PARTIAL PAYMENT DONE") {
                    amountReviced += paymentDetails.amountPaid;
                }
            }
            this.totalAmountReceived = amountReviced;
            refundAmount = this.totalAmountReceived - ticketingAmount;
        }

        let formData: FormData = new FormData();
        formData.append("loginUserID", this.userDetails.loginUserId);
        formData.append("requestId", this.gbRequest.requestId);
        formData.append("refundFare", refundAmount);

        this.loaderService.show();
        this.httpService.postCall(BOOKING_API_ENPOINTS.updateRefundToReport, formData, this.grmsUtils.getAuthMultiHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        this.getDepositWiseTa(penaltyAmount);
                        this.snackBar.open("Refund Amount updated successfully", "x", { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getPaymentTableName(requestDetails, penaltyAmountCharged) {
        let paidPaymentCount = 0;
        for (let paymentDetails of this.gbRequest.paymentDetails) {
            if (paymentDetails.paymentStatus === "PAYMENT DONE" || paymentDetails.paymentStatus === "PARTIAL PAYMENT DONE") {
                paidPaymentCount++;
            }
        }
        let sourceTableName;
        if (this.gbRequest.paymentStatus === 'PAYMENT DONE' && paidPaymentCount === 1) {
            sourceTableName = 'update_payment_description_deposite1';
        } else if (this.gbRequest.paymentStatus === 'PARTIAL PAYMENT DONE' && paidPaymentCount === 1 && this.totalAmountPaid > 0) {
            sourceTableName = 'update_payment_description_deposite1';
        } else if (this.gbRequest.paymentStatus === 'PAYMENT DONE' && paidPaymentCount === 2) {
            sourceTableName = 'update_payment_description_deposite2';
        } else if (this.gbRequest.paymentStatus === 'PARTIAL PAYMENT DONE' && paidPaymentCount === 2 && this.totalAmountPaid > 0) {
            sourceTableName = 'update_payment_description_deposite2';
        } else if (this.gbRequest.paymentStatus === 'PAYMENT DONE' && paidPaymentCount === 3) {
            sourceTableName = 'update_payment_description_deposite3';
        }

        requestDetails['sourceTable'] = sourceTableName;
        requestDetails['penaltyAmount'] = typeof (penaltyAmountCharged) === 'string' ? 0 : penaltyAmountCharged;
        return requestDetails;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
