import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../shared/services/gb-loader.services';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'gb-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(private loaderService: LoaderService) {
        // /this.isLoading = this.spinnerService.isLoading;
    }

    ngOnInit() {
        this.loaderService.isLoading
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isLoading.next(action);
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
