import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { GB_CONSTANTS } from '../../shared/static/constants';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { LOGIN_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { SideNavBarService } from "../../shared/services/side-nav-bar.service";
import { LoaderService } from '../../shared/services/gb-loader.services';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    public errorMessage;
    public newPwdVal = "";
    public newPwdError = "";
    public hideOldPwd = false;
    public hideNewPwd = false;
    public confirmPwdVal = "";
    public hideconfPwd = false;
    public confirmPwdError = "";
    public isChnagePwdErr = true;
    public passwordForm!: FormGroup;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS.REGISTER_FORM;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private sideNavBar: SideNavBarService,
        private userProfileService: UserProfileService,
    ) { }

    ngOnInit(): void {
        if (this.cookieService.get('isChangePwd') === 'Y') {
            this.sideNavBar.hideMenu();
        } else {
            this.sideNavBar.showMenu();
        }
        this.sideNavBar.hideSideNavBar();
        this.userProfileService.setUsername();
        this.userProfileService.setUserRole();
        this.userProfileService.setVistaraUserFlag();
        this.createForm();
    }

    formErrors = {
        "oldPassword": "",
        "newPassword": "",
    }

    createForm() {
        this.passwordForm = this.fb.group({
            oldPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.PASSWORD)]],
            confirmPassword: [null, [Validators.required]]
        });
        this.passwordForm.valueChanges
            .subscribe(data => {
                this.onValueChanged(data)
            });

        this.onValueChanged();
    }

    onValueChanged(data?: any) {
        if (!this.passwordForm) { return; }
        const form = this.passwordForm;

        for (const field in this.formErrors) {
            this.formErrors[field] = "";
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessage['password'];
                if (control.touched && control.status !== "INVALID") {
                    this.formErrors[field] += messages['required'] + "  ";
                } else {
                    for (const key in control.errors) {
                        this.formErrors[field] += messages[key] + "  ";
                    }
                }
            }
        }
    }
    checkPassword() {
        // if (this.passwordForm.status === 'INVALID') {
        //     this.newPwdError = this.validationMessage.password.required;
        // }
        if (this.newPwdVal !== this.passwordForm.controls['oldPassword'].value) {
            this.newPwdError = '';
        } else {
            this.newPwdError = "New Password should not same as Old Password";
        }

        if (this.confirmPwdVal !== undefined && this.newPwdVal !== this.confirmPwdVal) {
            this.confirmPwdError = "Confirm Password must be same as New Password";
        } else {
            this.confirmPwdError = "";
        }

        if (this.newPwdError || this.confirmPwdError) {
            this.isChnagePwdErr = true;
        } else if (this.passwordForm.status === 'INVALID') {
            this.isChnagePwdErr = true;
        } else {
            this.isChnagePwdErr = false;
        }

    }

    changePassword() {
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            "emailId": userDetails.loginUserId,
            "password": this.grmsUtils.encryptedData(this.passwordForm.controls['newPassword'].value),
            "oldPassword": this.grmsUtils.encryptedData(this.passwordForm.controls['oldPassword'].value)
        }
        this.loaderService.show();
        this.httpService.postCall(LOGIN_API_ENDPOINTS.changePassword, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.cookieService.delete('auth_token', '/');
                        this.cookieService.delete('login_time', '/');
                        this.cookieService.delete('userDetails', '/');
                        this.cookieService.delete('refresh_token', '/');
                        this.cookieService.delete('currentMenu', '/');
                        this.userProfileService.setExistingUserFlag()
                        this.router.navigate(['/']);
                        this.snackBar.open("Password Changed Successfully", 'X', { duration: 3000 });

                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}
