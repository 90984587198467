import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { GrmsUtils } from '../static/GRMSUtils';
import { CookieService } from 'ngx-cookie-service';
import { GRMS_CONSTANT } from '../static/constants';
import { LoaderService } from './gb-loader.services';
import { HttpService } from '../httpService/http.service';
import { REGISTER_API_POINTS } from '../httpService/apiURLConstant';
import { SessionModelComponent } from '../model/session-model/session-model.component';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    public isDialogOpened = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private cookieService: CookieService,
        private loaderService: LoaderService,
    ) { }

    public timerFnc;

    setSessionTimeout(timeoutInput?) {
        let expiryDate = new Date();
        let timetoutValue = (timeoutInput) ? timeoutInput : GRMS_CONSTANT.GRMS_SESSION_WARN_TIME;
        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);

        this.timerFnc = setTimeout(() => {

            let pageName = window.location.pathname.split('/')[2];
            if ((pageName !== '' && pageName !== '/' && pageName !== '/logout' && pageName !== 'register'
                && pageName !== 'vistara-login' && pageName !== 'vistara-logout') && !this.isDialogOpened) {
                // this.openDialog();
                this.extendSession();
                this.isDialogOpened = true;
            }
        }, timetoutValue * 1000);

    }

    clearSessionTimeout() {
        this.timerFnc = null;
        this.isDialogOpened = false;
        clearTimeout(this.timerFnc);
    }


    openDialog() {
        const dialogRef = this.dialog.open(SessionModelComponent, {
            disableClose: true,
            width: '495px',
            height: '145px',
            data: { isSessionExpire: false }
        })

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    extendSession() {
        let userDetails = this.cookieService.get('userDetails');
        let payload = {
            "accessToken": this.cookieService.get('auth_token'),
            "refreshToken": this.cookieService.get('refresh_token')
        }
        // this.loaderService.show();
        this.httpService.postCall(REGISTER_API_POINTS.sessionExtend, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    // this.loaderService.hide();
                    let expiryDate = new Date();
                    let loginTime = moment(new Date(), 'YYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A');
                    expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
                    sessionStorage.removeItem('auth_token');
                    sessionStorage.removeItem('login_type');
                    this.cookieService.delete('auth_token', '/');
                    this.cookieService.delete('login_time', '/');
                    this.cookieService.delete('userDetails', '/');
                    this.cookieService.delete('refresh_token', '/');
                    sessionStorage.setItem('auth_token', response.accessToken);
                    sessionStorage.setItem('login_type', userDetails['login_type']);
                    this.cookieService.set("login_time", loginTime, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("userDetails", userDetails, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("auth_token", response.accessToken, expiryDate, '/', '', false, "Lax");
                    this.cookieService.set("refresh_token", response.refreshToken, expiryDate, '/', '', false, "Lax");
                    this.setSessionTimeout();
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });

    }
}
