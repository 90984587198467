export class PaxDetails {
    origin?: string;
    destination?: string;
    flightNumbers?: string;
    cabinClass?: string;
    saleStartDate?: Date;
    saleEndDate?: Date;
    travelStartDate?: Date;
    travelEndDate?: Date;
    daysOfOperation?: Array<[]>;
    minGroupSize?: string;

    id?: number;
    flightNo?: string;
    flightDateFrom?: Date;
    flightDateTo?: Date;
    dow?: Array<[]>;
    daysToDeparture?: string;
    minLoadFactor?: string;
    maxLoadFactor?: string;
    minSize?: string;
    maxSize?: string;
    operationType?: string;
    adultFare?: number;
    infantFare?: number;
}
