import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import * as moment from 'moment';
import * as _ from "lodash";
import { catchError, forkJoin, of, Subject } from 'rxjs';
import { filter, takeUntil, tap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import {
    BOOKING_API_ENPOINTS,
    USER_RULE_API_ENDPOINTS,
    PAX_RESTRICTION_ENDPOINTS,
    COMMON_API_ENDPOINTS,
    DESK_USERS_ENDPOINTS,
    SUB_AGENT_ENDPOINTS,
    RULES_ENDPOINTS,
    REGISTER_API_POINTS,
    AGENT_RULE_API_ENDPOINTS,
    COPORATE_CODE_ENDPOINTS,
    TOUR_CODE_API_ENDPOINTD
} from 'src/app/shared/httpService/apiURLConstant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { PaxDetails, ProfileDetails, MANAGE_DETAILS } from 'src/app/shared/interface';
import { GB_CONSTANTS } from 'src/app/shared/static/constants';

@Component({
    selector: 'app-datatable-model',
    templateUrl: './datatable-model.component.html',
    styleUrls: ['./datatable-model.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DatatableModelComponent implements OnInit {

    public pageSrc;
    public selectedDays;
    public SPEIErrorMsg;
    public password = '';
    public btnText = 'Add';
    public columnDefs = [];
    public displayedColumns;
    public isGSAUser = false;
    public departureDays = "";
    private validationMessage;
    public cityList: any = [];
    public stateList: any = [];
    public endDate = new Date();
    public departureOption = "";
    public countryList: any = [];
    public pincodeList: any = [];
    public confPasswordValue = '';
    public startDate = new Date();
    public todayDate = new Date();
    public dataSource: any[] = [];
    public airportList: any[] = [];
    public isEmailDisable = false;
    public paxRuleForm!: FormGroup;
    public registerForm!: FormGroup;
    public operationMode = 'reject';
    public deskUserForm!: FormGroup;
    public saleStartDate = new Date();
    public isApprove: Boolean = false;
    public travelStartDate = new Date();
    public paxDetails = new PaxDetails();
    public hidePassword = false;
    public showsizeAndFare: Boolean = false;
    public hideConfPassword = false;
    public hidePasswordFields: Boolean = false;
    public profileDetails = new ProfileDetails();
    public manageDetails = new MANAGE_DETAILS();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    public paymentExemption: Array<any> = ['Applicable', 'Non Applicable'];
    public cabinClassList: any = ['Economy', 'Premium Economy', 'Business'];

    public roleList = [
        { 'code': 'ROLE_GS', "desc": "GS" },
        { 'code': 'ROLE_SU', "desc": "SU" }
    ];

    public filteredAirports = {
        from: [],
        to: []
    };

    public daysOfweekList = [
        { code: '1', desc: 'Monday' },
        { code: '2', desc: 'Tuesday' },
        { code: '3', desc: 'Wednesday' },
        { code: '4', desc: 'Thursday' },
        { code: '5', desc: 'Friday' },
        { code: '6', desc: 'Saturday' },
        { code: '7', desc: 'Sunday' }
    ]

    public daysOfDepartureList = ['>', '>=', '<', '<=', '='];

    public operationList = [
        { desc: 'REJECT', code: 'reject' },
        { desc: 'APPROVE', code: 'approve' }
    ];

    public noPaxFieldValidateList = ['flightNumbers', 'saleStartDate', 'saleEndDate',
        'travelStartDate', 'travelEndDate', 'daysOfOperation', 'minGroupSize'];

    public noRuleFieldValidateList = ['flightNo', 'flightDateFrom', 'flightDateTo', 'minSize', 'maxSize', 'adultFare', 'infantFare'];

    public operationBasedValidateList = ['minSize', 'maxSize', 'adultFare', 'infantFare'];

    public pageNameList = ['Agent', 'newUser', 'SA'];
    public newUserRoleList = [
        { 'code': 'ROLE_USER', "desc": "USER" },
        { 'code': 'ROLE_AGENT', "desc": "Agent" },
        { 'code': 'ROLE_DESK_USER', "desc": "Desk User" },
    ];


    public formErrors = {
        "emailId": "",
        "employeeId": "",
        "password": "",
        "confirmPassword": "",
        "userType": "",
        "origin": "",
        "destination": "",
        "flightNumbers": "",
        "flightNo": "",
        "cabinClass": "",
        "saleStartDate": "",
        "saleEndDate": "",
        "travelStartDate": "",
        "travelEndDate": "",
        "daysOfOperation": "",
        "minGroupSize": "",
        "flightDateFrom": "",
        "flightDateTo": "",
        "minSize": "",
        "maxSize": "",
        "adultFare": "",
        "infantFare": "",
        "country": "",
        "state": "",
        "city": "",
        "pincode": "",

        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "address": "",
        "agencyName": "",
        "paymentExemption": "",
        "salesPersonEmailId": "",
    };

    constructor(
        private dialogRef: MatDialogRef<DatatableModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private httpService: HttpService,
        private snackBar: MatSnackBar,
        private grmsUtils: GrmsUtils,
        private location: Location,
        private fb: FormBuilder,
        private router: Router,

    ) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
        this.todayDate.setDate(this.todayDate.getDate() - 0);
    }

    ngOnInit(): void {
        let userDetails = this.grmsUtils.getUserDetails();
        this.isGSAUser = userDetails?.gsaUser === 'Y' ? true : false;
        this.pageSrc = this.data.pageSrc;
        delete this.data.pageSrc;
        this.validationMessage = (this.pageSrc === 'DU') ? GB_CONSTANTS.FORM_VALIDATIONS.FORMS.DESK_USER_FROM :
            (this.pageNameList.indexOf(this.pageSrc) > -1) ? GB_CONSTANTS.FORM_VALIDATIONS.FORMS.REGISTER_FORM : GB_CONSTANTS.FORM_VALIDATIONS.FORMS.PAX_FROM;
        this.createForm();
        this.getAirportList();
        let selectedRow = (this.pageSrc !== 'ticketReports') ? _.cloneDeep(this.data.value) : _.cloneDeep(this.data.paymentDetails);
        if (selectedRow && Object.keys(selectedRow).length > 0) {
            this.getSelectedRowVal(selectedRow);
            if (this.pageSrc === 'SA' || this.pageSrc === 'Agent') {
                this.isEmailDisable = true;
                this.hidePasswordFields = true;
                this.registerForm.controls['password'].setValidators(null);
                this.registerForm.controls['confirmPassword'].setValidators(null);
                this.registerForm.controls['password'].updateValueAndValidity();
                this.registerForm.controls['confirmPassword'].updateValueAndValidity();
                if (this.pageSrc === 'Agent') {
                    let formFieldArr = ['firstName', 'lastName', 'phoneNumber'];
                    this.removeOrAddValidation(formFieldArr, 'registerForm');
                } else {
                    let formFieldArr = ['agencyName', 'iataCode', 'paymentExemption', 'salesPersonEmailId'];
                    this.removeOrAddValidation(formFieldArr, 'registerForm');
                }
                if (this.grmsUtils.isNotNull(selectedRow.country) && !this.isGSAUser) {
                    this.registerForm.controls['country'].setValue('IN');
                }
                let countryName = selectedRow.country ? selectedRow.country : (this.isGSAUser) ? 'IN' : '';
                let stateName = selectedRow.state ? selectedRow.state : '';
                let cityName = selectedRow.city ? selectedRow.city : '';
                selectedRow.pincode = selectedRow.pincode.toString();
                if (!this.isGSAUser) {
                    this.loaderService.show();
                    forkJoin({
                        country: this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                        state: this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                        city: this.httpService.getCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                        pincode: this.httpService.getCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                    }).subscribe((data) => {
                        this.loaderService.hide();
                        this.countryList = this.grmsUtils.forkHandling(data.country);
                        this.stateList = this.grmsUtils.forkHandling(data.state);
                        this.cityList = this.grmsUtils.forkHandling(data.city);
                        this.pincodeList = this.grmsUtils.forkHandling(data.pincode);
                    });
                } else {
                    this.getCountryList();
                }

            } else if (this.pageSrc === 'newUser') {
                let formFieldArr = ['emailId', 'password', 'confirmPassword', 'address1',
                    'country', 'state', 'city', 'pincode', 'agencyName', 'iataCode', 'paymentExemption', 'salesPersonEmailId'];
                this.removeOrAddValidation(formFieldArr, 'registerForm');
            }

        } else {
            this.updateFormValidation(this.pageSrc);
            this.loaderService.show();
            if (this.pageSrc === 'SA' || this.pageSrc === 'Agent') {
                this.hidePasswordFields = false;
                let formFieldArr = ['agencyName', 'iataCode', 'paymentExemption', 'salesPersonEmailId'];
                this.removeOrAddValidation(formFieldArr, 'registerForm');

                let payload = {
                    "loginUserID": userDetails.loginUserId
                }
                forkJoin({
                    country: this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                    state: this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/IN', this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                    userData: this.httpService.postCall(REGISTER_API_POINTS.retrieveProfile, payload, this.grmsUtils.getAuthHeader()).pipe(catchError(error => of(error))),
                }).subscribe((data) => {
                    this.loaderService.hide();
                    this.registerForm.controls['country'].setValue('IN');
                    this.countryList = this.grmsUtils.forkHandling(data.country); //data.country;
                    this.stateList = this.grmsUtils.forkHandling(data.state);
                    if (this.isGSAUser) {
                        this.countryList = this.countryList.filter((obj) => {
                            return obj.countryCode !== 'IN';
                        });
                        let loggedUserData = this.grmsUtils.forkHandling(data.userData.userDetails);
                        this.manageDetails.country = loggedUserData.country;
                        this.registerForm.controls['country'].setValue(loggedUserData.country);
                        this.manageDetails.state = loggedUserData.state;
                        this.manageDetails.city = loggedUserData.city;
                        this.manageDetails.pincode = loggedUserData.pincode;
                    }
                });
            }
        }
    }

    removeOrAddValidation(formFieldArr, formName?) {
        formFieldArr.forEach(field => {
            this[formName].controls[field].setValidators(null);
            this[formName].controls[field].updateValueAndValidity();
        });
    }

    getSelectedRowVal(selectedRow) {
        delete selectedRow.action;
        delete selectedRow.isEditable;
        delete selectedRow.isNewRow;
        this.dataSource = selectedRow;
        if (this.pageSrc === 'pax') {
            this.travelStartDate = new Date();
            this.saleStartDate = new Date();
            selectedRow.saleStartDate = new Date(selectedRow.saleStartDate);
            selectedRow.saleEndDate = new Date(selectedRow.saleEndDate);
            selectedRow.travelStartDate = new Date(selectedRow.travelStartDate);
            selectedRow.travelEndDate = new Date(selectedRow.travelEndDate);
            selectedRow.daysOfOperation = (!this.grmsUtils.isNull(selectedRow.daysOfOperation)) ? (selectedRow.daysOfOperation.indexOf(',') > -1) ? selectedRow.daysOfOperation.split(',') : selectedRow.daysOfOperation.split('') : '';
            this.paxDetails = selectedRow;
            this.updateFormValidation('pax');
        } else if (this.pageSrc === 'rule') {
            this.todayDate = new Date();
            selectedRow.flightDateFrom = new Date(selectedRow.flightDateFrom);
            selectedRow.flightDateTo = new Date(selectedRow.flightDateTo);
            selectedRow.maxLoadFactor = (selectedRow?.maxLoadFactor > 0) ? selectedRow.maxLoadFactor : 0;
            selectedRow.minLoadFactor = (selectedRow?.minLoadFactor > 0) ? selectedRow.minLoadFactor : 0;
            selectedRow.dow = (!this.grmsUtils.isNull(selectedRow.dow)) ? (selectedRow.dow.indexOf(',') > -1) ? selectedRow.dow.split(',') : selectedRow.dow.split('') : '';
            let str = (!this.grmsUtils.isNull(selectedRow.daysToDeparture)) ? selectedRow.daysToDeparture : '';
            for (let i = 0; i < str.length; i++) {
                if (str[i] !== "<" && str[i] !== "=" && str[i] !== ">") {
                    this.departureOption += str.substring(0, i);
                    this.departureDays = str.substring(i, str.length);
                    this.paxRuleForm.controls['departureOption'].setValue(this.departureOption);
                    this.paxRuleForm.controls['departureDays'].setValue(this.departureDays);
                    break;
                }
            }
            this.paxDetails = selectedRow;
            this.paxRuleForm.controls['operationType'].setValue(selectedRow.operationType);
            this.updateFormValidation('rule');
            this.hideAndShowFare();
        } else if (!!(this.pageSrc === 'SA' || this.pageSrc === 'Agent' || this.pageSrc === 'newUser' || this.pageSrc === 'oldUser'
            || this.pageSrc === 'DU' || this.pageSrc === 'Coporate' || this.pageSrc === 'tourCode' || this.pageSrc === 'auto-expiry')) {
            this.manageDetails = selectedRow;
        } else {
            this.columnDefs = this.data.columnDefs[0];
            if (this.pageSrc === 'oldUser') {
                delete this.columnDefs['action'];
            }
            this.displayedColumns = Object.keys(this.columnDefs);
        }
        this.btnText = (Object.keys(selectedRow).length > 0) ? 'Update' : 'Add';
    }

    createForm() {
        this.paxRuleForm = this.fb.group({
            origin: ['', [Validators.required]],
            destination: ['', [Validators.required]],
            flightNumbers: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            cabinClass: ['', [Validators.required]],
            saleStartDate: ['', [Validators.required]],
            saleEndDate: ['', [Validators.required]],
            travelStartDate: ['', [Validators.required]],
            travelEndDate: ['', [Validators.required]],
            daysOfOperation: ['',],
            minGroupSize: ['', [Validators.required]],

            dow: [''],
            flightNo: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            minLoadFactor: [''],
            maxLoadFactor: [''],
            departureDays: [''],
            operationType: [''],
            departureOption: [''],
            flightDateFrom: ['', [Validators.required]],
            flightDateTo: ['', [Validators.required]],
            minSize: ['', [Validators.required]],
            maxSize: ['', [Validators.required]],
            adultFare: ['', [Validators.required]],
            infantFare: ['', [Validators.required]],
        });

        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            lastName: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
            emailId: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            password: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.PASSWORD)]],
            confirmPassword: ['', [Validators.required]],
            address1: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            country: ['IN', [Validators.required]],
            state: ['', [Validators.required]],
            city: ['', [Validators.required]],
            pincode: ['', [Validators.required]],
            currencyCode: [''],
            agencyName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            iataCode: ['', [Validators.required, Validators.minLength(7)]],
            paymentExemption: ['', [Validators.required]],
            userType: [''],
            salesPersonEmailId: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
        });

        this.registerForm.valueChanges
            .subscribe(data => {
                this.onValueChanged(data, 'registerForm')
            });

        this.deskUserForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            lastName: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            emailId: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            employeeId: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            password: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.PASSWORD)]],
            confirmPassword: [null, [Validators.required]],
            userType: [null, [Validators.required]],
        });

        this.paxRuleForm.valueChanges
            .subscribe(data => this.onValueChanged(data, 'paxRuleForm'));
        this.onValueChanged();

        this.deskUserForm.valueChanges
            .subscribe(data => this.onValueChanged(data, 'deskUserForm'));
        this.onValueChanged();
    }

    onValueChanged(data?: any, formName?: string) {
        if (!this[formName]) { return; }
        const form = this[formName];

        for (const field in this.formErrors) {
            this.formErrors[field] = "";
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessage[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + "  ";
                }
            }
        }
    }

    checkPassword() {
        let password = (this.password) ? this.password : this.manageDetails.password;
        if (this.confPasswordValue !== undefined && password !== this.confPasswordValue) {
            this.formErrors["confirmPassword"] = "Password must be same";
        }
    }

    getAirportList() {
        this.loaderService.show();
        this.httpService.getCall(BOOKING_API_ENPOINTS.airportList, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.airportList.push(response);
                    this.loaderService.hide();
                }
            });
    }

    hideAndShowFare() {
        if (this.paxRuleForm.controls['operationType'].value === 'APPROVE') {
            this.showsizeAndFare = true;
            for (let i = 0; i < this.operationBasedValidateList.length; i++) {
                let fieldName = this.operationBasedValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators([Validators.required]);
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
        } else {
            this.showsizeAndFare = false;
            for (let i = 0; i < this.operationBasedValidateList.length; i++) {
                let fieldName = this.operationBasedValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators(null);
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
        }
    }

    updateFormValidation(formType) {
        if (formType === 'pax') {
            for (let i = 0; i < this.noPaxFieldValidateList.length; i++) {
                let fieldName = this.noPaxFieldValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators([Validators.required]);
                if (fieldName === 'flightNumbers') {
                    this.paxRuleForm.controls[fieldName].setValidators([Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]);
                }
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
            for (let i = 0; i < this.noRuleFieldValidateList.length; i++) {
                let fieldName = this.noRuleFieldValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators(null);
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
        } else {
            for (let i = 0; i < this.noRuleFieldValidateList.length; i++) {
                let fieldName = this.noRuleFieldValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators([Validators.required]);
                if (fieldName === 'flightNo') {
                    this.paxRuleForm.controls[fieldName].setValidators([Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]);
                }
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
            for (let i = 0; i < this.noPaxFieldValidateList.length; i++) {
                let fieldName = this.noPaxFieldValidateList[i];
                this.paxRuleForm.controls[fieldName].setValidators(null);
                this.paxRuleForm.controls[fieldName].updateValueAndValidity();
            }
        }
    }

    listenIndex(formControlVal) {
        this.listenToODFormChange(formControlVal)
    }

    listenToODFormChange(formControlVal) {
        this.paxRuleForm.controls[formControlVal].valueChanges.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((val) => {
                if (this.airportList[0].length > 0) {
                    let filteredOptions = this.airportList[0].filter(airport => {
                        if (airport.cityName.toLowerCase().includes(val.toLowerCase()) ||
                            (airport.airportCode.toLowerCase().includes(val.toLowerCase()))) {
                            return airport;
                        }
                    });
                    if (formControlVal === 'origin') {
                        this.filteredAirports.from = filteredOptions;
                    } else {
                        this.filteredAirports.to = filteredOptions;
                    }
                }
            });
    }

    setOperationMode(event) {
        this.operationMode = event.value;
        this.isApprove = (this.operationMode === 'approve') ? true : false;
    }

    sliderEvent() {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    resetForm(formName) {
        this[formName].reset();
    }

    processRequest(requestAction) {
        let userDetails = this.grmsUtils.getUserDetails();
        this.loaderService.show();
        let payload = {
            "id": this.data.value.id,
            "regemailId": this.data.value.regemailId,
            "reqOldEmailId": this.data.value.reqOldEmailId,
            "actionTaken": requestAction,
            "actionedBy": userDetails.loginUserId
        };
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
        this.requestServiceCAll(USER_RULE_API_ENDPOINTS.updateOldUsers, inputData, requestAction + ' Successfully');
    }

    submitPaxDetails(submitAction) {
        let apiEndPoint;
        let msgType;
        if (this.pageSrc === 'pax') {
            msgType = 'Pax Details';
            let str = (submitAction === 'Add') ? ' Added' : ' Updated';
            msgType = msgType + str;
            apiEndPoint = (submitAction === 'Add') ? PAX_RESTRICTION_ENDPOINTS.addAPI : PAX_RESTRICTION_ENDPOINTS.updateAPI;
        } else {
            msgType = 'Rule Details';
            let str = (submitAction === 'Add') ? ' Added' : ' Updated';
            msgType = msgType + str;
            apiEndPoint = (submitAction === 'Add') ? RULES_ENDPOINTS.addAPI : RULES_ENDPOINTS.updateAPI;
        }
        let payload = this.prepareRequestJSON(submitAction);
        if (this.paxRuleForm.dirty) {
            let validDetails = true;
            let origin = payload['origin'];
            let destination = payload['destination'];
            if (origin !== destination) {
                validDetails = true;
            } else {
                validDetails = false;
                this.snackBar.open("Origin & Destination should not be same", 'X', { duration: 3000 });
            }

            // let flightNo = (this.pageSrc === 'pax') ? this.paxRuleForm.controls['flightNumbers'].value :
            //     this.paxRuleForm.controls['flightNo'].value;

            if (validDetails) {
                this.requestServiceCAll(apiEndPoint, payload, msgType + ' Successfully');
            }
        } else {
            this.snackBar.open("No Changes to " + msgType, 'X', { duration: 3000 });
        }
    }

    prepareRequestJSON(submitAction) {
        let payload = {};
        let userDetails = this.grmsUtils.getUserDetails();
        if (this.pageSrc === 'pax') {
            payload = this.paxRuleForm.value
            let tempPayload = payload;
            if (submitAction === 'Update') {
                tempPayload['paxRuleID'] = this.data.value.paxRuleID;
            }
            tempPayload['saleStartDate'] = moment(tempPayload['saleStartDate']).format('DD-MMM-YYYY');
            tempPayload['saleEndDate'] = moment(tempPayload['saleEndDate']).format('DD-MMM-YYYY');
            tempPayload['travelStartDate'] = moment(tempPayload['travelStartDate']).format('DD-MMM-YYYY');
            tempPayload['travelEndDate'] = moment(tempPayload['travelEndDate']).format('DD-MMM-YYYY');
            tempPayload['daysOfOperation'] = tempPayload['daysOfOperation'].join("");
        } else {
            payload = {
                "origin": this.paxRuleForm.controls['origin'].value,
                "destination": this.paxRuleForm.controls['destination'].value,
                "operationType": this.paxRuleForm.controls['operationType'].value,
                "cabinClass": this.paxRuleForm.controls['cabinClass'].value,
                "flightNo": this.paxRuleForm.controls['flightNo'].value,
                "flightDateFrom": moment(this.paxRuleForm.controls['flightDateFrom'].value).format('DD-MMM-YYYY'),
                "flightDateTo": moment(this.paxRuleForm.controls['flightDateTo'].value).format('DD-MMM-YYYY'),
                "daysToDeparture": this.paxRuleForm.controls['departureOption'].value + this.paxRuleForm.controls['departureDays'].value,
                "loginUserID": userDetails.loginUserId,
                "minLoadFactor": this.paxRuleForm.controls['minLoadFactor'].value,
                "maxLoadFactor": this.paxRuleForm.controls['maxLoadFactor'].value,
                "actionType": submitAction.toUpperCase(),
                "dow": this.paxRuleForm.controls['dow'].value.join("")
            }
            if (this.paxRuleForm.controls['operationType'].value === 'APPROVE') {
                payload['minSize'] = this.paxRuleForm.controls['minSize'].value;
                payload['maxSize'] = this.paxRuleForm.controls['maxSize'].value;
                payload['adultFare'] = this.paxRuleForm.controls['adultFare'].value;
                payload['infantFare'] = this.paxRuleForm.controls['infantFare'].value;
            }
            if (submitAction === 'Update') {
                payload['id'] = this.paxDetails.id;
            }

        }
        return payload;
    }

    doRegister() {
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = _.cloneDeep(this.deskUserForm.value);
        payload['loginUserID'] = userDetails.loginUserId;
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
        if (payload['emailId'].endsWith('airvistara.com')) {
            this.requestServiceCAll(DESK_USERS_ENDPOINTS.registerDU, inputData, 'Registration Successfully, Please check your Email');
        } else {
            this.snackBar.open('Air Vistara Domain only accept', 'X', { duration: 3000 });
        }
    }

    updateSubAgents(updateAction) {
        let apiMsg = '';
        let apiEndPoint = '';
        let msg = (updateAction === 'Update') ? 'Updated' : 'Added';
        let payload = _.cloneDeep(this.registerForm.value);
        let userDetails = this.grmsUtils.getUserDetails();
        payload['loginUserID'] = userDetails.loginUserId;
        if (updateAction === 'Update') {
            payload['actionType'] = 'UPDATE'
        }
        if (this.pageSrc === 'Agent') {
            let agentObj = {};
            agentObj['city'] = payload.city;
            agentObj['state'] = payload.state;
            agentObj['id'] = this.data.value.id;
            agentObj['emailId'] = payload.emailId;
            agentObj['pincode'] = payload.pincode;
            agentObj['country'] = payload.country;
            agentObj['iataCode'] = payload.iataCode;
            agentObj['address1'] = payload.address1;
            agentObj['agencyName'] = payload.agencyName;
            agentObj['pincode'] = Number(payload['pincode']);
            agentObj['paymentExemption'] = payload.paymentExemption;
            agentObj['salesPersonEmailId'] = payload.salesPersonEmailId;
            payload = agentObj;
            apiEndPoint = AGENT_RULE_API_ENDPOINTS.updateAPI;
            apiMsg = 'Agent Details ' + msg + ' Successfully';
            if (!this.checkValidNotifyEmails(payload.salesPersonEmailId)) {
                this.snackBar.open('Please Enter valid details for Sale person email ID', 'X', { duration: 3000 });
                return false;
            }
        } else {
            if (updateAction === 'Update') {
                delete payload['userType'];
                delete payload['iataCode'];
                delete payload['password'];
                delete payload['agencyName'];
                delete payload['confirmPassword'];
                delete payload['paymentExemption'];
                delete payload['salesPersonEmailId'];
            } else if (updateAction === 'Add') {
                payload['iataCode'] = 0;
            }
            payload['currency'] = this.isGSAUser ? this.registerForm.controls['currencyCode'].value : 'INR';
            apiEndPoint = (updateAction === 'Update') ? SUB_AGENT_ENDPOINTS.updateAPI : SUB_AGENT_ENDPOINTS.addAPI;
            apiMsg = 'Sub Agent Details ' + msg + ' Successfully';
        }
        if (this.registerForm.dirty) {
            let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
            this.requestServiceCAll(apiEndPoint, inputData, apiMsg);
        } else {
            this.snackBar.open("No Changes to " + updateAction.toLowerCase(), 'X', { duration: 3000 });
        }
    }

    updateUserDetails() {
        let apiEndPoint = USER_RULE_API_ENDPOINTS.updateAPI;
        let payload = _.cloneDeep(this.registerForm.value);
        let userDetails = this.grmsUtils.getUserDetails();
        let tempObj = {
            "firstName": payload.firstName,
            "lastName": payload.lastName,
            "emailId": payload.emailId,
            "phoneNumber": payload.phoneNumber,
            "iataCode": payload.iataCode,
            "userType": payload.userType,
            "lusr": userDetails.loginUserId
        }
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(tempObj));
        if (this.registerForm.dirty) {
            this.requestServiceCAll(apiEndPoint, inputData, 'User Details Updated Successfully');
        } else {
            this.snackBar.open("No Changes to update User Details", 'X', { duration: 3000 });
        }
    }

    updateDeskUsers() {
        let tempObj = {};
        let userDetails = this.grmsUtils.getUserDetails();
        let formValue = this.deskUserForm.value;
        tempObj = {
            "firstName": formValue.firstName,
            "lastName": formValue.lastName,
            "emailId": formValue.emailId,
            "userType": formValue.userType,
            "loginUserID": userDetails.loginUserId
        }
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(tempObj));
        if (this.deskUserForm.dirty) {
            this.requestServiceCAll(DESK_USERS_ENDPOINTS.updateAPI, inputData, 'Desk User Details Updated Successfully');
        } else {
            this.snackBar.open("No Changes to update Desk User Details", 'X', { duration: 3000 });
        }
    }

    checkValidNotifyEmails(emails: string): boolean {
        if (emails === undefined || emails === "") {
            return true;
        }
        let emailList = emails.split(",");
        for (let email of emailList) {

            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(email)) {
                this.SPEIErrorMsg = email + " is not a valid Email ID, Kindly enter valid Email ID to be Notified.";
                return false;
            }
        }
        return true;
    }

    deleteSelectedRow() {
        this.requestServiceCAll(this.data.apiURL, this.data.deletePayload, 'Deleted Successfully');
    }

    requestServiceCAll(apiEndPoint, payload, successMsg) {
        this.loaderService.show();
        this.httpService.postCall(apiEndPoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.dialogRef.close();
                        this.snackBar.open(successMsg, 'X', { duration: 3000 });
                        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                            this.router.navigate([decodeURI(this.location.path())])
                        });
                    } else if (response.statusCode === 301) {
                        this.loaderService.hide();
                        this.dialogRef.close();
                        this.snackBar.open("Tour Code Details already exist!", 'X', { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    requestGetServiceCall(apiEndPoint, responseListName) {
        this.loaderService.show();
        this.httpService.getCall(apiEndPoint, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this[responseListName] = response;
                        if (this.isGSAUser && responseListName === 'countryList') {
                            this.countryList = this.countryList.filter((obj) => {
                                return obj.countryCode !== 'IN';
                            });
                        }
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getCountryList() {
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getCountryList, 'countryList');
    }

    getStateList() {
        let countryCode = this.registerForm.controls['country'].value;
        if (this.isGSAUser) {
            this.refreshAddressFields();
            this.getCurrencyList();
        } else {
            this.requestGetServiceCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryCode, 'stateList');
        }
    }

    getCityList() {
        let stateName = this.registerForm.controls['state'].value;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, 'cityList');
    }

    getZipCodeList() {
        let cityName = this.registerForm.controls['city'].value;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, 'pincodeList');
    }

    refreshAddressFields() {
        this.registerForm.controls['state'].setValue('');
        this.registerForm.controls['city'].setValue('');
        this.registerForm.controls['pincode'].setValue('');
    }

    updateCorporateDetails(actionType) {
        if (this.grmsUtils.isEmptyOrSpaces(this.manageDetails['corporatename']) || this.grmsUtils.isEmptyOrSpaces(this.manageDetails['corporatecode'])) {
            this.snackBar.open("Empty sapce not allowed, Kindly provide valid details", "x", { duration: 3000 });
            return false;
        }

        if (this.grmsUtils.isNotNull(this.manageDetails['corporatename']) && this.grmsUtils.isNotNull(this.manageDetails['corporatecode'])) {
            let payload = {
                "corporatename": this.manageDetails['corporatename'],
                "corporatecode": this.manageDetails['corporatecode']
            };
            if (actionType === 'Update') {
                payload["id"] = this.data.value.id;
            }
            let apiEndpoint = (actionType === 'Add') ? COPORATE_CODE_ENDPOINTS.addAPI : COPORATE_CODE_ENDPOINTS.updateAPI;
            this.requestServiceCAll(apiEndpoint, payload, 'Corporate Details ' + actionType + ' Successfully')
        } else {
            this.snackBar.open("Please provide all details", 'X', { duration: 3000 });
        }

    }

    fetchStateInfo() {
        this.loaderService.show();
        let tourCode = this.manageDetails.tourCode;
        this.httpService.getCall(TOUR_CODE_API_ENDPOINTD.fetchState + '/' + tourCode, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.manageDetails.state = "";
                        if (response['stateInfo']) {
                            this.manageDetails.state = response ? response['stateInfo'] : '';
                        } else {
                            this.snackBar.open("No State Info available, Please provide manually", 'x', { duration: 3000 });
                        }
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    updatTourCodeDetails(actionType) {
        if (this.grmsUtils.isEmptyOrSpaces(this.manageDetails['customerName']) || this.grmsUtils.isEmptyOrSpaces(this.manageDetails['customerNo'])) {
            this.snackBar.open("Empty sapce not allowed, Kindly provide valid details", "x", { duration: 3000 });
            return false;
        }
        if (this.grmsUtils.isNotNull(this.manageDetails['customerName']) && this.grmsUtils.isNotNull(this.manageDetails['customerNo']
            && this.grmsUtils.isNotNull(this.manageDetails['tourCode']) && this.grmsUtils.isNotNull(this.manageDetails['state']))) {
            let payload: any
            if (actionType === 'Update') {
                payload = {
                    "customerName": this.manageDetails['customerName'],
                    "customerNo": this.manageDetails['customerNo'],
                    "tourCode": this.manageDetails['tourCode'],
                    "state": this.manageDetails['state'],
                    "id": this.data.value.id
                }
            } else {
                payload = {
                    "templateInsert": false,
                    "grmsTourCode": []
                }
                payload["grmsTourCode"].push(
                    {
                        "customerName": this.manageDetails['customerName'],
                        "customerNo": this.manageDetails['customerNo'],
                        "tourCode": this.manageDetails['tourCode'],
                        "state": this.manageDetails['state']
                    }
                )
            }
            let apiEndpoint = (actionType === 'Add') ? TOUR_CODE_API_ENDPOINTD.addAPI : TOUR_CODE_API_ENDPOINTD.updateAPI;
            this.requestServiceCAll(apiEndpoint, payload, 'Tour Code Details ' + actionType + ' Successfully');
        } else {
            this.snackBar.open("Please provide all details", 'X', { duration: 3000 });
        }
    }

    updateAutoExpiryTime() {
        if (this.grmsUtils.isNotNull(this.manageDetails['expiryTimeInHrs'])) {
            let payload = {
                "id": this.manageDetails['id'],
                "departureDateFrom": this.manageDetails['departureDateFrom'],
                "departureDateTo": this.manageDetails['departureDateTo'],
                "expiryTimeInHrs": this.manageDetails['expiryTimeInHrs']
            }
            this.requestServiceCAll(BOOKING_API_ENPOINTS.updateAutoTimeline, payload, 'Auto Expiry Timeline updated Successfully')
        } else {
            this.snackBar.open("Please provide Expiry Time in Hrs", 'X', { duration: 3000 });
        }
    }

    getCurrencyList() {
        this.loaderService.show();
        let countryCode = this.registerForm.controls['country'].value;
        this.httpService.getCall(BOOKING_API_ENPOINTS.currencyCode + '/' + countryCode, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.manageDetails.currencyCode = "";
                        this.manageDetails.currencyCode = response ? response['currencyCode'] : 'EUR';
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });

    }

}
