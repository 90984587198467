import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DirtyComponent } from '../model/dirty.component';
import { UserProfileService } from './user-profile.service';
import { GrmsUtils } from '../static/GRMSUtils';


@Injectable({
    providedIn: 'root'
})
export class DirtyCheckService {

    constructor() { }
}


@Injectable({
    providedIn: 'root'
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {

    public userRole;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private userProfileService: UserProfileService,
        private grmsUtils: GrmsUtils,
        private router: Router
    ) { }

    canDeactivate(
        component: DirtyComponent,
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.canDeactivate()) {
            return confirm('There are changes you have made to the page. If you quit, you will lose your changes.');
        } else {
            return true;
        }
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let url: string = state.url;
        return this.checkLoggedUserRole(next, url);
    }

    checkLoggedUserRole(route: ActivatedRouteSnapshot, url: any): boolean {
        let userDetails = this.grmsUtils.getUserDetails();
        let user_role = (this.grmsUtils.isNotNull(userDetails?.user_role)) ? userDetails?.user_role : '';
        this.userRole = (user_role === "ROLE_AGENT") ? 'Agent' : (user_role === "ROLE_USER") ? 'User' :
            (user_role === "ROLE_SUB_AGENT") ? 'SA' : (user_role === "ROLE_ADMIN") ? 'Admin' :
                (user_role === "ROLE_GS") ? 'GS' : (user_role === "ROLE_SU") ? 'SU' :
                    (user_role === "ROLE_DESK_USER") ? 'DU' : "";

        if (route.data.role && route.data.role.indexOf(this.userRole) === -1) {
            this.router.navigate(['/logout']);
            return false;
        }
        return true;

    }

}
