import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../services/gb-loader.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { SessionModelComponent } from '../model/session-model/session-model.component';

@Injectable({
    providedIn: 'root'
})
export class GrmsUtils {

    constructor(
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private router: Router
    ) { };


    getJsonHeader() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let options = {
            headers: headers,
        }
        return options;
    }

    getPlainHeader() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let options = {
            headers: headers,
            responseType: 'text'
        }
        return options;
    }


    getFileJSONHeader(acceptFileType?) {
        let userDetails = this.getUserDetails();
        let headers = new HttpHeaders({
            "Authorization": this.cookieService.get('auth_token'), //`Bearer ${this.cookieService.get("auth_token")}`,
            "UserName": userDetails.username,
            "UserRole": userDetails.user_role,
            "UserType": userDetails.login_type,
            'Accept': (this.isNotNull(acceptFileType)) ? acceptFileType : "application/*",
        });
        let options = {
            headers: headers,
            responseType: 'blob'
        }
        return options;
    }

    getFileHeader() {
        let userDetails = this.getUserDetails();
        let headers = new HttpHeaders({
            "Authorization": this.cookieService.get('auth_token'), //`Bearer ${this.cookieService.get("auth_token")}`,
            "UserName": userDetails.username,
            "UserRole": userDetails.user_role,
            "UserType": userDetails.login_type,
            'Accept': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            'responseType': 'blob',
        });
        let options = {
            headers: headers
        }
        return options;
    }

    getHeaders() {
        if (this.cookieService.get('auth_token') && this.isNotNull(this.cookieService.get('userDetails'))) {
            let userDetails = this.getUserDetails();
            let headers = new HttpHeaders({
                "Authorization": this.cookieService.get('auth_token'), //`Bearer ${this.cookieService.get("auth_token")}`,
                "UserName": userDetails.username,
                "UserRole": userDetails.user_role,
                "UserType": userDetails.login_type,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type,**Authorization**',
            });
            return headers;
        } else {
            this.clearSession();
        }
    }

    getAuthHeader() {
        let options = {
            headers: this.getHeaders(),
        }
        return options;
    }

    getAuthWithTextHeader() {
        let options = {
            headers: this.getHeaders(),
            responseType: 'text'
        }
        return options;
    }

    getFileAuthHeader() {
        if (this.cookieService.get('auth_token') && this.isNotNull(this.cookieService.get('userDetails'))) {
            let userDetails = this.getUserDetails();
            let headers = new HttpHeaders({
                "Authorization": this.cookieService.get('auth_token'), //`Bearer ${this.cookieService.get("auth_token")}`,
                "UserName": userDetails.username,
                "UserRole": userDetails.user_role,
                "UserType": userDetails.login_type,
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type,**Authorization**'
            });
            let options = {
                headers: headers
            }
            return options;
        } else {
            this.clearSession();
        }
    }

    getAuthMultiHeader(isTextResponse?: any) {
        if (this.cookieService.get('auth_token') && this.isNotNull(this.cookieService.get('userDetails'))) {
            let userDetails = this.getUserDetails();
            let headers = new HttpHeaders({
                "Authorization": this.cookieService.get('auth_token'),
                "UserName": userDetails.username,
                "UserRole": userDetails.user_role,
                "UserType": userDetails.login_type,
            });
            let options = {
                headers: headers
            }
            if (isTextResponse === 'text') {
                options['responseType'] = 'text';
            }
            return options;
        } else {
            this.clearSession();
        }
    }

    getUserDetails() {
        if (this.isNotNull(this.cookieService.get('auth_token')) && this.isNotNull(this.cookieService.get('userDetails'))) {
            let userDetails = JSON.parse(this.cookieService.get('userDetails'));
            userDetails['loginUserId'] = (userDetails) ? userDetails.username : '';
            return userDetails;
        } else {
            this.clearSession();
        }
    }

    errorHandling(errorRepsonse, sourceComp?) {
        this.loaderService.hide();
        if (this.isNotNull(errorRepsonse.error)) {
            if (typeof (errorRepsonse.error) === 'string') {
                errorRepsonse.error = JSON.parse(errorRepsonse.error);
            }
            if (errorRepsonse.error['statusCode'] === 422) {
                this.snackBar.open(errorRepsonse.error['errMsgs'][0], 'X', { duration: 3000 });
                return false;
            }

            if ((errorRepsonse.error['statusCode'] === 878)) {
                this.snackBar.open('File size should be upto 2MB', 'X', { duration: 3000 });
                return false;
            }
            if ((errorRepsonse.error['statusCode'] === 654)) {
                this.snackBar.open('Forget Password Limit reached, please try again after 24 hours', 'X', { duration: 3000 });
                return false;
            }

            if (errorRepsonse.status === 504) {
                this.snackBar.open("Connection Timeout", 'X', { duration: 3000 });
                return;
            }

            if (errorRepsonse.status === 413) {
                this.snackBar.open("Record Limit Exceed", 'X', { duration: 3000 });
                return;
            }

            if (errorRepsonse.error.status === 400) {
                this.snackBar.open("Invalid Request", 'X', { duration: 3000 });
                return;
            }

            if (this.isNotNull(errorRepsonse.error.status) || errorRepsonse.error.status === 351) {
                this.clearSession();
                return;
            }
            if (this.isNotNull(errorRepsonse.error.statusMsg)) {
                this.snackBar.open(errorRepsonse.error.statusMsg, 'X', { duration: 30000 });
            } else if (this.isNotNull(errorRepsonse.error.statusMessage)) {
                this.snackBar.open(errorRepsonse.error.statusMessage, 'X', { duration: 3000 });
            } else if (this.isNotNull(errorRepsonse.error.errorMessage)) {
                this.snackBar.open(errorRepsonse.error.errorMessage, 'X', { duration: 3000 });
            } else if (this.isNotNull(errorRepsonse.error.errorMsg)) {
                this.snackBar.open(errorRepsonse.error.errorMsg, 'X', { duration: 3000 });
            } else {
                this.snackBar.open("Unable to Process your Request, Please try again", 'X', { duration: 3000 });
            }
        }
        else {
            if (errorRepsonse.status === 409) {
                if (sourceComp === 'loginPage') {
                    this.snackBar.open("INVALID CAPTCHA", 'X', { duration: 3000 });
                } else {
                    this.snackBar.open('Unable to Process your Request, Please try again', 'X', { duration: 3000 });
                }
            } else if (errorRepsonse.status === 412) {
                this.snackBar.open("Empty Space not allowed in form, kinldy provide valid details", 'X', { duration: 3000 });
                return;
            } else if (errorRepsonse.status === 401) {
                this.clearSession();
            } else if (errorRepsonse.status === 400) {
                this.snackBar.open("Invalid Request", 'X', { duration: 3000 });
                return;
            } else {
                this.snackBar.open("Unable to Process your Request, Please try again", 'X', { duration: 3000 });
            }
        }

    }

    clearSession() {

        this.loaderService.hide();
        const dialogRef = this.dialog.open(SessionModelComponent, {
            disableClose: true,
            width: '415px',
            height: '80px',
            data: { isSessionExpire: true }
        })

        dialogRef.afterClosed().subscribe(result => {
        });
        return false;
    }

    forkHandling(response) {
        if (!this.isNotNull(response.status)) {
            return response;
        } else {
            this.loaderService.hide();
            return;
        }
    }

    isNotNull(inputVal) {
        return (inputVal !== null && inputVal !== undefined && inputVal !== '') ? true : false;
        // if (inputVal !== null && inputVal !== undefined && inputVal !== '') {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    isNull(inputVal) {
        return (inputVal === null || inputVal === undefined || inputVal === '') ? true : false;
        // if (inputVal === null || inputVal === undefined || inputVal === '') {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    encryptedData(inputVal) {
        let key = CryptoJS.enc.Latin1.parse('Vistara@1357*($%');
        let iv = CryptoJS.enc.Latin1.parse('SecretIv#1234567');
        let encryptedStr = CryptoJS.AES.encrypt(
            inputVal,
            key,
            {
                iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding

            });
        return encryptedStr.toString();
    }

    decryptedData(inputVal) {
        let key = CryptoJS.enc.Latin1.parse('Vistara@1357*($%');
        let iv = CryptoJS.enc.Latin1.parse('SecretIv#1234567');
        let decryptedStr = CryptoJS.AES.decrypt(
            inputVal,
            key,
            {
                iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7

            });
        return decryptedStr.toString(CryptoJS.enc.Utf8);
    }

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

}
