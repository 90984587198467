import { AfterViewInit, Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from "rxjs";
import * as _ from "lodash";
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import { takeUntil } from "rxjs/operators";
import { DatatableModelComponent } from './datatable-model/datatable-model.component';
import {
    DESK_USERS_ENDPOINTS,
    USER_RULE_API_ENDPOINTS,
    COPORATE_CODE_ENDPOINTS,
    AGENT_RULE_API_ENDPOINTS,
    PAX_RESTRICTION_ENDPOINTS,
    RULES_ENDPOINTS,
    COMMON_API_ENDPOINTS,
    SUB_AGENT_ENDPOINTS,
    TOUR_CODE_API_ENDPOINTD,
    REPORT_API_ENDPOINTS
} from '../../httpService/apiURLConstant';
import { LoaderService } from '../../services/gb-loader.services';
import { HttpService } from '../../httpService/http.service';
import { GrmsUtils } from '../../static/GRMSUtils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DownloadUtil } from '../../static/downloadUtils';
import { MenuAccessService } from '../../services/menu-access.service';
import { CommanModalComponent } from '../../model/comman-modal/comman-modal.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'gb-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {

    @Input() fileName;
    @Input() parentPage;
    @Input() columnDefs;
    @Input() isActionFlag;
    @Input() addActionFlag;
    @Input() fileActionFlag;
    @Input() tableDataSource;
    @Input() tableActionFlag;
    @Input() deleteActionFlag;
    @ViewChild(MatSort) sort: any = MatSort;
    @ViewChild('fileUpload') fileUpload: ElementRef;
    @ViewChild(MatPaginator) paginator: any = MatPaginator;

    public pageSource;
    public filterText;
    public previousIndex;
    public apiEndPointList;
    public tempAddRow = {};
    public paxPreviousData;
    public displayedColumns;
    public previousRowValue;
    public tableColumns = [];
    public downloadData = [];
    public files: File[] = [];
    public inputFileName: any;
    public fileStatus = "open";
    public selectedRequest = "";
    public tableForm!: FormGroup;
    public isLoading: Boolean = false;
    public hasFormError: Boolean = false;
    public isEditableNew: boolean = true;
    public showAddAction: Boolean = true;
    public isHistoryView: Boolean = false;
    public showFileAction: Boolean = true;
    public isNoFilterData: Boolean = false;
    public showActionField: Boolean = true;
    public showTableAction: Boolean = true;
    public showDeleteAction: Boolean = true;
    public dataSource = new MatTableDataSource();
    public selection = new SelectionModel<any>(true, []);
    public emptyData = new MatTableDataSource([{ empty: "row" }]);
    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    public city = [];
    public state = [];
    public country = [];
    public pincode = [];
    public paymentExemption: Array<any> = ['Applicable', 'Non Applicable'];
    public roleList = [
        { 'code': 'ROLE_GS', "desc": "GS" },
        { 'code': 'ROLE_SU', "desc": "SU" },
    ];
    public newUserRoleList = [
        { 'code': 'ROLE_USER', "desc": "USER" },
        { 'code': 'ROLE_AGENT', "desc": "Agent" },
        { 'code': 'ROLE_DESK_USER', "desc": "Desk User" },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private location: Location,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private _formBuilder: FormBuilder,
        private downloadUtils: DownloadUtil,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private menuAccessService: MenuAccessService

    ) { }


    ngOnInit(): void {
        this.menuAccessService.hideAndShowMenu();
        this.pageSource = this.parentPage;
        this.showAddAction = this.addActionFlag;
        this.showActionField = this.isActionFlag;
        this.showFileAction = this.fileActionFlag;
        this.showTableAction = this.tableActionFlag;
        this.paxPreviousData = this.tableDataSource;
        this.downloadData = [...this.tableDataSource];
        this.showDeleteAction = this.deleteActionFlag;
        this.selectedRequest = this.cookieService.get('currentMenu');
        this.apiEndPointList = (this.parentPage === 'Agent') ? AGENT_RULE_API_ENDPOINTS :
            (this.parentPage === 'newUser' || this.parentPage === 'oldUser') ? USER_RULE_API_ENDPOINTS :
                (this.parentPage === 'DU') ? DESK_USERS_ENDPOINTS : (this.parentPage === 'Coporate') ? COPORATE_CODE_ENDPOINTS :
                    (this.parentPage === 'pax') ? PAX_RESTRICTION_ENDPOINTS : (this.parentPage === 'rule') ? RULES_ENDPOINTS :
                        (this.parentPage === 'SA') ? SUB_AGENT_ENDPOINTS : (this.parentPage === 'tourCode') ? TOUR_CODE_API_ENDPOINTD : '';

        if (this.parentPage !== 'dashboard-request') {
            this.tableDataSource.forEach((obj) => {
                obj['action'] = '';
            });
        }

        if (this.pageSource === 'oldUser') {
            this.columnDefs = [
                {
                    "regemailId": "Registered Email Id",
                    "reqOldEmailId": "Registered Old Email Id",
                    "actionTaken": "Action Taken",
                    "action": "Action"
                }
            ]
        }

        this.prepareRowData();
        this.isLoading = false;
    }

    ngAfterViewInit() {
        this.renderGrid();
    }

    prepareRowData() {
        this.tableDataSource = new MatTableDataSource(this.tableDataSource);

        this.tableForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });
        let tempDataSource: any[] = [];
        tempDataSource = this.constructDynamicObject();

        this.tableForm = this.fb.group({
            VORows: this.fb.array(tempDataSource.map(obj =>
                this.fb.group(
                    obj  // Push the dynamic object to form gorup
                )
            ))
        });
        this.tableColumns = Object.keys(this.columnDefs[0]);
        this.tempAddRow = this.constructDynamicRowObject();
    }

    renderGrid() {
        this.dataSource.paginator = this.paginator;
        const filterPredicate = this.dataSource.filterPredicate;
        this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
            return filterPredicate.call(this.dataSource, data.value, filter);
        };
        this.dataSource.data = (this.tableForm.get('VORows') as FormArray).controls;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data: AbstractControl, sortHeaderId: string) => {
            const value: any = data.value[sortHeaderId];
            return typeof value === "string" ? value.toLowerCase() : value;
        };
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
        this.displayedColumns = Object.keys(this.columnDefs[0])
        if (this.isActionFlag) {
            this.displayedColumns.unshift('select');
        }
    }

    getItemList(inputVal) {
        return this[inputVal];
    }

    constructDynamicObject() {   //Creating Dynamic Object from table source
        let tempArr: any[] = [];
        this.tableDataSource.data.map((obj => {
            let tempObj = {};
            let objKeys = Object.keys(obj);
            objKeys.forEach(val => {
                if (val === 'iataCode') {
                    tempObj[val] = new FormControl(obj[val], [Validators.required, Validators.minLength(7)]);
                } else if (val === 'phoneNumber' && this.parentPage !== 'DU') {
                    tempObj[val] = new FormControl(obj[val], [Validators.required, Validators.minLength(10)]);
                } else if (this.parentPage !== 'DU') {
                    tempObj[val] = new FormControl(obj[val], [Validators.required]);  // {position:1, name:Prasanth}
                } else {
                    tempObj[val] = new FormControl(obj[val]);
                }
            });
            if (this.parentPage == 'rule') {
                if (objKeys.indexOf('flightDateFrom') === -1) {
                    tempObj['flightDateFrom'] = '';
                }
                if (objKeys.indexOf('flightDateTo') === -1) {
                    tempObj['flightDateTo'] = '';
                }
                if (objKeys.indexOf('adultFare') === -1) {
                    tempObj['adultFare'] = '';
                }
                if (objKeys.indexOf('infantFare') === -1) {
                    tempObj['infantFare'] = '';
                }
            }
            tempObj['action'] = new FormControl('existingRecord');
            tempObj['isEditable'] = new FormControl(true);
            tempObj['isNewRow'] = new FormControl(false);
            tempArr.push(tempObj);
        }));
        return tempArr;
    }

    constructDynamicRowObject() { //Creating Dynamic new row object from table column
        let tempObj = {};
        this.tableColumns.map(obj => {
            if (obj === 'phoneNumber' && this.parentPage !== 'DU') {
                tempObj[obj] = new FormControl('', [Validators.required, Validators.minLength(10)]);
            } else if (this.parentPage !== 'DU') {
                tempObj[obj] = new FormControl('', [Validators.required]);
            } else {
                tempObj[obj] = new FormControl('');
            }
        });
        tempObj['action'] = new FormControl('newRecord');
        tempObj['isEditable'] = new FormControl(false);
        tempObj['isNewRow'] = new FormControl(true);
        return tempObj;
    }

    getSortedData(sortState: Sort) {
        this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    }

    addRowToForm(): FormGroup {
        let tempObj = this.tempAddRow;
        return this.fb.group(
            tempObj
        );
    }

    addRow() {
        if (this.parentPage !== 'oldUser' && this.parentPage !== 'rule' && this.parentPage !== 'pax' && this.parentPage !== 'DU' && this.pageSource !== 'SA'
            && this.parentPage !== 'Coporate' && this.parentPage !== 'tourCode') {
            const control = this.tableForm.get('VORows') as FormArray;
            control.insert(0, this.addRowToForm());
            this.dataSource = new MatTableDataSource(control.controls);
            this.dataSource.paginator = this.paginator;
        } else {
            let height = (this.parentPage === 'oldUser') ? '280px' : (this.parentPage === 'rule' || this.parentPage === 'pax') ?
                '500px' : (this.parentPage === 'DU') ? '450px' : (this.pageSource === 'SA') ? '440px' : '300px';
            let width = (this.parentPage === 'oldUser') ? '50%' : (this.parentPage === 'rule' || this.parentPage === 'pax') ?
                '100%' : (this.parentPage === 'DU' || this.parentPage === 'Coporate' || this.parentPage === 'tourCode') ? '50%' : (this.pageSource === 'SA') ? '62%' : '400px';
            let selectedRow = {};
            selectedRow['pageSrc'] = this.parentPage;
            const dialogRef = this.dialog.open(DatatableModelComponent, {
                width: width,
                height: height,
                disableClose: true,
                data: selectedRow
            })

            dialogRef.afterClosed().subscribe(result => {
            });
        }
    }

    removeRow() {
        let payload;
        let tempObj: any;
        let tembArr = [];
        let userDetails = this.grmsUtils.getUserDetails();
        if (this.pageSource === 'Coporate' || this.pageSource === 'tourCode') {
            tembArr = []
        } else if (this.pageSource === 'pax') {
            tempObj = {
                "rulesRequestDetails": {
                    "paxRestrictionDetailsList": []
                }
            }
        }
        if (this.selection.selected.length > 0) {
            this.selection.selected.forEach(s => {
                s.isEditable = true;
                if (this.pageSource === 'Agent' || this.pageSource === 'SA') {
                    tembArr.push({
                        'agentId': s.value?.id ? s.value.id : s.value.userId,
                        'lastUpdatedBy': userDetails.loginUserId
                    });
                } else if (this.pageSource === 'newUser' || this.pageSource === 'DU') {
                    tembArr.push({
                        'userId': s.value.userId,
                        'lastUpdatedBy': userDetails.loginUserId
                    });
                } else if (this.pageSource === 'Coporate') {
                    tembArr.push({
                        "id": s.value.id,
                        "corporatename": s.value.corporatename,
                        "corporatecode": s.value.corporatecode
                    });
                } else if (this.pageSource === 'tourCode') {
                    tembArr.push({
                        "id": s.value.id,
                        "state": s.value.state,
                        "tourCode": s.value.tourCode,
                        "customerNo": s.value.customerNo,
                        "customerName": s.value.customerName,
                    });
                } else if (this.pageSource === 'pax') {
                    tempObj.rulesRequestDetails.paxRestrictionDetailsList.push(s.value)
                } else if (this.pageSource === 'rule') {
                    tembArr.push({
                        "ruleId": s.value.id,
                        "lastUpdatedBy": userDetails.loginUserId,
                    });
                }
            });

            payload = (this.pageSource === 'Agent' || this.pageSource === 'newUser' || this.pageSource === 'rule'
                || this.pageSource === 'DU' || this.pageSource === 'SA' || this.pageSource === 'Coporate'
                || this.pageSource === 'tourCode') ? tembArr : tempObj;
            const dialogRef = this.dialog.open(DatatableModelComponent, {
                width: '425px',
                height: '130px',
                disableClose: true,
                data: { 'deletePayload': payload, 'apiURL': this.apiEndPointList.deleteAPI, 'isDeletePopup': true }
            })

            dialogRef.afterClosed().subscribe(result => {
            });
        } else {
            this.snackBar.open('Please select atleast one record', 'X', { duration: 3000 });
        }

    }

    filterDataTable(event) {
        if (this.previousIndex) {
            this.tableForm.get('VORows').value[this.previousIndex]['isEditable'] = true;
        }
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.isNoFilterData = (this.dataSource.filteredData.length === 0) ? true : false;
    }

    getRoleType(VOFormElement, index) {
        this.previousRowValue = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[index]['value'] : VOFormElement.get('VORows').at(index).value;
        let displayRole = this.previousRowValue.role === 'ROLE_SU' ? 'SU' : 'GS';
        return displayRole;
    }

    EditSVO(VOFormElement, i) {
        let pageNameList = ['Agent', 'newUser', 'SA'];
        if (this.grmsUtils.isNotNull(this.previousIndex)) {
            this.CancelSVO(VOFormElement, this.previousIndex);
        }
        this.addActionFlag = true;
        this.previousRowValue = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[i]['value'] : VOFormElement.get('VORows').at(i).value;

        let height = (this.parentPage === 'oldUser') ? '280px' : (this.parentPage === 'rule' || this.parentPage === 'pax')
            ? '505px' : (pageNameList.indexOf(this.pageSource) > -1) ? '440px' : (this.parentPage === 'DU'
                || this.parentPage === 'Coporate' || this.parentPage === 'auto-expiry') ? '267px' : (this.parentPage === 'tourCode') ? '280px' : '300px';

        let width = (this.parentPage === 'oldUser') ? '50%' : (this.parentPage === 'rule' || this.parentPage === 'pax')
            ? '100%' : (pageNameList.indexOf(this.pageSource) > -1) ? '62%' : (this.parentPage === 'DU' || this.parentPage === 'Coporate'
                || this.parentPage === 'tourCode' || this.parentPage === 'auto-expiry') ? '50%' : '400px';

        let selectedRow = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[i] : VOFormElement.get('VORows').at(i);
        selectedRow['pageSrc'] = this.parentPage;
        if (this.parentPage === 'newUser' || this.parentPage === 'oldUser') {
            selectedRow['columnDefs'] = this.columnDefs;
        }
        const dialogRef = this.dialog.open(DatatableModelComponent, {
            width: width,
            height: height,
            disableClose: true,
            data: selectedRow
        })

        dialogRef.afterClosed().subscribe(result => {
        });
        setTimeout(function () {
            VOFormElement.get('VORows').at(i).get('isEditable').setValue(true);
        }, 200);
    }

    SaveVO(VOFormElement, i) {
        let currentRowForm = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[i] : VOFormElement.get('VORows').at(i);
        let currentRow = currentRowForm.value

    }

    requestServiceCall(apiEndpoint, payload, msgValue) {
        this.loaderService.show();
        this.httpService.postCall(apiEndpoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.snackBar.open(msgValue, 'X', { duration: 3000 });
                        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                            this.router.navigate([decodeURI(this.location.path())])
                        });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    CancelSVO(VOFormElement, i) {
        this.hasFormError = false;
        if (VOFormElement.get('VORows').at(i).get('action').value === 'newRecord') {
            const control = this.tableForm.get('VORows') as FormArray;
            control.removeAt(i);
            this.dataSource = new MatTableDataSource(control.controls);
            this.dataSource.paginator = this.paginator;
        } else {
            VOFormElement.get('VORows').at(i).setValue(this.previousRowValue);
            VOFormElement.get('VORows').at(i).get('isEditable').setValue(true);
        }
    }

    ExportTOExcel() {
        if (this.downloadData.length > 0) {
            this.loaderService.show();
            let downloadColumnDefs = [];
            if (this.parentPage === 'pnr' || this.parentPage === 'GR') {
                downloadColumnDefs = Object.values(this.columnDefs[0]);
                let downloadColumnHeader = Object.keys(this.columnDefs[0]);
                let downloadDataHeader = Object.keys(this.downloadData[0]);
                for (let i = 0; i < this.downloadData.length; i++) {
                    downloadDataHeader.forEach(key => {
                        if (downloadColumnHeader.indexOf(key) === -1) {
                            delete this.downloadData[i][key];
                        }
                    });
                }
            } else {
                downloadColumnDefs = Object.keys(this.downloadData[0]);
                downloadColumnDefs.splice(downloadColumnDefs.length - 1, 1);
                downloadColumnDefs.forEach((word, index) => {
                    const firstLetter = word.charAt(0).toUpperCase();
                    const rest = word.slice(1).toLowerCase();

                    downloadColumnDefs[index] = firstLetter + rest;
                });
            }
            let finalColumnArr = [];
            finalColumnArr.push(downloadColumnDefs);
            this.downloadUtils.exportAsExcelFile(this.downloadData, finalColumnArr, this.fileName);
        } else {
            this.snackBar.open("No Records to Download", 'X', { duration: 3000 });
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    getPageDetails(event) {
        let component = this;
        component.isLoading = true;
        this.selection.clear();
        setTimeout(function () {
            const control: any = component.tableForm.get('VORows');
            control['value'].forEach(obj => { obj.isEditable = true });
            component.isLoading = false;
        }, 100)
    }

    viewHistory(VOFormElement, i) {
        this.tableDataSource = VOFormElement.get('VORows').at(i).value.history;
        this.columnDefs[0] = {
            'historyId': 'History Id',
            'origin': 'Origin',
            'destination': 'Destination',
            'flightNumbers': 'Flight Number',
            'cabinClass': 'Cabin Class',
            'saleStartDate': 'Sale Start Date',
            'saleEndDate': 'Sale End Date',
            'travelStartDate': 'Travel Start Date',
            'travelEndDate': 'Travel End Date',
            'daysOfOperation': 'Days Of Operation',
            'minGroupSize': 'Group Size',
        };
        this.isHistoryView = true;
        this.prepareRowData();
        this.renderGrid();
    }

    getPaymentDetails(VOFormElement, i) {
        let columnDefs = [{
            'paymentDate': 'Payment Date',
            'paymentMode': 'Payment Mode',
            'amountPaid': 'Amount Paid',
            'paymentStatus': 'Payment Status',
        }];
        let selectedRow = {};
        selectedRow['paymentDetails'] = VOFormElement;
        selectedRow['pageSrc'] = this.parentPage;
        selectedRow['columnDefs'] = columnDefs
        const dialogRef = this.dialog.open(DatatableModelComponent, {
            width: '100%',
            height: '325px',
            disableClose: true,
            data: selectedRow
        })

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    backToPaxRuleDetails() {
        this.tableDataSource = this.paxPreviousData;
        this.isHistoryView = false;
        this.columnDefs[0] = {
            'origin': 'Origin',
            'destination': 'Destination',
            'flightNumbers': 'Flight Number',
            'cabinClass': 'Cabin Class',
            'saleStartDate': 'Sale Start Date',
            'saleEndDate': 'Sale End Date',
            'travelStartDate': 'Travel Start Date',
            'travelEndDate': 'Travel End Date',
            'daysOfOperation': 'Days Of Operation',
            'minGroupSize': 'Group Size',
            'action': 'Action'
        };
        this.prepareRowData();
        this.renderGrid();
    }

    viewRequestFlow(requestId) {

    }

    getSelectList(searchType, index) {
        let searchValue = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[index]['value'][searchType] : this.tableForm.get('VORows').value[index][searchType];
        if (searchType === 'state') {
            this.getCityList(searchValue);
        } else if (searchType === 'city') {
            this.getZipCodeList(searchValue);
        }
    }

    requestGetServiceCall(apiEndPoint, responseListName) {
        this.loaderService.show();
        this.httpService.getCall(apiEndPoint, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this[responseListName] = response;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getCountryList(index) {
        let searchValue = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[index]['value']['country'] : this.tableForm.get('VORows').value[index]['country'];
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getCountryList, 'country');
    }

    getStateList(index) {
        let countryCode = (this.dataSource.filteredData.length !== 0 &&
            this.dataSource.filteredData.length !== this.paxPreviousData.length) ? this.dataSource.filteredData[index]['value']['country'] : this.tableForm.get('VORows').value[index]['country'];
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryCode, 'state');
    }

    getCityList(searchValue) {
        let stateName = searchValue;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, 'city');
    }

    getZipCodeList(searchValue) {
        let cityName = searchValue;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, 'pincode');
    }

    getRequestDetails(requestId) {
        if (this.selectedRequest !== 'series') {
            this.router.navigate([`request/requestview/${requestId}`]);
        } else {
            this.router.navigate([`series/overview/${requestId}`]);
        }

    }

    downloadAttachment(id: any, attchName: any, attchType: any) {
        this.httpService.getCall(REPORT_API_ENDPOINTS.downloadFile + id, this.grmsUtils.getFileJSONHeader(attchType))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    let blob: any = new Blob([response]);
                    FileSaver.saveAs(blob, attchName);

                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    onClick(event) {
        if (this.fileUpload)
            this.fileUpload.nativeElement.click()
    }

    onInput(event) {

    }

    onFileSelected(event) {
        this.files = [];
        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.files.push(file);
                const target: DataTransfer = <DataTransfer>(<unknown>event.target);
                if (target.files.length !== 1) {
                    throw new Error('Cannot use multiple files');
                }
                const reader: FileReader = new FileReader();
                reader.readAsBinaryString(target.files[0]);
                reader.onload = (e: any) => {
                    const binarystr: string = e.target.result;
                    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
                    const wsname: string = wb.SheetNames[0];
                    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws);
                    if (data.length > 0) {
                        this.fileStatus = "inprogress";
                        let payload = {
                            "templateInsert": true,
                            "grmsTourCode": data
                        }
                        this.loaderService.show();
                        this.httpService.postCall(TOUR_CODE_API_ENDPOINTD.validateFile, payload, this.grmsUtils.getAuthHeader())
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe({
                                next: (response: any) => {
                                    if (response.statusCode === 200) {
                                        this.loaderService.hide();
                                        if (this.grmsUtils.isNotNull(response.duplicateTourCodeData) && response.duplicateTourCodeData.length > 0) {
                                            this.fileStatus = "error";
                                            setTimeout(() => {
                                                this.fileStatus = "open";
                                            }, 2000);
                                            const dialogRef = this.dialog.open(CommanModalComponent, {
                                                width: '35%',
                                                height: '100px',
                                                disableClose: true,
                                                data: {
                                                    "isTicketError": false, 'showTicketPopup': false, 'showSeriesRequest': false, 'isDeletePopup': false,
                                                    'isDuplicatePopup': true, "displayMsg": "Duplicate records found in the file"
                                                }
                                            });

                                            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                                                if (result.data === 'yes') {
                                                    if (this.grmsUtils.isNotNull(response.uniqueTourCodeData) && response.uniqueTourCodeData.length === 0) {
                                                        this.downloadDuplicateRecords(response.duplicateTourCodeData);
                                                    } else {
                                                        this.downloadDuplicateRecords(response.duplicateTourCodeData, response.uniqueTourCodeData);
                                                    }
                                                } else {
                                                    return;
                                                }
                                            });
                                        } else {
                                            this.httpService.postCall(TOUR_CODE_API_ENDPOINTD.addAPI, payload, this.grmsUtils.getAuthHeader())
                                                .pipe(takeUntil(this.ngUnsubscribe))
                                                .subscribe({
                                                    next: (response: any) => {
                                                        if (response.statusCode === 200) {
                                                            this.loaderService.hide();
                                                            this.files = [];
                                                            this.inputFileName = "";
                                                            if (this.grmsUtils.isNotNull(response.duplicateTourCodeData) && response.duplicateTourCodeData.length > 0) {
                                                                this.fileStatus = "error";
                                                                setTimeout(() => {
                                                                    this.fileStatus = "open";
                                                                }, 2000);
                                                                const dialogRef = this.dialog.open(CommanModalComponent, {
                                                                    width: '35%',
                                                                    height: '100px',
                                                                    disableClose: true,
                                                                    data: {
                                                                        "isTicketError": false, 'showTicketPopup': false, 'showSeriesRequest': false, 'isDeletePopup': false,
                                                                        'isDuplicatePopup': true, "displayMsg": "Some rows already exist in the system"
                                                                    }
                                                                });

                                                                dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                                                                    if (result.data === 'yes') {
                                                                        this.downloadDuplicateRecords(response.duplicateTourCodeData);
                                                                    } else {
                                                                        return;
                                                                    }
                                                                });
                                                            } else {
                                                                this.fileStatus = "success";
                                                                this.snackBar.open("Tour Code Details Added Successfully", 'X', { duration: 3000 });
                                                                setTimeout(() => {
                                                                    this.fileStatus = "open";
                                                                    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                                                                        this.router.navigate([decodeURI(this.location.path())])
                                                                    });
                                                                }, 200);
                                                            }
                                                        }
                                                    },
                                                    error: (errorDetails) => {
                                                        this.grmsUtils.errorHandling(errorDetails);
                                                    }

                                                });
                                        }
                                    }
                                },
                                error: (errorDetails) => {
                                    this.grmsUtils.errorHandling(errorDetails);
                                }
                            });
                    }
                };
            } else {
                this.snackBar.open('Please upload XLSX / CSV files only', 'x', { duration: 3000 });
            }
        }
    }

    downloadDuplicateRecords(duplicateData, uniqueData?) {
        if (duplicateData.length > 0 && uniqueData === undefined) {
            this.downloadServiceCall(duplicateData, 'isExistError');
            this.fileStatus = "open";
            this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                this.router.navigate([decodeURI(this.location.path())])
            });
        } else if (uniqueData.length > 0) {
            for (let i = 0; i < uniqueData.length; i++) {
                uniqueData[i]['unique'] = true;
            }
            const finalArr = [...uniqueData, ...duplicateData];
            this.downloadServiceCall(finalArr, 'isFileError');
        } else {
            this.snackBar.open("No Records to Download", 'X', { duration: 3000 });
        }
    }

    downloadServiceCall(duplicateData, errorType) {
        let downloadColumnDefs = Object.keys(duplicateData[0]);
        downloadColumnDefs.splice(0, 1);  //removing Id column from the object
        let finalColumnDefs = [];
        finalColumnDefs.push(downloadColumnDefs);
        this.downloadUtils.exportExcelWithHighlight(duplicateData, finalColumnDefs, errorType, 'Duplicate_Tour_Code_Details');
    }
}

