import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private getDate = new Date()
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
    ) { }

    baseUrl = environment.API_URL;

    postCall(apiURL, payload?, headers?) {
        return this.http.post(this.baseUrl + apiURL, payload, headers);
    }

    getCall(apiURL, headers?) {
        let getMilliseconds = this.getDate.getMilliseconds();
        return this.http.get(this.baseUrl + apiURL + '?' + getMilliseconds, headers);
    }

}
