import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { GB_CONSTANTS } from 'src/app/shared/static/constants';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { LOGIN_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

    public errorMessage;
    public captchaResponse: any;
    public hidePassword = false;
    public loginForm!: FormGroup;
    public ACTION_TYPE = 'login';
    public isExistingUser = false;
    public showPasswordField = true;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public SITE_KEY = "6LekfGEeAAAAAG8bFBHpbg01ZtyJpOYIMcGthLwI";
    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS.LOGIN_FORM;

    constructor(
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private loaderService: LoaderService,
    ) { }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
        });
    }

    validateForm(fieldname: any): any {
        if (fieldname === 'email') {
            return this.loginForm.controls[fieldname].errors?.required ? this.validationMessage.EMAIL_MANDATORY :
                this.loginForm.controls[fieldname].errors?.pattern ? this.validationMessage.EMAIL_INVALID : '';
        }
    }

    forgetPassword() {
        let payload = {
            "emailId": this.loginForm.controls['email'].value,
        }
        this.httpService.postCall(LOGIN_API_ENDPOINTS.forgetPassword, payload, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    if (response.statusCode === 200) {
                        this.loginForm.reset();
                        this.showPasswordField = true;
                        this.snackBar.open("Temporary password has been mailed to the registered mail id.", 'X', { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }

            });
    }
}

