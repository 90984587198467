export class COMMON_API_ENDPOINTS {
    static getStateListByCountry = 'booking/get_states_by_country_code';
    static updateAllNotiicationStatus = 'booking/mark_all_as_read';
    static getAllNotification = 'booking/notification_status';
    static updateNotiicationStatus = 'booking/mark_as_read';
    static getCityListByState = 'booking/get_cities_by_state';
    static getZipcodeByCity = 'booking/get_zip_codes_by_city';
    static getCountryList = 'booking/nationality_list';
    static getRequestType = 'reports/pnr_search';
    static token_URL = 'registration/token';

}
export class REGISTER_API_POINTS {
    static registerUser = 'registration/register_user';
    static retrieveProfile = 'registration/get_user_data';
    static sessionExtend = 'registration/verify_and_extend';
    static updateProfile = 'registration/update_user_profile';
}

export class LOGIN_API_ENDPOINTS {
    static logout = 'registration/revoke';
    static checkLogin = 'registration/authorize';
    static forgetPassword = 'registration/forget_password';
    static changePassword = 'registration/change_password';
}

export class BOOKING_API_ENPOINTS {
    static addComments = 'booking/comments';
    static fetchWorklog = 'reports/worklog';
    static airportList = 'booking/airport_list';
    static currencyCode = 'booking/currency_code';
    static ticketReceipt = 'booking/ticket_receipt';
    static createRequest = 'booking/create_booking';
    static searchFlights = 'booking/search_flights';
    static currencyConvert = 'booking/convert_currency';
    static downloadComments = 'reports/downloads/attachments/';
    static updateMarkAsDone = "booking/update_comment_action_flag";
    static updatePaymentDescriptiondeposite1 = 'booking/update_payment_description_deposite1';
    static updatePaymentDescriptiondeposite2 = 'booking/update_payment_description_deposite2';
    static updatePaymentDescriptiondeposite3 = 'booking/update_payment_description_deposite3';
    static penaltyCal = 'booking/calculate_downsize_penalty';
    static paxRestriction = "rules/get_pax_restriction";
    static getAutoTimeline = "booking/get_autoExpiry_timeline_list";
    static updateAutoTimeline = "booking/update_auto_expiry_timeline";
    static updateRefundToReport = "booking/update_refund";
}

export class BOOKINGVIEW_API_ENPOINTS {
    static SimilarRequest = 'booking/search_similar_requests';
    static loadFactor = "booking/check_load_factor";
    static updateRequest = 'booking/update_booking';
    static PaxDownSize = 'booking/pax_downsize';
    static searchById = 'reports/search_by_id';
    static offlinePayment = 'booking/payment';

}

export class ONLINE_API_ENPOINTS {
    static onlinePayment = 'payment/online_payment';
}


// Manage Modules API Endpoints
export class USER_RULE_API_ENDPOINTS {
    static updateAPI = 'rules/update_user';
    static deleteAPI = 'rules/delete_user_profile';
    static retrieveUsers = 'rules/retrieve_users_list';
    static updateOldUsers = 'rules/update_old_mail_request';
    static allCorporate = 'rules/retrieve_corporate_profiles_list';
    static retieveOldUsers = 'rules/retrieve_old_mail_approval_list';
}

export class AGENT_RULE_API_ENDPOINTS {
    static updateAPI = 'rules/update_agent_profile';
    static deleteAPI = 'rules/delete_agent_profile';
    static retrieveAgents = 'rules/retrieve_all_agent_profiles';
}

export class SUB_AGENT_ENDPOINTS {
    static retrieveSubAgents = 'rules/manage_sub_agents';
    static addAPI = "registration/register_sub_agent";
    static updateAPI = 'rules/manage_sub_agents';
    static deleteAPI = 'rules/delete_sub_agent';
}

export class COPORATE_CODE_ENDPOINTS {
    static retrieveCoporates = "rules/retrieve_corporate_profiles_list";
    static updateAPI = "rules/update_corporate_profile";
    static deleteAPI = "rules/delete_corporate_profile";
    static addAPI = "rules/add_corporate_profile";
}

export class DESK_USERS_ENDPOINTS {
    static updateAPI = "rules/update_desk_user";
    static deleteAPI = "rules/delete_desk_user";
    static registerDU = "rules/register_desk_user";
    static retrieveDU = "rules/retrieve_desk_user_list";
}

export class PAX_RESTRICTION_ENDPOINTS {
    static retrievePax = "rules/retrieve_pax_restriction_list";
    static updateAPI = "rules/update_pax_restriction";
    static deleteAPI = "rules/delete_pax_restriction";
    static addAPI = "rules/add_pax_restriction";
}

export class RULES_ENDPOINTS {
    static addAPI = "rules/add_reject_rules";
    static deleteAPI = "rules/delete_reject_rules";
    static updateAPI = "rules/update_reject_rules";
    static retrieveRules = "rules/retrieve_reject_rules_list";
}

export class REPORT_API_ENDPOINTS {
    static worklogs = "reports/worklog";
    static searchReports = "reports/search";
    static getPNRReport = "reports/pnr_report";
    static filterReports = "reports/adhoc_filter";
    static gsaSearchReports = "reports/gsa_search";
    static gsaFilterReports = "reports/gsa_filter";
    static getMealCodeList = "reports/meal_code_list";
    static getRequestCount = "reports/get_request_count";
    static getMonthlyReport = "reports/get_monthly_report";
    static getTicketingReport = "reports/ticketing_report";
    static downloadFile = "reports/downloads/attachments/";
    static searchPaymentPending = "reports/payment_pending";
    static getGSARequestCount = "reports/get_gsa_request_count";
    static getChildRequest = 'reports/get_child_request_details';
    static getGSAMonthlyReport = "reports/get_gsa_monthly_report";
    static getGroupRequestReport = "reports/group_request_report";
    static getGSAGroupRequestReport = "reports/group_gsa_request_report";
}


export class SERIES_API_ENDPOINTS {
    static SERIES_CREATE_API = 'seriesbooking/create';
    static SERIES_REQ_SEARCH = 'seriesbooking/search';
    static SERIES_OFFLINE_PAY = 'seriesbooking/payment';
    static SERIES_PARENT_UPDATE = 'seriesbooking/update';
    static SERIES_SEARCH_BY_ID = 'seriesbooking/searchById';
    static SERIES_REQUEST_COMMENTS = 'seriesbooking/comments';
    static SERIES_PAX_DWON_SIZE = 'seriesbooking/pax_downsize';  //Pax sise reduce before Pax confirm API
    static SERIES_DELETE_SUBID = 'seriesbooking/deleteSubRequest';
    static SERIES_UPDATE_NOTIFICATION = 'seriesbooking/markAsRead';
    static SERIES_MONTHLY_REPORT = 'seriesbooking/getMonthlyReport';
    static SERIES_PAYMENT_PENDING = 'seriesbooking/payment_pending';
    static SERIES_ALL_NOTIFICATION = 'seriesbooking/notificationStatus';
    static SERIES_REQUEST_WORKLOG = "seriesbooking/worklog?requestType=";
    static SERIES_UPDATE_ALL_NOTIFICATION = 'seriesbooking/markAllAsRead';
    static SERIES_SUB_SEARCH_BY_ID = 'seriesbooking/searchBySubRequestId';
    static SERIES_REQUEST_COUNT = 'seriesbooking/get_series_request_count';
    static SERIES_PENALTY_CALC = 'seriesbooking/calculate_downsize_penalty';
    static SERIES_TEMPLATE_DOWNLOAD = "seriesbooking/downloads/attachments/";
    static SERIES_UPDATE_COMMENTS_ACTION = 'seriesbooking/updateCommentActionFlag';
    static updatePaymentDescriptiondeposite1 = 'seriesbooking/updatepaymentdescriptiondeposite1';
    static updatePaymentDescriptiondeposite2 = 'seriesbooking/updatepaymentdescriptiondeposite2';
    static updatePaymentDescriptiondeposite3 = 'seriesbooking/updatepaymentdescriptiondeposite3';
    static updatePaymentDescriptiondeposite4 = 'seriesbooking/updatepaymentdescriptiondeposite4';
}

export class TOUR_CODE_API_ENDPOINTD {
    static validateFile = "rules/check_duplicate_tour_code_list";
    static retrieveTourCodes = "rules/retrieve_tour_code_list";
    static fetchState = "rules/retrieve_state_info";
    static updateAPI = "rules/update_tour_code";
    static deleteAPI = "rules/delete_tour_code";
    static addAPI = "rules/add_tour_code_list";
}
