import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { GBCreateRequestRoutingModule } from './create-request-routing.module';
import { GBMaterialModule } from '../gb-material.module';
import { SharedModule } from '../shared/shared.module';
import { CreateRequestFormComponent } from './create-request-form/create-request-form.component';
import { PaxPopupFormComponent } from './pax-popup-form/pax-popup-form.component';
import { SearchFlightListComponent } from './search-flight-list/search-flight-list.component';
import { RequestViewComponent } from './request-view/request-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRequestComponent } from './dashboard-request/dashboard-request.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { flightSearchComponent } from './search-flight-list/flight-search/flight-search-table.component';


@NgModule({
    declarations: [
        CreateRequestFormComponent,
        PaxPopupFormComponent,
        SearchFlightListComponent,
        RequestViewComponent,
        DashboardComponent,
        DashboardRequestComponent,
        flightSearchComponent,
    ],
    imports: [
        CommonModule,
        MatNativeDateModule,
        GBCreateRequestRoutingModule,
        SharedModule,
        GBMaterialModule,
        CarouselModule
    ],
    providers: [],
    exports: [
    ]
})

export class GBCreateRequestModule { }
