import { environment } from "src/environments/environment";
const PATTERN = {
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_?])[A-Za-z\d~!@#$%^&*_?]{8,12}$/,
    STARTENDSPACE: /^[^\s]+(\s+[^\s]+)*$/,
    NAME: /^[a-zA-Z]^\s/,
    FIRSTNAME: /^([a-zA-Z\s]+[']*[a-zA-Z\s]*)+$/,
    LASTNAME: /^([a-zA-Z\s]+[']*[a-zA-Z\s]+)+$/,
};

const LOGIN_FORM = {
    EMAIL_MANDATORY: 'Email ID is required',
    PASSWORD_MANDATORY: 'Password is required',
    EMAIL_INVALID: 'Please provide a valid Email ID',
    PASSWORD_INVALID: 'Password should be minimum 8 characters with one Special Characters (~!@#$%^&*_?), Uppercase and Numbers',
};

const PRIVACY_POLICY = environment.DOMAIN_URL + "in/en/privacy-policy";

const BASICINFO_FORM = {
    EMAIL_INVALID: 'Please provide a valid Email',
    EMAIL_MANDATORY: 'Email is required',
    GROUPNAME_MANDATORY: 'Group name is required',
    FIRSTNAME_MANDATORY: 'First name is required',
    LASTNAME_MANDATORY: 'Last name is required',
    NAME_INVALID: 'Please provide a valid name',
    CONTACT_MANDATORY: 'Please provide a contact number',
    CONTACT_INVALID: 'Please provide valid phone number'
};

const WIDGET_FORM = {
    ORIGIN_MANDATORY: 'Please select origin',
    DESTINATION_MANDATORY: 'Please select Destination',
    DEPARTUREDATE_MANDATORY: 'Please select Departure Date',
    DATE_MANDATORY: 'Please select Departure and Return Date',
    PASSENGER_MANDATORY: 'Please add Passengers',
    TRIP_MANDATORY: 'Kindly select the Trip',
    TRIP_MISMATCH: 'Origin and Destination cannot be same. kindly re-select',
    PAXCOUNT_MISMATCH: 'Maximum of 4 passengers can travel',
    FLIGHTNO_MANDATORY: 'Please select flight no',
    PRE_FLIGHTNOTSELECTED: 'Please select previous itinerary flight no',
    PRE_DEPARTUREDATENOTSELECTED: 'Please select previous itinerary flight no',
    NOORIGIN_MATCHES: 'Please select a valid place of departure',
    NODESTINATION_MATCHES: 'Please select a valid place of arrival',
    TRIPCLASS_MANDATORY: 'Please select class of travel',
    FLIGHTTIME_MANDATORY: 'Please select flight time',
    TRAVELDURATION_MANDATORY: 'Please select travel duration',
    ARRIVALTIME_MANDATORY: 'Please select Arrival time',
    ARRIVALDATE_MANDATORY: 'Please select Arrival Date',
    PREMIUM_ECONONMY_COUNTMISMATCH: 'Group size (Adult+Child) for Premium Economy class should be less than 24',
    TERMS_MANDATORY: 'Please select Terms and Condition checkbox',
    EMAIL_INVALID: 'Please provide a valid Email',
    CORPORATENAME: 'Please select Corporate Name',
    CORPORATECODE: 'Please select Corporate Code'

};

const REGISTER_FORM = {
    "firstName": {
        "required": "First Name is required.",
        "pattern": "First and Last character should not be space",
        "minlength": "First Name must be at least 3 characters long.",
        "maxlength": "First Name cannot be more than 100 characters long.",
    },
    "agencyName": {
        "required": "Agency Name is required.",
        "pattern": "First and Last character should not be space",
        "minlength": "Agency Name must be at least 3 characters long.",
        "maxlength": "Agency Name cannot be more than 100 characters long.",
    },
    "lastName": {
        "required": "Last Name is required.",
        "pattern": "First and Last character should not be space",
        "minlength": "Last Name must be at least 1 characters long.",
        "maxlength": "Last Name cannot be more than 100 characters long.",
    },
    "email": {
        "required": "Email ID is required.",
        "pattern": 'Please provide a valid Email ID',
    },
    "emailId": {
        "required": "Email ID is required.",
        "pattern": 'Please provide a valid Email ID',
    },
    "phoneNumber": {
        "required": "Phone Number is required.",
        "minlength": "Phone Number must be of 10 digits"
    },
    "password": {
        "required": "Password is required.",
        "minlength": "Password must be of 4 characters long",
        "pattern": "Password should be minimum 8 characters with one Special Characters (~!@#$%^&*_?), Uppercase and Numbers"
    },
    "confirmPassword": {
        "required": "Confirm Password is required.",
        "pattern": "Password should be minimum 8 characters with  one Special Characters (~!@#$%^&*_?), Uppercase and Numbers"
    },
    "address1": {
        "required": "Address is required.",
        "pattern": "First and Last character should not be space",
    },
    "country": {
        "required": "Country is required.",
    },
    "state": {
        "required": "State is required.",
    },
    "city": {
        "required": "City is required.",
    },
    "pincode": {
        "required": "Pincode is required.",
    },
    "iataCode": {
        "required": "IATA Code is required for Travel Agent.",
        "minlength": "IATA code must be of 7 digits"
    },
    "privacyPolicy": {
        "required": "Kindly accept Privacy Policy.",
    },
    "paymentExemption": {
        "required": "Payment Exemption is required.",
    },
    "salesPersonEmailId": {
        "required": "Sale Person Email Id is required.",
        "pattern": 'Please provide a valid Email Id',
    }
};

const GSTFORMVALIDATION = {
    EMAIL_REQUIRED: 'Email ID is required',
    EMAIL_PATTERN: 'Please provide a valid Email ID',
    PHONE_REQUIRED: 'Phone Number is required.',
    PHONE_LENGTH: 'Phone Number must be of 10 digits',
    GST_NO: 'GST number required',
    GST_COMPANYNAME: 'Company Name is required',
}

const DESK_USER_FROM = {
    "firstName": {
        "required": "First Name is required.",
        "pattern": "First and Last character should not be space",
        "minlength": "First Name must be at least 3 characters long.",
        "maxlength": "First Name cannot be more than 100 characters long.",
    },
    "lastName": {
        "required": "Last Name is required.",
        "pattern": "First and Last character should not be space",
        "minlength": "Last Name must be at least 1 characters long.",
        "maxlength": "Last Name cannot be more than 100 characters long.",
    },
    "emailId": {
        "required": "Email ID is required.",
        "pattern": 'Please provide a valid Email ID',
    },
    "employeeId": {
        "required": "Employee ID is required.",
        "pattern": "First and Last character should not be space",
    },
    "password": {
        "required": "Password is required.",
        "minlength": "Password must be of 4 characters long",
        "pattern": "Password should be minimum 8 characters with one Special Characters (~!@#$%^&*_?), Uppercase and Numbers"
    },
    "confirmPassword": {
        "required": "Confirm Password is required.",
        "pattern": "Password should be minimum 8 characters with one Special Characters (~!@#$%^&*_?), Uppercase and Numbers"
    },
    "userType": {
        "required": "Role is required."
    },
};

const PAX_FROM = {
    "origin": {
        "required": "Origin is required.",
    },
    "destination": {
        "required": "Destination is required.",
    },
    "flightNumbers": {
        "required": "Flight No is required.",
        "pattern": "First and Last character should not be space",
    },
    "flightNo": {
        "required": "Flight No is required.",
        "pattern": "First and Last character should not be space",
    },
    "cabinClass": {
        "required": "Cabin Class is required.",
    },
    "saleStartDate": {
        "required": "Sale start date is required.",
    },
    "saleEndDate": {
        "required": "Sale End date is required.",
    },
    "travelStartDate": {
        "required": "Travel start date is required.",
    },
    "travelEndDate": {
        "required": "Travel start date is required.",
    },
    "daysOfOperation": {
        "required": "Operation Days is required.",
    },
    "minGroupSize": {
        "required": "Group Size is required.",
    },
    //Rule validation message start here
    "flightDateFrom": {
        "required": "Flight from date is required.",
    },
    "flightDateTo": {
        "required": "Flight to date is required.",
    },
    "minSize": {
        "required": "Minimum Group Size is required.",
    },
    "maxSize": {
        "required": "Maximum Group Size is required.",
    },

    "adultFare": {
        "required": "Adult Fare is required.",
    },
    "infantFare": {
        "required": "Infant Fare is required.",
    },

};


const FORM_MESSAGES = {
    LOGIN_FORM,
    BASICINFO_FORM,
    WIDGET_FORM,
    REGISTER_FORM,
    DESK_USER_FROM,
    PAX_FROM,

}
const VALIDATION_MESSAGES = {
    FORMS: FORM_MESSAGES
};

const SEARCH_CAROUSEL_JSON = [
    { url: './assets/images/home_banner_1.png', alt: 'home_banner_1' },
    { url: './assets/images/home_banner_2.png', alt: 'home_banner_2' },
];

const SEARCH_CAROUSEL_OPTIONS = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    items: 1,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
};


export const GB_CONSTANTS = {
    REGEX: PATTERN,
    FORM_VALIDATIONS: VALIDATION_MESSAGES,
    PRIVACY_POLICY_URL: PRIVACY_POLICY,
    SEARCH_CAROUSEL: SEARCH_CAROUSEL_JSON,
    SEARCH_CAROUSEL_OPTIONS,
    GSTINFO_FROM: GSTFORMVALIDATION,
}

export const REQUEST_LIST = [
    { "key": "Open", "code": "open", "icon": "./assets/images/icons/open.png", "hoverIcon": "./assets/images/icons/open_hover.png", "countDesc": "openStatusCount", "reqCode": "open" },
    { "key": "Quoted", "code": "quoted", "icon": "./assets/images/icons/quoted.png", "hoverIcon": "./assets/images/icons/quoted_hover.png", "countDesc": "quotedStatusCount", "reqCode": "quoted" },
    { "key": "Under Negotiation", "code": "un", "icon": "./assets/images/icons/under_negotiation.png", "hoverIcon": "./assets/images/icons/under_negotiation_hover.png", "countDesc": "underNegotiation", "reqCode": "underNegotiation" },
    { "key": "Quote Accepted", "code": "qa", "icon": "./assets/images/icons/quote_accepted.png", "hoverIcon": "./assets/images/icons/quote_accepted_hover.png", "countDesc": "quoteAccepted", "reqCode": "quoteAccepted" },
    { "key": "Customer Confirmed", "code": "qc", "icon": "./assets/images/icons/customer_confirmed.png", "hoverIcon": "./assets/images/icons/customer_confirmed_hover.png", "countDesc": "quoteConfirmedCount", "reqCode": "quoteConfirmed" },
    { "key": "PNR Created", "code": "pc", "icon": "./assets/images/icons/pnr_created.png", "hoverIcon": "./assets/images/icons/pnr_created_hover.png", "countDesc": "pnrCreated", "reqCode": "pnrCreated" },
    { "key": "Ticket Pending", "code": "tp", "icon": "./assets/images/icons/ticket_pending.png", "hoverIcon": "./assets/images/icons/ticket_pending_hover.png", "countDesc": "ticketPendingCount", "reqCode": "ticketPending" },
    { "key": "Payment Pending", "code": "pp", "icon": "./assets/images/icons/payment_pending.png", "hoverIcon": "./assets/images/icons/payment_pending_hover.png", "countDesc": "paymentPendingCount", "reqCode": "paymentPending" },
    { "key": "Schedule Changes", "code": "sc", "icon": "./assets/images/icons/schedule_change.png", "hoverIcon": "./assets/images/icons/schedule_change_hover.png", "countDesc": "scheduleChangesCount", "reqCode": "scheduleChanges" },
    { "key": "Modified Requests", "code": "mr", "icon": "./assets/images/icons/modified_request.png", "hoverIcon": "./assets/images/icons/modified_request_hover.png", "countDesc": "modifyRequestCount", "reqCode": "modifiedRequest" },
    { "key": "Service Request", "code": "SR", "icon": "./assets/images/icons/service_request.png", "hoverIcon": "./assets/images/icons/service_request_hover.png", "countDesc": "actionRequiredSearch", "reqCode": "serviceRequest" }
    // { "key": "Ticket Pending" },
    // { "key": "Ancillary Requests" },
    // { "key": "Info Pending" },
    // { "key": "Flying Today" },
    // { "key": "Flying in 9 Days" },
    // { "key": "Flying in 30 Days" },
];


export const GSA_REQ_LIST = [
    { "key": "Open", "code": "open", "icon": "./assets/images/icons/open.png", "hoverIcon": "./assets/images/icons/open_hover.png", "countDesc": "openStatusCount", "reqCode": "open" },
    { "key": "Quoted", "code": "quoted", "icon": "./assets/images/icons/quoted.png", "hoverIcon": "./assets/images/icons/quoted_hover.png", "countDesc": "quotedStatusCount", "reqCode": "quoted" },
    { "key": "Under Negotiation", "code": "un", "icon": "./assets/images/icons/under_negotiation.png", "hoverIcon": "./assets/images/icons/under_negotiation_hover.png", "countDesc": "underNegotiation", "reqCode": "underNegotiation" },
    { "key": "Quote Accepted", "code": "qa", "icon": "./assets/images/icons/quote_accepted.png", "hoverIcon": "./assets/images/icons/quote_accepted_hover.png", "countDesc": "quoteAccepted", "reqCode": "quoteAccepted" },
    { "key": "Customer Confirmed", "code": "qc", "icon": "./assets/images/icons/customer_confirmed.png", "hoverIcon": "./assets/images/icons/customer_confirmed_hover.png", "countDesc": "quoteConfirmedCount", "reqCode": "quoteConfirmed" },
    { "key": "Modified Requests", "code": "mr", "icon": "./assets/images/icons/modified_request.png", "hoverIcon": "./assets/images/icons/modified_request_hover.png", "countDesc": "modifyRequestCount", "reqCode": "modifiedRequest" },
    { "key": "Schedule Changes", "code": "sc", "icon": "./assets/images/icons/schedule_change.png", "hoverIcon": "./assets/images/icons/schedule_change_hover.png", "countDesc": "scheduleChangesCount", "reqCode": "scheduleChanges" },
];

export const CURRENCY_CODE = [
    { countryCode: 'IN', currencyCode: 'INR' },
    { countryCode: 'GB', currencyCode: 'EUR' },
    { countryCode: 'US', currencyCode: 'USD' },
];

export class GRMS_CONSTANT {
    public static HOME_PAGE_URL: string = "/group-booking";
    public static DOMAIN: string = environment.DOMAIN_URL;
    public static GRMS_SESSION_TIMEOUT: number = 1800;
    public static GRMS_SESSION_WARN_TIME: number = 1500;
    public static ADULT_PASSENGER_NAME_MAX_LEN: number = 53;
    public static INFANT_PASSENGER_NAME_MAX_LEN: number = 47;
    public static PREMIUM_DEFAULT_MAXLIMIT: number = 24;
    public static BUSINESS_DEFAULT_MAXLIMIT: number = 12;
    public static PREMIUM_ORIGIN_MAXLIMIT: number = 36;
    public static BUSINESS_ORIGIN_MAXLIMIT: number = 30;
}
