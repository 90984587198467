import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { Subject, forkJoin, of } from "rxjs";
import { takeUntil, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { RegisterModelComponent } from './register-model/register-model.component';
import { ProfileDetails } from '../../shared/interface/index';
import { SideNavBarService } from "../../shared/services/side-nav-bar.service"
import { HttpService } from 'src/app/shared/httpService/http.service';
import { GB_CONSTANTS, GRMS_CONSTANT } from '../../shared/static/constants';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { BOOKING_API_ENPOINTS, COMMON_API_ENDPOINTS, REGISTER_API_POINTS } from 'src/app/shared/httpService/apiURLConstant';
import { DirtyComponent } from '../../shared/model/dirty.component';
import { LoaderService } from '../../shared/services/gb-loader.services';
import { UserProfileService } from '../../shared/services/user-profile.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit, DirtyComponent {

    public userType = "";
    states: string[] = [];
    public oldMailId = '';
    public isDirty = false;
    public errorMessage = "";
    public isGSAUser = false;
    public passwordValue = "";
    public travelAgent = true;
    public hidePassword = false;
    public confPasswordValue = "";
    public isVistaraLogin = false;
    public hideConfPassword = false;
    public showUpdateAction = false;
    public registerForm!: FormGroup;
    public showCurrencyField = false;
    profileDetails = new ProfileDetails();
    public disableCountrySelection = true;
    public iataCodeRequired: Boolean = true;
    privacyPolicyURL = GB_CONSTANTS.PRIVACY_POLICY_URL;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private unSubscribe: Subject<boolean> = new Subject<boolean>();

    public dummyCountryList: any = [];
    public currencyList: any = [];
    public countryList: any = [];
    public pincodeList: any = [];
    public phoneCodeLength = 10;
    public stateList: any = [];
    public cityList: any = [];
    public userRole = '';

    public fieldValidation = ['phoneNumber', 'address1', 'iataCode', 'privacyPolicy', 'country', 'state', 'city', 'pincode'];

    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS.REGISTER_FORM;

    formErrors = {
        "firstName": "",
        "lastName": "",
        "emailId": "",
        "phoneNumber": "",
        "password": "",
        "confirmPassword": "",
        "address1": "",
        "iataCode": "",
        "privacyPolicy": "",
        "country": "",
        "city": "",
        "state": "",
        "pincode": "",
    };

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private sideNavBar: SideNavBarService,
        private httpService: HttpService,
        private router: Router,
        private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private grmsUtils: GrmsUtils,
        private userProfileService: UserProfileService
    ) { }

    ngOnInit(): void {
        this.createForm();
        let pageName = window.location.pathname.split('/')[2];
        this.userProfileService.vistaraUserFlag
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isVistaraLogin = (action === 'Non-Vistara') ? false : true;
            });
        if (pageName === "profile") {
            let userDetails = this.grmsUtils.getUserDetails();
            this.sideNavBar.hideSideNavBar();
            this.sideNavBar.showMenu();
            this.userProfileService.setUsername();
            this.userProfileService.setUserRole();
            this.userProfileService.setVistaraUserFlag();
            let payload = {
                "loginUserID": userDetails.loginUserId
            }
            this.httpService.postCall(REGISTER_API_POINTS.retrieveProfile, payload, this.grmsUtils.getAuthWithTextHeader())
                .pipe(takeUntil(this.unSubscribe))
                .subscribe({
                    next: (response: any) => {
                        let responseData = this.grmsUtils.decryptedData(response);
                        response = JSON.parse(responseData);
                        if (response.statusCode === 200) {
                            this.loaderService.hide();
                            this.profileDetails = _.cloneDeep(response.userDetails);
                            this.travelAgent = response.userDetails.travelAgent;
                            this.isGSAUser = response.userDetails.gsaUser === 'Y' ? true : false;
                            this.userRole = response.userDetails.userType;

                            let travelAgentDesc = this.travelAgent ? 'travelagent' : '';
                            if (this.isGSAUser) {
                                this.getCountryList();
                                this.phoneCodeLength = 15;
                                this.profileDetails.currencyCode = response.userDetails['currency'];
                            } else {
                                this.iataCode(travelAgentDesc);
                            }

                            if (this.userRole === 'ROLE_ADMIN' || this.userRole === 'ROLE_DESK_USER'
                                || this.userRole === 'ROLE_GS' || this.userRole === 'ROLE_SU') {
                                this.removeAddFieldValidation('remove');
                            } else {
                                this.removeAddFieldValidation('add');
                            }

                            this.showUpdateAction = true;
                            this.profileDetails['pincode'] = (this.profileDetails['pincode']) ? this.profileDetails['pincode'].toString() : "";
                            this.profileDetails.country = (this.profileDetails['country']) ? this.profileDetails.country.toUpperCase() : "";
                            this.profileDetails.state = (this.profileDetails['state']) ? this.profileDetails.state.toUpperCase() : "";
                            this.profileDetails.city = (this.profileDetails['city']) ? this.profileDetails.city.toUpperCase() : "";
                            if (this.profileDetails['iataCode'] === 0) {
                                this.registerForm.controls['iataCode'].setValue('');
                            }
                            this.registerForm.controls['password'].setValidators(null);
                            this.registerForm.controls['confirmPassword'].setValidators(null);
                            this.registerForm.controls['privacyPolicy'].setValidators(null);
                            this.registerForm.controls['password'].updateValueAndValidity();
                            this.registerForm.controls['confirmPassword'].updateValueAndValidity();
                            this.registerForm.controls['privacyPolicy'].updateValueAndValidity();
                            if (!this.isVistaraLogin && !this.isGSAUser && this.userRole !== 'ROLE_ADMIN' && this.userRole !== 'ROLE_DESK_USER'
                                && this.userRole !== 'ROLE_GS' && this.userRole !== 'ROLE_SU') {
                                this.profileDetails['country'] = 'IN';
                                let countryName = this.profileDetails.country ? this.profileDetails.country : 'IN';
                                let stateName = this.profileDetails.state ? this.profileDetails.state : '';
                                let cityName = this.profileDetails.city ? this.profileDetails.city : '';
                                this.loaderService.show();
                                forkJoin({
                                    country: this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                                    state: this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                                    city: this.httpService.getCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                                    pincode: this.httpService.getCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                                }).subscribe((data) => {
                                    this.loaderService.hide();
                                    this.countryList = this.grmsUtils.forkHandling(data.country) //data.country;
                                    this.stateList = this.grmsUtils.forkHandling(data.state); //(d;ata.state.status === 401) ? [] : data.state;
                                    this.cityList = this.grmsUtils.forkHandling(data.city); //(data.city.status === 401) ? [] : data.city;
                                    this.pincodeList = this.grmsUtils.forkHandling(data.pincode); //(data.pincode.status === 401) ? [] : data.pincode;
                                });
                            } else if (this.isVistaraLogin) {
                                this.registerForm.controls['address1'].setValidators(null);
                                this.registerForm.controls['address1'].updateValueAndValidity();
                            }
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });

        } else {
            if (pageName === "" || pageName === "home") {
                this.sideNavBar.showRegisterIcon();
            } else {
                this.sideNavBar.hideRegisterIcon();
            }

            if (pageName !== "" && pageName !== "register" && pageName !== "home") {
                this.sideNavBar.showMenu();
            } else {
                this.sideNavBar.hideMenu();
            }
            this.userProfileService.removeUserProfile();
            this.sideNavBar.newMail
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(value => {
                    this.registerForm.controls['emailId'].setValue(value);
                    this.profileDetails.emailId = value;
                });
            this.loaderService.show();
            forkJoin({
                country: this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
                state: this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/IN', this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
            }).subscribe((data) => {
                this.loaderService.hide();
                this.registerForm.controls['country'].setValue('IN');
                this.dummyCountryList = this.grmsUtils.forkHandling(data.country);
                this.countryList = [...this.dummyCountryList]
                this.stateList = this.grmsUtils.forkHandling(data.state);
            });
        }

    }

    removeAddFieldValidation(actionType) {
        for (let i = 0; i < this.fieldValidation.length; i++) {
            let fieldName = this.fieldValidation[i];
            if (actionType === 'remove') {
                this.registerForm.controls[fieldName].setValidators(null);
                this.registerForm.controls[fieldName].updateValueAndValidity();
            } else {
                if (fieldName === 'phoneNumber') {
                    this.registerForm.controls[fieldName].setValidators([Validators.required, Validators.minLength(10)]);
                } else if (fieldName === 'iataCode' && this.travelAgent) {
                    this.registerForm.controls[fieldName].setValidators([Validators.required, Validators.minLength(7)]);
                } else if (fieldName === 'iataCode' && !this.travelAgent) {
                    this.registerForm.controls[fieldName].setValidators(null);
                } else {
                    this.registerForm.controls[fieldName].setValidators([Validators.required]);
                }
                this.registerForm.controls[fieldName].updateValueAndValidity();
            }
        }
    }

    createForm() {
        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            lastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
            emailId: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            password: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.PASSWORD)]],
            confirmPassword: ['', [Validators.required]],
            address1: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE)]],
            address2: [''],
            country: ['IN', [Validators.required]],
            currencyCode: [''],
            state: ['', [Validators.required]],
            city: ['', [Validators.required]],
            pincode: ['', [Validators.required]],
            iataCode: ['', [Validators.required, Validators.minLength(7)]],
            privacyPolicy: [false, Validators.requiredTrue]
        });

        this.registerForm.valueChanges
            .subscribe(data => {
                this.onValueChanged(data)
                this.isDirty = this.registerForm.dirty;
            });

        this.onValueChanged();
    }

    onValueChanged(data?: any) {
        if (!this.registerForm) { return; }
        const form = this.registerForm;

        for (const field in this.formErrors) {
            this.formErrors[field] = "";
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessage[field];
                for (const key in control.errors) {
                    if ((this.userType === 'gsa' || this.isGSAUser) && field === 'phoneNumber' && key === "minlength") {
                        this.formErrors[field] += "Phone Number must be of 15 digits";
                    } else {
                        this.formErrors[field] += messages[key] + "  ";
                    }
                }
            }
        }
    }

    canDeactivate() {
        return this.isDirty;
    }

    checkPassword() {
        if (this.confPasswordValue !== undefined && this.profileDetails.password !== this.confPasswordValue) {
            this.formErrors["confirmPassword"] = "Confirm Password must be same as New Password";
        }
    }

    iataCode(selectedvalue: any) {
        this.phoneCodeLength = 15;
        this.showCurrencyField = false;
        this.disableCountrySelection = true;
        this.countryList = [...this.dummyCountryList];
        if (selectedvalue !== "gsa") {
            this.registerForm.controls['country'].setValue('IN');
            this.phoneCodeLength = 10;
        }
        //this.getStateList();
        let pageName = window.location.pathname.split('/')[2];
        if (pageName !== 'profile') {
            this.resetAddressFields();
            this.registerForm.reset(
                {
                    country: "IN"
                }
            );
        }

        this.travelAgent = (selectedvalue === "travelagent" || selectedvalue === "gsa") ? true : false;
        this.isGSAUser = (selectedvalue === "gsa") ? true : false;
        if (selectedvalue === "travelagent" || selectedvalue === 'gsa') {
            this.registerForm.controls['iataCode'].setValidators([Validators.required, Validators.minLength(7)]);
            this.registerForm.controls['iataCode'].updateValueAndValidity();
            this.iataCodeRequired = true;
            this.showCurrencyField = (selectedvalue !== 'gsa') ? false : true;
            this.disableCountrySelection = (selectedvalue === 'gsa') ? false : true;
            if (selectedvalue === 'gsa') {
                this.countryList = this.dummyCountryList.filter((obj) => {
                    return obj.countryCode !== 'IN';
                });
            }
        }
        else {
            this.registerForm.controls['iataCode'].setValidators([Validators.minLength(7)]);
            this.registerForm.controls['iataCode'].updateValueAndValidity();
            this.iataCodeRequired = false;

        }
        this.userType = selectedvalue;
    }

    resetAddressFields() {
        this.registerForm.controls['state'].setValue('');
        this.registerForm.controls['city'].setValue('');
        this.registerForm.controls['pincode'].setValue('');
    }

    registerWithId() {
        this.isDirty = false;
        this.canDeactivate();
        const dialogRef = this.dialog.open(RegisterModelComponent, {
            disableClose: true,
            width: '480px',
            height: '335px',
            data: { 'profileData': this.profileDetails, 'travelAgent': this.travelAgent, 'gsaUser': this.isGSAUser, 'currency': this.isGSAUser ? this.registerForm.controls['currencyCode'].value : 'INR' }
        })

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    doRegister() {
        this.loaderService.show();
        let payload = new ProfileDetails()
        this.sideNavBar.oldMail.subscribe((value) => this.oldMailId = value);
        payload = _.cloneDeep(this.profileDetails);
        payload.phoneNumber = Number(payload.phoneNumber);
        payload.iataCode = Number(payload.iataCode);
        payload['travelAgent'] = this.travelAgent;
        payload['gsaUser'] = this.isGSAUser ? 'Y' : 'N';
        payload['currency'] = this.isGSAUser ? this.registerForm.controls['currencyCode'].value : 'INR';
        payload['registerWithOldMailId'] = (this.oldMailId !== '' && this.oldMailId !== undefined && this.oldMailId !== null) ? "true" : "false";
        payload['oldMailId'] = this.oldMailId;
        // payload.password = this.grmsUtils.encryptedData(payload.password);
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
        this.httpService.postCall(REGISTER_API_POINTS.registerUser, inputData, this.grmsUtils.getPlainHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    let responseData = this.grmsUtils.decryptedData(response);
                    response = JSON.parse(responseData);
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.isDirty = false;
                        this.canDeactivate();
                        this.snackBar.open("Registration Successfully, Please check your Email", 'X', { duration: 3000 });
                        setTimeout(() => {
                            this.router.navigate(['/']);
                        }, 500);
                    } else if (response.statusCode === 555) {
                        this.loaderService.hide();
                        this.isDirty = false;
                        this.canDeactivate();
                        this.snackBar.open("Given email id has no privilege to register as GSA User", 'X', { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    reset() {
        let pageName = window.location.pathname.split('/')[2];
        if (pageName === "profile") {
            let userDetails = this.grmsUtils.getUserDetails();
            if (userDetails.user_role === "ROLE_AGENT" || userDetails.user_role === "ROLE_USER" ||
                userDetails.user_role === "ROLE_SUB_AGENT") {
                this.router.navigate(['/request/create']);
            } else {
                this.router.navigate(['/request']);
            }
        } else {
            this.registerForm.reset();
        }
    }

    updaetProfile() {
        let payload;
        payload = this.registerForm.value;
        payload['userId'] = this.profileDetails.userId;
        payload['gsaUser'] = this.isGSAUser ? 'Y' : 'N';
        payload['currency'] = this.isGSAUser ? this.registerForm.controls['currencyCode'].value : 'INR';
        payload.phoneNumber = Number(payload.phoneNumber);
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
        this.loaderService.show();
        this.httpService.postCall(REGISTER_API_POINTS.updateProfile, inputData, this.grmsUtils.getAuthWithTextHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    let responseData = this.grmsUtils.decryptedData(response);
                    response = JSON.parse(responseData);
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.profileDetails = response.userDetails;
                        this.profileDetails['pincode'] = (this.profileDetails['pincode']) ? this.profileDetails['pincode'].toString() : '';
                        this.profileDetails.currencyCode = response.userDetails['currency'];
                        let userDetails = this.setUserDetails(response.userDetails);
                        let expiryDate = new Date();
                        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
                        this.cookieService.set("userDetails", JSON.stringify(userDetails), expiryDate, '/', '', false, "Lax");
                        this.isDirty = false;
                        this.canDeactivate();
                        this.snackBar.open("Profile Details Update Successfully", 'X', { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    setUserDetails(userDetails) {
        let userData = {};
        let existingUserDetails = this.grmsUtils.getUserDetails();
        userData['userId'] = existingUserDetails.userId;
        userData['username'] = existingUserDetails.username;
        userData['user_role'] = existingUserDetails.user_role;
        userData['login_type'] = existingUserDetails.login_type;
        userData['travelAgent'] = existingUserDetails.travelAgent;
        userData['existingUser'] = existingUserDetails.existingUser;
        userData['gsaUser'] = (userDetails?.gsaUser === 'Y') ? userDetails?.gsaUser : 'N';
        userData['currency'] = (userDetails?.gsaUser === 'Y') ? userDetails?.currency : '';

        if (existingUserDetails.login_type === 'Non-Vistara') {
            userData['firstname'] = userDetails.firstName;
        } else {
            userData['lastname'] = userDetails.last_name;
            userData['firstname'] = userDetails.first_name;
        }
        return userData;
    }

    getCountryList() {
        this.loaderService.show();
        this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.countryList = response;
                        let pageName = window.location.pathname.split('/')[2];
                        if (this.isGSAUser && pageName === 'profile') {
                            this.dummyCountryList = response;
                            this.iataCode('gsa');
                        }
                        this.stateList = [];
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getCurrencyList() {
        this.loaderService.show();
        let countryCode = this.registerForm.controls['country'].value;
        this.httpService.getCall(BOOKING_API_ENPOINTS.currencyCode + '/' + countryCode, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    if (!!response) {
                        this.loaderService.hide();
                        this.profileDetails.currencyCode = "";
                        this.profileDetails.currencyCode = response ? response['currencyCode'] : 'EUR';
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });

    }

    getStateList(modeOfCall?) {
        let countryCode = this.registerForm.controls['country'].value;
        if (this.isGSAUser) {
            this.getCurrencyList();
            if (modeOfCall === 'onChange') {
                this.resetAddressFields();
            }
        } else {
            this.loaderService.show();
            this.stateList = [];
            this.registerForm.controls['state'].setValue('');
            this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryCode, this.grmsUtils.getJsonHeader())
                .pipe(takeUntil(this.unSubscribe))
                .subscribe({
                    next: (response) => {
                        if (response) {
                            this.loaderService.hide();
                            this.stateList = response;
                            this.cityList = [];
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }
    }

    getCityList() {
        this.loaderService.show();
        this.registerForm.controls['city'].setValue('');
        let stateName = this.registerForm.controls['state'].value;
        this.httpService.getCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.cityList = response;
                        this.pincodeList = [];
                        this.pincodeList = [];
                        this.registerForm.controls['pincode'].setValue('');
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getZipCodeList() {
        this.loaderService.show();
        this.registerForm.controls['pincode'].setValue('');
        let cityName = this.registerForm.controls['city'].value;
        this.httpService.getCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.pincodeList = response;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}

