import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './home/register/register.component';
import { ChangePasswordComponent } from './home/change-password/change-password.component';
import { LogoutComponent } from './home/logout/logout.component';
import { DirtyCheckGuard } from './shared/services/dirty-check.service';
import { RefreshComponent } from './generic/refresh/refresh.component';
import { VistaraLoginComponent } from './home/login/vistara-login/vistara-login.component';
import { VistaraLogoutComponent } from './home/logout/vistara-logout/vistara-logout.component';
import { ForgetPasswordComponent } from './home/forget-password/forget-password.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'vistara-login',
        component: VistaraLoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent,
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'profile',
        component: RegisterComponent,
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
    },
    {
        path: 'forget-password',
        component: ForgetPasswordComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'vistara-logout',
        component: VistaraLogoutComponent,
    },
    {
        path: 'refresh',
        component: RefreshComponent,
    },
    {
        path: 'request',
        loadChildren: () => import('./create-request/create-request.module').then(m => m.GBCreateRequestModule)
    },
    {
        path: 'manage',
        canActivate: [DirtyCheckGuard],
        data: {
            role: ['Admin', 'DU', 'GS', 'SU', 'Agent']
        },
        loadChildren: () => import('./manage-accounts/manage-accounts.module').then(m => m.ManageAccountsModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'series',
        loadChildren: () => import('./series-booking/series-request.module').then(m => m.SeriesRequestModule)
    },
    {
        path: 'service-request',
        canActivate: [DirtyCheckGuard],
        data: {
            role: ['Agent', 'User', 'SA']
        },
        loadChildren: () => import('./service-request/service-request.module').then(m => m.ServiceRequestModule)
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
