import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener, } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe, Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { GB_CONSTANTS } from '../../shared/static/constants';
import * as _ from 'lodash';
import * as FileSaver from "file-saver";
import { Router } from "@angular/router";
import { PaxPopupFormComponent } from '../pax-popup-form/pax-popup-form.component';
import { SearchFlightListComponent } from '../search-flight-list/search-flight-list.component';
import { LoaderService } from '../../shared/services/gb-loader.services';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SideNavBarService } from '../../shared/services/side-nav-bar.service';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { BOOKING_API_ENPOINTS, USER_RULE_API_ENDPOINTS, REPORT_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { PopOverContentComponent } from '../../shared/component/pop-over-content/pop-over-content.component';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { GrmsDiffCals } from '../../shared/static/grms-cals';
import { MenuAccessService } from 'src/app/shared/services/menu-access.service';
import { DirtyComponent } from 'src/app/shared/model/dirty.component';

@Component({
    selector: 'gb-create-request-form',
    templateUrl: './create-request-form.component.html',
    styleUrls: ['./create-request-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CreateRequestFormComponent implements OnInit, OnDestroy, DirtyComponent {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public createItrRequestFrom: FormGroup;
    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS;
    public carouselOptions: OwlOptions = GB_CONSTANTS.SEARCH_CAROUSEL_OPTIONS;
    public privacy_poli_url = GB_CONSTANTS.PRIVACY_POLICY_URL;
    public carouselImages = GB_CONSTANTS.SEARCH_CAROUSEL;
    public groupTypes: any = ['Business', 'Personal'];
    public tripClasses: any = ['Economy', 'Premium Economy', 'Business'];
    public airportsList: any = [];
    private corporateList: any = [];
    public filteredcorporateList: any = []
    public filteredAirports = {
        from: [],
        to: []
    };

    public currentDate: Date = new Date();
    public preItiArrivalDate: Date = new Date();
    private paxInfo: object;
    private flightSearchActive: boolean = false;
    public activeTripType: any = "Round Trip";
    private paxLimit: any = {
        'min': 1,
        'max': 99
    }

    public tripItineraryInfo: any = []
    private createRequest: any = {
        "isInternational": false,
        "loginUserID": "",
        "modifyRequest": "N",
        "requestgeneralData": {
            "flexibleTravelDates": "",
            "groupType": "",
            "fdb": false,
            "tripType": "",
            "noOfChild": 0,
            "noOfInfant": 0,
            "finalQuote": "false",
            "groupsizelimit": 0,
            "groupName": "",
            "companyName": "",
            "noOfAdult": 0,
            "expectedFarePerPax": 0,
            "groupSize": 0
        },
        "itinerayDetails": []
    }

    private Paxcount_MisMatch: any = "";
    public userDetails;
    public intRequest = false;
    public notifyEmailError = '';
    public flightDateList = [];
    public isOriginInternational = false;
    public isDestinationInternational = false;
    public showGroupNameErr = false;
    // public maxDateVal = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 331);
    public maxDateVal =new Date(2024, 10, 11);

    constructor(private formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private loaderService: LoaderService,
        private sideNavBarService: SideNavBarService,
        private httpService: HttpService,
        private grmsUtils: GrmsUtils,
        private cookieService: CookieService,
        public router: Router,
        public menuService: MenuAccessService,
        private profileService: UserProfileService,
        private routerNav: Router,
        private location: Location,
    ) { }

    ngOnInit() {
        this.getAirportList();
        this.createBooking();
        this.updateValidation();
        this.menuService.hideAndShowMenu();
        this.createItrRequestFrom;
        this.userDetails = this.grmsUtils.getUserDetails();
        String.prototype.includes = function (subStr) {
            if (subStr.length == 0 || this.indexOf(subStr) === -1) {
                return false;
            }
            return true;
        };
        setTimeout(() => {
            this.trigger()
        }, 300)

    }

    trigger() {
        window.dispatchEvent(new Event('resize'));
    }


    getAirportList() {
        this.loaderService.show();
        let userDetails = this.grmsUtils.getUserDetails();
        this.httpService.getCall(BOOKING_API_ENPOINTS.airportList, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.airportsList.push(response);
                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails)
                }
            });
        this.httpService.postCall(USER_RULE_API_ENDPOINTS.allCorporate, { "loginUserID": userDetails?.loginUserId }, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (corporateRes: any) => {
                    if (!!corporateRes) {
                        this.corporateList.push(corporateRes['corporateCodeDetails'])
                    }
                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })
        this.loaderService.hide();

    }

    checkGroupName(e) {
        if (!/^[a-zA-Z ]+$/.test(e.target.value)) {
            this.showGroupNameErr = true;
        } else {
            this.showGroupNameErr = false;
        }
    }

    createBooking() {
        this.createItrRequestFrom = this.formBuilder.group({
            groupName: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.STARTENDSPACE), Validators.maxLength(150), Validators.minLength(4)]],
            tourName: [null, [Validators.maxLength(150)]],
            groupType: ['Business', [Validators.required]],
            corporateName: [null],
            corporateCode: [null],
            tripType: ['Round Trip', [Validators.required]],
            flexibleTravelDate: [null],
            classOfTravel: [null, [Validators.required]],
            totalCount: [`${this.paxLimit.min}  (${this.paxLimit.min} Adult, 0 Child, 0 Infant)`, [Validators.required]],
            groupSize: [`${this.paxLimit.min}`],
            noOfAdult: [null],
            noOfChild: [null],
            noOfInfant: [null],
            itineraries: this.formBuilder.array([this.itineraryInfo()]),
            expectedQuote: [null, [Validators.required]],
            notifyOthers: [null],
            remark: [null, [Validators.maxLength(1000)]],
            termsCondition: [null, [Validators.required]]
        })
        this.onradioButtonChange({ 'value': 'Round Trip' })
    }

    get itineraryList() {
        return this.createItrRequestFrom.get('itineraries') as FormArray
    }

    itineraryInfo() {
        return this.formBuilder.group({
            // from: [{ value: '', disabled: false }, [Validators.required]],
            //to: [{ value: '', disabled: false }, [Validators.required]],
            from: [{ value: '', disabled: false }, [Validators.required]],
            to: [{ value: '', disabled: false }, [Validators.required]],
            departureDate: [null, [Validators.required]],
            flightNumber: [null, [Validators.required]],
            flightTime: [null, [Validators.required]],
            travelDuration: [null, [Validators.required]],
            arrivalTime: [null, [Validators.required]],
            arrivalDate: [null, [Validators.required]]
        })
    }

    addItineraryInfo() {
        this.itineraryList.push(this.itineraryInfo());
        this.flightDateList.push({ preItiArrivalDate: new Date() });
    }

    removeItineraryInfo(i: number) {
        this.itineraryList.removeAt(i);
    }

    onradioButtonChange($event) {
        this.intRequest = false;
        this.isOriginInternational = false;
        this.isDestinationInternational = false;
        this.flightDateList = [];
        this.filteredAirports = {
            from: [],
            to: []
        };
        if ($event.value == "Round Trip") {
            this.createItrRequestFrom.controls['itineraries']['controls'] = [];
            this.activeTripType = 'Round Trip';
            for (let i = 0; i < 2; i++) {
                this.addItineraryInfo();

            }
        } else if ($event.value == "One Way") {
            this.activeTripType = 'One Way';
            this.createItrRequestFrom.controls['itineraries']['controls'] = [];
            this.addItineraryInfo();
        } else if ($event.value == "Multiple") {
            this.activeTripType = 'Multiple';
            this.createItrRequestFrom.controls['itineraries']['controls'] = [];
            for (let i = 0; i < 2; i++) {
                this.addItineraryInfo();
            }
        }
        this.tripItineraryInfo = [];
        this.classTravelChange({ value: this.createItrRequestFrom.value['classOfTravel'] })
    }

    selectDepartureDate(event: any, i) {
        this.fieldChangeReset(i)
        if (i > 0) {
            if (!this.createItrRequestFrom.value.itineraries[i - 1]['departureDate'] || !this.createItrRequestFrom.value.itineraries[i - 1]['flightNumber']) {
                this.snackBar.open('please fill previous itinerary field', 'X', { duration: 3000 });
                this.createItrRequestFrom.controls['itineraries']['controls'][i - 1]['controls']['departureDate'].markAsTouched();
                this.createItrRequestFrom.controls['itineraries']['controls'][i - 1]['controls']['flightNumber'].markAsTouched();
                this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['departureDate'].setValue(null);
            }
        }
        if (this.activeTripType != 'One Way' && (!!this.createItrRequestFrom.value.itineraries[i + 1]?.departureDate || !!this.createItrRequestFrom.value.itineraries[i - 1]?.flightNumber)) {
            let timeDiff = GrmsDiffCals.findTimeDiffMins(this.createItrRequestFrom.value.itineraries[i + 1]?.departureDate,
                event.value, this.createItrRequestFrom.value.itineraries[i + 1]?.flightTime,
                '00:00')
            if (timeDiff >= 0) {
                this.snackBar.open('Please select before of next itineraries and change next itineraries for your plan', 'X', { duration: 3000 });
                this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['departureDate'].markAsTouched();
                this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['flightNumber'].markAsTouched();
                this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['departureDate'].setValue(null);
            } else {
                this.resetAllItinerariesDates(i);
            }
        }

    }

    setMinDate(i) {
        let getPreArrivelDate = this.createItrRequestFrom.get('itineraries') as FormArray;
        if (i != 0) {
            let preDate = moment(getPreArrivelDate.value[i - 1].arrivalDate);
            this.preItiArrivalDate = getPreArrivelDate.value[i - 1].arrivalDate != null ? preDate['_d'] : new Date();
            this.flightDateList[i].preItiArrivalDate = this.preItiArrivalDate;
        } else {
            this.flightDateList[i].preItiArrivalDate = new Date();
        }
    }

    resetAllItinerariesDates(index) {
        if (this.activeTripType !== 'One Way') {
            for (let i = index; i < this.flightDateList.length - 1; i++) {
                let indexVal = i + 1;
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['flightNumber'].setValue(null);
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['departureDate'].setValue(null);
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['travelDuration'].setValue(null);
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['arrivalTime'].setValue(null);
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['arrivalDate'].setValue(null);
                this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls['flightTime'].setValue(null);
            }
        }
    }

    filterAutoComplete(e, airportType, indexVal?) {
        let searchedVal = e.target.value;
        let filteredArr = this.airportsList[0].filter(obj => {
            if (obj.cityName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                (obj.airportCode.toLowerCase().includes(searchedVal.toLowerCase()))) {
                return obj;
            }
        });

        this.filteredAirports[airportType] = filteredArr;
    }

    setAutoCompleteVal(selectedval, field?, indexVal?) {
        if (selectedval) {
            let itinerariesArray = this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls;
            let itinerariesFieldValue = this.createItrRequestFrom.value?.itineraries[indexVal];
            itinerariesArray[field].setErrors(null);
            if (this.grmsUtils.isNotNull(selectedval) && this.grmsUtils.isNotNull(itinerariesFieldValue[field])) {
                let returnType = (field === 'from') ? 'to' : 'from';
                if (selectedval.trim().toLowerCase() === itinerariesFieldValue[returnType].trim().toLowerCase()) {
                    itinerariesArray[field].setErrors({ tripMismatch: true });
                } else {
                    itinerariesArray[field].setErrors(null);
                    let countryCode = this.airportsList[0].find(x => x.airportCode == selectedval)?.countryCode;
                    if (this.grmsUtils.isNull(countryCode)) {
                        if ((field === 'from')) {
                            itinerariesArray[field].setErrors({ nooriginfound: true });
                        } else {
                            itinerariesArray[field].setErrors({ nodestinationfound: true });
                        }
                    } else if (this.activeTripType === 'Round Trip') {
                        let roundTripField = (field === 'from') ? 'to' : 'from';
                        this.createItrRequestFrom.controls['itineraries']['controls'][indexVal].controls[field].setValue(selectedval);
                        this.createItrRequestFrom.controls['itineraries']['controls'][indexVal + 1].controls[roundTripField].setValue(selectedval);
                    }
                }
            }
        } else {
            this.validateForm(field, indexVal);
        }
    }

    // checkValidCode(e, i, field) {
    //     if (!!this.createItrRequestFrom.controls['itineraries']['controls'][i].controls[field].valid) {
    //         this.checkValidAirport(e.target.value, field, i)
    //     }
    // }

    // listenIndex(e, i, field) {
    //     this.fieldChangeReset(i);
    //     if (this.activeTripType == 'Round Trip') {
    //         this.fieldChangeReset(i + 1);
    //     }
    //     this.listenToODFormChange(e, i, field);
    // }

    listenCorporateNameChange() {
        this.createItrRequestFrom.controls['corporateName'].valueChanges.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((val) => {
                if (val == '') {
                    this.createItrRequestFrom.controls['corporateCode'].setValue(null);
                }
                if (this.corporateList.length > 0) {
                    this.filteredcorporateList = this.corporateList[0].filter(corp => {
                        if (corp.corporatename.toLowerCase().includes(val.toLowerCase())) {
                            return corp;
                        }
                    })

                }

            })

    }
    setCorporateCode(corporateName) {
        this.corporateList[0].find(corp => {
            if (corp.corporatename.toLowerCase() == corporateName.toLowerCase()) {
                this.createItrRequestFrom.controls['corporateCode'].setValue(corp.corporatecode);
            }
        })

    }

    // listenToODFormChange(e, i, field) {
    //     let searchedVal = e.target.value;
    //     let tempFlightArr = [];
    //     let details = [
    //         {
    //             "originCode": "",
    //             "destinationCode": ""
    //         }
    //     ];
    //     tempFlightArr.push({ details: details });
    //     this.filteredAirports[field] = this.airportsList[0].filter(airport => {
    //         if (airport.cityName.toLowerCase().includes(searchedVal.toLowerCase()) ||
    //             (airport.airportCode.toLowerCase().includes(searchedVal.toLowerCase()))) {
    //             return airport;
    //         }
    //     });

    //     if (this.filteredAirports[field].length === 0) {
    //         if (this.activeTripType === 'Round Trip') {
    //             let oppositeField = (field === 'from') ? 'to' : 'from';
    //             this.createItrRequestFrom.controls['itineraries']['controls'][i + 1].controls[oppositeField].setValue('');
    //         }
    //         this.createItrRequestFrom.controls['itineraries']['controls'][i].controls[field].setErrors({ nooriginfound: true });
    //         let airportType = (field === 'from') ? 'originCode' : 'destinationCode';
    //         let intRequestType = (field === 'from') ? 'isOriginInternational' : 'isDestinationInternational';
    //         tempFlightArr[0].details[0][airportType] = '';
    //         this[intRequestType] = this.isInternational(tempFlightArr);
    //     }

    // }

    // checkValidAirport(airport: string, field: string, i: any) {
    //     let itinerariesArray = this.createItrRequestFrom.controls['itineraries']['controls'][i].controls;
    //     let itinerariesFieldValue = this.createItrRequestFrom.value?.itineraries[i];
    //     let tempFlightArr = [];
    //     let details = [
    //         {
    //             "originCode": "",
    //             "destinationCode": ""
    //         }
    //     ];
    //     tempFlightArr.push({ details: details });
    //     if (field === 'from' || field === 'to') {
    //         itinerariesArray[field].setErrors(null);
    //         if (this.grmsUtils.isNotNull(airport) && this.grmsUtils.isNotNull(itinerariesFieldValue[field])) {
    //             let returnType = (field === 'from') ? 'to' : 'from';
    //             if (airport.trim().toLowerCase() === itinerariesFieldValue[returnType].trim().toLowerCase()) {
    //                 itinerariesArray[field].setErrors({ tripMismatch: true });
    //             }
    //             else {
    //                 itinerariesArray[field].setErrors(null);
    //                 this.createItrRequestFrom.controls['itineraries']['controls'][i].controls[field].setValue(airport);
    //                 if (this.activeTripType === 'Round Trip') {
    //                     let oppositeField = (field === 'from') ? 'to' : 'from';
    //                     this.createItrRequestFrom.controls['itineraries']['controls'][i + 1].controls[oppositeField].setValue(airport);
    //                 }

    //                 let airportType = (field === 'from') ? 'originCode' : 'destinationCode';
    //                 tempFlightArr[0].details[0][airportType] = this.createItrRequestFrom.controls['itineraries']['controls'][i].controls[field].value;
    //                 if (tempFlightArr[0].details[0][airportType]) {
    //                     let intRequestType = (field === 'from') ? 'isOriginInternational' : 'isDestinationInternational';
    //                     this[intRequestType] = this.isInternational(tempFlightArr);
    //                 }

    //             }

    //         }
    //     }

    // }

    validateForm(fieldname, i?) {
        let errorControls = this.createItrRequestFrom.controls[fieldname];

        switch (fieldname) {
            case 'groupName':
                return errorControls.errors?.required ? this.validationMessage.BASICINFO_FORM.GROUPNAME_MANDATORY :
                    errorControls.errors?.pattern ? ["First and Last character not be space"] : '';
            case 'corporateName':
                return errorControls.errors?.required ? this.validationMessage.WIDGET_FORM.CORPORATENAME : '';
            case 'corporateCode':
                return errorControls.errors?.required ? this.validationMessage.WIDGET_FORM.CORPORATECODE : '';
            case 'totalCount':
                return errorControls.errors?.required ? this.validationMessage.WIDGET_FORM.PASSENGER_MANDATORY :
                    errorControls.errors?.classNotSelected ? this.validationMessage.WIDGET_FORM.TRIPCLASS_MANDATORY : errorControls.errors?.paxCountMismatch ? this.Paxcount_MisMatch : '';
            case 'from':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.ORIGIN_MANDATORY :
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.tripMismatch ? this.validationMessage.WIDGET_FORM.TRIP_MISMATCH :
                        this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.nooriginfound ? this.validationMessage.WIDGET_FORM.NOORIGIN_MATCHES : '';
            case 'to':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.DESTINATION_MANDATORY :
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.tripMismatch ? this.validationMessage.WIDGET_FORM.TRIP_MISMATCH :
                        this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.nodestinationfound ? this.validationMessage.WIDGET_FORM.NODESTINATION_MATCHES : '';
            case 'flightNumber':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.FLIGHTNO_MANDATORY :
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.previousFlightnoNotFound ? this.validationMessage.WIDGET_FORM.PRE_FLIGHTNOTSELECTED : '';

            case 'departureDate':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.DEPARTUREDATE_MANDATORY :
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.previousDateNoFound ? this.validationMessage.WIDGET_FORM.PRE_DEPARTUREDATENOTSELECTED : '';
            case 'classOfTravel':
                return this.createItrRequestFrom['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.TRIPCLASS_MANDATORY : "";
            case 'flightTime':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.FLIGHTTIME_MANDATORY : "";
            case 'travelDuration':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.TRAVELDURATION_MANDATORY : "";
            case 'arrivalTime':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.ARRIVALTIME_MANDATORY : "";
            case 'arrivalDate':
                return this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.ARRIVALDATE_MANDATORY : "";
            case 'termsCondition':
                return this.createItrRequestFrom.controls[fieldname].errors?.required ? this.validationMessage.WIDGET_FORM.TERMS_MANDATORY : "";

            default:
                return null;
        }
    }

    updateValidation() {
        const groupType = this.createItrRequestFrom.get('groupType');
        const corporateName = this.createItrRequestFrom.get('corporateName');
        const corporateCode = this.createItrRequestFrom.get('corporateCode');
        if (groupType.value === null) {
            corporateName.setValidators(null);
            corporateName.setValue(null);
            corporateName.setErrors(null);
            corporateName.disable();
        }
        groupType.valueChanges.subscribe(value => {
            if (!!value) {
                if (value == 'Personal') {
                    corporateName.setValidators(null);
                    corporateName.setErrors(null);
                    corporateName.setValue(null);
                    corporateName.disable();
                    corporateCode.setValidators(null);
                    corporateCode.setErrors(null);
                    corporateCode.setValue(null);
                    corporateCode.disable();
                } else {
                    corporateName.setValidators([Validators.required]);
                    corporateName.enable();
                    corporateCode.setValidators([Validators.required]);
                    corporateCode.enable();
                    corporateName.updateValueAndValidity();

                }
            }
        })
    }

    classTravelChange(event) {
        if (event.value == "Premium Economy") {
            if (this.activeTripType == "One Way") {
                this.paxLimit.min = 1;
                this.paxLimit.max = 36;
            } else {
                this.paxLimit.min = 10;
                this.paxLimit.max = 24;
            }

        } else if (event.value == 'Business') {
            if (this.activeTripType == "One Way") {
                this.paxLimit.min = 1;
                this.paxLimit.max = 30;
            } else {
                this.paxLimit.min = 4;
                this.paxLimit.max = 12;
            }
        } else {
            if (this.activeTripType == "One Way") {
                this.paxLimit.min = 1;
                this.paxLimit.max = 99;
            } else {
                this.paxLimit.min = 10;
                this.paxLimit.max = 99;
            }
        }
        this.createItrRequestFrom.patchValue({
            totalCount: `${this.paxLimit.min}  (${this.paxLimit.min} Adult 0 Child  0 Infant)`,
            groupSize: `${this.paxLimit.min}`,
            noOfAdult: `${this.paxLimit.min}`,
            noOfChild: `0`,
            noOfInfant: `0`
        });
        this.itinerariesReset()

    }
    itinerariesReset() {
        for (let i = 0; i < this.createItrRequestFrom.value['itineraries'].length; i++) {
            this.fieldChangeReset(i)
        }
    }
    openPaxDialog() {
        let popAdult = this.createItrRequestFrom.value.noOfAdult > 0 ? this.createItrRequestFrom.value.noOfAdult : this.paxLimit.min;
        let popChild = this.createItrRequestFrom.value.noOfChild > 0 ? this.createItrRequestFrom.value.noOfChild : 0;
        let noOfInfant = this.createItrRequestFrom.value.noOfInfant > 0 ? this.createItrRequestFrom.value.noOfInfant : 0;
        this.paxInfo = { adult: popAdult, child: popChild, infant: noOfInfant };
        if (!!this.createItrRequestFrom.value?.classOfTravel) {
            let checkAirportCode = this.createItrRequestFrom.value['itineraries']
            if (this.activeTripType == "One Way" && this.createItrRequestFrom.value['classOfTravel'] == "Premium Economy") {
                if (checkAirportCode[0]['from'] == 'CDG'
                    || checkAirportCode[0]['from'] == 'LHR'
                    || checkAirportCode[0]['to'] == 'CDG'
                    || checkAirportCode[0]['to'] == 'LHR') {
                    this.paxLimit.min = 1;
                    this.paxLimit.max = 21;
                }
                else if (checkAirportCode[0]['from'] == 'FRA' || checkAirportCode[0]['to'] == 'FRA') {
                    this.paxLimit.min = 1;
                    this.paxLimit.max = 36;
                } else {
                    this.paxLimit.min = 1;
                    this.paxLimit.max = 24;
                }
            }
            else if (this.activeTripType == "One Way" && this.createItrRequestFrom.value['classOfTravel'] == "Business") {
                if (checkAirportCode[0]['from'] == 'CDG' || checkAirportCode[0]['from'] == 'LHR' || checkAirportCode[0]['from'] == 'FRA' || checkAirportCode[0]['to'] == 'CDG' || checkAirportCode[0]['to'] == 'LHR' || checkAirportCode[0]['to'] == 'FRA') {
                    this.paxLimit.min = 1;
                    this.paxLimit.max = 30;
                } else {
                    this.paxLimit.min = 1;
                    this.paxLimit.max = 12;
                }
            }
            let dialogRef = this.matDialog.open(PaxPopupFormComponent, {
                disableClose: true,
                width: '31%',
                data: { paxInfo: this.paxInfo, classOfTravel: this.createItrRequestFrom.value?.classOfTravel, paxLimit: this.paxLimit }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                if (result?.action !== "cancel") {
                    this.itinerariesReset()
                }
                const noOfPassengers = parseInt(result?.data?.adult) + parseInt(result?.data?.child);
                this.paxInfo = result?.data;

                this.createItrRequestFrom.patchValue({
                    totalCount: `${noOfPassengers}  (${result?.data?.adult} Adult ${result?.data?.child} Child ${result?.data?.infant} Infant)`,
                    groupSize: `${noOfPassengers} `,
                    noOfAdult: `${result?.data?.adult}`,
                    noOfChild: `${result?.data?.child}`,
                    noOfInfant: `${result?.data?.infant}`
                });

                dialogRef = null;
            });
        } else {

            this.createItrRequestFrom.controls.classOfTravel.markAsTouched()
            this.createItrRequestFrom.controls.classOfTravel.setErrors({ required: true })
            this.createItrRequestFrom.controls.totalCount.setErrors({ classNotSelected: true });
        }
    }

    searchItineraryInfo(i: any) {
        let invalidField = ['from', 'to', 'departureDate'];

        if (!this.flightSearchActive) {
            let checkItineraryInvalid = this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'];
            if (this.filteredAirports.from.length === 0) {
                this.snackBar.open('Please provide valid origin & destination to search flights', 'X', { duration: 3000 });
                return false;
            } else if (this.filteredAirports.to.length === 0) {
                this.snackBar.open('Please provide valid Destination to search flights', 'X', { duration: 3000 });
                return false;
            }
            invalidField.forEach((el) => {
                if (this.createItrRequestFrom.value['classOfTravel'] == null) {
                    this.createItrRequestFrom.controls['classOfTravel'].markAsTouched()
                }
                if (!checkItineraryInvalid[el].valid) {
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls'][el].markAsTouched()
                }
            });

            if (i > 0) {
                if (this.createItrRequestFrom.controls['itineraries']['controls'][i - 1]['controls']['flightNumber'] == null) {
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['flightNumber'].markAsTouched()
                    this.createItrRequestFrom.controls['itineraries']['controls'][i]['controls']['flightNumber'].setErrors({ previousFlightnoNotFound: true })
                } else {
                    if (!!this.createItrRequestFrom.value['classOfTravel'] && !!checkItineraryInvalid['from'].valid && !!checkItineraryInvalid['to'].valid && !!checkItineraryInvalid['departureDate'].valid) {
                        this.flightSearchActive = true;
                        this.doCheckFlight(i)
                    }
                }
            } else {
                if (!!this.createItrRequestFrom.value['classOfTravel'] && !!checkItineraryInvalid['from'].valid && !!checkItineraryInvalid['to'].valid && !!checkItineraryInvalid['departureDate'].valid) {
                    let checkAirportCode = this.createItrRequestFrom.value['itineraries']
                    if (this.activeTripType == "One Way" && this.createItrRequestFrom.value['classOfTravel'] == "Premium Economy") {
                        if (checkAirportCode[i]['from'] == 'CDG'
                            || checkAirportCode[i]['from'] == 'LHR'
                            || checkAirportCode[i]['to'] == 'CDG'
                            || checkAirportCode[i]['to'] == 'LHR') {
                            if (this.createItrRequestFrom.value.groupSize >= 1 && this.createItrRequestFrom.value.groupSize <= 21) {
                                this.flightSearchActive = true;
                                this.doCheckFlight(i)
                            } else {
                                this.orginRestriction(1, 21)
                            }
                        } else if (checkAirportCode[i]['from'] == 'FRA' || checkAirportCode[i]['to'] == 'FRA') {
                            if (this.createItrRequestFrom.value.groupSize >= 1 && this.createItrRequestFrom.value.groupSize <= 36) {
                                this.flightSearchActive = true;
                                this.doCheckFlight(i)
                            } else {
                                this.orginRestriction(1, 36)
                            }
                        } else if (this.createItrRequestFrom.value.groupSize >= 1 && this.createItrRequestFrom.value.groupSize <= 24) {
                            this.flightSearchActive = true;
                            this.doCheckFlight(i)
                        } else {
                            this.orginRestriction(1, 24)
                        }


                    } else if (this.activeTripType == "One Way" && this.createItrRequestFrom.value['classOfTravel'] == "Business") {
                        if (checkAirportCode[i]['from'] == 'CDG' || checkAirportCode[i]['from'] == 'LHR' || checkAirportCode[i]['from'] == 'FRA' || checkAirportCode[i]['to'] == 'CDG' || checkAirportCode[i]['to'] == 'LHR' || checkAirportCode[i]['to'] == 'FRA') {
                            if (this.createItrRequestFrom.value.groupSize >= 1 && this.createItrRequestFrom.value.groupSize <= 30) {
                                this.flightSearchActive = true;
                                this.doCheckFlight(i)
                            } else {
                                this.orginRestriction(1, 30)
                            }

                        } else if (this.createItrRequestFrom.value.groupSize >= 1 && this.createItrRequestFrom.value.groupSize > 12) {
                            this.orginRestriction(1, 12)
                        }
                        else {
                            this.flightSearchActive = true;
                            this.doCheckFlight(i)
                        }

                    } else {
                        this.flightSearchActive = true;
                        this.doCheckFlight(i)
                    }

                }
            }
        }
        this.flightSearchActive = false;
    }

    orginRestriction(min, max) {
        this.paxLimit.min = min;
        this.paxLimit.max = max;
        this.Paxcount_MisMatch = `kindly select minimum ${this.paxLimit.min} pax  and maximum ${this.paxLimit.max} pax `;
        this.createItrRequestFrom.controls.totalCount.markAsTouched()
        this.createItrRequestFrom.controls.totalCount.setErrors({ required: true })
        this.createItrRequestFrom.controls.totalCount.setErrors({ paxCountMismatch: true });
    }

    checkLeapYear(year) {
        if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
            return true;
        } else {
            return false;
        }
    }

    doCheckFlight(i) {
        let currentDate = new Date();
        let flightSearchedDate = new Date(this.createItrRequestFrom.value.itineraries[i]['departureDate']);
        let isLeapYear = this.checkLeapYear(flightSearchedDate.getFullYear());
        const diffTime = Math.abs(flightSearchedDate.getTime() - currentDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if ((isLeapYear && diffDays < 366) || (!isLeapYear && diffDays < 365)) {
            this.loaderService.show();
            let searchFlights = {
                "endDate": this.datePipe.transform(this.createItrRequestFrom.value.itineraries[i]['departureDate'], "dd-MMM-yyyy"),
                "flightDate": this.datePipe.transform(this.createItrRequestFrom.value.itineraries[i]['departureDate'], "dd-MMM-yyyy"),
                "departureStation": this.createItrRequestFrom.value.itineraries[i]['from'],
                "arrivalStation": this.createItrRequestFrom.value.itineraries[i]['to'],
                "cabinclass": this.createItrRequestFrom.value.classOfTravel,
                "session": null,
                "searchReq": null
            }

            this.httpService.postCall(BOOKING_API_ENPOINTS.searchFlights, searchFlights, this.grmsUtils.getAuthHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        if (!!response?.flightInfoList == false || (response?.flightInfoList[0].endOfSearch == true && Object.keys(response?.flightInfoList[0].flightList).length <= 0)) {
                            this.snackBar.open('No flights found', "x", { duration: 3000 });
                        } else {
                            if (!!response?.flightInfoList === false || (response?.flightInfoList[0].endOfSearch === false && Object.keys(response?.flightInfoList[0].flightList).length <= 0)
                                && this.grmsUtils.isNotNull(this.tripItineraryInfo[i])) {
                                this.tripItineraryInfo[i].details = [];
                            }
                            if (i > 0) {
                                let previousInfo = {
                                    preArrivalDate: moment(new Date(this.createItrRequestFrom.value['itineraries'][i - 1].arrivalDate)).format('YYYY-MM-DD'),
                                    preArrivalTime: this.createItrRequestFrom.value['itineraries'][i - 1].arrivalTime,
                                    curDepartureDate: moment(new Date(this.createItrRequestFrom.value['itineraries'][i].departureDate)).format('YYYY-MM-DD'),
                                }
                                this.openFlightListDialog(searchFlights, response, i, previousInfo)
                            } else {
                                this.openFlightListDialog(searchFlights, response, i)
                            }
                            this.flightSearchActive = false;
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                })
        } else {
            this.snackBar.open("Flight search should be less than 1 year", "x", { duration: 3000 });
        }

    }

    fieldChangeReset(i) {
        this.createItrRequestFrom.controls['itineraries']['controls'][i].controls['flightNumber'].setValue(null);
        this.createItrRequestFrom.controls['itineraries']['controls'][i].controls['travelDuration'].setValue(null);
        this.createItrRequestFrom.controls['itineraries']['controls'][i].controls['arrivalTime'].setValue(null);
        this.createItrRequestFrom.controls['itineraries']['controls'][i].controls['arrivalDate'].setValue(null);
        this.createItrRequestFrom.controls['itineraries']['controls'][i].controls['flightTime'].setValue(null);
        this.tripItineraryInfo[i] = '';
        //this.classTravelChange({value:this.createItrRequestFrom.value['classOfTravel']})
    }

    openFlightListDialog(requestData, responseData, i, previousInfo?) {
        let Index = i
        let dialogRef = this.matDialog.open(SearchFlightListComponent, {
            disableClose: true,
            width: '80%',
            height: '50%',
            data: { flightResponse: responseData, flightRequest: requestData, previousInfo: previousInfo }
        });
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            let isconData: boolean = false;
            if (!!result?.data.details) {
                result?.data.details.map((conData) => {
                    conData['startIndex'] = Index;
                    isconData = true;
                })
            } else {
                result.data['startIndex'] = Index;
                isconData = false;
            }

            this.tripItineraryInfo[Index] = result.data;
            this.createItrRequestFrom.controls['itineraries']['controls'][Index]['controls']['flightNumber'].setValue(result.data.flightNo);
            this.createItrRequestFrom.controls['itineraries']['controls'][Index]['controls']['arrivalTime'].setValue(result.data.arrivalTime)
            this.createItrRequestFrom.controls['itineraries']['controls'][Index]['controls']['arrivalDate'].setValue(this.datePipe.transform(result.data.arrivalDate, "MM/dd/yyyy"))
            this.createItrRequestFrom.controls['itineraries']['controls'][Index]['controls']['flightTime'].setValue(result.data.departureTime);
            this.createItrRequestFrom.controls['itineraries']['controls'][Index]['controls']['travelDuration'].setValue(result.data.travelDuration);
            if (this.createItrRequestFrom.value.tripType == 'One Way') {
                this.paxRestriction(i, isconData)
            }

        })
    }

    paxRestriction(i, isconData?) {
        let paxRestrictionInfo = {
            'rulesRequestDetails': {
                'origin': this.createItrRequestFrom.value.itineraries[i].from,
                'destination': this.createItrRequestFrom.value.itineraries[i].to,
                'flightNumber': this.createItrRequestFrom.value.itineraries[i].flightNumber,
                'cabinClass': this.createItrRequestFrom.value.classOfTravel,
                'createdDate': this.datePipe.transform(new Date(), "dd-MMM-yyyy"),
                'departureDate': this.datePipe.transform(this.createItrRequestFrom.value.itineraries[i].departureDate, "dd-MMM-yyyy"),
                'tripType': this.createItrRequestFrom.value.tripType
            }
        }
        this.httpService.postCall(BOOKING_API_ENPOINTS.paxRestriction, paxRestrictionInfo, this.grmsUtils.getAuthMultiHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    let minGroupSizeOneWay = response.paxRestrictionDetails.minGroupSize;
                    //parseInt(this.createItrRequestFrom.value.noOfAdult) + parseInt(this.createItrRequestFrom.value.noOfChild)
                    if (Number(this.createItrRequestFrom.value.groupSize.trim()) < minGroupSizeOneWay ||
                        Number(this.createItrRequestFrom.value.groupSize.trim()) > minGroupSizeOneWay) {

                        if (response.paxRestrictionAvailable == "Y") {
                            this.snackBar.open(`Could not able to select this flight for this pax size.`, 'X', { duration: 3000 })
                        } else {
                            this.snackBar.open(`Could not able to select this flight as minimum pax size is ${minGroupSizeOneWay}.`, 'X', { duration: 3000 })
                        }
                        this.fieldChangeReset(i)
                    }
                    if (isconData === true && this.createItrRequestFrom.value['classOfTravel'] == "Business" && this.createItrRequestFrom.value.groupSize > 12) {
                        this.fieldChangeReset(i)
                        this.snackBar.open(`Could not able to select this flight as maximum pax size is 12.`, 'X', { duration: 3000 })
                    }
                    if (isconData === true && this.createItrRequestFrom.value['classOfTravel'] == "Premium Economy" && this.createItrRequestFrom.value.groupSize > 24) {
                        this.fieldChangeReset(i)
                        this.snackBar.open(`Could not able to select this flight as maximum pax size is 24.`, 'X', { duration: 3000 })
                    }

                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })
    }

    isInternational(tempFlightList?): boolean {
        let itineraryDetails = (tempFlightList && tempFlightList.length > 0) ? tempFlightList : this.tripItineraryInfo;
        for (let itinerary of itineraryDetails) {
            let getItinerary = !!itinerary.details ? itinerary.details : [itinerary];
            for (let innerItinerary of getItinerary) {
                if (innerItinerary.originCode != undefined && innerItinerary.originCode != '') {
                    let cc = this.airportsList[0].find(x => x.airportCode == innerItinerary.originCode)?.countryCode;
                    if (cc != 'IN') {
                        this.intRequest = true;
                        return true;
                    }
                }
                if (innerItinerary.destinationCode != undefined && innerItinerary.destinationCode != '') {
                    let cc = this.airportsList[0].find(x => x.airportCode == innerItinerary.destinationCode)?.countryCode;
                    if (cc != 'IN') {
                        this.intRequest = true;
                        return true;
                    }
                }
            }

        }
        return false;
    }

    requestFormSubmit() {
        this.loaderService.show();
        if (!!this.createItrRequestFrom.valid) {
            let formValue = this.createItrRequestFrom.value;
            let generalData = this.createRequest.requestgeneralData;
            let itineraryData = this.createRequest.itinerayDetails;
            let itineraryCount = 0
            for (let i = 0; i < this.tripItineraryInfo.length; i++) {
                if (this.tripItineraryInfo[i].details) {
                    for (let j = 0; j < this.tripItineraryInfo[i].details.length; j++) {
                        this.tripItineraryInfo[i]['details'][j]['itineraryno'] = `${itineraryCount}1`;
                        itineraryCount++
                    }
                } else {
                    this.tripItineraryInfo[i]['itineraryno'] = `${itineraryCount}1`
                    itineraryCount++
                }
            }
            let userDetail = this.grmsUtils.getUserDetails()


            this.createRequest.loginUserID = userDetail.loginUserId;
            this.createRequest.isInternational = this.isInternational()
            this.createRequest.remarks = this.isEmptyOrSpaces(formValue.remark) ? "" : formValue.remark;
            this.createRequest.notifyOthersRequester = this.isEmptyOrSpaces(formValue.notifyOthers) ? "" : formValue.notifyOthers;

            generalData.flexibleTravelDates = formValue.flexibleTravelDate != null ? 'Y' : 'N';
            generalData.groupType = formValue.groupType;
            generalData.fdb = false;
            generalData.tripType = formValue.tripType;
            generalData.noOfAdult = formValue.noOfAdult;
            generalData.noOfChild = formValue.noOfChild;
            generalData.noOfInfant = formValue.noOfInfant;
            generalData.finalQuote = false;
            generalData.groupsizelimit = this.paxLimit.min;
            generalData.notifyOthersRequester = this.isEmptyOrSpaces(formValue.notifyOthers) ? "" : formValue.notifyOthers;

            generalData.groupName = this.isEmptyOrSpaces(formValue.groupName) ? "" : formValue.groupName;
            generalData.tourName = this.isEmptyOrSpaces(formValue.tourName) ? "" : formValue.tourName;
            !!formValue.corporateName ? generalData.companyName = formValue.corporateName : '';
            !!formValue.corporateCode ? generalData.corporateCode = formValue.corporateCode : '';
            generalData.expectedFarePerPax = Number(formValue.expectedQuote);
            generalData.groupSize = parseInt(formValue.noOfAdult) + parseInt(formValue.noOfChild);

            this.tripItineraryInfo.map((el) => {
                if (!!el.details) {
                    el.details.map((inEl) => {
                        itineraryData.push({
                            'showCalendar': false,
                            'from': inEl.originCode,
                            "flightNumber": inEl.flightNo,
                            'to': inEl.destinationCode,
                            "departureDate": this.datePipe.transform(inEl.departureDate, "dd-MMM-yyyy"),
                            "travelDuration": el.travelDuration,
                            "arrivalDate": this.datePipe.transform(inEl.arrivalDate, "dd-MMM-yyyy"),
                            "sdt": new Date(),
                            "flightTime": `${inEl.departureTime}-${inEl.arrivalTime}`,
                            "NoOfStops": el.stops,
                            "stops": inEl.startIndex,
                            "itineraryno": inEl.itineraryno,
                            "classOfTravel": formValue.classOfTravel,
                            'PopOverDetails': []

                        })
                    })
                } else {
                    itineraryData.push({
                        'showCalendar': false,
                        'from': el.originCode,
                        "flightNumber": el.flightNo,
                        'to': el.destinationCode,
                        "departureDate": this.datePipe.transform(el.departureDate, "dd-MMM-yyyy"),
                        "travelDuration": el.travelDuration,
                        "arrivalDate": this.datePipe.transform(el.arrivalDate, "dd-MMM-yyyy"),
                        "sdt": new Date(),
                        "flightTime": `${el.departureTime}-${el.arrivalTime}`,
                        "NoOfStops": el.stops,
                        "stops": el.startIndex,
                        "itineraryno": el.itineraryno,
                        "classOfTravel": formValue.classOfTravel,
                        'PopOverDetails': []
                    })
                }
            });
            if (this.createRequest.itinerayDetails.length === 0) {
                this.snackBar.open("Please select flight for all itinerary", "x", { duration: 3000 });
                return false;
            }

            this.httpService.postCall(BOOKING_API_ENPOINTS.createRequest, this.createRequest, this.grmsUtils.getAuthHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response) => {
                        this.loaderService.hide();
                        if (response['statusCode'] === 501) {
                            this.createItrRequestFrom.reset({
                                groupType: 'Business',
                                tripType: 'Round Trip',
                                totalCount: `${this.paxLimit.min}  (${this.paxLimit.min} Adult, 0 Child, 0 Infant)`,
                            }
                            );
                            this.createRequest.itinerayDetails = []
                            this.tripItineraryInfo = []
                            this.snackBar.open(`${response['statusMsg']}`, 'X', { duration: 3000 });
                            return;
                        } else {
                            this.createItrRequestFrom.reset();
                            this.createBooking();
                            let dialogRef = this.matDialog.open(PopOverContentComponent, {
                                disableClose: true,
                                width: '55%',
                                height: 'auto',
                                data: { data: response['requestDetails'].requestId, action: 'create' }
                            });
                            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

                                this.createItrRequestFrom.reset();
                                this.createItrRequestFrom.get('tripType').setValue('Round Trip');
                                this.createItrRequestFrom.get('groupType').setValue('Business');
                                this.onradioButtonChange({ 'value': 'Round Trip' })
                                this.tripItineraryInfo = []


                            })
                        }
                    },
                    error: (errorDetails) => {
                        // this.createRequest.itinerayDetails = []
                        this.tripItineraryInfo = []
                        this.loaderService.hide();
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                })


        } else {
            this.loaderService.hide();
            this.snackBar.open('Kindly select all required field', 'X', { duration: 3000 })
            this.createItrRequestFrom.markAllAsTouched();

        }
    }

    isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    downloadAttachment(id: any, attchName: any, attchType: any) {
        this.httpService.getCall(REPORT_API_ENDPOINTS.downloadFile + id, this.grmsUtils.getFileJSONHeader(attchType))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    let blob: any = new Blob([response]);
                    FileSaver.saveAs(blob, attchName);

                },
                error: (errorDetails) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    checkValidNotifyEmailsForRequester() {
        let emails = this.createItrRequestFrom.controls.notifyOthers.value;
        if (emails === undefined || emails === "") {
            return true;
        }
        let emailList = emails.split(",");
        for (let email of emailList) {

            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(email)) {
                this.notifyEmailError = "Please provide valid email Id"
                return false;
            } else {
                this.notifyEmailError = '';
            }
        }
    }

    canDeactivate() {
        return !!this.createItrRequestFrom.dirty ? true : false;
        // if (this.createItrRequestFrom.dirty) {
        //     return true;
        // } else {

        //     return false;
        // }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
