import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Directive({
    selector: '[currencyFormat]'
})
export class CurrencyFormatDirective implements OnInit {

    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private currencyPipe: CurrencyPipe
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.el.value = this.currencyPipe.transform(this.el.value, 'INR');
    }

    @HostListener('focus', ['$event.target.value'])
    onFocus(value) {
        this.el.value = value.replace(/[^0-9.]+/g, '');
        this.el.select();
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        this.el.value = this.currencyPipe.transform(value, 'INR');
    }

    @HostListener('keydown.control.z', ['$event.target.value'])
    onUndo(value) {
        this.el.value = '';
    }

}
