import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: 'gb-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

    constructor() {

    }
    ngOnInit(): void {

    }
    ngOnDestroy() {

    }
}
