import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { SideNavBarService } from 'src/app/shared/services/side-nav-bar.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';

@Component({
    selector: 'app-vistara-logout',
    templateUrl: './vistara-logout.component.html',
    styleUrls: ['./vistara-logout.component.scss']
})
export class VistaraLogoutComponent implements OnInit {

    public redirect_url = environment.VISTARA_URL;
    constructor(
        private cookieService: CookieService,
        private sideNavBar: SideNavBarService,
        private userProfileService: UserProfileService,
    ) { }

    ngOnInit(): void {
        this.sideNavBar.hideSideNavBar();
        this.sideNavBar.showMenu();
        this.userProfileService.setUsername();
        this.userProfileService.setUserRole();
        this.userProfileService.setVistaraUserFlag();
        sessionStorage.removeItem('auth_token');
        sessionStorage.removeItem('login_type');
        this.cookieService.delete('auth_token', '/');
        this.cookieService.delete('login_time', '/');
        this.cookieService.delete('userDetails', '/');
        this.cookieService.delete('refresh_token', '/');
        this.cookieService.delete('currentMenu', '/');
    }

}
