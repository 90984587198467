import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[alphabetsOnly]'
})
export class AlphabetsOnlyDirective {

    constructor(private _el: ElementRef) { }

    key;
    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
        // this.key = event.keyCode;
        // if ((this.key >= 15 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 105)) {
        //     event.preventDefault();
        // }
        var regex = new RegExp("^[a-zA-Z ]+$");
        if (!regex.test(event.key)) {
            event.preventDefault();
            return false;
        }
    }
}
