import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import { LoaderService } from '../services/gb-loader.services';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})

export class DownloadUtil {
    constructor(
        private loaderService: LoaderService,
    ) { }

    public exportAsExcelFile(json: any[], columnDefs: any[], excelFileName: string): void {

        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, columnDefs);
        XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, excelFileName + '.xlsx');
        this.loaderService.hide();
    }

    public exportExcelWithHighlight(json: any[], columnDefs: any[], errorType: string, excelFileName: string): void {
        const tempArr = [];
        const workBook = new Workbook();
        const workSheet = workBook.addWorksheet('test');
        const headerNames = columnDefs[0];
        let headerLength = (errorType === 'isFileError') ? headerNames.length - 1 : headerNames.length;
        for (let i = 0; i < headerLength; i++) {
            tempArr.push(headerNames[i]);
        }
        workSheet.addRow(tempArr);
        json.forEach((item) => {
            let dummyArr = [];
            for (let j = 0; j < tempArr.length; j++) {
                dummyArr.push(item[tempArr[j]]);
            }
            const row = workSheet.addRow(dummyArr);
            let cellLength = (errorType === 'isFileError') ? json.length + 1 : json.length;
            for (let k = 1; k < cellLength; k++) {
                const col = row.getCell(k);
                if (errorType === 'isFileError') {
                    col.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: +item.unique ? '70AD47' : 'ff2600' }
                    };
                }
                col.border = {
                    top: { style: 'thin', color: { argb: '000' } },
                    left: { style: 'thin', color: { argb: '000' } },
                    bottom: { style: 'thin', color: { argb: '000' } },
                    right: { style: 'thin', color: { argb: '000' } }
                };
            }
        });
        workBook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], {
                type: EXCEL_TYPE
            });
            const fileName = excelFileName + EXCEL_EXTENSION;
            FileSaver.saveAs(blob, fileName);
        })
    }
}
