import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserModule, } from '@angular/platform-browser';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { GBMaterialModule } from './gb-material.module';
import { LoginComponent } from './home/login/login.component';
import { LogoutComponent } from './home/logout/logout.component';
import { LoaderComponent } from './generic/loader/loader.component';
import { HeaderComponent } from './generic/header/header.component';
import { LoaderService } from './shared/services/gb-loader.services';
import { RefreshComponent } from './generic/refresh/refresh.component';
import { RegisterComponent } from './home/register/register.component';
import { GBCreateRequestModule } from './create-request/create-request.module';
import { SideNavBarComponent } from './generic/side-nav-bar/side-nav-bar.component';
import { ChangePasswordComponent } from './home/change-password/change-password.component';
import { VistaraLoginComponent } from './home/login/vistara-login/vistara-login.component';
import { ForgetPasswordComponent } from './home/forget-password/forget-password.component';
import { VistaraLogoutComponent } from './home/logout/vistara-logout/vistara-logout.component';
import { RegisterModelComponent } from './home/register/register-model/register-model.component';
import { PasswordPolicyComponent } from './home/login/password-policy/password-policy.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        HeaderComponent,
        LoaderComponent,
        LogoutComponent,
        RefreshComponent,
        RegisterComponent,
        SideNavBarComponent,
        VistaraLoginComponent,
        VistaraLogoutComponent,
        RegisterModelComponent,
        ChangePasswordComponent,
        PasswordPolicyComponent,
        ForgetPasswordComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        GBMaterialModule,
        SharedModule,
        NgxCaptchaModule,
        GBCreateRequestModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false },
        },
        LoaderService
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
