import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { COMMON_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';

@Component({
    selector: 'app-vistara-login',
    templateUrl: './vistara-login.component.html',
    styleUrls: ['./vistara-login.component.scss']
})
export class VistaraLoginComponent implements OnInit {

    public redirect_url = environment.VISTARA_URL;
    public resource = environment.resource;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public refresh_token_expiration_time_secs = 1 * 60 * 60;
    public grant_type_code = "authorization_code";
    public grant_type_refresh = "refresh_token";
    public clientId = environment.client_id;
    public app_group = "";
    public code = "";
    public error = "";

    public adfs_authorize = "https://federation-uat.airvistara.com/adfs/oauth2/authorize?response_type=code&client_id=" + this.clientId + "&resource=" + this.resource + "&redirect_uri=" + this.redirect_url;

    constructor(
        private router: Router,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private activatedRoute: ActivatedRoute,
        private userProfileService: UserProfileService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.code = params['code'];
            this.error = params['error'];
        });
    }

    getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(userDetails) {
        let userData = this.getUserdetails(userDetails);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + 1000 * userDetails.oAuthExpiresIn);
        let loginTime = moment(new Date(), 'YYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A');
        this.cookieService.set("login_time", loginTime, expiryDate, '/', '', false, "Lax");
        this.cookieService.set("currentMenu", '', expiryDate, '/', '', false, "Lax");
        this.cookieService.set("auth_token", userDetails.oAuthAccessToken, expiryDate, '/', '', false, "Lax");
        this.cookieService.set("userDetails", JSON.stringify(userData), expiryDate, '/', '', false, "Lax");
        this.cookieService.set("refresh_token", userDetails.oAuthRefreshToken, expiryDate, '/', '', false, "Lax");
        sessionStorage.setItem("auth_token", userDetails.oAuthAccessToken);
        sessionStorage.setItem("login_type", userDetails.login_type);
        this.userProfileService.setUsername();
        this.userProfileService.setUserRole();
        this.userProfileService.setVistaraUserFlag();
        this.userProfileService.setExistingUserFlag();
        return true;
    }

    getUserdetails(userDetails) {
        let userData = {};
        userData['user_role'] = userDetails.payload.role;
        userData['username'] = userDetails.payload.user_name + '@airvistara.com';
        userData['lastname'] = userDetails.payload.last_name;
        userData['firstname'] = userDetails.payload.first_name;
        userData['travelAgent'] = "";
        userData['login_type'] = userDetails.userLoginType;
        userData['existingUser'] = userDetails.payload.existingUser;
        return userData;
    }

    login() {
        this.loaderService.hide();
        window.location.href = this.adfs_authorize;
    }
    unAuthorized() {
        this.router.navigate(['/']);
    }

    ngOnInit() {
        this.loaderService.show();
        let payload = '';
        if (this.error != null && this.error != '') {
            this.snackBar.open("User Not Found", 'X', { duration: 3000 });
            this.loaderService.hide();
            this.unAuthorized();
        } else if (this.code != null && this.code != '') {
            payload = JSON.stringify({
                client_id: this.clientId,
                grant_type: this.grant_type_code,
                code: this.code,
                redirect_uri: this.redirect_url,
                resource: this.resource
            });
            this.requestServiceCall(payload);
        } else {
            this.login();
        }
    }

    requestServiceCall(payload) {
        this.httpService.postCall(COMMON_API_ENDPOINTS.token_URL, payload, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    let data: any = response;
                    if (data.access_token != null && data.access_token != '') {
                        this.setCookie(data);
                        if (data.payload.role === "ROLE_AGENT" || response.payload.role === "ROLE_USER" || response.payload.role === "ROLE_SUB_AGENT") {
                            this.router.navigate(['/request/create']);
                        } else {
                            this.router.navigate(['/request']);
                        }
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}
