import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GBBookingService } from '../../../shared/services/booking/gb-booking.service';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'flight-listtable',
    templateUrl: './flight-search-table.component.html',
    styleUrls: ['./flight-search-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class flightSearchComponent implements OnInit, AfterViewInit {
    @Input() tableData;
    @Input() columnHeader;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @Input() pageInfo;
    hidePaginator = false;
    public dataSource;
    objectKeys = Object.keys;
    @Output() selectedFlightInfo = new EventEmitter<any>();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private snackBar: MatSnackBar,
        public router: Router,
        private gbBookingService: GBBookingService
    ) { }

    ngOnInit(): void {
        if (this.pageInfo == 'createFLightList') {
            this.gbBookingService.getFlightListData().pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((response: any) => {
                    this.tableData = response;
                    this.loadTableData();

                })
        } else {
            this.loadTableData();
        }

    }
    ngAfterViewInit(): void {
        // this.dataSource.sort = this.sort;
        // this.paginator.pageIndex = 0;
        // this.dataSource.paginator = this.paginator;
    }



    loadTableData() {
        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = 0;
        this.dataSource.paginator = this.paginator;
        this.hidePaginator = this.tableData.length === 0 ? true : false;
        this.onChangePage();
    }

    paginationChange(page: PageEvent) {
        this.gbBookingService.setFlightListPagination(page.pageIndex);
    }
    onChangePage() {
        this.gbBookingService.getFlightListPagination()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response: any) => {
                this.paginator.pageIndex = !!response ? response : 0;
                this.dataSource.paginator = this.paginator;
            });
    }

    pageRoute($event) {
        this.router.navigate(['request/requestview', $event]);
    }

    private findTimeDiffInMin(preItiArrivalDate: any, currItiDepartDate: any, preItiTime: any, currItiTime: any) {
        let preItiDate = moment(new Date(preItiArrivalDate)).format('YYYY-MM-DD');
        let currItiDate = moment(new Date(currItiDepartDate)).format('YYYY-MM-DD');
        let preItiArrivalTime: any = preItiTime;
        let currItiDeptTime: any = currItiTime;
        let preItiDateWithArrTime = preItiDate + " " + preItiArrivalTime;
        let currItiDateWithDeptTime = currItiDate + " " + currItiDeptTime;
        let date1 = moment(preItiDateWithArrTime);
        let date2 = moment(currItiDateWithDeptTime);

        let diffTimeInMin = date2.diff(date1, "minutes");
        return diffTimeInMin;
    }

    getData(element) {
        if (!!this.tableData.previousInfo) {
            let timeDiff = this.findTimeDiffInMin(this.tableData.previousInfo.preArrivalDate, this.tableData.previousInfo.curDepartureDate, this.tableData.previousInfo.preArrivalTime, element.departureTime)
            if (timeDiff <= 0) {
                this.snackBar.open('Current departure time is before of previous arrival time  ', 'X', { duration: 3000 });
            } else {
                this.selectedFlightInfo.emit(element);
            }
        } else {
            this.selectedFlightInfo.emit(element);
        }


    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
