export const environment = {
    production: false,
    VISTARA_URL: 'https://www.uat-group-booking.airvistara.com/grms-booking/vistara-login',
    API_URL: 'https://www.uat-group-booking.airvistara.com/gbApi/',
    DOMAIN_URL: 'https://www.uat-group-booking.airvistara.com/',
    client_id: "vistara-GRMS-UAT-client",
    resource: "uatgrmsserviceAPI",
};

// export const environment = {
//     production: false,
//     VISTARA_URL: 'https://www.uat-aem.airvistara.com/grms-booking/vistara-login',
//     API_URL: 'https://www.uat-aem.airvistara.com/gbApi/',
//     DOMAIN_URL: 'https://www.uat-aem.airvistara.com/',
//     client_id: "vistara-GRMS-UAT-client",
//     resource: "uatgrmsserviceAPI",
// }