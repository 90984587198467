import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GBBookingService {
    private flightList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private flightListPaginationIndex$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor() { }

    setFlightListData(data) {
        this.flightList$.next(data);
    }

    getFlightListData(): Observable<any> {
        return this.flightList$.asObservable();
    }

    setFlightListPagination(data) {
        this.flightListPaginationIndex$.next(data)
    }
    getFlightListPagination(): Observable<any> {
        return this.flightListPaginationIndex$.asObservable();
    }
}
