import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CURRENCY_CODE, GB_CONSTANTS, GRMS_CONSTANT } from '../../static/constants';
import { Subject, forkJoin, of } from "rxjs";
import { filter, takeUntil, tap, take, catchError } from 'rxjs/operators';
import { HttpService } from '../../httpService/http.service';
import { COMMON_API_ENDPOINTS } from '../../httpService/apiURLConstant';
import { LoaderService } from '../../services/gb-loader.services';
import { GrmsUtils } from '../../static/GRMSUtils';
import { ProfileDetails, } from '../../interface/index';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe, Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GBSeriesBookingService } from '../../services/series/series-booking.service'
import * as moment from 'moment';
import * as _ from 'lodash';
@Component({
    selector: 'app-pop-over-content',
    templateUrl: './pop-over-content.component.html',
    styleUrls: ['./pop-over-content.component.scss']
    // encapsulation: ViewEncapsulation.None
})
export class PopOverContentComponent implements OnInit {
    public dialogContent: any;
    public gstInfoFrom!: FormGroup;
    public cancelFrom!: FormGroup;
    public countryList: any = [];
    public stateList: any = [];
    public cityList: any = [];
    public pincodeList: any = [];
    private validationMessage = GB_CONSTANTS.GSTINFO_FROM;
    profileDetails = new ProfileDetails();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public ischecked: boolean = false;
    public quotevalidtillControl = new FormControl();
    @ViewChild('quotevalidtill') quotevalidtill: any;
    public quoteValueMinDate: Date = new Date();
    public quoteValueMaxDate: Date = new Date('12-31-2100');
    public paymentSplitArr = [2, 3, 4];
    public dateTimePickerConfig = {
        showSpinners: true,
        showSeconds: false,
        enableMeridian: false,
        stepHour: 1,
        stepMinute: 1,
        stepSecond: 1,
        color: 'primary',
    };
    public seriesAction: any = {
        expected: "",
        quoted: "",
        quotedInfant: "",
        quotevalidtill: ''
    }
    public isinfant: boolean = false;
    public itineraryDetails: any = [];
    public currentReqData: any;
    public tripMode: any;
    public paymentSplit: any;
    public userDetails: object = {};
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<PopOverContentComponent>,
        private loaderService: LoaderService,
        public router: Router,
        private formBilder: FormBuilder,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private snackBar: MatSnackBar,
        private routerNav: Router,
        private location: Location,
        public seriesService: GBSeriesBookingService,
        private datePipe: DatePipe
    ) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
    }

    ngOnInit(): void {
        this.dialogContent = this.data;
        this.userDetails = this.grmsUtils.getUserDetails();
        if (this.data.action == 'gstform') {
            this.gstInfo()
            this.getLocationDetail()
        } else if (this.data.action == 'cancelRequest') {
            this.cancelInfo()
        } else if (this.data.action == "seriesAction") {
            if (!!this.dialogContent.validationInfo.quoteValidTill) {
                let getQuoteVal = moment(this.dialogContent.validationInfo.quoteValidTill)
                getQuoteVal['_i'];
                this.quotevalidtillControl.setValidators(null);
                this.quotevalidtillControl.setErrors(null);
                this.quotevalidtillControl.setValue(new Date(getQuoteVal['_i']))
                this.seriesAction.quotevalidtill = this.quotevalidtillControl.value;
                this.quoteValueMaxDate = new Date(this.dialogContent.validationInfo.depatureDate);
            } else {
                this.quotevalidtillControl = new FormControl();
                this.quoteValueMaxDate = new Date(this.dialogContent.validationInfo.depatureDate);
            }
            this.isinfant = !!this.dialogContent.validationInfo.isinfant ? true : false;
        } else if (this.data.action == 'itinerayinfo') {
            this.tripMode = this.dialogContent?.tripMode;
            this.currentReqData = this.data.data;
            this.constructItineraryDetails()

        } else if (this.data.action == 'paymentsplit') {
            this.paymentSplit = this.data.data;
        }

    }

    gstInfo() {
        this.gstInfoFrom = this.formBilder.group({
            gstnumber: ['', [Validators.required]],
            gstcompanyname: ['', [Validators.required]],
            gstphonenumber: ['', [Validators.required]],
            gstemail: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            gstcompanyaddress: ['', [Validators.required]],
            gstcompanyaddress2: [''],
            country: [''],
            state: [''],
            city: [''],
            pincode: ['']
        })
    }

    pageRoute($event) {
        if ($event == 'request') {
            this.closeDialog('cancel')
            this.router.navigate(['request/requestview', this.data.data]);
        }
    }
    seriesPageRoute($event, id) {
        if ($event == 'request') {
            this.closeDialog('cancel')
            this.router.navigate(['series/overview', id]);
        }
    }

    formValidation(fieldname) {
        switch (fieldname) {
            case 'gstnumber':
                return this.gstInfoFrom.controls[fieldname].errors?.required ? this.validationMessage.GST_NO : '';
            case 'gstcompanyname':
                return this.gstInfoFrom.controls[fieldname].errors?.required ? this.validationMessage.GST_COMPANYNAME : '';
            case 'gstphonenumber':
                return this.gstInfoFrom.controls[fieldname].errors?.required ? this.validationMessage.PHONE_REQUIRED : this.gstInfoFrom.controls[fieldname].errors?.maxLength ? this.validationMessage.PHONE_LENGTH : '';
            case 'gstemail':
                return this.gstInfoFrom.controls[fieldname].errors?.required ? this.validationMessage.EMAIL_REQUIRED : this.gstInfoFrom.controls[fieldname].errors?.pattern ? this.validationMessage.EMAIL_PATTERN : '';
            case 'cancelReq':
                return this.cancelFrom.controls[fieldname].errors?.required ? 'This field is required' : '';

            default:
                return null
        }
    }

    getLocationDetail() {
        this.loaderService.show();
        forkJoin({
            country: this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
            state: this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/IN', this.grmsUtils.getJsonHeader()).pipe(catchError(error => of(error))),
        }).subscribe((data) => {
            this.loaderService.hide();
            this.gstInfoFrom.controls['country'].setValue('IN');
            this.countryList = this.grmsUtils.forkHandling(data.country); //data.country;
            this.stateList = this.grmsUtils.forkHandling(data.state);
        });
    }

    getStateList() {
        this.loaderService.show();
        this.stateList = [];
        this.gstInfoFrom.controls['state'].setValue('');
        let countryCode = this.gstInfoFrom.controls['country'].value;
        let countryObj = CURRENCY_CODE.filter((obj) => {
            return obj.countryCode === countryCode;
        });

        this.httpService.getCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryCode, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.stateList = response;
                        this.cityList = [];
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getCityList() {
        this.loaderService.show();
        this.gstInfoFrom.controls['city'].setValue('');
        let stateName = this.gstInfoFrom.controls['state'].value;
        this.httpService.getCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.cityList = response;
                        this.pincodeList = [];
                        this.pincodeList = [];
                        this.gstInfoFrom.controls['pincode'].setValue('');
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getZipCodeList() {
        this.loaderService.show();
        this.gstInfoFrom.controls['pincode'].setValue('');
        let cityName = this.gstInfoFrom.controls['city'].value;
        this.httpService.getCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this.pincodeList = response;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    cancelInfo() {
        this.cancelFrom = this.formBilder.group({
            cancelReq: ['', [Validators.required]]
        })
    }

    dateChange(el, event: MatDatepickerInputEvent<Date>) {
        //let firstItrTimes = this.gbRequest.itinerayDetails[0].flightTime.split('-');
        this.seriesAction.quotevalidtill = this.datePipe.transform(this.quotevalidtillControl.value, 'dd-MMM-yyyy h:mm:ss')
        let getFirstItrDateTime = this.dialogContent.validationInfo.depatureDate;
        let firstItrDateTime = moment(getFirstItrDateTime);
        if (new Date().getTime() < new Date(firstItrDateTime['_i']).getTime() && new Date(firstItrDateTime['_i']).getTime() > new Date(event.value).getTime()) {
        } else {
            this.snackBar.open("Kindly select quote valid date between these date " + new Date() + " to " + this.dialogContent.validationInfo.depatureDate, 'X', { duration: 3000 });
        }
    }

    isEligibleFor(roles: any, modulename: any) {
        return this.seriesService.isEligibleFor(roles, modulename, this.dialogContent.validationInfo.currentassignee, this.dialogContent.validationInfo.currentStatus)
    }

    closeDialog(btnText) {
        if (btnText == 'OK') {
            this.dialogRef.close({ event: 'close', data: (btnText == 'OK') ? 'isSimilar' : '' });
        } else if (btnText == 'gstInfo') {
            if (!!this.gstInfoFrom.valid) {
                this.dialogRef.close({ event: 'close', data: (btnText == 'gstInfo') ? this.gstInfoFrom.value : '' });
                this.snackBar.open('GST info successfully submitted', 'X', { duration: 3000 })
            }
            else {
                this.gstInfoFrom.markAllAsTouched()
            }
        } else if (btnText == 'cancelReq') {
            if (!!this.cancelFrom.valid) {
                this.dialogRef.close({ event: 'close', data: (btnText == 'cancelReq') ? this.cancelFrom.value.cancelReq : '' });
            }
            else {
                this.cancelFrom.markAllAsTouched()
            }
        } else if (btnText == 'penalty') {
            this.dialogRef.close({ event: 'close', data: "continue" });
        } else if (btnText == 'penaltyAfterPaxConfirm') {
            this.dialogRef.close({ event: 'close', data: "afterPaxConfirm" });
        } else if (btnText == 'closeDownsize') {
            this.dialogRef.close({ event: 'close', data: '' })
        } else if (btnText == 'updatePax') {
            this.dialogRef.close({ event: 'close', data: "updatePax" });
        } else if (btnText == 'confirmPax') {
            this.dialogRef.close({ event: 'close', data: "confirmPax" });
        } else if (btnText == 'createClose') {
            this.dialogRef.close({ event: 'close', data: '' })
            this.routerNav.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                this.routerNav.navigate([decodeURI(this.location.path())])
            });
        } else if (btnText == 'seriesAction') {
            this.applyAction()
        } else if (btnText == 'paymentSplit') {
            this.dialogRef.close({ event: 'close', data: this.paymentSplit })
        }
        else if (btnText == 'conveniencefee') {
            this.dialogRef.close({ event: 'close', data: 'conveniencefee' })
        }
        else {
            this.dialogRef.close({ event: 'close', data: '' })
        }

    }

    applyAction() {
        if (this.dialogContent.actionName == 'negotiation') {
            if (this.seriesAction.expected > 0) {
                this.dialogRef.close({ event: 'close', data: this.seriesAction, actionName: this.dialogContent.actionName })
            } else {
                this.snackBar.open('Please enter valid input', 'X', { duration: 3000 })
            }
        }
        else if ((this.dialogContent.actionName == 'quoted')) {
            if (this.seriesAction.quoted > 0 && !!this.seriesAction.quotevalidtill) {
                this.dialogRef.close({ event: 'close', data: this.seriesAction, actionName: this.dialogContent.actionName })
            } else {
                this.snackBar.open('Please enter valid input', 'X', { duration: 3000 })
            }
        }
        else if ((this.dialogContent.actionName == 'quoteUpdate')) {
            if (this.seriesAction.quoted > 0 && !!this.seriesAction.quotevalidtill) {
                this.dialogRef.close({ event: 'close', data: this.seriesAction, actionName: this.dialogContent.actionName })
            } else {
                this.snackBar.open('Please enter valid input', 'X', { duration: 3000 })
            }
        }
        else if (this.dialogContent.actionName == 'selection') {
            if (this.seriesAction.quoted > 0 && !!this.seriesAction.quotevalidtill) {
                this.dialogRef.close({ event: 'close', data: this.seriesAction, actionName: this.dialogContent.actionName })
            } else {
                this.snackBar.open('Please enter valid input', 'X', { duration: 3000 })
            }
        }

    }

    constructItineraryDetails() {
        let itineraryArr = this.data.data;
        if (this.tripMode === 'One Way') {
            let stops = "";
            let flightNo = "";
            let seatAvailable = "";
            let lastItineraryIndex = itineraryArr.length - 1;
            for (let i = 0; i < itineraryArr.length; i++) {
                if (i !== 0) {
                    stops += (i < lastItineraryIndex) ? itineraryArr[i].from + " | " : itineraryArr[i].from;
                }
                seatAvailable += (i < lastItineraryIndex) ? itineraryArr[i].availableSeats + " | " : itineraryArr[i].availableSeats;
                flightNo += (i < lastItineraryIndex) ? itineraryArr[i].flightNumber + " | " : itineraryArr[i].flightNumber;
            }
            let minDate = moment(itineraryArr[0].departureDate);
            let tempItineraryObj =
            {
                flightNo: flightNo,
                originCity: itineraryArr[0].from,
                stopIndex: itineraryArr[0].stops,
                stops: stops ? stops : "No Stops",
                travelTime: itineraryArr[0].travelDuration,
                departureDate: itineraryArr[0].departureDate,
                minDate: minDate ? minDate['_d'] : new Date(),
                seatAvailable: seatAvailable ? seatAvailable : 0,
                destinationCity: (itineraryArr.length === 0) ? itineraryArr[0].to : itineraryArr[lastItineraryIndex].to,
                arrivalDate: (itineraryArr.length === 0) ? itineraryArr[0].arrivalDate : itineraryArr[lastItineraryIndex].arrivalDate,
            }
            this.itineraryDetails.push(tempItineraryObj);
        } else {
            this.constructRoundAndMultiItinerary();
        }
    }

    constructRoundAndMultiItinerary() {
        let itineraryArr = this.data.data;
        let groupByTrip = _.groupBy(itineraryArr, obj => obj.stops);
        Object.keys(groupByTrip).forEach(([key], index) => {
            let stops = "";
            let flightNo = "";
            let seatAvailable = "";
            let previousArrivalDate;
            let tripArr = groupByTrip[key];
            let lastrenderItineraryIndex = this.itineraryDetails.length - 1;
            let lastItineraryIndex = tripArr.length - 1;
            for (let i = 0; i < tripArr.length; i++) {
                if (i !== 0) {
                    stops += (i < lastItineraryIndex) ? tripArr[i].from + " | " : tripArr[i].from;
                }
                flightNo += (i < lastItineraryIndex) ? tripArr[i].flightNumber + " | " : tripArr[i].flightNumber;
                seatAvailable += (i < lastItineraryIndex) ? tripArr[i].availableSeats + " | " : tripArr[i].availableSeats;
                previousArrivalDate = (this.itineraryDetails.length === 0) ? tripArr[0].departureDate : moment(this.itineraryDetails[lastrenderItineraryIndex].arrivalDate);
            }


            let tempItineraryObj =
            {
                stopIndex: key,
                flightNo: flightNo,
                originCity: tripArr[0].from,
                stops: stops ? stops : "No Stops",
                travelTime: tripArr[0].travelDuration,
                departureDate: tripArr[0].departureDate,
                seatAvailable: seatAvailable ? seatAvailable : 0,
                minDate: previousArrivalDate ? previousArrivalDate['_d'] : new Date(),
                destinationCity: (tripArr.length === 0) ? tripArr[0].to : tripArr[lastItineraryIndex].to,
                arrivalDate: (tripArr.length === 0) ? tripArr[0].arrivalDate : tripArr[lastItineraryIndex].arrivalDate,
            }
            this.itineraryDetails.push(tempItineraryObj);
        });

    }

    showGSTForm() {
        this.ischecked = true;
    }


}
