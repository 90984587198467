import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateRequestFormComponent } from './create-request-form/create-request-form.component';
import { RequestViewComponent } from './request-view/request-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRequestComponent } from './dashboard-request/dashboard-request.component';
import { DirtyCheckGuard } from '../shared/services/dirty-check.service';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'create',
        component: CreateRequestFormComponent,
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'requestview/:id',
        component: RequestViewComponent
    },
    {
        path: 'dashboard',
        canActivate: [DirtyCheckGuard],
        data: {
            role: ['Admin', 'DU', 'GS', 'SU']
        },
        component: DashboardComponent,
    },
    {
        path: 'dashboard-request/:id',
        component: DashboardRequestComponent,
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GBCreateRequestRoutingModule { }
