import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { REQUEST_LIST, GSA_REQ_LIST } from '../../shared/static/constants';
import { REPORT_API_ENDPOINTS, SERIES_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { SideNavBarService } from "../../shared/services/side-nav-bar.service";
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';

@Component({
    selector: 'gb-sidenavbar',
    templateUrl: './side-nav-bar.component.html',
    styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent implements OnInit {

    public userRole;
    public selectedItem = 0;
    public selectedRequest = '';
    public requestCountList = {};
    public navBarCollapseed = false;
    private requestList = [...REQUEST_LIST];
    public isSideNavBarShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private userProfileService: UserProfileService,
        private changeDetect: ChangeDetectorRef,
        private sideNavBar: SideNavBarService,
        private cookieService: CookieService,
        private loaderService: LoaderService,
        private httpService: HttpService,
        private snackBar: MatSnackBar,
        private grmsUtils: GrmsUtils,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.sideNavBar.isSideNavBarShow
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isSideNavBarShow.next(action);
                if (action) {
                    let userDetails = this.grmsUtils.getUserDetails();
                    this.selectedRequest = this.cookieService.get('currentMenu');
                    this.requestList = [];
                    if (userDetails?.gsaUser === 'Y' || this.selectedRequest === 'gsa') {
                        this.requestList = [...GSA_REQ_LIST];
                    } else {
                        this.requestList = [...REQUEST_LIST];
                    }
                    this.requestList.unshift({ "key": "Dashboard", "code": "dashboard", "icon": "home", "hoverIcon": "", "countDesc": "", "reqCode": "" },);
                    this.getRequestCount(false);
                    this.setIndex(0);
                }
            });
        this.userProfileService.userRole
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.userRole = action;
            });

    }

    setIndex(inputVal) {
        this.selectedItem = inputVal;
        this.changeDetect.detectChanges();
    }

    getRequestCount(refreshFlag) {
        this.requestCountList = {};
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            "loginUserID": userDetails.loginUserId
        };
        if (refreshFlag) {
            this.loaderService.show();
        }
        let endPoint = (userDetails?.gsaUser === 'Y' || this.selectedRequest === 'gsa') ? REPORT_API_ENDPOINTS.getGSARequestCount :
            (this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_REQUEST_COUNT : REPORT_API_ENDPOINTS.getRequestCount;
        this.httpService.postCall(endPoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.statusCode === 200) {
                        this.requestCountList = response;
                        if (refreshFlag) {
                            this.loaderService.hide();
                        }
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
