import { Component, Inject, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { filter, tap, take } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationStart, RouterEvent } from '@angular/router';

import { GB_CONSTANTS } from '../../static/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileService } from '../../services/user-profile.service';
import { SERIES_CONSTANT } from '../../static/series_constants';
import { COMMON_API_ENDPOINTS } from '../../httpService/apiURLConstant';
import { LoaderService } from '../../services/gb-loader.services';
import { HttpService } from '../../httpService/http.service';
import { GrmsUtils } from '../../static/GRMSUtils';

@Component({
    selector: 'app-comman-modal',
    templateUrl: './comman-modal.component.html',
    styleUrls: ['./comman-modal.component.scss']
})
export class CommanModalComponent implements OnInit {

    public countDown;
    public duplicateMsg;
    public cityList = [];
    public stateList = [];
    public pincodeList = [];
    public isGSTForm = false;
    public isIdlePopup = false;
    public isPaxDelete = false;
    public isGSTAccept = false;
    public isConfirmPax = false;
    public seriesRequestId = [];
    public isTicketError = false;
    public isDeletePopup = false;
    public isPenaltyPopup = false;
    public isDuplicatePax = false;
    public isSeriesRequest = false;
    public gstInfoForm!: FormGroup;
    public showTicketPopup = false;
    public duplicateMsgFlag = false;
    public isDuplicatePopup = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public validationMessage = SERIES_CONSTANT.GST_FORM_ERR_MSG;
    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    public countryList = [{ "countryCode": "IN", "countryName": "INDIA" }];

    formErrors = {
        "gstcompanyaddress": "",
        "gstcompanyname": "",
        "gstphonenumber": "",
        "gstnumber": "",
        "gstemail": "",
        "country": "",
        "pincode": "",
        "state": "",
        "city": "",
    };

    public gstDetails = {
        "gstcompanyaddress": "",
        "gstcompanyname": "",
        "gstphonenumber": "",
        "gstnumber": "",
        "gstemail": "",
        "country": "IN",
        "pincode": "",
        "state": "",
        "city": "",
    }

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private userProfileService: UserProfileService,
        private dialogRef: MatDialogRef<CommanModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
    }

    ngOnInit(): void {
        this.isGSTForm = this.data.isGSTForm ? this.data.isGSTForm : false;
        this.duplicateMsg = (this.data.displayMsg) ? this.data.displayMsg : "";
        this.isIdlePopup = this.data.isIdlePopup ? this.data.isIdlePopup : false;
        this.isPaxDelete = this.data.isPaxDelete ? this.data.isPaxDelete : false;
        this.isGSTAccept = this.data.isGSTAccept ? this.data.isGSTAccept : false;
        this.isConfirmPax = this.data.isConfirmPax ? this.data.isConfirmPax : false;
        this.isTicketError = this.data.isTicketError ? this.data.isTicketError : false;
        this.duplicateMsgFlag = this.data.duplicateMsg ? this.data.duplicateMsg : false;
        this.isDeletePopup = (this.data.isDeletePopup) ? this.data.isDeletePopup : false;
        this.isDuplicatePax = this.data.isDuplicatePax ? this.data.isDuplicatePax : false;
        this.seriesRequestId = this.data.seriesRequestId ? this.data.seriesRequestId : [];
        this.isPenaltyPopup = this.data.isPenaltyPopup ? this.data.isPenaltyPopup : false;
        this.showTicketPopup = this.data.showTicketPopup ? this.data.showTicketPopup : false;
        this.isDuplicatePopup = (this.data.isDuplicatePopup) ? this.data.isDuplicatePopup : false;
        this.isSeriesRequest = (this.data.showSeriesRequest) ? this.data.showSeriesRequest : false;

        this.userProfileService.idleTimeWarning
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.countDown = action;
            });
        this.createForm();
        this.getStateList();
        this.gstInfoForm.controls['country'].setValue('IN');
    }

    createForm() {
        this.gstInfoForm = this.fb.group({
            gstemail: ['', [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            gstphonenumber: ['', [Validators.required, Validators.minLength(10)]],
            gstcompanyaddress: ['', [Validators.required]],
            gstcompanyname: ['', [Validators.required]],
            gstnumber: ['', [Validators.required]],
            country: ['IN', [Validators.required]],
            pincode: ['', [Validators.required]],
            state: ['', [Validators.required]],
            city: ['', [Validators.required]],
            gstcompanyaddress2: [''],
        });

        this.gstInfoForm.valueChanges
            .subscribe(data => {
                this.onValueChanged(data)
            });

        this.onValueChanged();
    }

    onValueChanged(data?: any) {
        if (!this.gstInfoForm) { return; }
        const form = this.gstInfoForm;

        for (const field in this.formErrors) {
            this.formErrors[field] = "";
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessage[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + "  ";
                }
            }
        }
    }

    backToHome() {
        let userDetails = this.grmsUtils.getUserDetails();
        if (userDetails.user_role === "ROLE_AGENT" || userDetails.user_role === "ROLE_USER" ||
            userDetails.user_role === "ROLE_SUB_AGENT") {
            this.router.navigate(['/request/create']);
        } else {
            this.router.navigate(['/request']);
        }
    }

    confirmDeleteAction(actionValue) {
        this.dialogRef.close({ event: 'close', data: actionValue });
    }

    confirmPaxDownSize(actionValue) {
        this.dialogRef.close({ event: 'close', data: { action: actionValue, penaltyAmount: this.data.penalty } });
    }

    deletepaxSize(actionValue) {
        this.dialogRef.close({ event: 'close', data: { action: actionValue } });
    }

    confirmPaxDetails(actionValue) {
        if (actionValue === 'no') {
            this.isGSTForm = false;
            this.dialogRef.close({ event: 'close', data: actionValue });
        } else {
            this.isGSTForm = true;
        }
    }

    confirmDialogAction(actionValue) {
        this.dialogRef.close({ event: 'close', data: actionValue });
    }

    redirectToOverview(subRequestId) {
        this.router.navigate(['series/overview', subRequestId]);
    }

    confirmGSTDetails(actionValue) {
        this.dialogRef.close({ event: 'close', data: actionValue });
    }

    updateGSTDetails() {
        this.dialogRef.close({ event: 'close', data: this.gstDetails });
    }

    getStateList() {
        let countryCode = this.gstInfoForm.controls['country'].value;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getStateListByCountry + '/' + countryCode, 'stateList');
    }

    getCityList() {
        let stateName = this.gstInfoForm.controls['state'].value;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getCityListByState + '/' + stateName, 'cityList');
    }

    getZipCodeList() {
        let cityName = this.gstInfoForm.controls['city'].value;
        this.requestGetServiceCall(COMMON_API_ENDPOINTS.getZipcodeByCity + '/' + cityName, 'pincodeList');
    }

    requestGetServiceCall(apiEndPoint, responseListName) {
        this.loaderService.show();
        this.httpService.getCall(apiEndPoint, this.grmsUtils.getJsonHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.loaderService.hide();
                        this[responseListName] = response;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}
