import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Location } from '@angular/common';
import { GrmsUtils } from '../../static/GRMSUtils';

@Injectable({
    providedIn: 'root'
})

export class GBSeriesBookingService {
    public readrules: any = {
        "expectedfare": ["QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION"],
        "confirmquoteaction": ["QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION"],
        "quotedfare": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED"],
        "infantquotedfare": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING"],
        "pnrno": ["ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED"],
        "payment": ["ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        "acceptaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED"],
        "quoteactionvalid": ["QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED"],
        "quoteaction": ["CREATE_IN_PROGRESS", "OPEN", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION"],
        "quoteupdateaction": ["QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION"],
        "negotiateaction": ["QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION"],
        "requestcancelaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "requestorrequestcancelaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "QUOTE EXPIRED"],
        "quoteexpiry": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "quotevalidtill": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "multiplequoteeaction": ["CREATE_IN_PROGRESS", "OPEN"],
        "flightupdateaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "QUOTE EXPIRED"],
        "updatepaxaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING"],
        "updateticketaction": ["PAX CONFIRMED"],
        "paxinfopendingaction": ["PNR CREATION IN PROGRESS", "PNR CREATED"],
        "confirmpaxinfoaction": ["PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING"],
        "paymentstatusupdateaction": ["ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        "commentaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED", "QUOTE EXPIRED"],
        "anotherquoteeaction": ["QUOTE EXPIRED"],
        "paxsizechangeaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED"],
        "paxchangeaction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED",],
        "similarRequestAction": ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED", "QUOTE EXPIRED"],
        "enableNegotiationAction": ["QUOTE IN PROGRESS", "QUOTED"],
        "paymentDescriptionAction": ["ACCEPT IN PROGRESS", "QUOTE ACCEPTED", "PNR CREATION IN PROGRESS", "PNR CREATED", "TICKETS ISSUED"],
        'downSizePax': ["PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING"],
        'downUpPax': ["PNR CREATION IN PROGRESS", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED"],
        'materializationRate': ["CREATE_IN_PROGRESS", "OPEN", "QUOTE IN PROGRESS", "QUOTED", "NEGOTIATE IN PROGRESS", "UNDER NEGOTIATION", "CUSTOMER CONFIRMATION IN PROGRESS", "CUSTOMER CONFIRMED",]
    };
    private userDetails = this.grmsUtils.getUserDetails();
    public currentrole = (this.userDetails.user_role === "ROLE_ADMIN" || this.userDetails.user_role === "ROLE_DESK_USER"
        || this.userDetails.user_role === "ROLE_GS" || this.userDetails.user_role === "ROLE_SU") ? "user" : "requester";
    public isRefresh = false;
    constructor(
        private httpClient: HttpClient,
        private cookieService: CookieService,
        private grmsUtils: GrmsUtils,
        private routerNav: Router,
        private location: Location,
    ) { }

    isEligibleFor(roles: any, modulename: any, currentassignee: any, currentStatus: any): boolean {
        this.userDetails = this.grmsUtils.getUserDetails();
        this.currentrole = (this.userDetails.user_role === "ROLE_ADMIN" || this.userDetails.user_role === "ROLE_DESK_USER"
            || this.userDetails.user_role === "ROLE_GS" || this.userDetails.user_role === "ROLE_SU") ? "user" : "requester";
        for (let i = 0; i < roles.length; i++) {
            if (roles[i] === this.currentrole) {
                if (currentassignee) {
                    if (this.readrules[modulename].indexOf(currentStatus) > -1) {
                        return false;
                    }
                }
                if (modulename === "paxsizechangeaction" || modulename === "paxchangeaction" || modulename === "enableNegotiationAction" ||
                    modulename === "paymentDescriptionAction") {
                    if (this.readrules[modulename].indexOf(currentStatus) > -1) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    reFreshDetails() {
        this.isRefresh = false;
        this.routerNav.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.routerNav.navigate([decodeURI(this.location.path())])
        });
    }
}
