import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import * as _ from 'lodash';
import * as FileSaver from "file-saver";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { SideNavBarService } from "../../shared/services/side-nav-bar.service";
import { UserProfileService } from "../../shared/services/user-profile.service";
import { LoaderService } from "src/app/shared/services/gb-loader.services";
import { HttpService } from "src/app/shared/httpService/http.service";
import { BOOKINGVIEW_API_ENPOINTS, COMMON_API_ENDPOINTS, REPORT_API_ENDPOINTS, SERIES_API_ENDPOINTS } from "src/app/shared/httpService/apiURLConstant";
import { GrmsUtils } from "src/app/shared/static/GRMSUtils";
import { CookieService } from "ngx-cookie-service";
import { GRMS_CONSTANT } from "src/app/shared/static/constants";
import { MatSnackBar } from "@angular/material/snack-bar";


@Component({
    selector: 'gb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

    @ViewChild('gb_notification') scroller: ElementRef;

    public pageName;
    public userDetails;
    public userName = "";
    public userRole = "";
    public requestID: string;
    public isNavShow = false;
    public isMenuShow = false;
    public showRegister = true;
    public selectedRequest = '';
    public isVistaraUser = false;
    public notificationArr = [];
    public isExistingUser = false;
    public isRegisterIcon = false;
    unSubscribe$: Subject<boolean> = new Subject<boolean>();
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public router: Router,
        private location: Location,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private sideNavBar: SideNavBarService,
        private userProfileService: UserProfileService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.userProfileService.setExistingUserFlag();
        this.sideNavBar.isNavShow
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isNavShow = action;
                this.selectedRequest = this.cookieService.get('currentMenu');
                this.userDetails = (this.cookieService.get('userDetails')) ? JSON.parse(this.cookieService.get('userDetails')) : "";
            });

        this.sideNavBar.isRegisterIcon
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isRegisterIcon = action;
            });

        this.userProfileService.userName
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.userName = action;
            });

        this.userProfileService.userRole
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.userRole = action;
                if ((this.grmsUtils.isNotNull(this.userRole) && this.userRole !== 'DU' && this.userRole !== 'Admin'
                    && this.userRole !== 'SU' && this.userRole !== 'GS')
                    && this.grmsUtils.isNotNull(this.cookieService.get('auth_token'))) {
                    this.getAllNotification();
                }
            });

        this.userProfileService.vistaraUserFlag
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isVistaraUser = (action === 'Non-Vistara') ? false : true;
            });

        this.userProfileService.existingUserFlag
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isExistingUser = action;
            });

        // this.userProfileService.selectedMenu
        //     .pipe(takeUntil(this.ngUnsubscribe))
        //     .subscribe(action => {
        //         this.selectedRequest = action;
        //     });
    }

    getRequestDetails() {
        this.loaderService.hide();
        let requestID_ = this.requestID;
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            loginUserID: userDetails.loginUserId,
            requestId: requestID_,
        }
        if (requestID_.toLowerCase().includes('uk')) {
            this.router.navigate([`request/requestview/${requestID_}`]);
        } else if (requestID_.toLowerCase().includes('sr')) {
            this.router.navigate([`series/overview/${requestID_}`]);
        } else if (requestID_.toLowerCase().includes('uks')) {
            this.router.navigate([`series/series-view/${requestID_}`]);
        } else {
            let formData: FormData = new FormData();
            formData.append("loginUserID", userDetails.loginUserId);
            formData.append("pnrNo", requestID_);
            this.loaderService.show();
            this.httpService.postCall(COMMON_API_ENDPOINTS.getRequestType, formData, this.grmsUtils.getAuthMultiHeader())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        if (response.isAdhocRequest) {
                            this.router.navigate([`request/requestview/${requestID_}`]);
                        } else {
                            this.router.navigate([`series/series-view/${requestID_}`]);
                        }
                    },
                    error: (errorDetails) => {
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }
    }

    downloadAttachment(fileId: any, attchName: string, acceptFileType: string) {
        if (fileId) {
            this.loaderService.show();
            this.httpService.getCall(REPORT_API_ENDPOINTS.downloadFile + fileId, this.grmsUtils.getFileJSONHeader(acceptFileType))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (response: any) => {
                        this.loaderService.hide();
                        let blob: any = new Blob([response]);
                        FileSaver.saveAs(blob, attchName);
                    },
                    error: (errorDetails: any) => {
                        this.loaderService.hide();
                        this.grmsUtils.errorHandling(errorDetails);
                    }
                });
        }
    }

    redirectToHome() {
        if (this.grmsUtils.isNotNull(this.userName) && (this.userRole !== 'User' && this.userRole !== 'Agent' && this.userRole !== 'SA')) {
            this.router.navigate(['/request']);
        } else if (this.grmsUtils.isNotNull(this.userName)) {
            this.router.navigate(['/request/create']);
        } else {
            this.router.navigate(['/']);
        }
    }

    getAllNotification() {
        this.notificationArr = [];
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            "userId": userDetails.loginUserId
        };
        let apiEndPoint = (this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_ALL_NOTIFICATION : COMMON_API_ENDPOINTS.getAllNotification;
        this.requestServiceCall(apiEndPoint, payload, 'getAll');

    }

    markAsRead(selectedNotification) {
        let payload = [{
            "id": selectedNotification.id,
            "markAsRead": true
        }];
        let apiEndPoint = (this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_UPDATE_NOTIFICATION : COMMON_API_ENDPOINTS.updateNotiicationStatus;
        this.loaderService.show();
        this.requestServiceCall(apiEndPoint, payload, 'markAsRead');
    }

    markAllAsRead() {
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            "userId": userDetails.loginUserId
        };
        let apiEndPoint = (this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_UPDATE_ALL_NOTIFICATION : COMMON_API_ENDPOINTS.updateAllNotiicationStatus;
        this.loaderService.show();
        this.requestServiceCall(apiEndPoint, payload, 'markAll');
    }

    requestServiceCall(apiEndPoint, payload, actionType) {
        this.httpService.postCall(apiEndPoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (actionType === 'getAll') {
                        this.notificationArr = response;
                    } else {
                        this.loaderService.hide();
                        this.getAllNotification();
                    }
                    if (actionType === 'markAsRead') {
                        this.snackBar.open("Notification marked as read", "X", { duration: 3000 });
                    }
                    if (actionType === 'markAll') {
                        this.snackBar.open("All Notifications are marked as read", "X", { duration: 3000 });
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

    getDashboardData(clickedMenu) {
        this.userProfileService.setSelectedMenu(clickedMenu);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
        this.cookieService.set('currentMenu', clickedMenu, expiryDate, '/', '', false, "Lax");
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([decodeURI('/request/dashboard')])
        });
    }

    redirectToCreation(clickedMenu) {
        this.userProfileService.setSelectedMenu(clickedMenu);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
        this.cookieService.set('currentMenu', clickedMenu, expiryDate, '/', '', false, "Lax");
        if (clickedMenu !== 'series') {
            this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                this.router.navigate([decodeURI('/request/create')]);
            });
        } else {
            this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                this.router.navigate([decodeURI('/series/create')]);
            });
        }
    }

    getAdhocRequestCount(clickedMenu) {
        this.userProfileService.setSelectedMenu(clickedMenu);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + 1000 * GRMS_CONSTANT.GRMS_SESSION_TIMEOUT);
        this.cookieService.set('currentMenu', clickedMenu, expiryDate, '/', '', false, "Lax");
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
            this.router.navigate([decodeURI('/request/dashboard-request/open')]);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
