import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from "rxjs";
import * as _ from 'lodash';
import { filter, takeUntil, tap, take } from "rxjs/operators";
import { SideNavBarService } from "../../../shared/services/side-nav-bar.service";
import { GB_CONSTANTS } from '../../../shared/static/constants';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { ProfileDetails } from 'src/app/shared/interface';
import { REGISTER_API_POINTS } from 'src/app/shared/httpService/apiURLConstant';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { Router, NavigationStart, RouterEvent } from '@angular/router';

@Component({
    selector: 'app-register-model',
    templateUrl: './register-model.component.html',
    styleUrls: ['./register-model.component.scss']
})
export class RegisterModelComponent implements OnInit {

    public registerOldForm!: FormGroup;
    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    private validationMessage = GB_CONSTANTS.FORM_VALIDATIONS.FORMS.LOGIN_FORM;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private sideNavBar: SideNavBarService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<RegisterModelComponent>,
    ) {
        router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap(() => this.dialogRef.close()),
            take(1),
        ).subscribe();
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.registerOldForm = this.fb.group({
            oldEmail: [null, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
            newEmailId: [this.data.profileData.emailId, [Validators.required, Validators.pattern(GB_CONSTANTS.REGEX.EMAIL)]],
        });
    }

    validateForm(fieldname: any): any {
        if (fieldname === 'oldEmail' || fieldname === 'newEmailId') {
            return this.registerOldForm.controls[fieldname].errors?.required ? this.validationMessage.EMAIL_MANDATORY :
                this.registerOldForm.controls[fieldname].errors?.pattern ? this.validationMessage.EMAIL_INVALID : '';
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    submitOldId() {
        this.loaderService.show();
        let payload = new ProfileDetails();
        payload = _.cloneDeep(this.data.profileData);
        payload['registerWithOldMailId'] = "true";
        payload.iataCode = Number(payload.iataCode);
        payload['travelAgent'] = this.data.travelAgent;
        payload.phoneNumber = Number(payload.phoneNumber);
        payload['gsaUser'] = this.data.gsaUser;
        payload['currency'] = this.data.currency;
        payload['oldMailId'] = this.registerOldForm.controls['oldEmail'].value;
        let inputData = this.grmsUtils.encryptedData(JSON.stringify(payload));
        this.sideNavBar.setOldMailId(this.registerOldForm.controls['oldEmail'].value);
        this.httpService.postCall(REGISTER_API_POINTS.registerUser, inputData, this.grmsUtils.getPlainHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    let responseData = this.grmsUtils.decryptedData(response);
                    response = JSON.parse(responseData);
                    if (response.statusCode === 200) {
                        this.loaderService.hide();
                        this.dialogRef.close();
                        this.snackBar.open("Registration Successfully, Please check your Email", 'X', { duration: 3000 });
                        setTimeout(() => {
                            this.router.navigate(['/']);
                        }, 500);
                    }
                },
                error: (errorDetails) => {
                    this.sideNavBar.setOldMailId("");
                    this.registerOldForm.controls['oldEmail'].setValue('');
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}
