export const FROM_ERRORS = {
    "to": "",
    "from": "",
    "tourName": "",
    "groupName": "",
    "groupType": "",
    "arrivalDate": "",
    "departureDate": "",
    "corporateName": "",
};

const ROUND_TRIP_DATA = {
    "travelDuration": "",
    "departureDate": "",
    "classOfTravel": "",
    "flightNumber": "",
    "arrivalDate": "",
    "itineraryno": "",
    "flightTime": "",
    "stops": 0,
    "from": "",
    "to": "",
}

const SERIES_FORM_ERROR_MSG = {
    "to": {
        "required": "Please select the destination place",
        "no_destination": "Please select a valid origin",
        "city_match": 'Origin and Destination cannot be same. kindly re-select',
    },
    "from": {
        "required": "Please select the origin place",
        "no_origin": "Please select a valid destination",
        "city_match": 'Origin and Destination cannot be same. kindly re-select',
    },
    "groupName": {
        "required": "Group Name is required.",
        "pattern": "First and Last character not be space",
        "minlength": "Group Name must be at least 4 characters long.",
        "maxlength": "Group Name cannot be more than 150 characters long.",
    },
    "tourName": {
        "required": "Tour Name is required.",
        "pattern": "First and Last character not be space",
    },
    "groupType": {
        "required": "Trip Type is required.",
    },
    "corporateName": {
        "required": "Corporate name is required.",
        "pattern": "First and Last character not be space",
    },
    "departureDate": {
        "required": "Start date is required.",
    },
    "arrivalDate": {
        "required": "End date is required.",
    },
    "classOfTravel": {
        "required": "Please select the Cabin Class"
    },
    "expectedFare": {
        "required": "Please provide Expected Fare"
    },
};

const SERIES_GST_FORM_ERR_MSG = {
    "gstnumber": {
        "required": "GST Number is required.",
    },
    "gstcompanyname": {
        "required": "GST Company name is required.",
        "pattern": "First and Last character not be space",
    },
    "gstemail": {
        "required": "Email ID is required.",
        "pattern": 'Please provide a valid Email ID',
    },
    "gstphonenumber": {
        "required": "Phone Number is required.",
        "minlength": "Phone Number must be of 10 digits"
    },
    "gstcompanyaddress": {
        "required": "Address is required.",
        "pattern": "First and Last character not be space",
    },
    "country": {
        "required": "Country is required.",
    },
    "state": {
        "required": "State is required.",
    },
    "city": {
        "required": "City is required.",
    },
    "pincode": {
        "required": "Pincode is required.",
    },
};

const PAX_ARR = [
    { desc: 'Adult', totalCount: "totalAdult", paxCount: [{ totalAdult: 1, totalChild: 0, totalInfant: 0 }], addDisabled: false, minusDisabled: false, viewKey: 'noOfAdult', baseFare: 'baseFare', taxFare: 'taxFare', seriesAddDisabled: true, seriesMinusDisabled: true },
    { desc: 'Child', totalCount: "totalChild", paxCount: [{ totalAdult: 1, totalChild: 0, totalInfant: 0 }], addDisabled: false, minusDisabled: true, viewKey: 'noOfChild', baseFare: 'baseFare', taxFare: 'taxFare', seriesAddDisabled: true, seriesMinusDisabled: true },
    { desc: 'Infant', totalCount: "totalInfant", paxCount: [{ totalAdult: 1, totalChild: 0, totalInfant: 0 }], addDisabled: false, minusDisabled: true, viewKey: 'noOfInfant', baseFare: 'infantBaseFare', taxFare: 'infantTaxFare', seriesAddDisabled: true, seriesMinusDisabled: true }
]

const DAYS_OF_WEEK = [
    { code: '0', desc: 'Sunday' },
    { code: '1', desc: 'Monday' },
    { code: '2', desc: 'Tuesday' },
    { code: '3', desc: 'Wednesday' },
    { code: '4', desc: 'Thursday' },
    { code: '5', desc: 'Friday' },
    { code: '6', desc: 'Saturday' },
];

const SERIES_MONTH = [
    {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    }
]

const REQUEST_PROGRESS = ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED",
    "QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKET ISSUED"];

const UPDATE_BTN_AD = ["OPEN", "QUOTED", "UNDER NEGOTIATION", "CUSTOMER CONFIRMED",
    "QUOTE ACCEPTED"];

const UPDATE_BTN_PAX = ["PNR CREATED"];

const PAX_INT_TABLE = {
    select: "", paxRowNo: "Sno", title: "Title*", firstName: "First Name*", lastName: "Last Name",
    type: "Type*", associatedAdult: "Associated Adult", ticketNumber: 'Ticket No', deleted: 'User Status',
    docnumber: 'Passport Number', action: 'Action'
}

const PAX_HOME_TABLE = {
    select: "", paxRowNo: "Sno", title: "Title*", firstName: "First Name*", lastName: "Last Name",
    type: "Type*", associatedAdult: "Associated Adult", ticketNumber: 'Ticket No', deleted: 'User Status', action: 'Action'
}

const PAX_TITLE_ARR = ["MR", "MRS", "MS", "MSTR", "MISS", "DR"];

const PAX_TYPE_ARR = ["Adult", "Child", "Infant"];

const GENDER_TYPE_ARR = [
    { genderDesc: "Male", genderCode: "M" },
    { genderDesc: "Female", genderCode: "F" }
];

const PAX_UPDATE_FORM = {
    "firstName": {
        "required": "First Name is required.",
        "pattern": "First and Last character not be space",
        "minlength": "First Name must be at least 3 characters long.",
    },
    "lastName": {
        "required": "Last Name is required.",
        "pattern": "First and Last character not be space",
        "minlength": "Last Name must be at least 1 characters long.",
    },
    "email": {
        "required": "Email ID is required.",
        "pattern": 'Please provide a valid Email ID',
    },
    "contactNo": {
        "required": "Phone Number is required.",
        "minlength": "Phone Number must be of 10 digits"
    },
    "dob": {
        "required": "Date of Birth is required.",
    },
    "title": {
        "required": "Title is required.",
    },
    "gender": {
        "required": "Gender is required.",
    },
    "paxType": {
        "required": "Pax type is required.",
    },
    "doctype": {
        "required": "Document type is required.",
    },
    "dobdoc": {
        "required": "Document date of birth is required.",
    },
    "docnumber": {
        "required": "Document number is required.",
    },
    "docexpiry": {
        "required": "Document expiry date is required.",
    },
    "nationality": {
        "required": "Country is required.",
    },
    "issuecountry": {
        "required": "Issued country is required.",
    },
    "mealPreference": {
        "required": "Meal is required.",
    },
};

export const SERIES_CONSTANT = {
    GST_FORM_ERR_MSG: SERIES_GST_FORM_ERR_MSG,
    FORM_ERR_MSG: SERIES_FORM_ERROR_MSG,
    PAX_FORM_ERR_MSG: PAX_UPDATE_FORM,
    HOME_TABLE_HEADER: PAX_HOME_TABLE,
    PAX_GENDER_TYPE: GENDER_TYPE_ARR,
    INT_TABLE_HEADER: PAX_INT_TABLE,
    PROGRESS_LIST: REQUEST_PROGRESS,
    SERIES_MONTH_LIST: SERIES_MONTH,
    ROUND_TRIP_OBJ: ROUND_TRIP_DATA,
    PAX_UPDATE_PNR: UPDATE_BTN_PAX,
    DAYS_PREFERENCE: DAYS_OF_WEEK,
    PAX_UPDATE_AD: UPDATE_BTN_AD,
    PAX_TYPE_ARRAY: PAX_TYPE_ARR,
    PAX_TITLES: PAX_TITLE_ARR,
    PAX_TYPE_LIST: PAX_ARR,

}
