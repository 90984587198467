import { Component, HostListener } from '@angular/core';
import { fadeAnimation } from './shared/animations/animations';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { Subscription } from 'rxjs';
import { SideNavBarService } from "./shared/services/side-nav-bar.service";
import { CookieService } from 'ngx-cookie-service';
import { NavigationStart, Router } from '@angular/router';
import { UserProfileService } from './shared/services/user-profile.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { CommanModalComponent } from './shared/model/comman-modal/comman-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GrmsUtils } from './shared/static/GRMSUtils';
import { SessionModelComponent } from './shared/model/session-model/session-model.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [fadeAnimation]
})

export class AppComponent {

    public isSideNavBarShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public isAuthenticated: boolean = false;
    public isExistingUser: boolean = false;
    public browserRefresh = false;
    public cooikeeCleared = false;
    subscription: Subscription;
    public isRefreshed = false;
    public popupOpend = false;
    public fullWidth = false;

    constructor(
        private userProfileService: UserProfileService,
        private sideNavBar: SideNavBarService,
        private cookieService: CookieService,
        private grmsUtils: GrmsUtils,
        private matDialog: MatDialog,
        private router: Router,
        private idle: Idle,
    ) {
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.browserRefresh = !router.navigated;
            }
        });

        setInterval(() => {
            this.userProfileService.existingUserFlag
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(action => {
                    this.isExistingUser = action;
                });
            if (this.router.url === "/logout") {
                this.cookieService.deleteAll();
                this.sessionClear();
            } else if (this.router.url.startsWith("/vistara-login")) {
                this.router.navigate(["/vistara-login"]);
            } else if (this.router.url.startsWith("/vistara-logout")) {
                this.router.navigate(["/vistara-logout"]);
            } else if (this.router.url === "/register") {
                this.router.navigate(["/register"]);
            } else if (this.router.url === "/forget-password") {
                this.router.navigate(["/forget-password"]);
            } else if (!this.cookieService.get("auth_token")) {
                this.cooikeeCleared = true;
                let pageName = window.location.pathname.split('/')[2];
                if (!this.popupOpend && (pageName !== '' && pageName !== '/' && pageName !== '/logout' && pageName !== 'register'
                    && pageName !== 'vistara-login' && pageName !== 'vistara-logout')) {
                    this.popupOpend = true;
                    const dialogRef = this.matDialog.open(SessionModelComponent, {
                        disableClose: true,
                        width: '415px',
                        height: '80px',
                        data: { isSessionExpire: true }
                    })
                    dialogRef.afterClosed().subscribe(result => {
                    });
                }
            }
        }, 500);

        idle.setIdle(900);  //15 mins Idle listen
        idle.setTimeout(10); //10 sec warning message
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            let userDetails = this.grmsUtils.getUserDetails();
            this.sessionClear();
            if (this.grmsUtils.isNull(userDetails) || userDetails?.login_type === 'Non-Vistara') {
                this.router.navigate(['/']);
            } else {
                window.location.href = 'https://federation-uat.airvistara.com/adfs/ls/?wa=wsignout1.0';
            }
        });

        idle.onIdleStart.subscribe(() => {
            console.log('No longer idle');
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            let pageName = window.location.pathname.split('/')[2];
            if ((!this.popupOpend && !this.cooikeeCleared) && (pageName !== '' && pageName !== '/' && pageName !== '/logout' && pageName !== 'register'
                && pageName !== 'vistara-login' && pageName !== 'vistara-logout')) {
                this.userProfileService.setIdleCount(countdown);
                const dialogRef = this.matDialog.open(CommanModalComponent, {
                    disableClose: true,
                    width: '555px',
                    height: '145px',
                    data: { "isIdlePopup": true }
                })

                dialogRef.afterClosed().subscribe(result => {
                });
            } else {
                this.userProfileService.setIdleCount(countdown);
            }

            this.popupOpend = true;
        });

    }

    ngOnInit(): void {
        let component = this;
        window['component'] = this;
        window.onload = function (event) {
            if (component.router.url !== '' && component.router.url !== '/' && component.router.url !== '/logout' && component.router.url !== "/register" && component.router.url !== "/forget-password" && !component.isExistingUser) {
                if (component.cookieService.get('auth_token') === undefined || component.cookieService.get('auth_token') === null ||
                    component.cookieService.get('auth_token') === '') {
                    this.component.sessionClear();
                    this.component.router.navigate(["/"]);
                }
            }

            if (component.isExistingUser) {
                this.component.router.navigate(["/"]);
            }
        }

        this.sideNavBar.isSideNavBarShow
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(action => {
                this.isSideNavBarShow.next(action);
                this.fullWidth = action;
                this.isAuthenticated = (this.cookieService.get("auth_token")) ? true : false;
                if (this.isAuthenticated) {
                    this.reset();
                } else {
                    this.idle.stop();
                    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                    this.popupOpend = false;
                }
            });
        this.isAuthenticated = (this.cookieService.get("auth_token")) ? true : false;
    }

    reset() {
        this.idle.watch();
        this.popupOpend = false;
    }

    doUnload() {
        this.doBeforeUnload();
    }

    doBeforeUnload() {
        this.sessionClear();
    }


    sessionClear() {
        this.cookieService.delete('auth_token', '/');
        this.cookieService.delete('login_time', '/');
        this.cookieService.delete('userDetails', '/');
        this.cookieService.delete('refresh_token', '/');
        this.cookieService.delete('currentMenu', '/');
    }


}
