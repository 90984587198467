
import { Headers, RequestOptions, RequestOptionsArgs, Response } from "@angular/http";
import { CookieService } from "ngx-cookie-service";
import * as moment from 'moment';

let window: any;
export class GrmsDiffCals {

    static cookieService:CookieService;

    public static getFormatedStringDate( date: Date ): string {
        let m_names: Array<String> = new Array( "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec" );
        date = new Date( date );
        let dateString: string = this.getZeroPrefixedString( date.getDate() ) + "-" +
            m_names[date.getMonth()] + "-" + this.getZeroPrefixedString( date.getFullYear() );
        return dateString;
    }

    public static getFormatedStringTime( date: Date ): string {
        return "" + this.getZeroPrefixedString( date.getHours() ) + ": " +
            this.getZeroPrefixedString( date.getMinutes() ) + ": 00";
    }

    public static getZeroPrefixedString( digit: number ): string {
        return ( digit < 10 ) ? "0" + String( digit ) : String( digit );
    }

    public static getDateFromString( stringDate: string ): Date {
        return new Date( stringDate );
    }
    public static addDays( date: Date, days: number ): Date {
        let newDate = new Date();
        newDate.setDate( date.getDate() + days );
        return newDate;
    }





    public static findDayDifference( startDate: any, endDate: any ) {
        if(/[0-3]{1}[0-9]{1}-[a-zA-z]{3}-[0-9]{4}/.test(startDate)){
            startDate = startDate.replace("-"," ").replace("-"," ");
        }
        if(/[0-3]{1}[0-9]{1}-[a-zA-z]{3}-[0-9]{4}/.test(endDate)){
            endDate = endDate.replace("-"," ").replace("-"," ");
        }
        let date1 = new Date( startDate );
        let date2 = new Date( endDate );

        let timeDiff = date2.getTime() - date1.getTime();
        let diffDays = timeDiff / ( 1000 * 3600 * 24 );
        return diffDays;
    }

    public static findTimeDifference( preItineraryDate: any, currItineraryDate: any, preItineraryTime: any, currItineraryTime: any ) {
        let m_names: Array<String> = new Array( "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec" );
        if(/[0-3]{1}[0-9]{1}-[a-zA-z]{3}-[0-9]{4}/.test(preItineraryDate)){
            preItineraryDate = preItineraryDate.split("-");
        }
        if(/[0-3]{1}[0-9]{1}-[a-zA-z]{3}-[0-9]{4}/.test(currItineraryDate)){
            currItineraryDate = currItineraryDate.split("-");
        }

        let preItiArrivalTime : any = (preItineraryTime.split("-")[1]).split(":");
        let currItiDepartureTime : any = currItineraryTime.split(":");

        let date1 = new Date( preItineraryDate[2], m_names.indexOf(preItineraryDate[1]), preItineraryDate[0], preItiArrivalTime[0], preItiArrivalTime[1], 0, 0 );
        let date2 = new Date( currItineraryDate[2], m_names.indexOf(currItineraryDate[1]), currItineraryDate[0], currItiDepartureTime[0], currItiDepartureTime[1], 0, 0 );

        let timeDiff = date2.getTime() - date1.getTime();
        let timeDiffInMinutes = timeDiff / ( 1000 * 60 );

        return timeDiffInMinutes;
    }

    public static findTimeDiffInMin(preItiArrivalDate: any, currItiDepartDate: any, preItiTime: any, currItiTime: any) {
        let preItiDate = moment(new Date(preItiArrivalDate)).format('YYYY-MM-DD');
        let currItiDate = moment(new Date(currItiDepartDate)).format('YYYY-MM-DD');
        let preItiArrivalTime : any = preItiTime.split("-")[1];
        let currItiDeptTime : any = currItiTime;
        let preItiDateWithArrTime = preItiDate + " " + preItiArrivalTime;
        let currItiDateWithDeptTime = currItiDate + " " + currItiDeptTime;
        let date1 = moment(preItiDateWithArrTime);
        let date2 = moment(currItiDateWithDeptTime);
        let diffTimeInMin = date2.diff(date1, "minutes");
        return diffTimeInMin;
    }
    public static findTimeDiffMins(preItiArrivalDate: any, currItiDepartDate: any, preItiTime: any, currItiTime: any) {
        let preItiDate = moment(new Date(preItiArrivalDate)).format('YYYY-MM-DD');
        let currItiDate = moment(new Date(currItiDepartDate)).format('YYYY-MM-DD');
        let preItiArrivalTime : any = preItiTime;
        let currItiDeptTime : any = currItiTime;
        let preItiDateWithArrTime = preItiDate + " " + preItiArrivalTime;
        let currItiDateWithDeptTime = currItiDate + " " + currItiDeptTime;
        let date1 = moment(preItiDateWithArrTime);
        let date2 = moment(currItiDateWithDeptTime);

        let diffTimeInMin = date2.diff(date1, "minutes");
        return diffTimeInMin;
      }


    public static interceptError( error: any ): void {
    }
    public static findTimeDifferenceStatus(quoteValidTill: any): boolean{
        let currDate = new Date();
        let currDateSec: number = currDate.getHours()*3600 + currDate.getMinutes()*60 + currDate.getSeconds();
        let quoteValidSec: number = quoteValidTill.getHours()*3600 + quoteValidTill.getMinutes()*60 + quoteValidTill.getSeconds();
        if(currDate.getDate()==quoteValidTill.getDate() && currDate.getMonth()==quoteValidTill.getMonth() &&
         currDate.getFullYear()==quoteValidTill.getFullYear() && quoteValidSec<currDateSec){
            return false;
        }
        return true;
    }
}
