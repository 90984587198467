import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appCommentsAlphaNumeric]'
})
export class CommentsAlphaNumericDirective {

    constructor(private _el: ElementRef) { }

    key;
    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
        var regex = new RegExp("^[a-zA-Z0-9 ,.()]+$");
        if (!regex.test(event.key)) {
            event.preventDefault();
            return false;
        }
    }
}

