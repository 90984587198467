import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GSA_REQ_LIST, REQUEST_LIST } from '../../shared/static/constants';
import { REPORT_API_ENDPOINTS, SERIES_API_ENDPOINTS } from '../../shared/httpService/apiURLConstant';
import { HttpService } from '../../shared/httpService/http.service';
import { GrmsUtils } from '../../shared/static/GRMSUtils';
import { LoaderService } from '../../shared/services/gb-loader.services';
import { MenuAccessService } from 'src/app/shared/services/menu-access.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {


    private unSubscribe: Subject<boolean> = new Subject<boolean>();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public monthlyColumnDefs: any[] = [];
    public requestList = REQUEST_LIST;
    public selectedRequest = '';
    public dashboardTitle = '';
    public monthlyReportData;
    monthlyReport = [];


    constructor(
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private menuService: MenuAccessService,
        private userProfileService: UserProfileService
    ) { }

    ngOnInit(): void {
        this.menuService.hideAndShowMenu();
        this.selectedRequest = this.cookieService.get('currentMenu');
        this.dashboardTitle = (this.selectedRequest !== 'gsa' && this.selectedRequest !== 'series') ? "Adhoc Request Details" :
            (this.selectedRequest !== 'gsa' && this.selectedRequest === 'series') ? "Series Request Details" : "GSA Request Details";
        this.loadMonthlyData();
    }

    refresh() {
        this.monthlyReport = [];
        this.monthlyColumnDefs = [];
        this.loadMonthlyData();
    }

    loadMonthlyData() {
        this.monthlyReportData = [];
        this.monthlyColumnDefs = [];
        this.monthlyReport = [];
        let userDetails = this.grmsUtils.getUserDetails();
        let payload = {
            "userId": userDetails.loginUserId,
        }
        if (this.selectedRequest === 'gsa') {
            this.requestList = [...GSA_REQ_LIST];
        } else {
            this.requestList = [...REQUEST_LIST];
        }
        let endPoint = (userDetails?.gsaUser === 'Y' || this.selectedRequest === 'gsa') ? REPORT_API_ENDPOINTS.getGSAMonthlyReport :
            (userDetails?.gsaUser !== 'Y' && this.selectedRequest === 'series') ? SERIES_API_ENDPOINTS.SERIES_MONTHLY_REPORT : REPORT_API_ENDPOINTS.getMonthlyReport;
        this.loaderService.show();
        this.httpService.postCall(endPoint, payload, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.unSubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    this.monthlyReport = response;
                    let groupByMonthReport = _.groupBy(this.monthlyReport, report => report.month);
                    let colObjs = Object.keys(groupByMonthReport);
                    colObjs.forEach(item => {
                        let tempObj = {};
                        tempObj['header'] = item;
                        tempObj['headerDesc'] = item + " '" + groupByMonthReport[item][0].year.substring(2, 4);
                        this.monthlyColumnDefs.push(tempObj);
                    });

                    let tempObj = {};

                    this.requestList.forEach(item => {
                        tempObj[item.key] = [];
                        this.monthlyReport.forEach(item1 => {
                            let obj = {};
                            let keyName = item1.month;
                            let valueName = item1[item.reqCode];
                            obj[keyName] = valueName;
                            tempObj[item.key].push(obj);
                            return;
                        });
                    });

                    this.monthlyReportData = tempObj;
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}


