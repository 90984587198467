import { Component, OnInit, OnDestroy, Inject, EventEmitter, ViewEncapsulation, Output, Input, ViewChild, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GBBookingService } from '../../shared/services/booking/gb-booking.service';
import { GrmsUtils } from 'src/app/shared/static/GRMSUtils';
import { HttpService } from 'src/app/shared/httpService/http.service';
import { BOOKING_API_ENPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { LoaderService } from 'src/app/shared/services/gb-loader.services';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector: 'search-flight-list',
    templateUrl: './search-flight-list.component.html',
    styleUrls: ['./search-flight-list.component.css']
})
export class SearchFlightListComponent implements OnInit {
    // @Input() getData;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    //@Output() tableData =  new EventEmitter<any>();
    public tableDatas: any = []
    public columnHeader: any;
    public enableLoadMoreOnwardAndReturn: boolean;
    private searchFlights: any;
    private searchReqCount: number = 0;
    private searchSession: any;
    public errorMessage: any;
    public disableLoadMore: boolean = false;
    public showTable: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SearchFlightListComponent>,
        private gbBookingService: GBBookingService,
        private grmsUtils: GrmsUtils,
        private snackBar: MatSnackBar,
        private httpService: HttpService,
        private loaderService: LoaderService) { }

    ngOnInit(): void {
        this.searchFlights = this.data.flightRequest;
        this.searchSession = this.data.flightResponse.session;
        this.searchFlights['searchReq'] = this.data.flightResponse.session.sequenceNumber
        this.searchFlights['session'] = this.searchSession;
        let flightListDetials = this.data?.flightResponse.flightInfoList[0];
        if (this.data.flightResponse?.flightInfoList[0]?.endOfSearch == false && Object.keys(this.data.flightResponse?.flightInfoList[0]?.flightList).length <= 0) {
            this.showTable = false;
            this.errorMessage = 'No flights found for this search, please click load more button to search for flights.';
        } else if (this.data.flightResponse.flightInfoList[0].endOfSearch == true && Object.keys(this.data.flightResponse?.flightInfoList[0]?.flightList).length > 0) {
            this.tableContent(flightListDetials);
            this.disableLoadMore = true;
            this.showTable = true;
        } else if (this.data.flightResponse?.flightInfoList[0]?.endOfSearch == false && Object.keys(this.data.flightResponse?.flightInfoList[0]?.flightList).length > 0) {
            this.tableContent(flightListDetials);
            this.showTable = true;
        } else {
            this.showTable = false;
            this.disableLoadMore = true;
            this.errorMessage = "No Flights Found";
        }

    }

    tableContent(info) {
        this.columnHeader = { flightNo: "Flight No", originCode: "From", destinationCode: "To", departureTime: "Departure Time", arrivalTime: "Arrival Time", travelDuration: "Travel Duration", stops: "No Of Stops" }
        const flightArr = ['directFlight', 'connectingFlight'];
        for (let j = 0; j < flightArr.length; j++) {
            if(info.flightList[flightArr[j]]?.length > 0) {
                for (let i = 0; i < info.flightList[flightArr[j]].length; i++) {
                    let singleChild = { flightNo: "", originCode: "", destinationCode: "", departureDate: "", departureTime: "", arrivalTime: "", arrivalDate: "", travelDuration: "", stops: "" }
                    if(flightArr[j] == 'connectingFlight'){
                        let ele = info.flightList['connectingFlight'];
                        singleChild.travelDuration = ele[i].totalDuration;
                        singleChild['details'] = ele[i].connectingFlight;
                        for (let j = 0; j < ele[i].connectingFlight.length; j++) {
                            if (j != ele[i].connectingFlight.length - 1) {
                                singleChild.flightNo += ele[i].connectingFlight[j].flightNo + " | ";
                            } else {
                                singleChild.flightNo += ele[i].connectingFlight[j].flightNo;
                            }
                            singleChild.stops = `${ele[i].connectingFlight.length - 1}`;
                            singleChild.originCode = ele[i].connectingFlight[0].originCode;
                            singleChild.destinationCode = ele[i].connectingFlight[ele[i].connectingFlight.length - 1].destinationCode;
                            singleChild.departureTime = ele[i].connectingFlight[0].departureTime;
                            singleChild.arrivalTime = ele[i].connectingFlight[ele[i].connectingFlight.length - 1].arrivalTime;
                            singleChild.arrivalDate = ele[i].connectingFlight[ele[i].connectingFlight.length - 1].arrivalDate;
                        }
                        this.tableDatas.push(singleChild);
                    }
                    else {
                        let ele = info.flightList[flightArr[j]];
                        singleChild.flightNo = ele[i].flightNo;
                        singleChild.originCode = ele[i].originCode;
                        singleChild.destinationCode = ele[i].destinationCode;
                        singleChild.departureTime = ele[i].departureTime;
                        singleChild.arrivalTime = ele[i].arrivalTime;
                        singleChild.arrivalDate = ele[i].arrivalDate;
                        singleChild.travelDuration = ele[i].travelDuration;
                        singleChild.departureDate = ele[i].departureDate;
                        singleChild.stops = ele[i].stops;
                        this.tableDatas.push(singleChild);
                    }
                }
            }
        }
        if(this.tableDatas.length > 0){
            this.gbBookingService.setFlightListData(this.tableDatas);
        } else {
            this.gbBookingService.setFlightListData([]);
        }
        this.tableDatas['previousInfo'] = this.data.previousInfo;
    }

    loadMore() {
        this.loaderService.show();
        this.httpService.postCall(BOOKING_API_ENPOINTS.searchFlights, this.searchFlights, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    if (response.flightInfoList[0].endOfSearch === true && Object.keys(response.flightInfoList[0].flightList).length === 0) {
                        this.disableLoadMore = true;
                        this.errorMessage = 'No flights found';

                    } else if (Object.keys(response.flightInfoList[0].flightList).length <= 0) {
                        this.errorMessage = 'No flights found for this search, please click load more button to serach for flights.';
                    }
                    else {
                        this.errorMessage = '';
                        this.searchReqCount = this.searchReqCount + 1;
                        this.searchFlights['searchReq'] = response.session.sequenceNumber;
                        this.searchFlights['session'] = response.session;
                        this.tableContent(response.flightInfoList[0])
                        //this.tableDatas.reverse();
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            })
    }

    getFlightInfo(data) {
        if (data.originCode.toLowerCase() == this.data.flightRequest.departureStation.toLowerCase() && data.destinationCode.toLowerCase() == this.data.flightRequest.arrivalStation.toLowerCase()) {
            this.dialogRef.close({ event: 'close', data: data });
        } else {
            this.snackBar.open('Please select same origin and destination entered in request', 'X', { duration: 3000 })
        }

    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
