import { NgModule } from '@angular/core';
import { GBMaterialModule } from '../gb-material.module'
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { CommentsComponent } from './component/comments/comments.component';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { DatatableComponent } from './component/datatable/datatable.component';
import { AlphabetsOnlyDirective } from './directives/alphabets-only.directive';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { CommanModalComponent } from './model/comman-modal/comman-modal.component';
import { SessionModelComponent } from './model/session-model/session-model.component';
import { AlphaNumericPaymentDirective } from './directives/alpha-numeric-payment.directive';
import { AlphaNumericSpecialDirective } from './directives/alpha-numeric-special.directive';
import { CommentsAlphaNumericDirective } from './directives/comments-alpha-numeric.directive';
import { BookingDatatableComponent } from './component/booking-datatable/booking-datatable.component'
import { DatatableModelComponent } from './component/datatable/datatable-model/datatable-model.component';
import { PopOverContentComponent } from '../shared/component/pop-over-content/pop-over-content.component';

@NgModule({
    declarations: [
        TruncateTextPipe,
        CommentsComponent,
        DatatableComponent,
        NumberOnlyDirective,
        CommanModalComponent,
        SessionModelComponent,
        AlphaNumericDirective,
        AlphabetsOnlyDirective,
        DatatableModelComponent,
        PopOverContentComponent,
        CurrencyFormatDirective,
        BookingDatatableComponent,
        AlphaNumericSpecialDirective,
        AlphaNumericPaymentDirective,
        CommentsAlphaNumericDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        GBMaterialModule,
        ReactiveFormsModule
    ],
    exports: [
        DatatableComponent,
        CommentsComponent,
        NumberOnlyDirective,
        AlphaNumericDirective,
        AlphabetsOnlyDirective,
        PopOverContentComponent,
        DatatableModelComponent,
        CurrencyFormatDirective,
        BookingDatatableComponent,
        AlphaNumericSpecialDirective,
        AlphaNumericPaymentDirective
    ],
    providers: [CurrencyPipe],
})

export class SharedModule { }
