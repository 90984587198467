import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SideNavBarService {

    constructor() { }

    public oldMail: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public newMail: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isNavShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isSideNavBarShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isRegisterIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    setOldMailId(inputValue) {
        this.oldMail.next(inputValue);
    }

    setNewMailId(inputValue) {
        this.newMail.next(inputValue);
    }

    showMenu() {
        this.isNavShow.next(true);
    }

    showSideNavBar() {
        this.isSideNavBarShow.next(true);
    }

    showRegisterIcon() {
        this.isRegisterIcon.next(true);
    }

    hideMenu() {
        this.isNavShow.next(false);
    }

    hideSideNavBar() {
        this.isSideNavBarShow.next(false);
    }

    hideRegisterIcon() {
        this.isRegisterIcon.next(false);
    }
}
