export class ProfileUpdates {
    userId?: string;
    firstName?: string;
    lastName?: string;
    emailId?: string;
    phoneNumber?: Number;
    address1?: string;
    address2?: string;
    country?: string;
    state?: string;
    city?: string;
    pincode?: string;
}
