import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GBBookingService } from '../../services/booking/gb-booking.service';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { COMMON_API_ENDPOINTS, REPORT_API_ENDPOINTS } from '../../../shared/httpService/apiURLConstant';
import { HttpService } from '../../../shared/httpService/http.service';
import { GrmsUtils } from '../../../shared/static/GRMSUtils';
import { GrmsDiffCals } from '../../static/grms-cals';
import { GRMS_CONSTANT } from '../../static/constants';
import { LoaderService } from '../../services/gb-loader.services';
import { DatePipe } from '@angular/common';
import { PopOverContentComponent } from '../pop-over-content/pop-over-content.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CommanModalComponent } from '../../model/comman-modal/comman-modal.component';

export const MY_FORMATS = {
    parse: {
        dateInput: "DD-MMM-YYYY"
    },
    display: {
        dateInput: "DD-MMM-YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "DD-MMM-YYYY",
        monthYearA11yLabel: "MMMM YYYY"
    }
};
@Component({
    selector: 'booking-datatable',
    templateUrl: './booking-datatable.component.html',
    styleUrls: ['./booking-datatable.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class BookingDatatableComponent implements OnInit {
    @Input() tableData;
    @Input() columnHeader;
    @Input() dataRequest;
    @Input() isClaim;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @Input() pageInfo;
    hidePaginator = false;
    public dataSource = new MatTableDataSource();
    objectKeys = Object.keys;
    @Output() selectedPaxInfo = new EventEmitter<any>();
    @Output() confirmPaxInfo = new EventEmitter<any>();
    @Output() createPnr = new EventEmitter<any>();
    @Output() paxDownSizeChange = new EventEmitter<any>()
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public paxtitiles: string[] = ["MR", "MRS", "MS", "MSTR", "MISS", "DR"];
    public passengertype: string[] = ["Adult", "Child", "Infant"];
    public genderList: any[] = [
        { genderType: "Male", genderCode: "M" },
        { genderType: "Female", genderCode: "F" }
    ];
    public countryResponseList: any = [];
    public nationalityResponseList: any = [];
    public mealDetails: any = [];
    public apiDocument: any = [{ documentType: "PASSPORT", documentCode: "P" }]
    public expandedElement: null;
    base64File: string = null;
    filename: string = null;
    private gbRequest: any = { 'pax': [] }
    public paxUpdateform: FormGroup;
    public tempAddRow = {};
    private paxArray: any[] = [];
    public displayColumn: any = [];
    public setPaxLimit: any;
    private userDetails: any;
    private loginUserID: any;
    public currentrole: any;
    public currentassignee: boolean = false;
    public Inter: any = {
        gender: '', dobdoc: '', nationality: '', doctype: '',
        docnumber: '', issuecountry: '', docexpiry: ''
    }
    public isInternational: boolean = false;
    public associatedAdults: number[] = [];
    public isPaxUpdate: boolean = false;
    public loginUserRole: any;
    public readrules: any = {
        "confirmpaxinfoaction": ["PNR CREATED", "PAX INFO PENDING"],
        'downSizePax': ["PAX CONFIRMED", "TICKETS ISSUED"],
        "pnrno": ["QUOTE ACCEPTED", "PNR CREATED", "PAX INFO PENDING", "PAX CONFIRMED", "TICKETS ISSUED"],
    }
    public loginUserEmail: string = "";
    public currentDate: Date = new Date();
    public phoneCodeLength = "";
    public selection = new SelectionModel<any>(true, []);
    public totalAdult = 0;
    public totalChild = 0;
    public totalInfant = 0;
    public associatedAdultList = [];
    public isNotValidAssociate = false;
    public alreadySelectedAssociate = [];

    constructor(
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private httpService: HttpService,
        private gbBookingService: GBBookingService,
        private formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private grmsUtils: GrmsUtils,
        private loaderService: LoaderService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.userDetails = this.grmsUtils.getUserDetails();
        this.loginUserEmail = this.cookieService.get("emailId");
        this.loginUserID = this.userDetails.loginUserId;
        this.loginUserRole = this.userDetails.user_role;
        this.setPaxLimit = this.dataRequest.requestgeneralData.noOfAdult + this.dataRequest.requestgeneralData.noOfChild + this.dataRequest.requestgeneralData.noOfInfant;
        this.totalAdult = this.dataRequest.requestgeneralData.noOfAdult;
        this.totalChild = this.dataRequest.requestgeneralData.noOfChild;
        this.totalInfant = this.dataRequest.requestgeneralData.noOfInfant;
        let userPaxUpdate = true;
        this.currentrole = (this.userDetails.user_role === "ROLE_ADMIN" || this.userDetails.user_role === "ROLE_DESK_USER"
            || this.userDetails.user_role === "ROLE_GS" || this.userDetails.user_role === "ROLE_SU") ? "user" : "requester";
        this.currentassignee = (this.dataRequest.currAssinee.indexOf(this.loginUserEmail) > -1) ? true : false;
        this.isInternational = this.dataRequest.isInternational;
        this.phoneCodeLength = (this.userDetails['gsaUser'] === 'Y' || this.isInternational) ? "15" : "10";

        this.dataRequest.paxDetails.forEach(obj => {
            let adultName = obj['firstName'] + " " + obj['lastName'];
            if (this.grmsUtils.isNotNull(adultName) && this.associatedAdultList.indexOf(adultName) === -1 && obj.type === 'Adult') {
                this.associatedAdultList.push({ code: obj.paxRowNo, displayName: adultName });
            }

            if (obj.type === 'Infant' && this.grmsUtils.isNotNull(obj.associatedAdult)) {
                this.alreadySelectedAssociate.push({ "infantCode": obj.paxRowNo, "associateCode": obj.associatedAdult });
            }
        })

        if ((this.dataRequest.currAssinee.indexOf("DESK_USER") > -1) && (this.loginUserRole === "ROLE_ADMIN" || this.loginUserRole === "ROLE_DESK_USER"
            || this.loginUserRole === "ROLE_GS" || this.loginUserRole === "ROLE_SU")) {
            userPaxUpdate = false;
        }
        this.isPaxUpdate = (this.dataRequest.requestStatus !== "PAX CONFIRMED" && this.dataRequest.requestStatus !== "TICKETS ISSUED" && this.dataRequest.requestStatus !== "REQUEST CANCELED" && userPaxUpdate) ? true : false;
        this.httpService.postCall(REPORT_API_ENDPOINTS.getMealCodeList, { userId: this.loginUserID }, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (mealResponse) => {
                    if (mealResponse) {
                        this.mealDetails = mealResponse['mealCodeList']
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
        this.httpService.getCall(COMMON_API_ENDPOINTS.getCountryList, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.nationalityResponseList = response;
                    }
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
        this.updatePaxInfo()
        if (this.dataRequest.requestStatus !== 'PAX CONFIRMED') {
            delete this.columnHeader.deleted
        } else if (this.dataRequest.requestStatus == 'PAX CONFIRMED' || this.dataRequest.requestStatus == 'TICKETS ISSUED') {
            delete this.columnHeader.action
        }

    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    onInput(event) { }

    docdetailsChange(event, index) {
        this.paxUpdateform.value['paxInfo'][index].docdetails = (event.checked) ? true : false;
    }

    isEligibleFor(roles: any, modulename: any): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (roles[i] === this.currentrole) {
                if (this.currentassignee) {
                    if (this.readrules[modulename].indexOf(this.dataRequest.requestStatus) > -1) {
                        return false;
                    }
                }


                if (modulename === "paxsizechangeaction" || modulename === "enableNegotiationAction" || modulename === "paymentDescriptionAction") {
                    if (this.readrules[modulename].indexOf(this.dataRequest.requestStatus) > -1) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    downloadAttachment(id: any, attchName: any, attchType: any) {
        this.loaderService.show();
        this.httpService.getCall(REPORT_API_ENDPOINTS.downloadFile + id, this.grmsUtils.getFileJSONHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    let blob: any = new Blob([response]);
                    FileSaver.saveAs(blob, attchName);

                },
                error: (errorDetails: any) => {
                    this.loaderService.hide();
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }
    updatePaxInfo() {
        this.paxUpdateform = this.formBuilder.group({
            paxInfo: this.formBuilder.array([])
        })
        let tempDataSource: any[] = [];
        tempDataSource = this.createFormObject();
        this.paxUpdateform = this.formBuilder.group({
            paxInfo: this.formBuilder.array(tempDataSource.map(obj =>
                this.formBuilder.group(
                    obj
                )
            ))
        });

        this.tempAddRow = this.createTableObject();
        this.loadTableData()
    }

    createFormObject() {
        this.tableData.map((el => {
            let paxValidResult = this.paxValidtion(el)
            this.paxArray.push(paxValidResult);
        }));
        return this.paxArray;
    }

    paxValidtion(el) {
        let paxObj = {};
        Object.keys(el).forEach((val, i) => {
            if (val == 'dob') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(new Date(el[val]));
                } else {
                    paxObj[val] = new FormControl(el[val]);
                    paxObj[val].setValue(null)
                    paxObj[val].setValidators(null)
                    paxObj[val].setErrors(null);
                }
            } else if (val == 'firstName') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl('', [Validators.required]);
                }
            } else if (val == 'lastName') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl('', [Validators.required]);
                }
            } else if (val == 'title') {
                if (!!el[val]) {
                    if (this.paxtitiles.includes(el[val])) {
                        paxObj[val] = new FormControl(el[val]);
                    } else {
                        paxObj[val] = new FormControl(null, [Validators.required]);
                        paxObj[val].setValidators(Validators.required);
                    }

                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            } else if (val == 'type') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'ticketNumber') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null);
                    paxObj[val].setValue('')
                }
            }
            else if (val == 'gender') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'dobdoc') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(new Date(el[val]));
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'nationality') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'doctype') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'docnumber') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'issuecountry') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'docexpiry') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(new Date(el[val]));
                } else {
                    paxObj[val] = new FormControl(null, [Validators.required]);
                    paxObj[val].setValidators(Validators.required);
                }
            }
            else if (val == 'docdetails') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                    paxObj[val].setValue(el[val] == 'true' || el[val] == true ? true : false)
                } else {
                    paxObj[val] = new FormControl(null);
                    // if (!!this.isInternational) {
                    //     paxObj[val].setValue(true)
                    //     paxObj[val].setValidators(Validators.required);
                    // }

                }
            }
            else if (val == 'deleted') {
                if (!!el[val]) {
                    paxObj[val] = new FormControl(el[val]);
                } else {
                    paxObj[val] = new FormControl(null);
                    paxObj[val].setValue('NO');
                }
            }
            else {
                paxObj[val] = new FormControl(el[val]);
            }
            paxObj['action'] = new FormControl(false);
        });

        return paxObj
    }

    createTableObject() {
        let formObj = {};
        for (const property in this.columnHeader) {
            formObj[property] = new FormControl('');
        }
        formObj['action'] = new FormControl(false);
        return formObj;
    }

    addRow() {
        const control = this.paxUpdateform.get('paxInfo') as FormArray;
        let newRow = this.formBuilder.group(this.paxValidtion(this.createNewUser(this.paxUpdateform.value.paxInfo.length + 1)))
        this.tableData = this.paxUpdateform.value;
        control.push(newRow);
        this.dataSource = new MatTableDataSource(control.controls);
        this.loadTableData()
    }

    deletePax(el, i) {
        const data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + i, 1);
        this.paxArray.splice((this.paginator.pageIndex * this.paginator.pageSize) + i, 1);
        const control = this.paxUpdateform.get('paxInfo') as FormArray;
        control.value.splice((this.paginator.pageIndex * this.paginator.pageSize) + i, 1)
        this.dataSource = new MatTableDataSource(control.controls);
        this.loadTableData();
    }

    loadTableData() {
        let tableData = (this.paxUpdateform.get('paxInfo') as FormArray).controls
        this.dataSource.data = tableData;
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = 0;
        this.dataSource.paginator = this.paginator;
        this.hidePaginator = this.tableData.length === 0 ? true : false;
        this.onChangePage();
    }

    paginationChange(page: PageEvent) {
        this.gbBookingService.setFlightListPagination(page.pageIndex);
    }

    onChangePage() {
        this.gbBookingService.getFlightListPagination()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response: any) => {
                this.paginator.pageIndex = !!response ? response : 0;
                this.dataSource.paginator = this.paginator;
            });
    }

    private findTimeDiffInMin(preItiDepartDate: any, currItiDepartDate: any, preItiTime: any, currItiTime: any) {
        let preItiDate = moment(new Date(preItiDepartDate)).format('YYYY-MM-DD');
        let currItiDate = moment(new Date(currItiDepartDate)).format('YYYY-MM-DD');
        let preItiArrivalTime: any = preItiTime;
        let currItiDeptTime: any = currItiTime;
        let preItiDateWithArrTime = preItiDate + " " + preItiArrivalTime;
        let currItiDateWithDeptTime = currItiDate + " " + currItiDeptTime;
        let date1 = moment(preItiDateWithArrTime);
        let date2 = moment(currItiDateWithDeptTime);
        let diffTimeInMin = date2.diff(date1, "minutes");
        return diffTimeInMin;
    }

    createNewUser(id: number) {
        if (this.isInternational == true) {
            return {
                paxRowNo: '', title: '', firstName: '', lastName: '', type: '', dob: '',
                email: '', contactNo: '', mealPreference: '', associatedAdult: '', ticketNumber: '', docdetails: '',
                action: '', deleted: '', gender: '', dobdoc: '', nationality: '', doctype: '',
                docnumber: '', issuecountry: '', docexpiry: ''
            }
        } else {
            return {
                paxRowNo: '', title: '', firstName: '', lastName: '', type: '', dob: '',
                email: '', contactNo: '', mealPreference: '', associatedAdult: '', ticketNumber: '', action: '', deleted: '',

            }
        }

    }

    clearFieldsOnChangingPaxType(i: any) {
        //this.paxUpdateform.value.paxInfo[i]
        this.paxUpdateform.value.paxInfo[i].dob = "";
        this.paxUpdateform.value.paxInfo[i].associatedAdult = undefined;
        this.paxUpdateform.value.paxInfo[i].docdetails = "";
        this.paxUpdateform.value.paxInfo[i].issuecountry = "";
        this.paxUpdateform.value.paxInfo[i].docnumber = "";
        this.paxUpdateform.value.paxInfo[i].nationality = "";
        this.paxUpdateform.value.paxInfo[i].docexpiry = "";
        if (!!this.paxUpdateform.value.paxInfo[i].mealPreferencemp) {
            this.paxUpdateform.value.paxInfo[i].mealPreferencemp = "";
        }
        if (i == 0 && this.paxUpdateform.value.paxInfo[i].type != 'Adult') {
            this.snackBar.open("First Pax should be Adult", "X", { duration: 3000 });
        }
        this.bindCorrectGender(i);
    }

    bindCorrectGender(i: any) {
        let j = this.paxUpdateform.value.paxInfo[i];
        if (j.title) {
            if (j.type === "Infant") {
                if (j.title === "MR" || j.title === "MSTR") {
                    j['gender'] = "MI";
                } else {
                    if (j.title === "MRS" || j.title === "MS" || j.title === "MISS") {
                        j['gender'] = "FI";
                    } else {
                        j['gender'] = "";
                    }
                }
            } else {
                if (j.title === "MR" || j.title === "MSTR") {
                    j['gender'] = "M";
                } else {
                    if (j.title === "MRS" || j.title === "MS" || j.title === "MISS") {
                        j['gender'] = "F";
                    } else {
                        j['gender'] = "";
                    }
                }
            }
        } else {
            j['gender'] = "";
        }


        return j;
    }

    addPax(index) {
        this.gbRequest.pax.push(this.createNewUser(index))
    }

    loadExcelForPax(e: any) {
        let fileList: FileList = e.target.files;
        if (fileList.length > 0) {
            let file: File = e.target.files[0];
            let supportedFileList = [".xls", ".xlsx"];
            let isValidFile: boolean = false;
            for (let fileType of supportedFileList) {
                if (file.name.endsWith(fileType)) {
                    isValidFile = true;
                }
            }

            if (isValidFile) {
                let reader = new FileReader();
                let worksheet: any;
                let rows: number;
                this.gbRequest.pax = [];

                reader.onload = (e: any) => {
                    this.filename = file.name;
                    this.base64File = e.target.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, { type: 'binary' });

                    let first_sheet_name = workbook.SheetNames[0];
                    // let address_of_cell = 'A1';

                    /* Get worksheet */
                    worksheet = workbook.Sheets[first_sheet_name];
                    let str = worksheet['!ref'] + "";
                    let rang_last_cell = (str.split(':'))[1];
                    rows = parseInt(rang_last_cell.substr(1, rang_last_cell.length));


                    let persons = this.setPaxLimit;

                    for (let i = 2, j = 1; j <= persons && i <= 100; i++, j++) {
                        this.addPax(j);
                        let index = i - 2;
                        this.gbRequest.pax[index].paxRowNo = index + 1
                        this.gbRequest.pax[index].title = worksheet['B' + i] != undefined ? worksheet['B' + i].v : "";
                        this.gbRequest.pax[index].firstName = worksheet["C" + i] != undefined ? worksheet['C' + i].v : "";
                        this.gbRequest.pax[index].lastName = worksheet['D' + i] != undefined ? worksheet['D' + i].v : "";
                        this.gbRequest.pax[index].type = worksheet['E' + i] != undefined ? worksheet['E' + i].v : "Adult";
                        this.gbRequest.pax[index].dob = worksheet['F' + i] != undefined ? worksheet['F' + i].w : "";
                        this.gbRequest.pax[index].email = worksheet['G' + i] != undefined ? worksheet['G' + i].v : "";
                        this.gbRequest.pax[index].contactNo = worksheet['H' + i] != undefined ? worksheet['H' + i].v : "";
                        let mealValue = worksheet['I' + i] != undefined ? worksheet['I' + i].v : "";
                        this.gbRequest.pax[index].mealPreference = "";
                        this.gbRequest.pax[index]['ticketNumber'] = "";
                        this.gbRequest.pax[index]['action'] = "";
                        this.gbRequest.pax[index]['deleted'] = 'NO';
                        for (let meal of this.mealDetails) {
                            if (mealValue == meal.mealType) {
                                this.gbRequest.pax[index].mealPreference = meal.mealCode;
                                break;
                            }
                        }
                        if (this.gbRequest.pax[index].type === 'Infant') {
                            if (this.gbRequest.pax[index].mealType) {
                                if (this.gbRequest.pax[index].mealPreference != "BBML") {
                                    this.gbRequest.pax[index].mealPreference = "";
                                }
                            }
                        } else {
                            if (this.gbRequest.pax[index].mealPreference) {
                                if (this.gbRequest.pax[index].mealType == "BBML") {
                                    this.gbRequest.pax[index].mealPreference = "";
                                }
                            }
                        }
                        this.gbRequest.pax[index].associatedAdult = worksheet['J' + i] != undefined ? worksheet['J' + i].v : 0;
                        if (this.gbRequest.pax[index].type === 'Infant') {
                            if (this.gbRequest.pax[index].associatedAdult) {
                                if (this.gbRequest.pax[index].associatedAdult > persons) {
                                    this.gbRequest.pax[index].associatedAdult = undefined;
                                }
                            }
                        } else {
                            this.gbRequest.pax[index].associatedAdult = undefined;
                        }
                        if (this.isInternational == true) {
                            let genderVal = worksheet['K' + i] != undefined ? worksheet['K' + i].v : "";
                            this.gbRequest.pax[index].gender = "";
                            if (this.gbRequest.pax[index].type === 'Infant') {
                                if (genderVal) {
                                    if (genderVal.toUpperCase() === 'MALE') {
                                        this.gbRequest.pax[index].gender = 'MI';
                                    } else {
                                        this.gbRequest.pax[index].gender = 'FI';
                                    }
                                }
                            } else {
                                if (genderVal) {
                                    if (genderVal.toUpperCase() === 'MALE') {
                                        this.gbRequest.pax[index].gender = 'M';
                                    } else {
                                        this.gbRequest.pax[index].gender = 'F';
                                    }
                                }
                            }
                            this.gbRequest.pax[index].dobdoc = worksheet['L' + i] != undefined ? worksheet['L' + i].w : "";
                            let countryName = worksheet['M' + i] != undefined ? worksheet['M' + i].v : "";
                            this.gbRequest.pax[index].issuecountry = "";
                            for (let countrySelect of this.nationalityResponseList) {
                                if (countryName === countrySelect['countryName']) {
                                    this.gbRequest.pax[index].issuecountry = countrySelect['countryCode'];
                                    break;
                                }
                            }
                            let documentType = worksheet['N' + i] != undefined ? worksheet['N' + i].v : "";
                            this.gbRequest.pax[index].doctype = "";

                            for (let doucment of this.apiDocument) {
                                if (documentType.toUpperCase() == doucment['documentType'].toUpperCase()) {

                                    this.gbRequest.pax[index].doctype = doucment['documentCode']
                                    break;
                                }
                            }

                            this.gbRequest.pax[index].docnumber = worksheet['O' + i] != undefined ? worksheet['O' + i].v : "";
                            let nationalityName = worksheet['P' + i] != undefined ? worksheet['P' + i].v : "";
                            this.gbRequest.pax[index].nationality = "";
                            for (let nationalitySelect of this.nationalityResponseList) {
                                if (nationalityName === nationalitySelect['nationality']) {
                                    this.gbRequest.pax[index].nationality = nationalitySelect['countryCode'];
                                    break;
                                }
                            }
                            this.gbRequest.pax[index].docexpiry = worksheet['Q' + i] != undefined ? worksheet['Q' + i].w : "";
                            if (this.gbRequest.pax[index].gender || this.gbRequest.pax[index].dobdoc ||
                                this.gbRequest.pax[index].issuecountry || this.gbRequest.pax[index].doctype ||
                                this.gbRequest.pax[index].docnumber || this.gbRequest.pax[index].nationality ||
                                this.gbRequest.pax[index].docexpiry) {
                                this.gbRequest.pax[index].docdetails = true;
                            } else {
                                this.gbRequest.pax[index].docdetails = false;
                            }
                        }
                    }
                    this.tableData = []
                    this.paxArray = []
                    this.paxUpdateform.value.paxInfo = []

                    this.paxUpdateform = this.formBuilder.group({
                        paxInfo: this.formBuilder.array([])
                    });
                    this.tableData = this.gbRequest.pax;
                    this.paxUpdateform.get('paxInfo').setValue([]);

                    this.gbRequest.pax.forEach(obj => {
                        let adultName = obj['firstName'] + " " + obj['lastName'];
                        if (this.grmsUtils.isNotNull(adultName) && this.associatedAdultList.indexOf(adultName) === -1 && obj.type === 'Adult') {
                            this.associatedAdultList.push({ code: obj.paxRowNo, displayName: adultName });
                        }

                        if (obj.type === 'Infant' && this.grmsUtils.isNotNull(obj.associatedAdult) && obj.associatedAdult !== "0") {
                            this.alreadySelectedAssociate.push({ "infantCode": obj.paxRowNo, "associateCode": obj.associatedAdult });
                        }
                    });
                    this.updatePaxInfo()

                }
                reader.readAsBinaryString(file);

            } else {
                this.snackBar.open(" File type should be .xlsx,.xls", 'X', { duration: 3000 });
            }
        }
    }


    checkSameName(option: string) {
        let fullNameArray: string[] = [];
        let sameNameArray: string[] = [];
        let tempFullName: string;
        for (let paxObj of this.paxUpdateform.value.paxInfo) {
            if (paxObj.firstName != undefined && paxObj.lastName != undefined) {
                //&& fullNameArray.length > 0
                tempFullName = (paxObj.firstName + paxObj.lastName).toUpperCase().trim();
                if (tempFullName != '' && tempFullName != undefined && fullNameArray.indexOf(tempFullName) > -1) {
                    if (sameNameArray.indexOf(tempFullName) == -1) {
                        sameNameArray.push(tempFullName);
                    }
                }
                fullNameArray.push(tempFullName);
            }
        }
        let confirmTxt;
        if (sameNameArray.length > 0) {
            if (option == 'updatePax') {
                confirmTxt = 'Duplicate names are presented in the Passenger List. Do you want to continue with the duplicate names?'
                this.openConformation(option, confirmTxt)
            } else if (option == 'confirmPax') {
                confirmTxt = 'Duplicate names are presented in the Passenger List. Once Confirmed, any changes in names will be chargeable as per T&Cs. Do you want to confirm the passenger details?'
                this.openConformation(option, confirmTxt)
            }
            // this.snackBar.open("Duplicate Pax in the Pax Info, Please validate and then confirm", "X", { duration: 3000 });
            // return false;
        }
        else {
            if (option == 'updatePax') {
                this.paxUpdateSubmit();
            } else if (option == 'confirmPax') {
                confirmTxt = 'Once Confirmed, any changes in names will be chargeable as per T&Cs. Do you want to confirm the passenger details?'
                this.openConformation(option, confirmTxt)
            }
        }
    }

    openConformation(option, message) {
        let dialogRef = this.matDialog.open(PopOverContentComponent, {
            disableClose: true,
            width: '45%',
            data: { data: message, action: option }
        })
        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result?.data == 'updatePax') {
                this.paxUpdateSubmit()
            } else if (result?.data == 'confirmPax') {
                this.confirmSubmit()
            }
        })

    }

    dobValueChange(index, event?) {
        let singlePax = this.paxUpdateform.value.paxInfo[index];
        if (!!singlePax.type) {
            if (singlePax.dob !== null && singlePax.dob !== undefined && singlePax.dob !== "") {
                let ageDiff = GrmsDiffCals.findDayDifference(
                    new Date(singlePax.dob),
                    new Date(this.dataRequest.itinerayDetails[this.dataRequest.itinerayDetails.length - 1].departureDate)) / 365.25;
                if (singlePax.type === "Adult") {
                    if (ageDiff < 12) {
                        this.snackBar.open("DOB for Pax No " + (index + 1) + " should be greater than 12 years", "X", { duration: 3000 });
                        this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValue()
                    } else {
                        if (this.isInternational == true) {
                            if (!!singlePax.dobdoc) {
                                if (new Date(singlePax.dob).getTime() != new Date(singlePax.dobdoc).getTime() && singlePax.docdetails == true) {
                                    this.snackBar.open("DOB and Document DOB for Pax No " + (index + 1) + " should be Same", "X", { duration: 3000 });
                                    this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                                }
                            } else {
                                this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                            }
                        }

                    }
                }
                else if (singlePax.type === "Child") {
                    if (ageDiff < 2 || ageDiff > 12) {
                        this.snackBar.open("Pax No " + (index + 1) +
                            " is choosen as a child and his age should be 2-12 years from the last Itinerary date", 'X', { duration: 3000 });
                        this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                        this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValue()
                    } else {
                        if (!!this.isInternational) {
                            if (!!singlePax.dobdoc) {
                                if (new Date(singlePax.dob).getTime() != new Date(singlePax.dobdoc).getTime()) {
                                    this.snackBar.open("DOB and Document DOB for Pax No " + (index + 1) + " should be Same", "X", { duration: 3000 });
                                    this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                                }
                            } else {
                                this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dobdoc'].setValue(singlePax.dob);
                            }
                        }
                    }
                }
                else if (singlePax.type === "Infant") {
                    if (ageDiff >= 2) {
                        this.snackBar.open("Pax No " + (index + 1) +
                            " is choosen as a infant and his age should be 0-2 years from the last Itinerary date", 'X', { duration: 3000 });
                        this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                        this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValue()
                    } else {
                        if (!!this.isInternational) {
                            if (!!singlePax.dobdoc) {
                                if (new Date(singlePax.dob).getTime() != new Date(singlePax.dobdoc).getTime()) {
                                    this.snackBar.open("DOB and Document DOB for Pax No " + (index + 1) + " should be Same", "X", { duration: 3000 });
                                    this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                                }
                            } else {
                                this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dobdoc'].setValue(singlePax.dob);
                                this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValidators([Validators.required]);
                            }
                        }
                    }
                }
            }

        } else {
            this.snackBar.open("Kindly select DOB for Pax No " + (index + 1), 'X', { duration: 3000 });
            this.paxUpdateform.controls['paxInfo']['controls'][index].controls['dob'].setValue();
        }

    }

    checkValidPaxUpdate(requestFormDetails: any, checkPaxCount?) {
        this.associatedAdults = [];
        if (this.paxUpdateform.value.paxInfo && this.paxUpdateform.value.paxInfo.length > 0) {
            let adult: number = 0;
            let child: number = 0;
            let infant: number = 0;
            let hasGl: boolean = false;
            let cnumCount: number = 0;
            let paxIndex: number = 0;
            for (let [i, paxObj] of this.paxUpdateform.value.paxInfo.entries()) {

                if (paxObj.title === null || paxObj.title === undefined || paxObj.title === "" || !/^[a-zA-Z]+$/i.test(paxObj.title)) {
                    this.snackBar.open("Kindly enter pax title(Only Alphabets) for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "X", { duration: 3000 });
                    return false;
                } else if (paxObj.firstName == undefined || paxObj.firstName == null || paxObj.firstName == "" || !/^([a-zA-Z\s]+[']*[a-zA-Z\s]*)+$/i.test(paxObj.firstName)) {
                    this.snackBar.open("Kindly enter valid first name for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                    return false;
                } else if (paxObj.lastName == undefined || paxObj.lastName == null || paxObj.lastName == "" || !/^([a-zA-Z\s]+[']*[a-zA-Z\s]+)+$/i.test(paxObj.lastName)) {
                    this.snackBar.open("Kindly enter valid last name for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                    return false;
                } else if (paxObj.type === null || paxObj.type === undefined || paxObj.type === "" || this.passengertype.indexOf(paxObj.type) == -1) {
                    this.snackBar.open("Kindly select pax type for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                    return false;
                } else if (paxObj.dob != null && paxObj.dob != undefined && paxObj.dob != "" && !paxObj.dob) {
                    this.snackBar.open("Kindly enter valid DOB for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                    return false;
                } else {
                    if (this.paxUpdateform.value.paxInfo[0].type != "Adult") {
                        this.snackBar.open("First Pax should be Adult", "X", { duration: 3000 });
                        return false;
                    }
                    if (this.paxUpdateform.value.paxInfo[0].contactNo == "") {
                        this.snackBar.open("First Pax should be add  contact no", "X", { duration: 3000 });
                        return false;
                    }

                    if (paxObj.type === "Adult") {
                        if (paxObj.dob !== null && paxObj.dob !== undefined && paxObj.dob !== "") {
                            let ageDiff = GrmsDiffCals.findDayDifference(
                                new Date(paxObj.dob),
                                new Date(this.dataRequest.itinerayDetails[this.dataRequest.itinerayDetails.length - 1].departureDate)) / 365.25;

                            if (ageDiff < 12) {
                                this.snackBar.open("DOB for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1) + " should be greater than 12 years", "x", { duration: 3000 });
                                return false;
                            }
                        }
                        adult++;
                    } else {
                        if (paxObj.dob === null || paxObj.dob === undefined || paxObj.dob === "") {
                            this.snackBar.open("Kindly select DOB for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                            return false;
                        } else {
                            let ageDiff = GrmsDiffCals.findDayDifference(
                                new Date(paxObj.dob),
                                new Date(this.dataRequest.itinerayDetails[this.dataRequest.itinerayDetails.length - 1].departureDate)) / 365.25;
                            if (paxObj.type === "Child") {
                                if (ageDiff < 2 || ageDiff > 12) {
                                    this.snackBar.open("Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1) +
                                        " is choosen as a child and his age should be 2-12 years from the last Itinerary date", "x", { duration: 3000 });
                                    return;
                                }
                                child++;
                            } else if (paxObj.type === "Infant") {
                                if (ageDiff >= 2) {
                                    this.snackBar.open("Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1) +
                                        " is choosen as a infant and his age should be 0-2 years from the last Itinerary date", "x", { duration: 3000 });
                                    return;
                                }
                                infant++;
                            }
                        }
                    }
                    this.paxUpdateform.value.paxInfo[paxIndex].firstName = this.paxUpdateform.value.paxInfo[paxIndex].firstName.trim();
                    this.paxUpdateform.value.paxInfo[paxIndex].lastName = this.paxUpdateform.value.paxInfo[paxIndex].lastName.trim();
                    paxIndex++;




                    // Validation for not selecting Child as associated adult for Infant
                    if (paxObj.type === "Infant") {
                        for (let pax of this.paxUpdateform.value.paxInfo) {
                            let indexValue = pax.prn + 1;
                            if (paxObj.associatedAdult === indexValue) {
                                if (pax.tp === "Child") {
                                    paxObj.associatedAdult = undefined;
                                }
                            }
                        }
                    }

                    if (paxObj.type === "Infant") {
                        if (paxObj.associatedAdult === undefined || paxObj.associatedAdult === null || paxObj.associatedAdult === '') {
                            this.snackBar.open("Select Associated Adult for all Infants", "x", { duration: 3000 });
                            return false;
                        } else if (this.associatedAdults.indexOf(paxObj.associatedAdult) > -1) {
                            this.snackBar.open("Selected Adult for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1) + " is already associated to one Infant, Kindly associate other Adult", "x", { duration: 3000 });
                            return false;
                        }
                        this.associatedAdults.push(paxObj.associatedAdult);
                    }

                    if (paxObj.type === "Infant") {
                        let associatePaxIndex = paxObj.associatedAdult - 1; //Before paxRowNo start from 0 now start from 1 so reduce minus 1 from index to get paxUpdateForm obj
                        if (paxObj.associatedAdult !== undefined || paxObj.associatedAdult !== null || paxObj.associatedAdult !== '') {
                            let str = paxObj.firstName + paxObj.lastName + this.paxUpdateform.value.paxInfo[associatePaxIndex].firstName + this.paxUpdateform.value.paxInfo[associatePaxIndex].lastName;
                            let strLength: number = str.length;
                            if (paxObj.dob != undefined && paxObj.dob != "") {
                                strLength += 8;
                            }
                            if (this.paxUpdateform.value.paxInfo[associatePaxIndex].dob != undefined && this.paxUpdateform.value.paxInfo[associatePaxIndex].dob != "") {
                                strLength += 8;
                            }
                            if (strLength > GRMS_CONSTANT.INFANT_PASSENGER_NAME_MAX_LEN) {
                                this.snackBar.open("Full name(Infant name + Associated Adult name + Adult DOB + Infant DOB) length of passenger should not be more than " + GRMS_CONSTANT.INFANT_PASSENGER_NAME_MAX_LEN + ", Kindly check for Pax No " + (this.gbRequest.pax.indexOf(paxObj) + 1), "x", { duration: 3000 });
                                return false;
                            }
                        } else {
                            this.snackBar.open(`Select Associated Adult for all Infants `, "X", { duration: 3000 });
                            return false;
                        }
                        if (paxObj.mealPreference !== 'BBML') {
                            this.snackBar.open(`Kindly select baby food for Infant `, "X", { duration: 3000 });
                        }
                    } else {
                        let str = paxObj.firstName + paxObj.lastName;
                        let strLength: number = str.length;
                        if (paxObj.dob != undefined && paxObj.dob != "") {
                            strLength += 8;
                        }
                        if (strLength > GRMS_CONSTANT.ADULT_PASSENGER_NAME_MAX_LEN) {
                            this.snackBar.open("Full name length of passenger should not be more than " + GRMS_CONSTANT.ADULT_PASSENGER_NAME_MAX_LEN + ", Kindly check for Pax No " + (this.gbRequest.pax.indexOf(paxObj) + 1), "x", { duration: 3000 });
                            return false;
                        }
                        if (paxObj.mealPreference == 'BBML') {
                            this.snackBar.open(`Baby food only for Infant, Please change the meals for this ${(this.gbRequest.pax.indexOf(paxObj) + 1)} Pax `, "x", { duration: 3000 });
                        }
                    }


                    //  Full name length Validation Ends
                }
                if (paxObj.email !== null && paxObj.email !== undefined && paxObj.email !== "") {
                    if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(paxObj.email)) {
                        this.snackBar.open("Kindly enter valid email ID for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    }
                }
                if (paxObj.contactNo !== null && paxObj.contactNo !== undefined && paxObj.contactNo !== "") {
                    if (!/^\d{10}$/.test(paxObj.contactNo)) {
                        this.snackBar.open("Kindly enter valid contact number for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else {
                        cnumCount++;
                    }
                }
                if (cnumCount > 5) {
                    this.snackBar.open("You can't add more than 5 contact numbers.", "X", { duration: 3000 });
                    return false;
                }

                if (paxObj.isgl === "Y") {
                    if (!/^\d{10}$/.test(paxObj.contactNo)) {
                        this.snackBar.open("Kindly enter valid contact number for Pax No " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    }
                }

                if (!this.dataRequest.isInternational && paxObj.type === "Adult") {
                    if (paxObj.dob) {
                        for (let paxObj of this.paxUpdateform.value.paxInfo) {
                            if (paxObj.type === "Adult") {
                                paxObj.dob = "";
                            }
                        }
                        this.snackBar.open("Date Of Birth is not required for an Adult. Kindly click on Update Pax Details once again", 'X', { duration: 3000 });
                        return;
                    }
                }

                if (!!this.dataRequest.isInternational && paxObj.docdetails == true) {
                    paxObj.doctype = "P";
                    if (!paxObj.gender) {
                        this.snackBar.open("Kindly select Gender for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.dobdoc) {
                        this.snackBar.open(`Kindly enter Date Of Birth for Pax No  ${(this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1)}`, "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.nationality) {
                        this.snackBar.open("Kindly select Nationality for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.doctype) {
                        this.snackBar.open("Kindly select Document Type for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.docnumber) {
                        this.snackBar.open("Kindly enter Document Number for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!/^[0-9a-zA-Z ]+$/.test(paxObj.docnumber)) {
                        this.snackBar.open("Kindly enter valid Document Number for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.issuecountry) {
                        this.snackBar.open("Kindly select Issue Country for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (!paxObj.docexpiry) {
                        this.snackBar.open("Kindly fill Document Expiry Date or valid Document Expiry Date for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "x", { duration: 3000 });
                        return false;
                    } else if (paxObj.type === "Child" || paxObj.type === "Infant") {
                        let differenceOfDays = GrmsDiffCals.findDayDifference(paxObj.dob, paxObj.dobdoc);
                        if (differenceOfDays != 0) {
                            this.snackBar.open("API DOB and Pax DOB should be same for Pax No  " + (this.paxUpdateform.value.paxInfo.indexOf(paxObj) + 1), "X", { duration: 3000 });
                            return false;
                        }
                    }
                } else {
                    paxObj['docdetails'] = false;
                    if (!!this.isInternational) {
                        this.paxUpdateform.controls['paxInfo']['controls'][i].controls['docdetails'].setValue(false);
                        for (let field in this.Inter) {
                            if (!!this.paxUpdateform.controls['paxInfo']['controls'][i].controls[field]) {
                                this.paxUpdateform.controls['paxInfo']['controls'][i].controls[field].setValidators(null);
                                this.paxUpdateform.controls['paxInfo']['controls'][i].controls[field].setValue(null);
                                this.paxUpdateform.controls['paxInfo']['controls'][i].controls[field].setErrors(null);
                            }
                        }
                    }

                }
            }

            if (adult > this.dataRequest.requestgeneralData.noOfAdult) {
                this.snackBar.open("Only " + this.dataRequest.requestgeneralData.noOfAdult + " adult are allowed", 'X', { duration: 3000 });
                return false;
            } else if (child > this.dataRequest.requestgeneralData.noOfChild) {
                this.snackBar.open("Only " + this.dataRequest.requestgeneralData.noOfChild + " child are allowed", 'X', { duration: 3000 });
                return false;
            } else if (infant > this.dataRequest.requestgeneralData.noOfInfant) {
                this.snackBar.open("Only " + this.dataRequest.requestgeneralData.noOfInfant + " infant are allowed", 'X', { duration: 3000 });
                return false;
            }
            if (checkPaxCount) {
                if (adult < this.dataRequest.requestgeneralData.noOfAdult) {
                    this.snackBar.open("Kindly enter " + this.dataRequest.requestgeneralData.noOfAdult + " adult", 'X', { duration: 3000 });
                    return false;
                } else if (child < this.dataRequest.requestgeneralData.noOfChild) {
                    this.snackBar.open("Kindly enter " + this.dataRequest.requestgeneralData.noOfChild + " child", 'X', { duration: 3000 });
                    return false;
                } else if (infant < this.dataRequest.requestgeneralData.noOfInfant) {
                    this.snackBar.open("Kindly enter " + this.dataRequest.requestgeneralData.noOfInfant + " infant", 'X', { duration: 3000 });
                    return false;
                }
            }
        } else {
            this.snackBar.open("Kindly enter atleast one passenger to update pax details", 'X', { duration: 3000 });
            return false;
        }
        return true;
    }

    paxUpdateSubmit() {
        let validationCheck = this.checkValidPaxUpdate(this.paxUpdateform.value.paxInfo, false);
        if (!!validationCheck) {
            if (!this.paxUpdateform.valid) {
                for (let j = 0; j < this.paxUpdateform.value.paxInfo.length; j++) {
                    if (this.paxUpdateform.controls['paxInfo']['controls'][j]['status'] == "INVALID") {
                        this.snackBar.open(`Kindly enter valid input for this pax ${j + 1}`, "X", { duration: 3000 });
                        this.paxUpdateform.markAsTouched()
                        break;
                    }
                }
            }
            else {
                this.gbRequest.pax = this.paxUpdateform.value.paxInfo;
                for (let i = 0; i < this.gbRequest.pax.length; i++) {
                    if (i == 0) {
                        this.gbRequest.pax[i]['isGroupLeader'] = 'Y';
                    } else {
                        this.gbRequest.pax[i]['isGroupLeader'] = 'N';

                    }
                    if (!this.gbRequest.pax[i]['associatedAdult']) {
                        delete this.gbRequest.pax[i]['associatedAdult'];
                    }
                    if (!!this.isInternational) {
                        this.gbRequest.pax[i]['docdetails'] = this.gbRequest.pax[i].docdetails == true ? true : false;
                        this.gbRequest.pax[i]['dobdoc'] = this.datePipe.transform(this.gbRequest.pax[i]['dobdoc'], "dd-MMM-yyyy")
                        this.gbRequest.pax[i]['docexpiry'] = this.datePipe.transform(this.gbRequest.pax[i]['docexpiry'], "dd-MMM-yyyy")
                    } else {
                        this.gbRequest.pax[i]['docdetails'] = false;
                    }
                    this.gbRequest.pax[i]['paxRowNo'] = i + 1;
                    delete this.gbRequest.pax[i]['action'];
                    this.gbRequest.pax[i]['dob'] = this.datePipe.transform(this.gbRequest.pax[i]['dob'], "dd-MMM-yyyy")
                }
                this.selectedPaxInfo.emit(this.gbRequest.pax);
            }
        }
    }

    confirmSubmit(): void {
        let validationCheck = this.checkValidPaxUpdate(this.paxUpdateform.value.paxInfo, true);
        if (!!validationCheck) {
            if (!this.paxUpdateform.valid) {
                for (let j = 0; j < this.paxUpdateform.value.paxInfo.length; j++) {
                    if (this.paxUpdateform.controls['paxInfo']['controls'][j]['status'] == "INVALID") {
                        this.snackBar.open("Kindly enter valid input for this pax " + j + 1, 'x', { duration: 3000 });
                        break;
                    }

                }
                this.paxUpdateform.markAsTouched()
            }
            else {
                this.gbRequest.pax = this.paxUpdateform.value.paxInfo;
                for (let i = 0; i < this.gbRequest.pax.length; i++) {
                    if (i == 0) {
                        this.gbRequest.pax[i]['isGroupLeader'] = 'Y';
                    } else {
                        this.gbRequest.pax[i]['isGroupLeader'] = 'N';
                    }
                    if (!this.gbRequest.pax[i]['associatedAdult']) {
                        delete this.gbRequest.pax[i]['associatedAdult'];
                    }
                    if (!!this.isInternational) {
                        this.gbRequest.pax[i]['docdetails'] = this.gbRequest.pax[i].docdetails == true ? true : false;
                        this.gbRequest.pax[i]['dobdoc'] = this.datePipe.transform(this.gbRequest.pax[i]['dobdoc'], "dd-MMM-yyyy")
                        this.gbRequest.pax[i]['docexpiry'] = this.datePipe.transform(this.gbRequest.pax[i]['docexpiry'], "dd-MMM-yyyy")
                    } else {
                        this.gbRequest.pax[i]['docdetails'] = false;
                    }
                    this.gbRequest.pax[i]['paxRowNo'] = i + 1;
                    this.gbRequest.pax[i]['ticketNumber'] = '';
                    this.gbRequest.pax[i]['dob'] = this.datePipe.transform(this.gbRequest.pax[i]['dob'], "dd-MMM-yyyy")
                    delete this.gbRequest.pax[i]['action'];
                }
                this.confirmPaxInfo.emit(this.gbRequest.pax);
            }
        }
    }
    generatePnr() {
        this.createPnr.emit('')
    }

    StatusAdded(el, i) {
        let adult: number = 0;
        let child: number = 0;
        let infant: number = 0;
        for (let i = 0; i < this.paxUpdateform.value.paxInfo.length; i++) {
            if (this.paxUpdateform.value.paxInfo[i].type == "Adult" && this.paxUpdateform.value.paxInfo[i].deleted == "NO") {
                adult++;
            } else if (this.paxUpdateform.value.paxInfo[i].type == 'Child' && this.paxUpdateform.value.paxInfo[i].deleted == "NO") {
                child++;
            } else if (this.paxUpdateform.value.paxInfo[i].type == 'Infant' && this.paxUpdateform.value.paxInfo[i].deleted == "NO") {
                infant++;
            }
        }


        if (this.dataRequest.requestgeneralData.noOfAdult < adult && el.value.type == 'Adult') {
            this.paxUpdateform.controls['paxInfo']['controls'][i]['controls']['deleted'].setValue('YES')
        } else if (this.dataRequest.requestgeneralData.noOfChild < child && el.value.type == 'Child') {
            this.paxUpdateform.controls['paxInfo']['controls'][i]['controls']['deleted'].setValue('YES')
        } else if (this.dataRequest.requestgeneralData.noOfInfant < infant && el.value.type == 'Infant') {
            if (this.paxUpdateform.value.paxInfo[i].associatedAdult > 0 && this.paxUpdateform.value.paxInfo[this.paxUpdateform.value.paxInfo[i].associatedAdult].deleted == "NO") {
                this.snackBar.open("Please delete associated adult before deleting Infant", 'x', { duration: 3000 })
                return;
            } else {
                this.paxUpdateform.controls['paxInfo']['controls'][i]['controls']['deleted'].setValue('YES')
            }

        } else {
            this.snackBar.open("Can't able to delete, check adult or child or infant count ", 'X', { duration: 3000 })
        }

    }

    paxDownSize() {
        let isGroupLeader = false;
        this.selection.selected.forEach((paxInfo) => {
            let paxObj = paxInfo.value;
            if (paxObj.type === "Adult" && paxObj.isGroupLeader === 'Y') {
                isGroupLeader = true;
                this.snackBar.open('Pax group leader cannot not be delete', 'x', { duration: 3000 });

            }
        });
        if (!isGroupLeader) {
            if (this.selection.selected.length > 0) {
                const dialogRef = this.matDialog.open(CommanModalComponent, {
                    width: '44em',
                    height: '13em',
                    disableClose: true,
                    data: { isPaxDelete: true }
                });

                dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
                    if (result?.data.action === "yes") {
                        let adultMinus = 0;
                        let childMinus = 0;
                        let infantMinus = 0;
                        this.selection.selected.forEach((paxInfo) => {
                            let paxObj = paxInfo.value
                            if (paxObj.type === "Adult") {
                                let paxRowNo = paxObj.paxRowNo + 1;
                                this.dataRequest.paxDetails.forEach((obj, index) => {
                                    if (obj.type === "Infant" && obj.associatedAdult === paxRowNo) {
                                        obj['deleted'] = 'YES';
                                        infantMinus++;
                                    }
                                    if (paxObj.paxRowNo === index) {
                                        adultMinus++;
                                        obj['deleted'] = 'YES';
                                    }
                                });

                            } else if (paxObj.type === "Infant") {
                                this.dataRequest.paxDetails.forEach((obj, index) => {
                                    if (obj.type === "Adult" && obj.paxRowNo === paxObj.associatedAdult) {
                                        obj['deleted'] = 'YES';
                                        adultMinus++;
                                    }
                                    if (paxObj.paxRowNo === index) {
                                        infantMinus++;
                                        obj['deleted'] = 'YES';
                                    }
                                });
                            } else {
                                this.dataRequest.paxDetails.forEach((obj, index) => {
                                    if (paxObj.paxRowNo === index) {
                                        childMinus++;
                                        obj['deleted'] = 'YES';
                                    }
                                });
                            }
                        });

                        this.dataRequest.requestgeneralData['noOfAdult'] = this.totalAdult - adultMinus;
                        this.dataRequest.requestgeneralData['noOfChild'] = this.totalChild - childMinus;
                        this.dataRequest.requestgeneralData['noOfInfant'] = this.totalInfant - infantMinus;

                        let downSizeData = {
                            paxDetail: this.dataRequest.paxDetails,
                            requestDetails: this.dataRequest,
                            eventName: 'AfterConfirmDownSize'
                        }
                        this.paxDownSizeChange.emit(downSizeData)
                    }
                });
            } else {
                this.snackBar.open("Please select atleat one passenger to downsize", "x", { duration: 3000 });
                return;
            }
        } else {
            return false;
        }

    }

    checkDuplicateAssociate(e, paxIndex) {
        let paxIndexVal = paxIndex + 1;
        let selectedAssociateIndex = this.alreadySelectedAssociate.findIndex(obj => obj.infantCode === paxIndexVal);
        let duplicateAssociateIndex = this.alreadySelectedAssociate.findIndex(obj => obj.associateCode === e.value);
        if (duplicateAssociateIndex > -1) {
            this.isNotValidAssociate = true;
            if (selectedAssociateIndex > -1) {
                this.alreadySelectedAssociate.splice(selectedAssociateIndex, 1);
            }
            this.paxUpdateform.value.paxInfo[paxIndex].associatedAdult = undefined;
            this.snackBar.open("Selected associate adult already assigned to other Infant, please select other Adult in row :" + paxIndexVal, "x", { duration: 3000 });
            return;
        } else {
            this.isNotValidAssociate = false;
            if (selectedAssociateIndex > -1) {
                this.alreadySelectedAssociate.splice(selectedAssociateIndex, 1);
            }
            this.alreadySelectedAssociate.push({ "infantCode": paxIndexVal, "associateCode": e.value });
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
