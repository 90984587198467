import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from '../../shared/httpService/http.service';
import { LOGIN_API_ENDPOINTS } from 'src/app/shared/httpService/apiURLConstant';
import { GrmsUtils } from '../../shared/static/GRMSUtils';
import { LoaderService } from '../../shared/services/gb-loader.services';
import { SessionService } from 'src/app/shared/services/session.service';


@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {


    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private router: Router,
        private grmsUtils: GrmsUtils,
        private httpService: HttpService,
        private loaderService: LoaderService,
        private cookieService: CookieService,
        private sessionService: SessionService,
    ) { }

    ngOnInit(): void {
        this.loaderService.show();
        this.httpService.getCall(LOGIN_API_ENDPOINTS.logout, this.grmsUtils.getAuthHeader())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    this.loaderService.hide();
                    sessionStorage.removeItem('auth_token');
                    sessionStorage.removeItem('login_type');
                    this.sessionService.clearSessionTimeout();
                    this.cookieService.delete('auth_token', '/');
                    this.cookieService.delete('login_time', '/');
                    this.cookieService.delete('userDetails', '/');
                    this.cookieService.delete('refresh_token', '/');
                    this.cookieService.delete('currentMenu', '/');
                    this.router.navigate(['/']);
                },
                error: (errorDetails) => {
                    this.grmsUtils.errorHandling(errorDetails);
                }
            });
    }

}
